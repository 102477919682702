import React from 'react';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator } from 'react-tabulator';

import { v4 as uuidv4 } from 'uuid';

import { Combobox } from "./Combobox";
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";

import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { TextField, FormControlLabel, Checkbox } from '@mui/material';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Global } from "./Global"
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

export class ServisVyjezdPraceForm extends React.Component {
    constructor(props) {
        super(props);

        if (props.dataObject) this.state = {
            sepr_id: props.dataObject.sepr_id,
            sepr_poznamka: props.dataObject.sepr_poznamka,
            sepr_datum: new Date(props.dataObject.sepr_datum),
            spp_idU1: Global.JeGuidValid(props.dataObject.spp_parent_id) ? props.dataObject.spp_parent_id : props.dataObject.spp_id,
            spp_idU2: Global.JeGuidValid(props.dataObject.spp_parent_id) ? props.dataObject.spp_id : Global.GuidEmpty,

            spp_popis: props.dataObject.spp_popis,
            spp_poznamka: props.dataObject.spp_poznamka,
            spp_skupina: props.dataObject.spp_skupina,
            spp_JeNadrazeny: props.dataObject.spp_JeNadrazeny,
            spp_popis_aj: props.dataObject.spp_popis_aj,
            spp_parent_id: props.dataObject.spp_parent_id,
            sepr_offlineStav: props.dataObject.sepr_offlineStav,

            bNovy: false,
            bHromadneZadani: false,
            dataPraceCiselnikU1: null,
            dataPraceCiselnikU2: null
        }
        else this.state = {
            sepr_id: Global.GuidEmpty,
            sepr_poznamka: "",
            sepr_datum: new Date(),
            spp_idU1: Global.GuidEmpty,
            spp_idU2: Global.GuidEmpty,

            spp_popis: "",
            spp_poznamka: "",
            spp_skupina: 0,
            spp_JeNadrazeny: false,
            spp_popis_aj: "",
            spp_parent_id: Global.GuidEmpty,
            sepr_offlineStav: 0,

            bNovy: true,
            bHromadneZadani: false,
            dataPraceCiselnikU1: null,
            dataPraceCiselnikU2: null
        }

        this.tabServZarizeni = React.createRef();

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
        this.razeniCiselnikPrace = this.razeniCiselnikPrace.bind(this);
        this.onFiltrHromadneZadaniChecked = this.onFiltrHromadneZadaniChecked.bind(this);
    }

    onParamaterChange(event) {
        if (event.target.name == "sepr_poznamka") this.setState({ sepr_poznamka: event.target.value });
        else if (event.target.name == "spp_idU1") {
            let spp_idU2New = this.state.spp_idU2;
            let spp_popisNew = this.state.spp_popis;
            let spp_poznamkaNew = this.state.spp_poznamka;
            let spp_skupinaNew = this.state.spp_skupina;
            let spp_JeNadrazenyNew = this.state.spp_JeNadrazeny;
            let spp_popis_ajNew = this.state.spp_popis_aj;
            let spp_parent_idNew = this.state.spp_parent_id;

            if (!event.objectSelected.spp_podrazene.find(z => z.id == spp_idU2New)) {
                if (event.objectSelected.spp_podrazene.length > 0) {
                    spp_idU2New = event.objectSelected.spp_podrazene[0].id;
                    spp_popisNew = event.objectSelected.spp_podrazene[0].spp_popis;
                    spp_poznamkaNew = event.objectSelected.spp_podrazene[0].spp_poznamka;
                    spp_skupinaNew = event.objectSelected.spp_podrazene[0].spp_skupina;
                    spp_JeNadrazenyNew = event.objectSelected.spp_podrazene[0].spp_JeNadrazeny;
                    spp_popis_ajNew = event.objectSelected.spp_podrazene[0].spp_popis_aj;
                    spp_parent_idNew = event.objectSelected.spp_podrazene[0].spp_parent_id;
                }
                else {
                    spp_idU2New = Global.GuidEmpty;
                    spp_popisNew = event.objectSelected.spp_popis;
                    spp_poznamkaNew = event.objectSelected.spp_poznamka;
                    spp_skupinaNew = event.objectSelected.spp_skupina;
                    spp_JeNadrazenyNew = event.objectSelected.spp_JeNadrazeny;
                    spp_popis_ajNew = event.objectSelected.spp_popis_aj;
                    spp_parent_idNew = event.objectSelected.spp_parent_id;
                }
            }

            const ciselnikU2 = event.objectSelected.spp_podrazene;
            if (ciselnikU2) ciselnikU2.sort(this.razeniCiselnikPrace);

            this.setState({
                sepr_poznamka: spp_poznamkaNew,
                spp_idU1: event.target.value,
                spp_idU2: spp_idU2New,

                spp_popis: spp_popisNew,
                spp_poznamka: spp_poznamkaNew,
                spp_skupina: spp_skupinaNew,
                spp_JeNadrazeny: spp_JeNadrazenyNew,
                spp_popis_aj: spp_popis_ajNew,
                spp_parent_id: spp_parent_idNew,

                dataPraceCiselnikU2: ciselnikU2
            });
        }
        else if (event.target.name == "spp_idU2") {
            this.setState({
                sepr_poznamka: event.objectSelected.spp_poznamka,
                spp_idU2: event.target.value,

                spp_popis: event.objectSelected.spp_popis,
                spp_poznamka: event.objectSelected.spp_poznamka,
                spp_skupina: event.objectSelected.spp_skupina,
                spp_JeNadrazeny: event.objectSelected.spp_JeNadrazeny,
                spp_popis_aj: event.objectSelected.spp_popis_aj,
                spp_parent_id: event.objectSelected.spp_parent_id
            });
        }
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();

        const zarizeniSevy = this.props.sevyData.servisZarizeni.find(v => v.seve_zarizeni.vent_id == this.props.zarizeni.vent_id);
        if (zarizeniSevy == null) this.zavritDialog();
    }

    razeniCiselnikPrace(a, b) {
        if (a.spp_popis == "Nezadáno") return -1;
        if (b.spp_popis == "Nezadáno") return 1;

        return a.spp_popis.localeCompare(b.spp_popis);
    }

    inicializace() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisPraceCiselnik() : fetchWrapper.VratServisPraceCiselnikOffline())
            .then(data => {

                data.sort(this.razeniCiselnikPrace);

                const vybranySel = data.find(z => z.id == this.state.spp_idU1);

                if (vybranySel) {
                    const ciselnikU2 = vybranySel.spp_podrazene;
                    if (ciselnikU2) ciselnikU2.sort(this.razeniCiselnikPrace);

                    this.setState({ dataPraceCiselnikU1: data, dataPraceCiselnikU2: ciselnikU2 });
                }
                else if (this.state.spp_idU1 == Global.GuidEmpty && data.length > 0) {
                    let spp_idU2New = Global.GuidEmpty;
                    let spp_popisNew = "";
                    let spp_poznamkaNew = "";
                    let spp_skupinaNew = 0;
                    let spp_JeNadrazenyNew = false;
                    let spp_popis_ajNew = "";
                    let spp_parent_idNew = Global.GuidEmpty;

                    if (data[0].spp_podrazene && data[0].spp_podrazene.length > 0) {
                        spp_idU2New = data[0].spp_podrazene[0].id;
                        spp_popisNew = data[0].spp_podrazene[0].spp_popis;
                        spp_poznamkaNew = data[0].spp_podrazene[0].spp_poznamka;
                        spp_skupinaNew = data[0].spp_podrazene[0].spp_skupina;
                        spp_JeNadrazenyNew = data[0].spp_podrazene[0].spp_JeNadrazeny;
                        spp_popis_ajNew = data[0].spp_podrazene[0].spp_popis_aj;
                        spp_parent_idNew = data[0].spp_podrazene[0].spp_parent_id;
                    }
                    else {
                        spp_idU2New = Global.GuidEmpty;
                        spp_popisNew = data[0].spp_popis;
                        spp_poznamkaNew = data[0].spp_poznamka;
                        spp_skupinaNew = data[0].spp_skupina;
                        spp_JeNadrazenyNew = data[0].spp_JeNadrazeny;
                        spp_popis_ajNew = data[0].spp_popis_aj;
                        spp_parent_idNew = data[0].spp_parent_id;
                    }

                    const ciselnikU2 = data[0].spp_podrazene;
                    if (ciselnikU2) ciselnikU2.sort(this.razeniCiselnikPrace);

                    this.setState({
                        sepr_poznamka: spp_poznamkaNew,
                        spp_idU1: data[0].id,
                        spp_idU2: spp_idU2New,

                        spp_popis: spp_popisNew,
                        spp_poznamka: spp_poznamkaNew,
                        spp_skupina: spp_skupinaNew,
                        spp_JeNadrazeny: spp_JeNadrazenyNew,
                        spp_popis_aj: spp_popis_ajNew,
                        spp_parent_id: spp_parent_idNew,

                        dataPraceCiselnikU1: data,
                        dataPraceCiselnikU2: ciselnikU2
                    });
                }
                else this.setState({ dataPraceCiselnikU1: data, dataPraceCiselnikU2: [] });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník prací: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    formSubmit(event) {
        event.preventDefault();

        let chyba = "";
        const spp_id = (this.state.dataPraceCiselnikU2 && this.state.dataPraceCiselnikU2.length > 0) ? this.state.spp_idU2 : this.state.spp_idU1;
        if (!Global.JeGuidValid(spp_id)) {
            chyba += "Vyberte popis práce z číselníku. ";
        }

        let vybranaServisZarizeni = null;
        if (this.state.bHromadneZadani) {
            vybranaServisZarizeni = this.tabServZarizeni.current.getSelectedData();

            if (!vybranaServisZarizeni || vybranaServisZarizeni.length <= 0) {
                chyba += "Nebylo vybrané žádné servisované zařízení. ";
            }
        }

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }


        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const dataObject = {
            sepr_id: (!jeOfflineRezim || (jeOfflineRezim && !this.state.bNovy)) ? this.state.sepr_id : uuidv4(),
            sepr_poznamka: Global.textAreaOpravaPredUlozeni(this.state.sepr_poznamka),
            vent_id: this.props.zarizeni.vent_id,
            sepr_datum: Global.toISOString(this.state.sepr_datum),
            sevy_id: this.props.sevyData.sevy_id,
            spp_id: spp_id,
            spp_popis: this.state.spp_popis,
            spp_poznamka: this.state.spp_poznamka,
            spp_skupina: this.state.spp_skupina,
            spp_JeNadrazeny: this.state.spp_JeNadrazeny,
            spp_popis_aj: this.state.spp_popis_aj,
            spp_parent_id: this.state.spp_parent_id,
            servisZarizeni: vybranaServisZarizeni,
            sepr_offlineStav: this.state.sepr_offlineStav
        }

        if (this.state.bNovy) {
            LoadingSpinner.open();

            if (this.state.bHromadneZadani) {
                fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisPraceHromadne() : fetchWrapper.NovyServisPraceHromadneOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
            else {
                fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisPrace() : fetchWrapper.NovyServisPraceOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
        else {
            LoadingSpinner.open();

            if (this.state.bHromadneZadani) {
                fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisPraceHromadne() : fetchWrapper.EditServisPraceHromadneOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
            else {
                fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisPrace() : fetchWrapper.EditServisPraceOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false, null);
    }

    onFiltrHromadneZadaniChecked() {
        const novyStav = !this.state.bHromadneZadani;

        this.setState({ bHromadneZadani: novyStav });
    }

    render() {

        console.log("KEYS");
        console.log(this.state.dataPraceCiselnikU1);
        console.log(this.state.dataPraceCiselnikU2);

        const tabServZarizeniColumns = [
            {
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerHozAlign: "center", headerSort: false, cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                }
            },
            {
                field: 'seve_zarizeni.vent_typ', title: 'Typ zařízení', width: 200, headerSort: true, formatter: function (cell, formatterParams, onRendered) {
                    if (cell.getValue() == 0) return "Ventilátor";
                    else return "Jiné";
                }
            },
            { field: 'seve_zarizeni.vent_vyrobni_cislo', title: 'Výr.číslo', width: 100, sorter: "number", headerSort: true },
            { field: 'seve_zarizeni.vent_popis', title: 'Typové označení', width: 400, headerSort: true },
            { field: 'seve_zarizeni.vent_popis_zarizeni', title: 'Popis zařízení', width: 400, headerSort: true },
            { field: 'seve_popis', title: 'Poznámka', width: 400, headerSort: true }
        ];

        return (
            <form onSubmit={this.formSubmit}>
                <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                    <h1>Servisní práce - {this.state.bNovy ? "nový záznam" : "upravit záznam"}</h1>

                    <div className="form_udaj_container">
                        <div className="left" style={{ width: 120 }}>Vybrané zařízení: </div>
                        <div className="righttext">
                            {this.state.bHromadneZadani ? "Všechna zařízení" : this.props.zarizeni.vent_vyrobni_cislo + " - " + this.props.zarizeni.vent_popis}
                        </div>
                    </div>

                    <div className="form_udaj_container">
                        <div className="left" style={{ width: 120 }}>Práce: </div>
                        <div className="right">
                            {this.state.dataPraceCiselnikU1 ?
                                <Combobox klicID="id" klicPopis="spp_popis" ID={this.state.spp_idU1} name="spp_idU1" data={this.state.dataPraceCiselnikU1} onChange={this.onParamaterChange} />
                                :
                                <img src="./img/loading16.gif" height={30} />}
                            {this.state.dataPraceCiselnikU2 && this.state.dataPraceCiselnikU2.length > 0 && <> <Combobox klicID="id" klicPopis="spp_popis" ID={this.state.spp_idU2} name="spp_idU2" bEmptyOption={false} data={this.state.dataPraceCiselnikU2} onChange={this.onParamaterChange} /></>}
                        </div>
                    </div>

                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.sepr_poznamka} widthLeft={120} typ="TEXTAREA" onChange={e => this.setState({ sepr_poznamka: e.target.value })} />
                    </div>


                    <div className="form_udaj_container">
                        <div className="left" style={{ width: 120 }}>Datum: </div>
                        <div className="right">
                            <DesktopDatePicker
                                inputFormat="dd.MM.yyyy"
                                value={this.state.sepr_datum}
                                onChange={date => this.setState({ sepr_datum: date })}
                                renderInput={(params) => <TextField {...params} />}
                                inputProps={{
                                    style: {
                                        padding: 5,
                                        width: 90
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="form_udaj_container">
                        <div className="left" style={{ width: 120 }}> </div>
                        <div className="right">
                            <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                            <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                            <FormControlLabel style={{ display: 'inline-flex', marginLeft: 10 }}
                                name="filtrHromadneZadani"
                                control={<Checkbox
                                    checked={this.state.bHromadneZadani}
                                    onClick={this.onFiltrHromadneZadaniChecked} />}
                                label="Hromadný zápis" />
                        </div>
                    </div>

                    <div className="grid_12" style={{ display: this.state.bHromadneZadani ? 'block' : 'none' }}>
                        <h2>Servisovaná zařízení</h2>
                        <ReactTabulator
                            onRef={(r) => (this.tabServZarizeni = r)}
                            data={this.props.sevyData.servisZarizeni}
                            columns={tabServZarizeniColumns}
                            layout={'fitDataStretch'}
                            initialSort={[{ column: "seve_zarizeni.vent_vyrobni_cislo", dir: "asc" }]}
                        />
                    </div>
                    <div className="clear" />
                </LocalizationProvider>
            </form>
        );
    }
}