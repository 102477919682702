import React from 'react';
import { fetchWrapper } from "../FetchWrapper";
import CacheData from "../CacheData"
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator } from 'react-tabulator';
import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";
import { ProvozniHodnotyDokument } from "./ProvozniHodnotyDokument";
import { Global } from './Global';

import { FormControlLabel, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import AuthService from "../AuthService";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

export class ZarizeniProvozniHodnotyList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            vybranyZaznamMereni: null,
            zobrazenyPanel: "VYPIS",
            pdfDokumentBlob: null,
            bDleServisVyjezdu: props.bFiltrMereni
        };

        this.tabMereni = React.createRef();

        this.rowClick = this.rowClick.bind(this);
        this.noveMereni = this.noveMereni.bind(this);
        this.vybratVychoziMereni = this.vybratVychoziMereni.bind(this);

        this.novyZaznam = this.novyZaznam.bind(this);
        this.novyZaznamKopie = this.novyZaznamKopie.bind(this);
        this.upravitZaznam = this.upravitZaznam.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
        this.zobrazitPanel = this.zobrazitPanel.bind(this);

        this.onFiltrDleVyjezduChecked = this.onFiltrDleVyjezduChecked.bind(this);

        this.generovatPDFDokument = this.generovatPDFDokument.bind(this);
    }

    componentDidUpdate(prevProps) {

        if (this.props.zarizeniData.vent_id != prevProps.zarizeniData.vent_id) {

            this.setState({ pdfDokumentBlob: null, zobrazenyPanel: "VYPIS" });
            if ((this.state.bDleServisVyjezdu && this.props.dataVyjezdu.length == 0)
                || (!this.state.bDleServisVyjezdu && this.props.data.length == 0)) this.setState({ vybranyZaznamMereni: null });
        }
    }

    rowClick = (e, row) => {
        this.setState({ vybranyZaznamMereni: row.getData(), pdfDokumentBlob: null, zobrazenyPanel: "VYPIS" });
    };

    noveMereni() {
        this.props.otevritDialogZaznam("PROVHODN");
    }

    vybratVychoziMereni() {
        if (this.tabMereni.current.getRows().length > 0) {
            let row = this.tabMereni.current.getRows()[0];

            this.tabMereni.current.selectRow(row);
            this.setState({ vybranyZaznamMereni: row.getData(), pdfDokumentBlob: null, zobrazenyPanel: "VYPIS" });
        }
        else this.setState({ vybranyZaznamMereni: null, pdfDokumentBlob: null });
    }

    novyZaznam = async (e, cell) => {
        this.props.otevritDialogZaznam("PROVHODN");
    }

    novyZaznamKopie(e, cell) {
        this.props.otevritDialogZaznamKopie("PROVHODN", cell.getRow().getData());
    }

    upravitZaznam = async (e, cell) => {
        const zaznamMereni = cell.getRow().getData();
        if (zaznamMereni.sevy_id != this.props.sevyData.sevy_id) {

            await MessageBox.open({
                title: "Chyba",
                content: <p>Nelze upravit záznam měření z jiného servisního výjezdu.</p>,
                buttons: [
                    { name: "OK", handler: () => "ok" }
                ]
            });

            return;
        }

        this.props.otevritDialogZaznam("PROVHODN", zaznamMereni);
    }

    smazatZaznam = async (e, cell) => {
        const zaznamMereni = cell.getRow().getData();
        if (zaznamMereni.sevy_id != this.props.sevyData.sevy_id) {

            await MessageBox.open({
                title: "Chyba",
                content: <p>Nelze smazat záznam měření z jiného servisního výjezdu.</p>,
                buttons: [
                    { name: "OK", handler: () => "ok" }
                ]
            });

            return;
        }

        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraný záznam?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") {
            this.setState({ vybranyZaznamMereni: null })
            this.props.smazatZaznam("PROVHODN", zaznamMereni);
        }
    }

    zobrazitPanel(event) {
        event.preventDefault();

        if (event.target.value == "PDF") {
            if (this.state.pdfDokumentBlob) this.setState({ zobrazenyPanel: event.target.value });
            else this.generovatPDFDokument(false);
        }
        else {
            this.setState({ zobrazenyPanel: event.target.value });
        }
    }

    generovatPDFDokument(bReload) {
        this.setState({ zobrazenyPanel: "PDF", pdfDokumentBlob: null });

        LoadingSpinner.open();
        fetchWrapper.fileLoad(fetchWrapper.VratProvozniHodnotyProtokol(this.state.vybranyZaznamMereni.vph_id, bReload))
            .then(fileData => {
                LoadingSpinner.close();

                this.setState({ pdfDokumentBlob: fileData });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se vygenerovat dokument: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    onFiltrDleVyjezduChecked() {
        const novyStav = !this.state.bDleServisVyjezdu;

        if ((novyStav && this.props.dataVyjezdu.length == 0)
            || (!novyStav && this.props.data.length == 0)) this.setState({ bDleServisVyjezdu: novyStav, vybranyZaznamMereni: null });
        else this.setState({ bDleServisVyjezdu: novyStav });
    }

    render() {
        let cellContextMenu = [];

        if (this.props.bAkce) {
            cellContextMenu = [
                {
                    label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                    action: this.novyZaznam
                },
                {
                    label: "<img src='./img/copy.png'/> <div class='customContextmenuArea3ListItem'>Kopie</div>",
                    action: this.novyZaznamKopie
                },
                {
                    label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                    action: this.upravitZaznam
                },
                {
                    label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                    action: this.smazatZaznam
                }
            ];
        }


        const tabMereniColumns = [{
            field: 'vph_datum', title: 'Měření', width: 130, headerSort: true, contextMenu: cellContextMenu,
            formatter: function (cell, formatterParams, onRendered) {

                return Global.toDateTimeStr(new Date(cell.getValue()));
            },
            sorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
                //a, b - the two values being compared
                //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
                //column - the column component for the column being sorted
                //dir - the direction of the sort ("asc" or "desc")
                //sorterParams - sorterParams object from column definition array
                if (new Date(a) < new Date(b)) return -1;
                else if (new Date(a) > new Date(b)) return 1;
                return 0;
            }
        }];

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        return (
            <>
                <div >
                    <div className='grid_12'>
                        <h2>
                            Provozní hodnoty {this.props.bAkce && <button onClick={this.noveMereni} className="form_button" style={{ marginLeft: 10, width: 140 }}>Nové měření</button>}
                            <FormControlLabel style={{ display: !this.props.bFiltrMereni ? 'none' : 'inline-flex', marginLeft: 10 }}
                                name="filtrDleVyjezdu"
                                control={<Checkbox
                                    checked={this.state.bDleServisVyjezdu}
                                    onClick={this.onFiltrDleVyjezduChecked} />}
                                label="Dle aktuálního výjezdu" />
                        </h2>
                    </div>
                    <div className="clear" />

                    <div className='grid_12'>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item style={{ width: 170 }} >
                                    {(this.state.bDleServisVyjezdu && this.props.dataVyjezdu.length > 0)
                                        || (!this.state.bDleServisVyjezdu && this.props.data.length > 0) ?
                                        <ReactTabulator
                                            onRef={(r) => (this.tabMereni = r)}
                                            data={this.state.bDleServisVyjezdu ? this.props.dataVyjezdu : this.props.data}
                                            columns={tabMereniColumns}
                                            layout={'fitDataStretch'}
                                            height={'100%'}
                                            events={{ rowClick: this.rowClick, renderComplete: this.vybratVychoziMereni }}
                                            style={{ minHeight: 100 }}
                                            selectable={1}
                                        />
                                        :
                                        <div>Žádné záznamy.</div>
                                    }
                                </Grid>
                                <Grid item xs md>
                                    {this.state.vybranyZaznamMereni &&
                                        <div className='grid_12' style={{ marginTop: 10, minWidth: 300 }}>
                                            {this.props.bAkce &&
                                                <>
                                                    <div className='grid_12' style={{ marginLeft: 0 }}>
                                                        <button className={this.state.zobrazenyPanel == "VYPIS" ? "form_button_sel" : "form_button"} onClick={this.zobrazitPanel} value="VYPIS">Výpis</button>
                                                        {!jeOfflineRezim && <button className={this.state.zobrazenyPanel == "PDF" ? "form_button_sel" : "form_button"} style={{ marginLeft: 5 }} onClick={this.zobrazitPanel} value="PDF">PDF dokument</button>}
                                                        {this.state.zobrazenyPanel == "PDF" && <button className="form_button_img" style={{ marginLeft: 10, backgroundImage: "url(/img/refresh.png)" }} onClick={e => this.generovatPDFDokument(true)}></button>}
                                                    </div>
                                                    <div className="clear" />
                                                </>
                                            }


                                            {this.state.zobrazenyPanel == "VYPIS" &&
                                                <div style={{ border: "solid", borderWidth: 1, borderColor: "#204562", marginTop: 10 }}>

                                                    <div className='grid_5'>
                                                        <ObjektDetailHodnotaRadek popis="Datum a čas:" hodnota={Global.toDateTimeStr(new Date(this.state.vybranyZaznamMereni.vph_datum))} widthLeft={140} />
                                                    </div>
                                                    <div className='grid_4'>
                                                        <ObjektDetailHodnotaRadek popis="Místo:" hodnota={Global.EnumMistoMereni(this.state.vybranyZaznamMereni.vph_misto)} widthLeft={140} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className='grid_12'>
                                                        <ObjektDetailHodnotaRadek popis="Kontroloval:" hodnota={this.state.vybranyZaznamMereni.vph_kontroloval_PrijmeniJmeno} widthLeft={140} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className='grid_5'>
                                                        <ObjektDetailHodnotaRadek popis="Typ uložení:" hodnota={this.state.vybranyZaznamMereni.vph_typ_ulozeni} widthLeft={140} />
                                                    </div>
                                                    <div className='grid_4'>
                                                        <ObjektDetailHodnotaRadek popis="Uspořádání:" hodnota={this.state.vybranyZaznamMereni.vent_usporadani} widthLeft={140} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className='grid_5'>
                                                        <ObjektDetailHodnotaRadek popis="Zkušební otáčky [RPM]:" hodnota={this.state.vybranyZaznamMereni.vph_zkusebni_otacky} widthLeft={140} />
                                                    </div>
                                                    <div className='grid_7'>

                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12 odsazeni_top10 odsazeni_bottom10" style={{ backgroundColor: "grey", height: 1 }} >
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <div className="grid_6">
                                                            <ObjektDetailHodnotaRadek popis={<span style={{ fontWeight: "bold" }}>Ložisko 1</span>} hodnota="" widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Teplota [°C]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_1_teplota} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis={"Zrychlení [" + this.state.vybranyZaznamMereni.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vybranyZaznamMereni.vph_lozisko_1_zrychleni} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_horizont1_vibrace} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_vert1_vibrace} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_axial1_vibrace} widthLeft={200} />
                                                        </div>
                                                        <div className="grid_6">
                                                            <ObjektDetailHodnotaRadek popis={<span style={{ fontWeight: "bold" }}>Ložisko 2</span>} hodnota="" widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Teplota [°C]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_2_teplota} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis={"Zrychlení [" + this.state.vybranyZaznamMereni.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vybranyZaznamMereni.vph_lozisko_2_zrychleni} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_horizont2_vibrace} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_vert2_vibrace} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_axial2_vibrace} widthLeft={200} />
                                                        </div>
                                                        <div className="clear" />

                                                        {(Global.VratEnumUsporadani(this.props.zarizeniData.vent_usporadani) == 0 || Global.VratEnumUsporadani(this.props.zarizeniData.vent_usporadani) == 1) &&
                                                            <div className="odsazeni_top10">
                                                                <div className="grid_6">
                                                                    <ObjektDetailHodnotaRadek popis={<span style={{ fontWeight: "bold" }}>Ložisko 3</span>} hodnota="" widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Teplota [°C]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_3_teplota} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis={"Zrychlení [" + this.state.vybranyZaznamMereni.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vybranyZaznamMereni.vph_lozisko_3_zrychleni} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_horizont3_vibrace} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_vert3_vibrace} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_axial3_vibrace} widthLeft={200} />
                                                                </div>

                                                                <div className="grid_6">
                                                                    <ObjektDetailHodnotaRadek popis={<span style={{ fontWeight: "bold" }}>Ložisko 4</span>} hodnota="" widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Teplota [°C]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_4_teplota} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis={"Zrychlení [" + this.state.vybranyZaznamMereni.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vybranyZaznamMereni.vph_lozisko_4_zrychleni} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_horizont4_vibrace} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_vert4_vibrace} widthLeft={200} />
                                                                    <ObjektDetailHodnotaRadek popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vybranyZaznamMereni.vph_lozisko_axial4_vibrace} widthLeft={200} />
                                                                </div>
                                                                <div className="clear" />
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12 odsazeni_top10 odsazeni_bottom10" style={{ backgroundColor: "grey", height: 1 }} >
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <div className="grid_6">
                                                            <ObjektDetailHodnotaRadek popis="Napnutí řemene:" hodnota={this.state.vybranyZaznamMereni.vph_napnuti_remene} typ="CHECKBOX" widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Frekvence napnutí řemene [Hz]:" hodnota={this.state.vybranyZaznamMereni.vph_napnuti_remene ? this.state.vybranyZaznamMereni.vph_frekvence_napnuti_remene : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Proudové zatížení motoru:" hodnota={this.state.vybranyZaznamMereni.vph_proudove_zatizeni_motoru} typ="CHECKBOX" widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Sdružené zatížení:" hodnota={this.state.vybranyZaznamMereni.vph_proud_sdruz_zatizeni} typ="CHECKBOX" widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Proud sdruženého zatížení [A]:" hodnota={this.state.vybranyZaznamMereni.vph_proudove_zatizeni_motoru && this.state.vybranyZaznamMereni.vph_proud_sdruz_zatizeni ? this.state.vybranyZaznamMereni.vph_proud_sdruz_zatizeni_hodnota : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Proud fáze U [A]:" hodnota={this.state.vybranyZaznamMereni.vph_proudove_zatizeni_motoru ? this.state.vybranyZaznamMereni.vph_proud_faze_u : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Proud fáze V [A]:" hodnota={this.state.vybranyZaznamMereni.vph_proudove_zatizeni_motoru ? this.state.vybranyZaznamMereni.vph_proud_faze_v : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Proud fáze W [A]:" hodnota={this.state.vybranyZaznamMereni.vph_proudove_zatizeni_motoru ? this.state.vybranyZaznamMereni.vph_proud_faze_w : "-"} widthLeft={200} />

                                                        </div>
                                                        <div className="grid_6">
                                                            <ObjektDetailHodnotaRadek popis="Vyrovnání spojky:" hodnota={this.state.vybranyZaznamMereni.vph_vyrovnani_spojky} typ="CHECKBOX" widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Přesazení os u spojky - X [mm]:" hodnota={this.state.vybranyZaznamMereni.vph_vyrovnani_spojky ? this.state.vybranyZaznamMereni.vph_presazeni_os : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Přesazení os u spojky - Y [mm]:" hodnota={this.state.vybranyZaznamMereni.vph_vyrovnani_spojky ? this.state.vybranyZaznamMereni.vph_presazeni_os_y : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Úhlový zlom u spojky - X [mm]:" hodnota={this.state.vybranyZaznamMereni.vph_vyrovnani_spojky ? this.state.vybranyZaznamMereni.vph_uhlovy_zlom : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Úhlový zlom u spojky - Y [mm]:" hodnota={this.state.vybranyZaznamMereni.vph_vyrovnani_spojky ? this.state.vybranyZaznamMereni.vph_uhlovy_zlom_y : "-"} widthLeft={200} />
                                                            <ObjektDetailHodnotaRadek popis="Mezera u spojky [mm]:" hodnota={this.state.vybranyZaznamMereni.vph_vyrovnani_spojky ? this.state.vybranyZaznamMereni.vph_spojka_mezera : "-"} widthLeft={200} />
                                                        </div>
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12 odsazeni_top10 odsazeni_bottom10">
                                                        <ObjektDetailHodnotaRadek popis="Poznámka:" hodnota={this.state.vybranyZaznamMereni.vph_poznamka} typ="TEXTAREA" widthLeft={60} />
                                                    </div>
                                                    <div className="clear" />

                                                </div>}



                                            {this.props.bAkce && this.state.zobrazenyPanel === "PDF" && this.state.vybranyZaznamMereni && this.state.pdfDokumentBlob &&
                                                <ProvozniHodnotyDokument sevyData={this.props.sevyData} vphData={this.state.vybranyZaznamMereni} dataDokumentPDF={this.state.pdfDokumentBlob} />
                                            }



                                        </div>

                                    }
                                </Grid>
                                <Box width="100%" />
                            </Grid>
                        </Box>
                    </div>
                </div>
                <div className="clear" />
            </>
        );
    }
}