import React from 'react';
import { NavLink } from 'react-router-dom'
import './Navbar.css'

import AuthService from '../../AuthService';
import CacheData from '../../CacheData';

import { fetchWrapper } from "../../FetchWrapper";
import MessageBox from "../MessageBox/MessageBox";
import LoadingSpinner from "../Loader/LoadingSpinner";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNavbar: false,
            vybranyServisniVyjezd: null
        };

        this.handleShowNavbar = this.handleShowNavbar.bind(this);
        this.handleStorage = this.handleStorage.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.aktivniServisVyjezdNaHlavniStranku = this.aktivniServisVyjezdNaHlavniStranku.bind(this);
    }

    /*Router.events.on('routeChangeComplete', (url) => {
  if (window.location.pathname == "/uzivatele")  
  {
    this.setState( {classesUsers: "ic-users current", classesVentilatory: "ic-fans"});
  }
  else if (window.location.pathname == "/zarizeni") 
  {
    this.setState( {classesUsers: "ic-users", classesVentilatory: "ic-fans current"});
  }
});*/

    componentDidMount() {
        this.handleStorage();

        window.addEventListener('sevyStorageUpdate', this.handleStorage);
    }

    componentWillUnmount() {
        window.removeEventListener('sevyStorageUpdate', this.handleStorage)
    }

    handleStorage() {
        let aktualniVyjezd = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        this.setState({ vybranyServisniVyjezd: aktualniVyjezd });
    }

    handleShowNavbar() {
        this.setState({ showNavbar: !this.state.showNavbar })
    }

    reloadData(event) {
        event.preventDefault();

        if (this.state.vybranyServisniVyjezd)
        {
            LoadingSpinner.open();
            fetchWrapper.get(fetchWrapper.VratServisniVyjezd(this.state.vybranyServisniVyjezd.sevy_id))
                .then(dataVyjezd => {
                    LoadingSpinner.close();

                    this.setState({ vybranyServisniVyjezd: dataVyjezd });
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", dataVyjezd);

                    window.dispatchEvent(new Event('reloadData'));
                })
                .catch(error => {
                    LoadingSpinner.close();
                   

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
            
        }
        else window.dispatchEvent(new Event('reloadData'));

    }

    aktivniServisVyjezdNaHlavniStranku() {
        if (this.state.showNavbar) this.handleShowNavbar();

        window.dispatchEvent(new Event('aktivniServisVyjezdNaHlavniStranku'));
    }

    render() {
        const uziv = AuthService.getCurrentUser();
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        if (!uziv) return <></>;

        return (
            <nav className="navbar">
                <div className="container">
                    <div className="logo">

                    </div>

                    {!jeOfflineRezim && <div>
                        <div className="menu-icon" onClick={this.handleShowNavbar}>
                            <img src="./img/toggleMenu.png"></img>
                        </div>
                        <div className={`nav-elements  ${this.state.showNavbar && 'active'}`}>
                            <ul className="nav">
                                <li>
                                    <NavLink to="/" onClick={this.state.showNavbar && this.handleShowNavbar}><img src="./img/vyjezdy.png" /><div className="odkaz_text"> Servisní výjezdy</div></NavLink>                            </li>
                                {uziv && uziv.uziv_role.includes("A") &&
                                    <li>
                                        <NavLink to="/uzivatele" onClick={this.state.showNavbar && this.handleShowNavbar}><img src="./img/users.png" /><div className="odkaz_text"> Administrace uživatelů</div></NavLink>
                                    </li>
                                }
                                <li>
                                    <NavLink to="/zarizeni" onClick={this.state.showNavbar && this.handleShowNavbar}><img src="./img/fans.png" /><div className="odkaz_text"> Zařízení</div></NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>}

                    <div className="menu_vyjezdaktual">
                        <ul className="nav">
                            {this.state.vybranyServisniVyjezd &&
                                <li className="vyjezdaktual">
                                    <NavLink /*reloadDocument*/ to="/servisvyjezd" onClick={this.aktivniServisVyjezdNaHlavniStranku}>
                                        <div className="container_vyjezdaktual">
                                            <div className="container_vyjezdaktual_image"><img src="./img/vyjezdy.png" /></div>
                                            <div className="container_vyjezdaktual_text">Aktuální výjezd:<br />{this.state.vybranyServisniVyjezd.semi_misto.semi_adresa}</div>
                                        </div>
                                    </NavLink>
                                </li>
                            }
                        </ul>
                    </div>

                    {!jeOfflineRezim && < div className="menu_refresh">
                        <ul className="nav">
                            <li>
                                <a onClick={this.reloadData}><img src="./img/refresh.png" /></a>
                            </li>
                        </ul>
                    </div>}
                </div>
            </nav>
        )
    }
}


export default Navbar