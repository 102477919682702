import React from 'react';
import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService";
import CacheData from "../CacheData"

import { EditorHodnotyForm } from "./EditorHodnotyForm"

import { SMapy } from "./SMapy/SMapy"

import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "./MuiTheme";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Radio, RadioGroup, FormControlLabel,  TextField, Autocomplete } from '@mui/material';

import Global from "./Global"
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";


export class ServisMistoForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            semi_id: this.props.mistoData.semi_id,
            semi_kod: "",
            semi_nazev: "",
            semi_ulice: "",
            semi_psc: "",
            semi_adresa: "",
            semi_misto: "",
            semi_stat: "",
            semi_vzdalenost_km: 0,
            semi_gps: "",
            semi_oznaceni_provozu: "",
            semi_mapa_url: "",

            mistoZpusobZadani: 0,
            semiCiselnik: null,
            semi_itemSel: null,

            mistoAdresaMapa: (this.props.mistoData.semi_ulice + ", " + this.props.mistoData.semi_psc + ", " + this.props.mistoData.semi_misto),
            semi_hledana_adresa: "",
            semi_hledana_adresa_stat: "Česká republika"
        }


        this.onServisMistoZpusobZadaniChange = this.onServisMistoZpusobZadaniChange.bind(this);
        this.vytvoritVybratServisMisto = this.vytvoritVybratServisMisto.bind(this);
        this.onServisMistoChange = this.onServisMistoChange.bind(this);
        this.vyhledatServisMisto = this.vyhledatServisMisto.bind(this);
        this.zpracovatDataAdresyZMapy = this.zpracovatDataAdresyZMapy.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
    }

    onServisMistoZpusobZadaniChange(event) {
        this.setState({ mistoZpusobZadani: event.currentTarget.value });
    }

    onServisMistoChange(event, item) {
        const hledanaAdresa = (item.semi_ulice + ", " + item.semi_psc + ", " + item.semi_misto);

        this.setState({
            semi_itemSel: item,
            semi_id: item.semi_id,
            mistoAdresaMapa: hledanaAdresa
        });
    }

    vytvoritVybratServisMisto(event) {
        event.preventDefault();
        let dataObject = null;

        if (this.state.mistoZpusobZadani == 0) {

            let chyba = "";
            if (!this.state.semi_itemSel) chyba += "Není vybráno žádné místo. ";

            if (chyba != "") {
                MessageBox.open({
                    title: "Chyba",
                    content: <p> {chyba}</p>,
                    buttons: [{ name: "OK", handler: () => "OK" }]
                });
                return;
            }

            if (this.state.semi_itemSel.semi_id == this.props.mistoData.semi_id) {
                this.zavritDialog();
                return;
            }

            dataObject = {
                sevy_id: this.props.sevyData.sevy_id,
                semi: this.state.semi_itemSel
            };
        }
        else {
            let chyba = "";
            if (this.state.semi_nazev.trim() == "") chyba += "V adrese není zadán název. ";
            if (this.state.semi_stat.trim() == "") chyba += "V adrese není zadána země. ";
            if (this.state.semi_ulice.trim() == "") chyba += "V adrese není zadána ulice. ";
            if (this.state.semi_misto.trim() == "") chyba += "V adrese není zadáno místo. ";
            if (this.state.semi_psc.trim() == "") chyba += "V adrese není zadáno PSČ. ";

            if (chyba != "") {
                MessageBox.open({
                    title: "Chyba",
                    content: <p> {chyba}</p>,
                    buttons: [{ name: "OK", handler: () => "OK" }]
                });
                return;
            }

            dataObject = {
                sevy_id: this.props.sevyData.sevy_id,
                semi: {
                    semi_id: Global.GuidEmpty,
                    semi_kod: this.state.semi_kod,
                    semi_nazev: this.state.semi_nazev,
                    semi_ulice: this.state.semi_ulice,
                    semi_psc: this.state.semi_psc,
                    semi_misto: this.state.semi_misto,
                    semi_stat: this.state.semi_stat,
                    semi_vzdalenost_km: this.state.semi_vzdalenost_km,
                    semi_gps: this.state.semi_gps,
                    semi_mapa_url: "",
                    semi_oznaceni_provozu: this.state.semi_oznaceni_provozu,
                    semi_adresa: (this.state.semi_nazev + ", " + this.state.semi_ulice + ", " +
                        this.state.semi_psc + ", " + this.state.semi_misto + ", " + this.state.semi_stat)
                }
            };
        }

        LoadingSpinner.open();

        if (this.props.mistoTyp === "SERVIS") {
            fetchWrapper.post(fetchWrapper.NovyServisVyjezdMisto(), dataObject)
                .then(semi_mistoNew => {
                    LoadingSpinner.close();

                    this.props.sevyData.semi_misto = semi_mistoNew.semi;
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);

                    this.props.zavritDialog(true);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (this.props.mistoTyp === "ZAHAJENI") {
            fetchWrapper.post(fetchWrapper.NovyServisVyjezdMistoZahajeni(), dataObject)
                .then(semi_mistoNew => {
                    LoadingSpinner.close();

                    this.props.sevyData.semi_misto_zahajeni = semi_mistoNew.semi;
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);

                    this.props.zavritDialog(true);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (this.props.mistoTyp === "UKONCENI") {
            fetchWrapper.post(fetchWrapper.NovyServisVyjezdMistoUkonceni(), dataObject)
                .then(semi_mistoNew => {
                    LoadingSpinner.close();

                    this.props.sevyData.semi_misto_ukonceni = semi_mistoNew.semi;
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);

                    this.props.zavritDialog(true);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else { console.log(this.props.mistoTyp) }
    }


    componentDidMount() {
        fetchWrapper.get(fetchWrapper.VratServisVyjezdMista())
            .then(data => {
                const semi_itemSelNew = data.find(item => item.semi_id == this.state.semi_id);

                this.setState({ semiCiselnik: data, semi_itemSel: semi_itemSelNew });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník servisních míst: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }

    vyhledatServisMisto() {
        this.setState({ semi_ulice: "", semi_misto: "", semi_stat: "", semi_psc: "", semi_vzdalenost_km: 0, semi_gps: "", mistoAdresaMapa: this.state.semi_hledana_adresa + ", " + this.state.semi_hledana_adresa_stat });
    }

    zpracovatDataAdresyZMapy(adresa) {
        console.log(adresa);
        if (this.state.mistoZpusobZadani == 0) return;

        if (adresa.semi_gps) this.setState({ semi_gps: adresa.semi_gps });

        if (adresa.semi_psc == null) {

        }

        if (adresa.semi_misto) {
            const stat = this.state.semi_hledana_adresa_stat == " " ? adresa.semi_stat : this.state.semi_hledana_adresa_stat;
            this.setState({
                semi_misto: adresa.semi_misto,
                semi_psc: adresa.semi_psc,
                semi_ulice: adresa.semi_ulice,
                semi_stat: stat
            });
        }


        if (adresa.semi_vzdalenost_km) this.setState({ semi_vzdalenost_km: adresa.semi_vzdalenost_km });
    }


    render() {

        return (
            <div className="grid_12">
                {this.props.mistoTyp === "SERVIS" && <h1>Změnit místo servisu</h1>}
                {this.props.mistoTyp === "ZAHAJENI" && <h1>Změnit místo zahájení</h1>}
                {this.props.mistoTyp === "UKONCENI" && <h1>Změnit místo ukončení</h1>}
                <ThemeProvider theme={MuiTheme}>
                    <RadioGroup
                        row
                        value={this.state.mistoZpusobZadani}
                        onChange={(e, value) => this.setState({ mistoZpusobZadani: value })}
                        name="parStav"
                        style={{ marginLeft: 5 }}
                    >
                        <FormControlLabel value="0" control={<Radio />} label="Vybrat existující" />
                        <FormControlLabel value="1" control={<Radio />} label="Zadat nové" />
                    </RadioGroup>


                    <div className="grid_6">
                        <div style={{ display: this.state.mistoZpusobZadani == 0 ? "block" : "none" }}>
                            {this.state.semiCiselnik ?
                                <Autocomplete
                                    disablePortal
                                    value={this.state.semi_itemSel || null}
                                    getOptionLabel={(option) => option.semi_adresa}
                                    options={this.state.semiCiselnik}
                                    sx={{ width: "100%" }}
                                    classes={{}}
                                    renderInput={(params) => <TextField {...params} />}
                                    onChange={this.onServisMistoChange}
                                />
                                : <img src="./img/loading16.gif" height={30} />
                            }
                        </div>

                        <div style={{ display: this.state.mistoZpusobZadani == 1 ? "block" : "none" }}>
                            <div className="container_12" style={{ marginBottom: 10 }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={1}>
                                        <Grid item style={{ width: 100 }}>
                                            <strong>Stát:</strong>
                                        </Grid>
                                        <Grid xs md>
                                            <EditorHodnotyForm popis="" hodnota={this.state.semi_hledana_adresa_stat} widthLeft={0} fill={true} typ="COMBOBOX" klicID="kod" klicPopis="popis" dataCiselnik={Global.EnumStatyCiselnik()} onChange={e => this.setState({ semi_hledana_adresa_stat: e.target.value })} />
                                        </Grid>

                                        <Box width="100%" />

                                        <Grid item style={{ width: 100 }}>
                                            <strong>Adresa:</strong>
                                        </Grid>
                                        <Grid xs md>
                                            <Grid container spacing={0}>
                                                <Grid item xs>
                                                    <EditorHodnotyForm popis="" hodnota={this.state.semi_hledana_adresa} typ="TEXT" widthLeft={0} stylesParent={{ float: "left", width: "100%" }} onChange={e => this.setState({ semi_hledana_adresa: e.target.value })} />
                                                </Grid>
                                                <Grid item style={{ width: "105px" }}>
                                                    <button className="form_button" onClick={this.vyhledatServisMisto} style={{ marginLeft: 5, float: "left" }}>Vyhledat</button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Box width="100%" />
                                    </Grid>
                                </Box>
                            </div>

                            <div className="box round">
                                <h2>Nové místo:</h2>

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Kód:" hodnota={this.state.semi_kod} typ="TEXT" widthLeft={110} stylesRight={{ maxWidth: 250 }} onChange={e => this.setState({ semi_kod: e.target.value })} />
                                </div>
                                <div className="clear" />

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Název:" hodnota={this.state.semi_nazev} typ="TEXT" widthLeft={110} onChange={e => this.setState({ semi_nazev: e.target.value })} />
                                </div>
                                <div className="clear" />

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Ulice:" hodnota={this.state.semi_ulice} typ="TEXT" widthLeft={110} onChange={e => this.setState({ semi_ulice: e.target.value })} />
                                </div>
                                <div className="clear" />

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Místo:" hodnota={this.state.semi_misto} typ="TEXT" widthLeft={110} onChange={e => this.setState({ semi_misto: e.target.value })} />
                                </div>
                                <div className="clear" />

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="PSČ:" hodnota={this.state.semi_psc} typ="TEXT" widthLeft={110} stylesRight={{ maxWidth: 100 }} onChange={e => this.setState({ semi_psc: e.target.value })} />
                                </div>
                                <div className="clear" />


                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Stát:" hodnota={this.state.semi_stat} typ="TEXT" widthLeft={110} onChange={e => this.setState({ semi_stat: e.target.value })} />
                                </div>
                                <div className="clear" />


                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Vzdálenost [km]:" hodnota={this.state.semi_vzdalenost_km} typ="CISLO" widthLeft={110} stylesRight={{ maxWidth: 100 }} onChange={e => this.setState({ semi_vzdalenost_km: e.target.value })} />
                                </div>
                                <div className="clear" />

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="GPS:" hodnota={this.state.semi_gps} typ="TEXT" widthLeft={110} onChange={e => this.setState({ semi_gps: e.target.value })} />
                                </div>
                                <div className="clear" />

                                <div className="grid_12">
                                    <EditorHodnotyForm popis="Označení provozu" hodnota={this.state.semi_oznaceni_provozu} typ="TEXT" widthLeft={110} onChange={e => this.setState({ semi_oznaceni_provozu: e.target.value })} />
                                </div>
                                <div className="clear" />

                            </div>
                        </div>
                    </div>
                    <div className="grid_6" >
                        <div className="box round" style={{ height: 522 }}>
                            <h2>Mapa místa:</h2>
                            {this.state.mistoAdresaMapa && <SMapy mistoAdresa={this.state.mistoAdresaMapa} zpracovatDataAdresyZMapy={this.zpracovatDataAdresyZMapy} />}
                        </div>
                    </div>
                    <div className="clear" />

                    <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                    <button className="form_button" onClick={this.vytvoritVybratServisMisto} style={{ marginBottom: 10, marginLeft: 7 }}>{this.state.mistoZpusobZadani == 0 ? "Vybrat" : "Vytvořit a vybrat"}</button>
                </ThemeProvider>
            </div>
        );
    }
}
