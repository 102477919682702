import React, { useState, useEffect } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import AuthService from "./AuthService";


export const PrivateRoute = () => {
    const user = AuthService.getCurrentUser();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return user ? <Outlet /> : <Navigate to="/login" />;
}


export const DefaultRoute = () => {
    return <Navigate to="/" />;
}

/*
export function PrivateRoute({ component: Component, ...rest }) {
    
    return (
        <Route {...rest} render={props => {
            if (!user) {
                // not logged in so redirect to login page with the return url
                return <Navigate to="/login" />;
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}*/