import React from 'react';
import { ServisVyjezdDetail } from './ServisVyjezdDetail';
import AuthService from "../AuthService"
import CacheData from "../CacheData"

export class ServisVyjezd extends React.Component {
    constructor(props) {
        super(props);
        this.state = { detailServisVyjezdVychozi: null };

        this.reloadData = this.reloadData.bind(this);
    }

    componentDidMount() {
        this.reloadData();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        let detailServisVyjezdNew = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        this.setState({ detailServisVyjezdVychozi: detailServisVyjezdNew });
    }


    render() {        
        if (this.state.detailServisVyjezdVychozi === null) {
            return (
                <div className="grid_12 odsazeni_bottom50 odsazeni_top50">
                    <div className="error_zprava" >Není nastaven žádný výjezd.</div>
                </div>
            );
        }

        return (
            <div className="grid_12 odsazeni_bottom50">
                <ServisVyjezdDetail navigate={this.props.navigate} sevyData={this.state.detailServisVyjezdVychozi} bDetailVychozi={true} bTlacitkoZpet={false} />
            </div>
        );
    }
}



