import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import MessageBox from "./MessageBox/MessageBox";

export class ServisVyjezdTechniciList extends React.Component {
    constructor(props) {
        super(props);

        this.novyZaznam = this.novyZaznam.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
    }

    novyZaznam() {
        this.props.otevritDialogZaznam("TECHNICI");
    }

    smazatZaznam = async (e, cell) => {
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraného technika?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") this.props.smazatZaznam("TECHNICI", cell.getRow().getData());
    }


    render() {

        let cellContextMenu = [];

        if (this.props.bAkce) {
            cellContextMenu = [
                {
                    label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                    action: this.novyZaznam
                },
                {
                    label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                    action: this.smazatZaznam
                }
            ];
        }

        const tabServTechnici = [
            {
                field: 'sete_b_vedouci', title: 'Vedoucí', width: 80, headerSort: false, contextMenu: cellContextMenu, formatter: "tickCross",
                formatterParams: { allowEmpty: true, crossElement: "" }, hozAlign: "center"
            },
            { field: 'prijmeniJmeno', title: 'Servisní technik', width: 200, headerSort: true, contextMenu: cellContextMenu }
        ];

        return <div>
            <h2>Servisní technici {this.props.bAkce && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.novyZaznam}>Nový záznam</button>} </h2>
            <ReactTabulator
                data={this.props.data}
                columns={tabServTechnici}
                layout={'fitDataStretch'}
                height={'200px'}
                selectable={1}
            />
        </div>;
    }
}