import React from 'react';
/*import { Document, Page, pdfjs } from "react-pdf";*/
import styles from './ServisniListDokument.module.css'
import SignatureCanvas from 'react-signature-canvas'
import Checkbox from '@mui/material/Checkbox';
import { fetchWrapper } from "../FetchWrapper";
import { CacheData } from "../CacheData";
import AuthService from "../AuthService";


import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";
import { EditorHodnotyForm } from './EditorHodnotyForm';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


/*pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;*/


export class ServisniListDokument extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            emailZakaznik: props.sevyData.sevy_email_kontakt_os,
            seve_prevzal: props.sevyData.servisZarizeni[0].seve_prevzal,
            seve_prevzalOld: props.sevyData.servisZarizeni[0].seve_prevzal,
            bOdeslatEmail: ("" + props.sevyData.sevy_email_kontakt_os != ""),
            dataDokumentPDF_URL: window.URL.createObjectURL(this.props.dataDokumentPDF)
        };

        this.sigCanvas = React.createRef();

        this.odeslatKeSchvaleni = this.odeslatKeSchvaleni.bind(this);
        this.podpisReset = this.podpisReset.bind(this);
        this.potvrditAOdeslat = this.potvrditAOdeslat.bind(this);
    }

    odeslatKeSchvaleni() {
        const dokumentData = {
            aktualniSevy_id: this.props.sevyData.sevy_id,
            vyjezdyIds: this.props.servisniListVyjezdyVybraneIds,
            jazyk: this.props.servisniListJazyk,
            podpisIMGbase64PNG: "",
            bOdeslatEmail: false,
            seve_prevzal: "",
            email: ""
        }

        LoadingSpinner.open();

        fetchWrapper.fileLoadPost(fetchWrapper.SchvalitServisVyjezdProtokol(), dokumentData)
            .then(data => {
                LoadingSpinner.close();

                this.props.aktualizaceDokumentuPDF(data, true);
                this.setState({ dataDokumentPDF_URL: window.URL.createObjectURL(data) });

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Servisní list byl odeslán ke schválení.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    podpisReset() {
        this.sigCanvas.clear();
    }

    potvrditAOdeslat() {
        const podpisImg = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');

        const dokumentData = {
            aktualniSevy_id: this.props.sevyData.sevy_id,
            vyjezdyIds: this.props.servisniListVyjezdyVybraneIds,
            jazyk: this.props.servisniListJazyk,
            podpisIMGbase64PNG: podpisImg,
            bOdeslatEmail: this.state.bOdeslatEmail,
            seve_prevzal: this.state.seve_prevzal,
            email: this.props.sevyData.sevy_email_kontakt_os
        }

        LoadingSpinner.open();

        fetchWrapper.fileLoadPost(fetchWrapper.UlozitServisVyjezdProtokol(), dokumentData)
            .then(data => {
                LoadingSpinner.close();

                //doplneni seve_prevzal, pokud doslo ke zmene
                if (this.state.seve_prevzalOld != this.state.seve_prevzal) {
                    this.props.sevyData.servisZarizeni.forEach((seve) => {
                        seve.seve_prevzal = this.state.seve_prevzal;
                    });

                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);
                }

                this.props.aktualizaceDokumentuPDF(data, this.props.servisniListSchvaleno);
                this.setState({ dataDokumentPDF_URL: window.URL.createObjectURL(data) });

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Potvrzený servisní list byl úspěšně uložen.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    render() {
        /*<Document file={this.state.dataDokumentPDF} onLoadError={console.error} >
            <Page pageNumber={*} />
        </Document>*/


        /*if (!this.props.dataDokumentPDF) {
            return (
                <>
                    <h2>Není vygenerován dokument</h2>
                </>
            );
        }*/

        return (
            <div className="grid_12" style={{ minHeight: 200, maxWidth: 1000 }}>
                <BrowserView>
                    <iframe id="iframepdf" style={{ width: "100%", aspectRatio: "1.414", marginTop: 15 }} src={this.state.dataDokumentPDF_URL/* + "#toolbar=0"*/}></iframe>
                </BrowserView>
                <MobileView>
                    <div className="grid_12" style={{ maxWidth: 500, marginLeft: 0 }}>
                        <a href={this.state.dataDokumentPDF_URL} target="_blank"><button className="form_button" style={{ marginTop: 20, width: "100%" }}>Stáhnout dokument PDF</button></a>
                    </div>
                    <div className="clear"></div>
                </MobileView>

                {!this.props.servisniListSchvaleno &&
                    <>
                        <div className="grid_12" style={{ maxWidth: 250, marginLeft: 0 }}>
                            <button className="form_button" style={{ marginTop: 10, width: "100%" }} onClick={this.odeslatKeSchvaleni}>Odeslat ke schválení</button>
                        </div>
                        <div className="clear"></div>
                    </>
                }

                {this.props.servisniListSchvaleno &&
                    <>
                        <h3>Převzal:</h3>
                        <div className="grid_12" style={{ maxWidth: 500 }}>
                            <EditorHodnotyForm popis="" hodnota={this.state.seve_prevzal} typ="TEXT" widthLeft={10} onChange={e => this.setState({ seve_prevzal: e.target.value })} />
                        </div>
                        <div className="clear" />

                        <h3>Podpis</h3>
                        <div className={styles.sigCanvas}>
                            <SignatureCanvas
                                penColor='blue'
                                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                ref={(ref) => { this.sigCanvas = ref }} />
                        </div>
                        <div className={styles.panelPodpisy} >
                            <button className="form_button" onClick={this.podpisReset}>Smazat podpis</button>
                            <button className="form_button" onClick={this.potvrditAOdeslat}>Potvrdit a uložit dokument s podpisem</button><br /><br />
                            {this.state.emailZakaznik &&
                                <div className="grid_12">
                                    <Checkbox
                                        checked={this.state.bOdeslatEmail}
                                        onClick={(event) => {
                                            this.setState({ bOdeslatEmail: !this.state.bOdeslatEmail })
                                        }}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        style={{ padding: 4 }}
                                    />
                                    Odeslat kopii zákazníkovi na email:<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {this.state.emailZakaznik}
                                </div>
                            }

                        </div>
                        <div className="clear" />
                    </>
                }

            </div>
        );
    }
}
