import AuthService from './AuthService';
import Global from './components/Global';

import Dexie from 'dexie'

const IndexedDBVersion = 1;

export const CacheData = {
    SetData,
    GetData,
    DeleteData,

    IndexedDBSetData,
    IndexedDBGetData,
    IndexedDBDeleteData,

    SetSessionData,
    GetSessionData,
    DeleteSessionData,
};

export default CacheData;

function GetData(user, klic) {

    if (user) klic = klic + "__" + user.uziv_id;
    const dataObjekt = localStorage.getItem(klic);

    if (dataObjekt == null) return null;

    try {
        return JSON.parse(dataObjekt);
    }
    catch
    {
        return null;
    }
}

function SetData(user, klic, dataObjekt) {
    if (user) {
        localStorage.setItem(klic + "__" + user.uziv_id, JSON.stringify(dataObjekt));
    }
    else localStorage.setItem(klic, JSON.stringify(dataObjekt));
}

function DeleteData(user, klic) {
    if (user) {
        localStorage.removeItem(klic + "__" + user.uziv_id);
    }
    else localStorage.removeItem(klic);
}




function IndexedDBGetData(databaze, user, klic)
{
    const db = new Dexie(databaze);
    db.version(IndexedDBVersion).stores(
        { dbdata: "++id, user_id, klic, data" }
    );

    return db.dbdata.where("klic").equalsIgnoreCase(klic).first();
}

function IndexedDBSetData(databaze, user, klic, dataObjekt) {
    const db = new Dexie(databaze);
    db.version(IndexedDBVersion).stores(
        { dbdata: "++id,user_id, klic, data" }
    )

    return db.dbdata.where("klic").equalsIgnoreCase(klic).first().then(zaznam => {
        if (zaznam) {
            zaznam.data = dataObjekt;
            return db.dbdata.put(zaznam);
        }
        else {
            return db.dbdata.add({
                user_id: user,
                klic: klic,
                data: dataObjekt
            });
        }
    });
}

function IndexedDBDeleteData(databaze, user, klic) {
    const db = new Dexie(databaze);
    db.version(IndexedDBVersion).stores(
        { dbdata: "++id,user_id, klic, data" }
    )

    db.dbdata.where("klic").equalsIgnoreCase(klic).delete()
        .then(function (deleteCount) {
            console.log("Deleted " + deleteCount + " objects");
    });  
}




function GetSessionData(user, klic) {

    if (user) klic = klic + "__" + user.uziv_id;
    const dataObjekt = sessionStorage.getItem(klic);

    if (dataObjekt == null) return null;

    try {
        return JSON.parse(dataObjekt);
    }
    catch
    {
        return null;
    }
}

function SetSessionData(user, klic, dataObjekt) {
    if (user) {
        sessionStorage.setItem(klic + "__" + user.uziv_id, JSON.stringify(dataObjekt));
    }
    else sessionStorage.setItem(klic, JSON.stringify(dataObjekt));
}

function DeleteSessionData(user, klic) {
    if (user) {
        sessionStorage.removeItem(klic + "__" + user.uziv_id);
    }
    else sessionStorage.removeItem(klic);
}