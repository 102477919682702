import React from "react";
import ReactDOM from "react-dom";
import styles from './LoadingSpinner.module.css'

const defaultProps = {
    title: "",
    content: <></>,
    container: "#app-spinner"
};

export default {
    open: props => {
        const { title, content, container } = {
            ...defaultProps,
            ...props
        };
        const containerElement = document.querySelector(container);
        if (!containerElement) throw Error(`can't find container ${container}`);

        return ReactDOM.render(
                <div className={styles.modal__backdrop_spinner}>
                    <div className={styles.modal__wrapper_spinner}>
                    <div className={styles.modal__content_spinner}>
                            <img src="./img/loading.gif" />
                        </div>
                    </div>
                </div>,
                containerElement
            );
    },

    close: props => {
        const { title, content, container } = {
            ...defaultProps,
            ...props
        };
        const containerElement = document.querySelector(container);
        if (!containerElement) throw Error(`can't find container ${container}`);

        return ReactDOM.render(
            "",
            containerElement
        );
    }
};

