import React from 'react';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator } from 'react-tabulator';

import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";

import { v4 as uuidv4 } from 'uuid';

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";
import { Radio, RadioGroup, FormControlLabel, TextField, Autocomplete } from '@mui/material';
import { EditorHodnotyForm } from "./EditorHodnotyForm"
import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek"

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'

import { Global } from "./Global";

import AuthService from "../AuthService";

export class ServisovaneZarizeniForm extends React.Component {
    constructor(props) {
        super(props);

        let vychoziTechnikPredal_PrijmeniJmeno = "";
        let vychoziTechnikPredal_id = Global.GuidEmpty;
        try {
            vychoziTechnikPredal_PrijmeniJmeno = this.props.dataTechnici.find(item => item.sete_b_vedouci).prijmeniJmeno;
            vychoziTechnikPredal_id = this.props.dataTechnici.find(item => item.sete_b_vedouci).sete_prac_id;
        }
        catch { }

        if (props.dataObject) {
            this.state = {
                vent_id: props.dataObject.seve_zarizeni.vent_id,
                vent_hmotnost_kg: props.dataObject.seve_zarizeni.vent_hmotnost_kg,
                vent_max_otacky: props.dataObject.seve_zarizeni.vent_max_otacky,
                vent_min_otacky: props.dataObject.seve_zarizeni.vent_min_otacky,
                vent_popis: props.dataObject.seve_zarizeni.vent_popis,
                vent_popis_zarizeni: props.dataObject.seve_zarizeni.vent_popis_zarizeni,
                vent_typ: props.dataObject.seve_zarizeni.vent_typ,
                vent_typ_ventilatoru: props.dataObject.seve_zarizeni.vent_typ_ventilatoru,
                vent_prutok_m3s: props.dataObject.seve_zarizeni.vent_prutok_m3s,
                vent_remen_pocet: props.dataObject.seve_zarizeni.vent_remen_pocet,
                vent_rok_vyroby: props.dataObject.seve_zarizeni.vent_rok_vyroby,
                vent_termin_zaruky: new Date(props.dataObject.seve_zarizeni.vent_termin_zaruky),
                vent_vyrobni_cislo: props.dataObject.seve_zarizeni.vent_vyrobni_cislo,
                vent_b_fm: props.dataObject.seve_zarizeni.vent_b_fm,
                vent_hz: props.dataObject.seve_zarizeni.vent_hz,
                vent_vyrobce: props.dataObject.seve_zarizeni.vent_vyrobce,

                vent_lozisko_pevne_interval_domazavani: props.dataObject.seve_zarizeni.vent_lozisko_pevne_interval_domazavani,
                vent_lozisko_pevne_mazivo: props.dataObject.seve_zarizeni.vent_lozisko_pevne_mazivo,
                vent_lozisko_pevne_typ: props.dataObject.seve_zarizeni.vent_lozisko_pevne_typ,
                vent_lozisko_pevne_vyrobce: props.dataObject.seve_zarizeni.vent_lozisko_pevne_vyrobce,
                vent_lozisko_pevne_zb_rad_vule: props.dataObject.seve_zarizeni.vent_lozisko_pevne_zb_rad_vule,

                vent_lozisko_volne_interval_domazavani: props.dataObject.seve_zarizeni.vent_lozisko_volne_interval_domazavani,
                vent_lozisko_volne_mazivo: props.dataObject.seve_zarizeni.vent_lozisko_volne_mazivo,
                vent_lozisko_volne_typ: props.dataObject.seve_zarizeni.vent_lozisko_volne_typ,
                vent_lozisko_volne_vyrobce: props.dataObject.seve_zarizeni.vent_lozisko_volne_vyrobce,
                vent_lozisko_volne_zb_rad_vule: props.dataObject.seve_zarizeni.vent_lozisko_volne_zb_rad_vule,

                vent_motor_napeti: props.dataObject.seve_zarizeni.vent_motor_napeti,
                vent_motor_prikon_kw: props.dataObject.seve_zarizeni.vent_motor_prikon_kw,
                vent_motor_typ: props.dataObject.seve_zarizeni.vent_motor_typ,
                vent_motor_vyrobce: props.dataObject.seve_zarizeni.vent_motor_vyrobce,

                vent_remen_delka: props.dataObject.seve_zarizeni.vent_remen_delka,
                vent_remen_hz: props.dataObject.seve_zarizeni.vent_remen_hz,
                vent_remen_typ: props.dataObject.seve_zarizeni.vent_remen_typ,

                vent_spojka_provedeni: props.dataObject.seve_zarizeni.vent_spojka_provedeni,
                vent_spojka_typ: props.dataObject.seve_zarizeni.vent_spojka_typ,

                vent_tlak_pa: props.dataObject.seve_zarizeni.vent_tlak_pa,
                vent_ulozeni: props.dataObject.seve_zarizeni.vent_ulozeni,

                semi_misto: props.dataObject.seve_zarizeni.semi_misto,

                vent_provozni_teplota: props.dataObject.seve_zarizeni.vent_provozni_teplota,
                vent_otacky_motoru: props.dataObject.seve_zarizeni.vent_otacky_motoru,
                vent_motor_proud: props.dataObject.seve_zarizeni.vent_motor_proud,
                vent_ostatni_vybaveni_poznamka: props.dataObject.seve_zarizeni.vent_ostatni_vybaveni_poznamka,

                vent_lozisko_de_typ: props.dataObject.seve_zarizeni.vent_lozisko_de_typ,
                vent_lozisko_de_mazivo: props.dataObject.seve_zarizeni.vent_lozisko_de_mazivo,
                vent_lozisko_de_interval_domazavani: props.dataObject.seve_zarizeni.vent_lozisko_de_interval_domazavani,

                vent_lozisko_nde_typ: props.dataObject.seve_zarizeni.vent_lozisko_nde_typ,
                vent_lozisko_nde_mazivo: props.dataObject.seve_zarizeni.vent_lozisko_nde_mazivo,
                vent_lozisko_nde_interval_domazavani: props.dataObject.seve_zarizeni.vent_lozisko_nde_interval_domazavani,

                vent_loziska_motoru_poznamka: props.dataObject.seve_zarizeni.vent_loziska_motoru_poznamka,
                vent_usporadani: props.dataObject.seve_zarizeni.vent_usporadani,
                vent_usporadaniEnum: Global.VratEnumUsporadani(props.dataObject.seve_zarizeni.vent_usporadani),

                vent_vyza_id: props.dataObject.seve_zarizeni.vent_vyza_id,

                vent_remenice_hnaci: props.dataObject.seve_zarizeni.vent_remenice_hnaci,
                vent_remenice_hnana: props.dataObject.seve_zarizeni.vent_remenice_hnana,
                vent_remen_pruhyb: props.dataObject.seve_zarizeni.vent_remen_pruhyb,
                vent_remen_test_sila: props.dataObject.seve_zarizeni.vent_remen_test_sila,
                vent_remenice_profil: props.dataObject.seve_zarizeni.vent_remenice_profil,

                provozniHodnoty: props.dataObject.seve_zarizeni.provozniHodnoty,
                provozniHodnotyDeleted: props.dataObject.seve_zarizeni.provozniHodnotyDeleted,

                bNovy: false,
                bZobrazitPreddefinovaneFraze: false,
                preddefinovaneFraze: [],

                techniciCiselnik: null,
                ventCiselnik: null,
                vent_itemSel: null,
                zpusobVyberuZarizeni: 0,
                seve_id: props.dataObject.seve_id,
                seve_vent_id: props.dataObject.seve_zarizeni.vent_id,
                seve_opravy: props.dataObject.seve_opravy,
                seve_popis: props.dataObject.seve_popis,
                seve_konecny_stav: props.dataObject.seve_konecny_stav,
                seve_opravy_doplneno: props.dataObject.seve_opravy_doplneno,
                seve_predal_PrijmeniJmeno: props.dataObject.seve_predal_PrijmeniJmeno,
                seve_predal_id: props.dataObject.seve_predal_id,
                seve_prevzal: props.dataObject.seve_prevzal,
                seve_offlineStav: props.dataObject.seve_offlineStav,
                dataMoneyZamestnanci: null
            }
        }
        else if (props.dataObjectKopieZdroj) {
            this.state = {
                vent_id: Global.GuidEmpty,
                vent_hmotnost_kg: props.dataObjectKopieZdroj.vent_hmotnost_kg,
                vent_max_otacky: props.dataObjectKopieZdroj.vent_max_otacky,
                vent_min_otacky: props.dataObjectKopieZdroj.vent_min_otacky,
                vent_popis: props.dataObjectKopieZdroj.vent_popis,
                vent_popis_zarizeni: props.dataObjectKopieZdroj.vent_popis_zarizeni,
                vent_typ: props.dataObjectKopieZdroj.vent_typ,
                vent_typ_ventilatoru: props.dataObjectKopieZdroj.vent_typ_ventilatoru,
                vent_prutok_m3s: props.dataObjectKopieZdroj.vent_prutok_m3s,
                vent_remen_pocet: props.dataObjectKopieZdroj.vent_remen_pocet,
                vent_rok_vyroby: props.dataObjectKopieZdroj.vent_rok_vyroby,
                vent_termin_zaruky: new Date(props.dataObjectKopieZdroj.vent_termin_zaruky),
                vent_vyrobni_cislo: props.dataObjectKopieZdroj.vent_vyrobni_cislo,
                vent_b_fm: props.dataObjectKopieZdroj.vent_b_fm,
                vent_hz: props.dataObjectKopieZdroj.vent_hz,
                vent_vyrobce: props.dataObjectKopieZdroj.vent_vyrobce,


                vent_lozisko_pevne_interval_domazavani: props.dataObjectKopieZdroj.vent_lozisko_pevne_interval_domazavani,
                vent_lozisko_pevne_mazivo: props.dataObjectKopieZdroj.vent_lozisko_pevne_mazivo,
                vent_lozisko_pevne_typ: props.dataObjectKopieZdroj.vent_lozisko_pevne_typ,
                vent_lozisko_pevne_vyrobce: props.dataObjectKopieZdroj.vent_lozisko_pevne_vyrobce,
                vent_lozisko_pevne_zb_rad_vule: props.dataObjectKopieZdroj.vent_lozisko_pevne_zb_rad_vule,

                vent_lozisko_volne_interval_domazavani: props.dataObjectKopieZdroj.vent_lozisko_volne_interval_domazavani,
                vent_lozisko_volne_mazivo: props.dataObjectKopieZdroj.vent_lozisko_volne_mazivo,
                vent_lozisko_volne_typ: props.dataObjectKopieZdroj.vent_lozisko_volne_typ,
                vent_lozisko_volne_vyrobce: props.dataObjectKopieZdroj.vent_lozisko_volne_vyrobce,
                vent_lozisko_volne_zb_rad_vule: props.dataObjectKopieZdroj.vent_lozisko_volne_zb_rad_vule,

                vent_motor_napeti: props.dataObjectKopieZdroj.vent_motor_napeti,
                vent_motor_prikon_kw: props.dataObjectKopieZdroj.vent_motor_prikon_kw,
                vent_motor_typ: props.dataObjectKopieZdroj.vent_motor_typ,
                vent_motor_vyrobce: props.dataObjectKopieZdroj.vent_motor_vyrobce,

                vent_remen_delka: props.dataObjectKopieZdroj.vent_remen_delka,
                vent_remen_hz: props.dataObjectKopieZdroj.vent_remen_hz,
                vent_remen_typ: props.dataObjectKopieZdroj.vent_remen_typ,

                vent_spojka_provedeni: props.dataObjectKopieZdroj.vent_spojka_provedeni,
                vent_spojka_typ: props.dataObjectKopieZdroj.vent_spojka_typ,

                vent_tlak_pa: props.dataObjectKopieZdroj.vent_tlak_pa,
                vent_ulozeni: props.dataObjectKopieZdroj.vent_ulozeni,

                semi_misto: props.dataObjectKopieZdroj.semi_misto,

                vent_provozni_teplota: props.dataObjectKopieZdroj.vent_provozni_teplota,
                vent_otacky_motoru: props.dataObjectKopieZdroj.vent_otacky_motoru,
                vent_motor_proud: props.dataObjectKopieZdroj.vent_motor_proud,
                vent_ostatni_vybaveni_poznamka: props.dataObjectKopieZdroj.vent_ostatni_vybaveni_poznamka,

                vent_lozisko_de_typ: props.dataObjectKopieZdroj.vent_lozisko_de_typ,
                vent_lozisko_de_mazivo: props.dataObjectKopieZdroj.vent_lozisko_de_mazivo,
                vent_lozisko_de_interval_domazavani: props.dataObjectKopieZdroj.vent_lozisko_de_interval_domazavani,

                vent_lozisko_nde_typ: props.dataObjectKopieZdroj.vent_lozisko_nde_typ,
                vent_lozisko_nde_mazivo: props.dataObjectKopieZdroj.vent_lozisko_nde_mazivo,
                vent_lozisko_nde_interval_domazavani: props.dataObjectKopieZdroj.vent_lozisko_nde_interval_domazavani,

                vent_loziska_motoru_poznamka: props.dataObjectKopieZdroj.vent_loziska_motoru_poznamka,
                vent_usporadani: props.dataObjectKopieZdroj.vent_usporadani,
                vent_usporadaniEnum: Global.VratEnumUsporadani(props.dataObjectKopieZdroj.vent_usporadani),

                vent_vyza_id: props.dataObjectKopieZdroj.vent_vyza_id,

                vent_remenice_hnaci: props.dataObjectKopieZdroj.vent_remenice_hnaci,
                vent_remenice_hnana: props.dataObjectKopieZdroj.vent_remenice_hnana,
                vent_remen_pruhyb: props.dataObjectKopieZdroj.vent_remen_pruhyb,
                vent_remen_test_sila: props.dataObjectKopieZdroj.vent_remen_test_sila,
                vent_remenice_profil: props.dataObjectKopieZdroj.vent_remenice_profil,

                provozniHodnoty: props.dataObjectKopieZdroj.seve_zarizeni.provozniHodnoty,
                provozniHodnotyDeleted: props.dataObjectKopieZdroj.seve_zarizeni.provozniHodnotyDeleted,

                bNovy: true,
                bZobrazitPreddefinovaneFraze: false,
                preddefinovaneFraze: [],

                techniciCiselnik: null,
                ventCiselnik: null,
                vent_itemSel: null,
                zpusobVyberuZarizeni: 0,
                seve_id: Global.GuidEmpty,
                seve_vent_id: props.dataObjectKopieZdroj.vent_id,
                seve_opravy: props.dataObjectKopieZdroj.seve_opravy,
                seve_popis: props.dataObjectKopieZdroj.seve_popis,
                seve_konecny_stav: props.dataObjectKopieZdroj.seve_konecny_stav,
                seve_opravy_doplneno: props.dataObjectKopieZdroj.seve_opravy_doplneno,
                seve_predal_PrijmeniJmeno: props.dataObjectKopieZdroj.seve_predal_PrijmeniJmeno,
                seve_predal_id: props.dataObjectKopieZdroj.seve_predal_id,
                seve_prevzal: props.dataObjectKopieZdroj.seve_prevzal,
                seve_offlineStav: 0,
                dataMoneyZamestnanci: null
            }
        }
        else {
            this.state = {
                vent_id: Global.GuidEmpty,
                vent_hmotnost_kg: 0,
                vent_max_otacky: 0,
                vent_min_otacky: 0,
                vent_popis: "",
                vent_popis_zarizeni: "",
                vent_typ: 0,
                vent_typ_ventilatoru: 0,
                vent_prutok_m3s: 0,
                vent_remen_pocet: 0,
                vent_rok_vyroby: 0,
                vent_termin_zaruky: new Date(),
                vent_vyrobni_cislo: "",
                vent_b_fm: false,
                vent_hz: 0,
                vent_vyrobce: "",

                vent_lozisko_pevne_interval_domazavani: 0,
                vent_lozisko_pevne_mazivo: "",
                vent_lozisko_pevne_typ: "",
                vent_lozisko_pevne_vyrobce: "",
                vent_lozisko_pevne_zb_rad_vule: 0,

                vent_lozisko_volne_interval_domazavani: 0,
                vent_lozisko_volne_mazivo: "",
                vent_lozisko_volne_typ: "",
                vent_lozisko_volne_vyrobce: "",
                vent_lozisko_volne_zb_rad_vule: 0,

                vent_motor_napeti: 0,
                vent_motor_prikon_kw: 0,
                vent_motor_typ: "",
                vent_motor_vyrobce: "",

                vent_remen_delka: 0,
                vent_remen_hz: 0,
                vent_remen_typ: "",

                vent_spojka_provedeni: 0,
                vent_spojka_typ: "",

                vent_tlak_pa: 0,
                vent_ulozeni: "",

                semi_misto: this.props.sevyData.semi_misto,

                vent_provozni_teplota: 0,
                vent_otacky_motoru: 0,
                vent_motor_proud: 0,
                vent_ostatni_vybaveni_poznamka: "",

                vent_lozisko_de_typ: "",
                vent_lozisko_de_mazivo: "",
                vent_lozisko_de_interval_domazavani: 0,

                vent_lozisko_nde_typ: "",
                vent_lozisko_nde_mazivo: "",
                vent_lozisko_nde_interval_domazavani: 0,

                vent_loziska_motoru_poznamka: "",
                vent_usporadani: "Napřímo",
                vent_usporadaniEnum: 2,
                vent_vyza_id: Global.GuidEmpty,

                vent_remenice_hnaci: "",
                vent_remenice_hnana: "",
                vent_remen_pruhyb: 0,
                vent_remen_test_sila: 0,
                vent_remenice_profil: 0,

                provozniHodnoty: [],
                provozniHodnotyDeleted: [],
                preddefinovaneFraze: [],

                bNovy: true,
                bZobrazitPreddefinovaneFraze: false,

                techniciCiselnik: null,
                ventCiselnik: null,
                vent_itemSel: null,
                zpusobVyberuZarizeni: 0,
                seve_id: Global.GuidEmpty,
                seve_vent_id: "00000000-0000-0000-0000-000000000000",
                seve_opravy: "",
                seve_popis: "",
                seve_konecny_stav: "",
                seve_opravy_doplneno: "",
                seve_predal_PrijmeniJmeno: vychoziTechnikPredal_PrijmeniJmeno,
                seve_predal_id: vychoziTechnikPredal_id,
                seve_prevzal: "",
                seve_offlineStav: 0,
                dataMoneyZamestnanci: null
            }
        }

        this.tabServPreddFraze = React.createRef();

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.otevritPreddefinovaneFraze = this.otevritPreddefinovaneFraze.bind(this);
        this.zavritPreddefinovaneFraze = this.zavritPreddefinovaneFraze.bind(this);
        this.vlozitPreddefinovaneFraze = this.vlozitPreddefinovaneFraze.bind(this);
        this.pridatPoleKonecnyStavDoFrazi = this.pridatPoleKonecnyStavDoFrazi.bind(this);
        this.smazatPoleKonecnyStav = this.smazatPoleKonecnyStav.bind(this);
        this.nacistVybraneZarizeni = this.nacistVybraneZarizeni.bind(this);
        this.resetovatVybraneZarizeni = this.resetovatVybraneZarizeni.bind(this);
        this.onZmenaZpusobuVyberuZarizeni = this.onZmenaZpusobuVyberuZarizeni.bind(this);
        this.onZarizeniVyberChange = this.onZarizeniVyberChange.bind(this);

        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
    }

    onParamaterChange(event) {
        if (event.target.name == "sepr_poznamka") this.setState({ sepr_poznamka: event.target.value });
        else if (event.target.name == "spp_id") {
            this.setState({ spp_id: event.target.value, sepr_poznamka: event.objectSelected.spp_poznamka });
        }
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();

        if (!this.state.bNovy) {
            const zarizeniSevy = this.props.sevyData.servisZarizeni.find(v => v.seve_zarizeni.vent_id == this.state.seve_vent_id);
            if (zarizeniSevy == null) this.zavritDialog();
        }
    }

    inicializace() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        LoadingSpinner.open();

        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratZarizeniCiselnikJednoduchy(this.props.sevyData.semi_misto.semi_id) : fetchWrapper.VratZarizeniCiselnikJednoduchyOffline())
            .then(data => {
                LoadingSpinner.close();

                const techniciCiselnikNew = [];
                if (this.state.seve_predal_id && this.state.seve_predal_id != Global.GuidEmpty) {
                    if (!this.props.dataTechnici.find(st => st.sete_prac_id == this.state.seve_predal_id)) {
                        techniciCiselnikNew.push({
                            sete_id: Global.GuidEmpty,
                            sete_b_vedouci: false,
                            sete_prac_id: this.state.seve_predal_id,
                            osCislo: "",
                            prijmeniJmeno: this.state.seve_predal_PrijmeniJmeno,
                            sevy_id: this.props.sevyData.sevy_id,
                            sete_offlineStav: 0
                        });
                    }
                }
                techniciCiselnikNew.push(...this.props.dataTechnici);

                const ventCiselnikNew = [];
                if (this.state.seve_vent_id && this.state.seve_vent_id != Global.GuidEmpty) {
                    if (!data.find(zc => zc.vent_id == this.state.seve_vent_id)) {
                        ventCiselnikNew.push({
                            vent_id: this.state.seve_vent_id,
                            vent_typ: this.state.vent_typ,
                            vent_popis: this.state.vent_popis,
                            vent_vyrobce: this.state.vent_vyrobce,
                            vent_vyrobni_cislo: this.state.vent_vyrobni_cislo,
                            vent_vyza_id: this.state.vent_vyza_id,
                            semi_id: this.state.semi_misto.semi_id
                        });
                    }
                }
                ventCiselnikNew.push(...data);

                const vent_itemSelNew = ventCiselnikNew.find(item => item.vent_id == this.state.seve_vent_id);

                this.setState({ techniciCiselnik: techniciCiselnikNew, ventCiselnik: ventCiselnikNew, vent_itemSel: vent_itemSelNew });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník existujících zařízení: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });

        /*fetchWrapper.get(API_URL + "Zamestnanec")
            .then(data => {
                const roleFiltr = ["montér", "mst", "serv.tech"];
                const dataZamFiltr = data.filter(z => roleFiltr.includes(("" + z.pracovniPozice_Kod).trim().toLowerCase()));

                let seve_predal_idNew = Global.GuidEmpty;
                if (dataZamFiltr.find(z => z.id == this.state.seve_predal_id)) {
                    seve_predal_idNew = this.state.seve_predal_id;
                }
                else if (dataZamFiltr.length > 0) {

                    let vychoziTechnikPredal_id = Global.GuidEmpty;
                    try {
                        vychoziTechnikPredal_id = this.props.dataTechnici.find(item => item.sete_b_vedouci).sete_prac_id;
                    }
                    catch { }

                    if (dataZamFiltr.find(z => z.id == vychoziTechnikPredal_id)) seve_predal_idNew = vychoziTechnikPredal_id;
                    else  seve_predal_idNew = dataZamFiltr[0].id;
                }

                this.setState({ dataMoneyZamestnanci: dataZamFiltr, seve_predal_id: seve_predal_idNew });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník zaměstnanců: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });*/
    }

    formSubmit(event) {
        event.preventDefault();

        console.log(this.state.vent_itemSel);

        let chyba = "";
        if (!Global.JeGuidValid(this.state.seve_predal_id)) chyba += "Není vybrán zaměstnanec pro předání. ";

        let seve_zarizeniVybrane = null;
        let seve_zarizeni_idVybrane = Global.GuidEmpty;

        if (this.state.zpusobVyberuZarizeni == 0) {
            if (this.state.vent_itemSel == null) chyba += "Není vybráno žádné zařízení. ";
            else seve_zarizeni_idVybrane = this.state.vent_itemSel.vent_id;
        }

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        const bNovyVent = this.state.zpusobVyberuZarizeni != 0;

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;
        const offlineRequest = jeOfflineRezim ? { bNovyVent: bNovyVent } : null;

        seve_zarizeniVybrane = {
            vent_id: this.state.vent_id,
            sevy_id: null,
            vent_b_fm: this.state.vent_b_fm,
            vent_hmotnost_kg: this.state.vent_hmotnost_kg,
            vent_hz: this.state.vent_hz,
            vent_typ_ventilatoru: this.state.vent_typ_ventilatoru,
            vent_typ: this.state.vent_typ,

            vent_lozisko_pevne_interval_domazavani: this.state.vent_lozisko_pevne_interval_domazavani,
            vent_lozisko_pevne_mazivo: this.state.vent_lozisko_pevne_mazivo,
            vent_lozisko_pevne_typ: this.state.vent_lozisko_pevne_typ,
            vent_lozisko_pevne_vyrobce: this.state.vent_lozisko_pevne_vyrobce,
            vent_lozisko_pevne_zb_rad_vule: this.state.vent_lozisko_pevne_zb_rad_vule,

            vent_lozisko_volne_interval_domazavani: this.state.vent_lozisko_volne_interval_domazavani,
            vent_lozisko_volne_mazivo: this.state.vent_lozisko_volne_mazivo,
            vent_lozisko_volne_typ: this.state.vent_lozisko_volne_typ,
            vent_lozisko_volne_vyrobce: this.state.vent_lozisko_volne_vyrobce,
            vent_lozisko_volne_zb_rad_vule: this.state.vent_lozisko_volne_zb_rad_vule,

            vent_max_otacky: this.state.vent_max_otacky,
            vent_min_otacky: this.state.vent_min_otacky,

            vent_motor_napeti: this.state.vent_motor_napeti,
            vent_motor_prikon_kw: this.state.vent_motor_prikon_kw,
            vent_motor_typ: this.state.vent_motor_typ,
            vent_motor_vyrobce: this.state.vent_motor_vyrobce,

            vent_popis: this.state.vent_popis,
            vent_popis_zarizeni: this.state.vent_popis_zarizeni,
            vent_prutok_m3s: this.state.vent_prutok_m3s,

            vent_remen_delka: this.state.vent_remen_delka,
            vent_remen_hz: this.state.vent_remen_hz,
            vent_remen_pocet: this.state.vent_remen_pocet,
            vent_remen_typ: this.state.vent_remen_typ,

            vent_rok_vyroby: this.state.vent_rok_vyroby,
            vent_termin_zaruky: this.state.vent_termin_zaruky.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.vent_termin_zaruky),

            vent_spojka_provedeni: this.state.vent_spojka_provedeni,
            vent_spojka_typ: this.state.vent_spojka_typ,

            vent_tlak_pa: this.state.vent_tlak_pa,
            vent_ulozeni: this.state.vent_ulozeni,
            vent_vyrobce: this.state.vent_vyrobce,
            vent_vyrobni_cislo: this.state.vent_vyrobni_cislo,

            vent_provozni_teplota: this.state.vent_provozni_teplota,
            vent_otacky_motoru: this.state.vent_otacky_motoru,
            vent_motor_proud: this.state.vent_motor_proud,
            vent_ostatni_vybaveni_poznamka: Global.textAreaOpravaPredUlozeni(this.state.vent_ostatni_vybaveni_poznamka),

            vent_lozisko_de_typ: this.state.vent_lozisko_de_typ,
            vent_lozisko_de_mazivo: this.state.vent_lozisko_de_mazivo,
            vent_lozisko_de_interval_domazavani: this.state.vent_lozisko_de_interval_domazavani,

            vent_lozisko_nde_typ: this.state.vent_lozisko_nde_typ,
            vent_lozisko_nde_mazivo: this.state.vent_lozisko_nde_mazivo,
            vent_lozisko_nde_interval_domazavani: this.state.vent_lozisko_nde_interval_domazavani,

            vent_loziska_motoru_poznamka: Global.textAreaOpravaPredUlozeni(this.state.vent_loziska_motoru_poznamka),
            vent_usporadani: this.state.vent_usporadani,

            vent_vyza_id: this.state.vent_vyza_id,

            vent_remenice_hnaci: this.state.vent_remenice_hnaci,
            vent_remenice_hnana: this.state.vent_remenice_hnana,
            vent_remen_pruhyb: this.state.vent_remen_pruhyb,
            vent_remen_test_sila: this.state.vent_remen_test_sila,
            vent_remenice_profil: this.state.vent_remenice_profil,
            semi_misto: this.state.semi_misto,

            provozniHodnoty: jeOfflineRezim ? this.state.provozniHodnoty : [],
            provozniHodnotyDeleted: jeOfflineRezim ? this.state.provozniHodnotyDeleted : []
        }

        if (this.state.vent_usporadaniEnum != 0) {
            seve_zarizeniVybrane.vent_spojka_provedeni = 0;
            seve_zarizeniVybrane.vent_spojka_typ = "";
        }

        if (this.state.vent_usporadaniEnum != 1) {

            seve_zarizeniVybrane.vent_remen_delka = 0;
            seve_zarizeniVybrane.vent_remen_hz = 0;
            seve_zarizeniVybrane.vent_remen_typ = "";
            seve_zarizeniVybrane.vent_remen_pocet = 0;
            seve_zarizeniVybrane.vent_remenice_hnaci = "";
            seve_zarizeniVybrane.vent_remenice_hnana = "";
            seve_zarizeniVybrane.vent_remen_pruhyb = 0;
            seve_zarizeniVybrane.vent_remen_test_sila = 0;
            seve_zarizeniVybrane.vent_remenice_profil = 0;
        }

        if (("" + seve_zarizeniVybrane.vent_vyrobni_cislo).trim() == "") chyba += "Není zadáno výrobní číslo. ";
        if (!(seve_zarizeniVybrane.vent_rok_vyroby > 1900)) chyba += "Rok výroby musí být číslo větší než 1900. ";
        if (!seve_zarizeniVybrane.semi_misto) chyba += "Není zadáno místo servisu. ";

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }


        if (jeOfflineRezim && bNovyVent) {
            seve_zarizeniVybrane.vent_id = uuidv4();
            seve_zarizeni_idVybrane = seve_zarizeniVybrane.vent_id;
        }

        const dataSeveObject = {
            seve_id: (!jeOfflineRezim || (jeOfflineRezim && !this.state.bNovy)) ? this.state.seve_id : uuidv4(),
            sevy_id: this.props.sevyData.sevy_id,
            seve_opravy: this.state.seve_opravy,
            seve_popis: this.state.seve_popis,
            seve_konecny_stav: Global.textAreaOpravaPredUlozeni(this.state.seve_konecny_stav),
            seve_opravy_doplneno: this.state.seve_opravy_doplneno,
            seve_predal_PrijmeniJmeno: this.state.seve_predal_PrijmeniJmeno,
            seve_predal_id: this.state.seve_predal_id,
            seve_prevzal: this.state.seve_prevzal,

            seve_zarizeni_id: seve_zarizeni_idVybrane,
            seve_zarizeni: seve_zarizeniVybrane,

            seve_offlineStav: this.state.seve_offlineStav
        }

        LoadingSpinner.open();

        if (this.state.bNovy) {
            fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisovaneZarizeni() : fetchWrapper.NovyServisovaneZarizeniOffline(), dataSeveObject, offlineRequest)
                .then(data => {
                    LoadingSpinner.close();

                    this.props.zavritDialog(true, data);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else {
            fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisovaneZarizeni() : fetchWrapper.EditServisovaneZarizeniOffline(), dataSeveObject, offlineRequest)
                .then(data => {
                    LoadingSpinner.close();

                    this.props.zavritDialog(true, null);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false, null);
    }

    otevritPreddefinovaneFraze() {
        if (!this.state.bZobrazitPreddefinovaneFraze) {
            const user = AuthService.getCurrentUser();

            if (user) {
                LoadingSpinner.open();

                fetchWrapper.get(fetchWrapper.VratServisPreddefinovaneFraze(user.uziv_money_prac_id))
                    .then(data => {
                        LoadingSpinner.close();

                        this.setState({ bZobrazitPreddefinovaneFraze: true, preddefinovaneFraze: data });
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se načíst předdefinované fráze: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
    }

    zavritPreddefinovaneFraze() {
        if (this.state.bZobrazitPreddefinovaneFraze) {
            this.setState({ bZobrazitPreddefinovaneFraze: false, preddefinovaneFraze: [] });
        }
    }

    vlozitPreddefinovaneFraze() {
        let chyba = "";
        let vybraneServisFraze = null;
        if (this.tabServPreddFraze.current) {
            vybraneServisFraze = this.tabServPreddFraze.current.getSelectedData();
        }

        if (!vybraneServisFraze || vybraneServisFraze.length <= 0) {
            chyba += "Nebyly vybrány žádné fráze pro vložení. ";
        }

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        let konecnyStav = this.state.seve_konecny_stav;
        for (let i = 0; i < vybraneServisFraze.length; i++) {

            if (konecnyStav != "") konecnyStav += "\n";
            konecnyStav += vybraneServisFraze[i].slpz_cesky_jazyk;
        }

        this.setState({ seve_konecny_stav: konecnyStav });
        this.zavritPreddefinovaneFraze();
    }

    pridatPoleKonecnyStavDoFrazi() {
        this.zavritPreddefinovaneFraze();

        const user = AuthService.getCurrentUser();

        let chyba = "";
        if (("" + this.state.seve_konecny_stav).trim() == "") chyba += "Není zadán žádný text pro přidání do frází. ";
        if (!user) chyba += "Nepodařilo se načíst aktuálně příhlášeného uživatele. ";

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        const dataSpdfObject = {
            spdf_id: Global.GuidEmpty,
            spdf_prac_id: user.uziv_money_prac_id,
            spdf_prac_PrijmeniJmeno: user.uziv_jmeno,
            spdf_preklad_id: Global.GuidEmpty,
            slpz_anglicky_jazyk: "",
            slpz_cesky_jazyk: Global.textAreaOpravaPredUlozeni(this.state.seve_konecny_stav)
        }

        LoadingSpinner.open();

        fetchWrapper.post(fetchWrapper.NovyServisPreddefinovaneFraze(), dataSpdfObject)
            .then(data => {
                LoadingSpinner.close();

                if (data && data.length > 0) {
                    let zprava = "";
                    if (data.length == 1) zprava = "Byla přidána " + data.length + " nová fráze.";
                    else if (data.length > 1 && data.length < 5) zprava = "Byly přidány " + data.length + " nové fráze.";
                    else zprava = "Bylo přidáno " + data.length + " nových frází.";

                    MessageBox.open({
                        title: "Zpráva",
                        content: <p>{zprava} </p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                }
                else {
                    MessageBox.open({
                        title: "Upozornění",
                        content: <p>Nebyly přidány žádné nové fráze.</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                }
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    smazatPoleKonecnyStav() {
        this.zavritPreddefinovaneFraze();
        this.setState({ seve_konecny_stav: "" });
    }

    nacistVybraneZarizeni(zarizeni_Vybrane, zpusobVyberu) {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;
        const offlineRequest = jeOfflineRezim ? { vent_id: zarizeni_Vybrane.vent_id } : null;

        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratZarizeni(zarizeni_Vybrane.vent_id) : fetchWrapper.VratZarizeniOffline(), offlineRequest)
            .then(data => {
                this.setState({
                    vent_id: data.vent_id,
                    vent_hmotnost_kg: data.vent_hmotnost_kg,
                    vent_max_otacky: data.vent_max_otacky,
                    vent_min_otacky: data.vent_min_otacky,
                    vent_popis: data.vent_popis,
                    vent_popis_zarizeni: data.vent_popis_zarizeni,
                    vent_typ: data.vent_typ,
                    vent_typ_ventilatoru: data.vent_typ_ventilatoru,
                    vent_prutok_m3s: data.vent_prutok_m3s,
                    vent_remen_pocet: data.vent_remen_pocet,
                    vent_rok_vyroby: data.vent_rok_vyroby,
                    vent_termin_zaruky: new Date(data.vent_termin_zaruky),
                    vent_vyrobni_cislo: data.vent_vyrobni_cislo,
                    vent_b_fm: data.vent_b_fm,
                    vent_hz: data.vent_hz,
                    vent_vyrobce: data.vent_vyrobce,

                    vent_lozisko_pevne_interval_domazavani: data.vent_lozisko_pevne_interval_domazavani,
                    vent_lozisko_pevne_mazivo: data.vent_lozisko_pevne_mazivo,
                    vent_lozisko_pevne_typ: data.vent_lozisko_pevne_typ,
                    vent_lozisko_pevne_vyrobce: data.vent_lozisko_pevne_vyrobce,
                    vent_lozisko_pevne_zb_rad_vule: data.vent_lozisko_pevne_zb_rad_vule,

                    vent_lozisko_volne_interval_domazavani: data.vent_lozisko_volne_interval_domazavani,
                    vent_lozisko_volne_mazivo: data.vent_lozisko_volne_mazivo,
                    vent_lozisko_volne_typ: data.vent_lozisko_volne_typ,
                    vent_lozisko_volne_vyrobce: data.vent_lozisko_volne_vyrobce,
                    vent_lozisko_volne_zb_rad_vule: data.vent_lozisko_volne_zb_rad_vule,

                    vent_motor_napeti: data.vent_motor_napeti,
                    vent_motor_prikon_kw: data.vent_motor_prikon_kw,
                    vent_motor_typ: data.vent_motor_typ,
                    vent_motor_vyrobce: data.vent_motor_vyrobce,

                    vent_remen_delka: data.vent_remen_delka,
                    vent_remen_hz: data.vent_remen_hz,
                    vent_remen_typ: data.vent_remen_typ,

                    vent_spojka_provedeni: data.vent_spojka_provedeni,
                    vent_spojka_typ: data.vent_spojka_typ,

                    vent_tlak_pa: data.vent_tlak_pa,
                    vent_ulozeni: data.vent_ulozeni,

                    semi_misto: (data.semi_misto && Global.JeGuidValid(data.semi_misto.semi_id)) ? data.semi_misto : this.props.sevyData.semi_misto,

                    vent_provozni_teplota: data.vent_provozni_teplota,
                    vent_otacky_motoru: data.vent_otacky_motoru,
                    vent_motor_proud: data.vent_motor_proud,
                    vent_ostatni_vybaveni_poznamka: data.vent_ostatni_vybaveni_poznamka,

                    vent_lozisko_de_typ: data.vent_lozisko_de_typ,
                    vent_lozisko_de_mazivo: data.vent_lozisko_de_mazivo,
                    vent_lozisko_de_interval_domazavani: data.vent_lozisko_de_interval_domazavani,

                    vent_lozisko_nde_typ: data.vent_lozisko_nde_typ,
                    vent_lozisko_nde_mazivo: data.vent_lozisko_nde_mazivo,
                    vent_lozisko_nde_interval_domazavani: data.vent_lozisko_nde_interval_domazavani,

                    vent_loziska_motoru_poznamka: data.vent_loziska_motoru_poznamka,
                    vent_usporadani: data.vent_usporadani,
                    vent_usporadaniEnum: Global.VratEnumUsporadani(data.vent_usporadani),

                    vent_vyza_id: data.vent_vyza_id,

                    vent_remenice_hnaci: data.vent_remenice_hnaci,
                    vent_remenice_hnana: data.vent_remenice_hnana,
                    vent_remen_pruhyb: data.vent_remen_pruhyb,
                    vent_remen_test_sila: data.vent_remen_test_sila,
                    vent_remenice_profil: data.vent_remenice_profil,

                    provozniHodnoty: data.provozniHodnoty,
                    provozniHodnotyDeleted: data.provozniHodnotyDeleted,

                    zpusobVyberuZarizeni: zpusobVyberu,
                    vent_itemSel: zarizeni_Vybrane,
                    seve_vent_id: zarizeni_Vybrane.vent_id
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                this.resetovatVybraneZarizeni(zpusobVyberu);

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    resetovatVybraneZarizeni(zpusobVyberu) {
        this.setState({
            vent_id: Global.GuidEmpty,
            vent_hmotnost_kg: 0,
            vent_max_otacky: 0,
            vent_min_otacky: 0,
            vent_popis: "",
            vent_popis_zarizeni: "",
            vent_typ: 0,
            vent_typ_ventilatoru: 0,
            vent_prutok_m3s: 0,
            vent_remen_pocet: 0,
            vent_rok_vyroby: 0,
            vent_termin_zaruky: new Date(),
            vent_vyrobni_cislo: "",
            vent_b_fm: false,
            vent_hz: 0,
            vent_vyrobce: "",

            vent_lozisko_pevne_interval_domazavani: 0,
            vent_lozisko_pevne_mazivo: "",
            vent_lozisko_pevne_typ: "",
            vent_lozisko_pevne_vyrobce: "",
            vent_lozisko_pevne_zb_rad_vule: 0,

            vent_lozisko_volne_interval_domazavani: 0,
            vent_lozisko_volne_mazivo: "",
            vent_lozisko_volne_typ: "",
            vent_lozisko_volne_vyrobce: "",
            vent_lozisko_volne_zb_rad_vule: 0,

            vent_motor_napeti: 0,
            vent_motor_prikon_kw: 0,
            vent_motor_typ: "",
            vent_motor_vyrobce: "",

            vent_remen_delka: 0,
            vent_remen_hz: 0,
            vent_remen_typ: "",

            vent_spojka_provedeni: 0,
            vent_spojka_typ: "",

            vent_tlak_pa: 0,
            vent_ulozeni: "",

            semi_misto: this.props.sevyData.semi_misto,

            vent_provozni_teplota: 0,
            vent_otacky_motoru: 0,
            vent_motor_proud: 0,
            vent_ostatni_vybaveni_poznamka: "",

            vent_lozisko_de_typ: "",
            vent_lozisko_de_mazivo: "",
            vent_lozisko_de_interval_domazavani: 0,

            vent_lozisko_nde_typ: "",
            vent_lozisko_nde_mazivo: "",
            vent_lozisko_nde_interval_domazavani: 0,

            vent_loziska_motoru_poznamka: "",
            vent_usporadani: "Napřímo",
            vent_usporadaniEnum: 2,
            vent_vyza_id: Global.GuidEmpty,

            vent_remenice_hnaci: "",
            vent_remenice_hnana: "",
            vent_remen_pruhyb: 0,
            vent_remen_test_sila: 0,
            vent_remenice_profil: 0,

            provozniHodnoty: [],
            provozniHodnotyDeleted: [],

            zpusobVyberuZarizeni: zpusobVyberu
        });
    }

    onZmenaZpusobuVyberuZarizeni(event) {
        if (event.currentTarget.value == 0 && this.state.vent_itemSel) {
            this.nacistVybraneZarizeni(this.state.vent_itemSel, event.currentTarget.value);
        }
        else this.resetovatVybraneZarizeni(event.currentTarget.value);
    }

    onZarizeniVyberChange(event, item) {
        if (item) {
            this.nacistVybraneZarizeni(item, this.state.zpusobVyberuZarizeni);
        }
        else this.resetovatVybraneZarizeni(this.state.zpusobVyberuZarizeni);
    }

    render() {

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const tabServPreddFrazeColumns = [
            {
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerHozAlign: "center", headerSort: false, cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                }
            },
            { field: 'slpz_cesky_jazyk', title: 'Český jazyk', width: '50%', headerSort: true },
            { field: 'slpz_anglicky_jazyk', title: 'Anglický jazyk', width: '50', headerSort: true }
        ];

        return (
            <form onSubmit={this.formSubmit}>
                <h1>{this.state.bNovy ? "Nový záznam" : "Upravit záznam"}</h1>

                <div className='grid_12'>
                    <div className="box round" style={{ position: "relative", minHeight: 260 }}>
                        <h2>Servis zařízení</h2>
                        <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.seve_popis} widthLeft={180} typ="TEXT" onChange={e => this.setState({ seve_popis: e.target.value })} />
                        <EditorHodnotyForm popis="Požadované opravy:" hodnota={this.state.seve_opravy} widthLeft={180} typ="TEXTAREA" onChange={e => this.setState({ seve_opravy: e.target.value })} />
                        <EditorHodnotyForm popis="Požadované opravy (doplněno technikem):" hodnota={this.state.seve_opravy_doplneno} widthLeft={180} typ="TEXTAREA" onChange={e => this.setState({ seve_opravy_doplneno: e.target.value })} />
                        <EditorHodnotyForm popis="Stav po dokončení prací:" hodnota={this.state.seve_konecny_stav} widthLeft={180} typ="TEXTAREA" onChange={e => this.setState({ seve_konecny_stav: e.target.value })} />

                        {!jeOfflineRezim && <div className="form_udaj_container odsazeni_bottom5">
                            <div className="left" style={{ minHeight: 1, width: 180 }}> </div>
                            <div className="right">
                                <button type="button" className={this.state.bZobrazitPreddefinovaneFraze ? "form_button_sel" : "form_button"} style={{ marginLeft: 7, marginBottom: 5, minWidth: 135 }} onClick={this.otevritPreddefinovaneFraze}>Vložit text</button>
                                <button type="button" className="form_button" style={{ marginLeft: 7, marginBottom: 5, minWidth: 135 }} onClick={this.pridatPoleKonecnyStavDoFrazi}>Přidat do frází</button>
                                <button type="button" className="form_button" style={{ marginLeft: 7, marginBottom: 5, minWidth: 135 }} onClick={this.smazatPoleKonecnyStav}>Smazat vše</button>
                            </div>
                        </div>}

                        <div style={{ display: this.state.bZobrazitPreddefinovaneFraze ? "block" : "none" }}>
                            <div className="form_udaj_container" style={{ marginTop: 5 }}>
                                <div className="left" style={{ minHeight: 1, width: 180 }}> </div>
                                <div style={{ width: 'auto' }}>
                                    {this.state.preddefinovaneFraze && this.state.preddefinovaneFraze.length ?
                                        <ReactTabulator
                                            onRef={(r) => (this.tabServPreddFraze = r)}
                                            data={this.state.preddefinovaneFraze}
                                            columns={tabServPreddFrazeColumns}
                                            layout={'fitDataStretch'}
                                            initialSort={[{ column: "slpz_cesky_jazyk", dir: "asc" }]}
                                            style={{ maxHeight: 250 }}
                                        />
                                        :
                                        <div>Žádné fráze.</div>
                                    }
                                </div>
                            </div>

                            <div className="form_udaj_container odsazeni_bottom5">
                                <div className="left" style={{ minHeight: 1, width: 180 }}> </div>
                                <div className="right">
                                    <button type="button" className="form_button" style={{ marginLeft: 7, marginBottom: 5, minWidth: 90 }} onClick={this.zavritPreddefinovaneFraze}>Zavřít</button>
                                    <button type="button" className="form_button" style={{ marginLeft: 7, marginBottom: 5, minWidth: 90 }} onClick={this.vlozitPreddefinovaneFraze}>Vložit</button>
                                </div>
                            </div>
                        </div>

                        <EditorHodnotyForm popis="Předal:" typ="COMBOBOX" klicID="sete_prac_id" klicPopis="prijmeniJmeno" hodnota={this.state.seve_predal_id} name="seve_predal_id" dataCiselnik={this.state.techniciCiselnik} widthLeft={180} onChange={e => this.setState({ seve_predal_id: e.objectSelected.sete_prac_id, seve_predal_PrijmeniJmeno: e.objectSelected.prijmeniJmeno })} />
                        <EditorHodnotyForm popis="Převzal:" hodnota={this.state.seve_prevzal} widthLeft={180} typ="TEXT" onChange={e => this.setState({ seve_prevzal: e.target.value })} />

                        <div className="odsazeni_bottom10 odsazeni_top20">
                            <RadioGroup
                                row
                                value={this.state.zpusobVyberuZarizeni}
                                onChange={this.onZmenaZpusobuVyberuZarizeni}
                                name="filtrVyberZarizeni"
                                style={{ display: "inline" }}
                            >
                                <FormControlLabel size="small" value="1" control={<Radio size="small" />} label="Nové" />
                                <FormControlLabel size="small" value="0" control={<Radio size="small" />} label="Existující zařízení" />
                            </RadioGroup>
                        </div>

                        <div style={{ display: this.state.zpusobVyberuZarizeni == 0 ? "block" : "none" }}>
                            {this.state.ventCiselnik &&
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    value={this.state.vent_itemSel || null}
                                    getOptionLabel={(option) => option.vent_vyrobni_cislo + " - " + option.vent_popis}
                                    options={this.state.ventCiselnik}
                                    sx={{ width: "100%" }}
                                    classes={{}}
                                    renderInput={(params) => <TextField {...params} />}
                                    onChange={this.onZarizeniVyberChange}
                                />
                            }

                            <div className="form_udaj_container">
                                <div className="left" style={{ width: 120 }}> </div>
                                <div className="right">
                                    <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                                    <button type="submit" value="EXISTZARIZENI" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div>
                    <h1>{this.state.zpusobVyberuZarizeni == 0 ? "Existující zařízení" : "Nové zařízení"}</h1>
                    <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                        <div className='grid_6'>
                            <div className="box round" style={{ position: "relative", minHeight: 520 }}>
                                <h2>Základní údaje</h2>
                                <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_typ} typ="COMBOBOX" klicID="kod" klicPopis="popis" dataCiselnik={Global.EnumZarizeniTypCiselnik()} onChange={e => this.setState({ vent_typ: e.target.value })} />
                                <EditorHodnotyForm popis="Výrobní číslo:" hodnota={this.state.vent_vyrobni_cislo} typ="TEXT" disabled={this.state.zpusobVyberuZarizeni == 0} onChange={e => this.setState({ vent_vyrobni_cislo: e.target.value })} />
                                <EditorHodnotyForm popis="Typové označení:" hodnota={this.state.vent_popis} typ="TEXT" onChange={e => this.setState({ vent_popis: e.target.value })} />
                                <EditorHodnotyForm popis="Popis zařízení:" hodnota={this.state.vent_popis_zarizeni} typ="TEXT" onChange={e => this.setState({ vent_popis_zarizeni: e.target.value })} />
                                <EditorHodnotyForm popis="Výrobce:" hodnota={this.state.vent_vyrobce} typ="TEXT" onChange={e => this.setState({ vent_vyrobce: e.target.value })} />
                                <EditorHodnotyForm popis="Rok výroby:" hodnota={this.state.vent_rok_vyroby} typ="CISLO" onChange={e => this.setState({ vent_rok_vyroby: e.target.value })} />
                                <EditorHodnotyForm popis="Termín záruky:" hodnota={this.state.vent_termin_zaruky} stylesRight={{ width: 190 }} typ="DATETIME" onChange={date => this.setState({ vent_termin_zaruky: date })} />
                                <br />
                                <ObjektDetailHodnotaRadek popis="Adresa:" hodnota={this.state.semi_misto.semi_adresa} />
                                <br />
                                <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.vent_ostatni_vybaveni_poznamka} typ="TEXTAREA" minRows={5} onChange={e => this.setState({ vent_ostatni_vybaveni_poznamka: e.target.value })} />
                            </div>
                        </div>
                    </LocalizationProvider>

                    <div className='grid_6'>
                        <div className="box round" style={{ minHeight: 520 }}>
                            <h2>Ventilátor</h2>
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_typ_ventilatoru} widthLeft={140} typ="TYPVENTILATORU" onChange={e => this.setState({ vent_typ_ventilatoru: e.target.value })} />



                            <EditorHodnotyForm popis="Uložení:" hodnota={this.state.vent_ulozeni} widthLeft={140} typ="TYPULOZENI" onChange={e => this.setState({ vent_ulozeni: e.target.value })} />
                            <EditorHodnotyForm popis="Uspořádání:" hodnota={this.state.vent_usporadani} widthLeft={140} typ="VENTUSPORADANI" onChange={e => this.setState({ vent_usporadani: e.target.value, vent_usporadaniEnum: Global.VratEnumUsporadani(e.target.value) })} />

                            <EditorHodnotyForm popis="Min. otáčky [1/min]:" hodnota={this.state.vent_min_otacky} widthLeft={140} typ="CISLO" onChange={e => this.setState({ vent_min_otacky: e.target.value })} />

                            <EditorHodnotyForm popis="Max. otáčky [1/min]:" hodnota={this.state.vent_max_otacky} widthLeft={140} typ="CISLO" onChange={e => this.setState({ vent_max_otacky: e.target.value })} />
                            <EditorHodnotyForm popis="Hmotnost [kg]:" hodnota={this.state.vent_hmotnost_kg} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_hmotnost_kg: e.target.value })} />
                            <EditorHodnotyForm popis="Průtok [m3/s]:" hodnota={this.state.vent_prutok_m3s} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_prutok_m3s: e.target.value })} />
                            <EditorHodnotyForm popis="Tlak  [Pa]:" hodnota={this.state.vent_tlak_pa} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_tlak_pa: e.target.value })} />
                            <EditorHodnotyForm popis="Provozní teplota  [°C]:" hodnota={this.state.vent_provozni_teplota} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_provozni_teplota: e.target.value })} />
                        </div>
                    </div>
                    <div className="clear" />




                    <div className="grid_6">

                        <div className="box round" style={{ minHeight: 425 }}>
                            <h2>Motor</h2>
                            <EditorHodnotyForm popis="Výrobce:" hodnota={this.state.vent_motor_vyrobce} widthLeft={140} typ="TEXT" onChange={e => this.setState({ vent_motor_vyrobce: e.target.value })} />
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_motor_typ} widthLeft={140} typ="TEXT" onChange={e => this.setState({ vent_motor_typ: e.target.value })} />
                            <EditorHodnotyForm popis="Výkon [kW]:" hodnota={this.state.vent_motor_prikon_kw} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_motor_prikon_kw: e.target.value })} />
                            <EditorHodnotyForm popis="Frekvence [Hz]:" hodnota={this.state.vent_hz} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_hz: e.target.value })} />
                            <EditorHodnotyForm popis="Otáčky motoru [1/min]:" hodnota={this.state.vent_otacky_motoru} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_otacky_motoru: e.target.value })} />
                            <EditorHodnotyForm popis="Napětí [V]:" hodnota={this.state.vent_motor_napeti} widthLeft={140} typ="CISLO" onChange={e => this.setState({ vent_motor_napeti: e.target.value })} />
                            <EditorHodnotyForm popis="Proud [A]:" hodnota={this.state.vent_motor_proud} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ vent_motor_proud: e.target.value })} />
                            <EditorHodnotyForm popis="FM:" hodnota={this.state.vent_b_fm} widthLeft={140} typ="CHECKBOX" onChange={e => this.setState({ vent_b_fm: !this.state.vent_b_fm })} />
                        </div>
                    </div>

                    {this.state.vent_usporadaniEnum == 1 &&
                        <div className="grid_6">
                            <div className="box round" style={{ minHeight: 425 }}>
                                <h2>Řemen</h2>
                                <EditorHodnotyForm popis="Typ řemenu:" hodnota={this.state.vent_remen_typ} widthLeft={160} typ="TEXT" onChange={e => this.setState({ vent_remen_typ: e.target.value })} />
                                <EditorHodnotyForm popis="Počet řemenů:" hodnota={this.state.vent_remen_pocet} widthLeft={160} typ="CISLO" onChange={e => this.setState({ vent_remen_pocet: e.target.value })} />
                                <EditorHodnotyForm popis="Délka řemenu LW:" hodnota={this.state.vent_remen_delka} widthLeft={160} typ="CISLOD" onChange={e => this.setState({ vent_remen_delka: e.target.value })} />
                                <EditorHodnotyForm popis="Profil řemenice:" hodnota={this.state.vent_remenice_profil} widthLeft={160} typ="COMBOBOX" klicID="kod" klicPopis="popis" dataCiselnik={Global.EnumProfilRemeniceCiselnik()} onChange={e => this.setState({ vent_remenice_profil: e.target.value })} />
                                <EditorHodnotyForm popis="Řemenice hnací:" hodnota={this.state.vent_remenice_hnaci} widthLeft={160} typ="TEXT" onChange={e => this.setState({ vent_remenice_hnaci: e.target.value })} />
                                <EditorHodnotyForm popis="Řemenice hnaná:" hodnota={this.state.vent_remenice_hnana} widthLeft={160} typ="TEXT" onChange={e => this.setState({ vent_remenice_hnana: e.target.value })} />
                                <EditorHodnotyForm popis="Řemen - frekvence [Hz]:" hodnota={this.state.vent_remen_hz} widthLeft={160} typ="CISLOD" onChange={e => this.setState({ vent_remen_hz: e.target.value })} />
                                <EditorHodnotyForm popis="Průhyb řemenu [mm]:" hodnota={this.state.vent_remen_pruhyb} widthLeft={160} typ="CISLOD" onChange={e => this.setState({ vent_remen_pruhyb: e.target.value })} />
                                <EditorHodnotyForm popis="Řemen - testovací síla [N]:" hodnota={this.state.vent_remen_test_sila} widthLeft={160} typ="CISLOD" onChange={e => this.setState({ vent_remen_test_sila: e.target.value })} />
                            </div>
                        </div>
                    }

                    {this.state.vent_usporadaniEnum == 0 &&
                        <div className="grid_6">
                            <div className="box round" style={{ minHeight: 100 }}>
                                <h2>Spojka</h2>
                                <EditorHodnotyForm popis="Typ spojky:" hodnota={this.state.vent_spojka_typ} widthLeft={150} typ="TEXT" onChange={e => this.setState({ vent_spojka_typ: e.target.value })} />
                                <EditorHodnotyForm popis="Provedení spojky:" hodnota={this.state.vent_spojka_provedeni} widthLeft={150} typ="COMBOBOX" klicID="kod" klicPopis="popis" dataCiselnik={Global.EnumSpojkaProvedeniCiselnik()} onChange={e => this.setState({ vent_spojka_provedeni: e.target.value })} />
                            </div>
                        </div>
                    }

                    <div className="clear"></div>


                    <div className="grid_6" >
                        <div className="box round" style={{ minHeight: 250 }}>
                            <h2>Ložisko ventilátoru pevné</h2>
                            <EditorHodnotyForm popis="Výrobce:" hodnota={this.state.vent_lozisko_pevne_vyrobce} typ="TEXT" onChange={e => this.setState({ vent_lozisko_pevne_vyrobce: e.target.value })} />
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_lozisko_pevne_typ} typ="TEXT" onChange={e => this.setState({ vent_lozisko_pevne_typ: e.target.value })} />
                            <EditorHodnotyForm popis="Mazivo:" hodnota={this.state.vent_lozisko_pevne_mazivo} typ="TEXT" onChange={e => this.setState({ vent_lozisko_pevne_mazivo: e.target.value })} />
                            <EditorHodnotyForm popis="Zb. rad. vůle [mm]:" hodnota={this.state.vent_lozisko_pevne_zb_rad_vule} typ="CISLOD" onChange={e => this.setState({ vent_lozisko_pevne_zb_rad_vule: e.target.value })} />
                            <EditorHodnotyForm popis="Interval domaz.[hod]:" hodnota={this.state.vent_lozisko_pevne_interval_domazavani} typ="CISLO" onChange={e => this.setState({ vent_lozisko_pevne_interval_domazavani: e.target.value })} />
                        </div>
                    </div>

                    <div className="grid_6">
                        <div className="box round" style={{ minHeight: 250 }}>
                            <h2>Ložisko ventilátoru volné</h2>
                            <EditorHodnotyForm popis="Výrobce:" hodnota={this.state.vent_lozisko_volne_vyrobce} typ="TEXT" onChange={e => this.setState({ vent_lozisko_volne_vyrobce: e.target.value })} />
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_lozisko_volne_typ} typ="TEXT" onChange={e => this.setState({ vent_lozisko_volne_typ: e.target.value })} />
                            <EditorHodnotyForm popis="Mazivo:" hodnota={this.state.vent_lozisko_volne_mazivo} typ="TEXT" onChange={e => this.setState({ vent_lozisko_volne_mazivo: e.target.value })} />
                            <EditorHodnotyForm popis="Zb. rad. vůle [mm]:" hodnota={this.state.vent_lozisko_volne_zb_rad_vule} typ="CISLOD" onChange={e => this.setState({ vent_lozisko_volne_zb_rad_vule: e.target.value })} />
                            <EditorHodnotyForm popis="Interval domaz.[hod]:" hodnota={this.state.vent_lozisko_volne_interval_domazavani} typ="CISLO" onChange={e => this.setState({ vent_lozisko_volne_interval_domazavani: e.target.value })} />
                        </div>
                    </div>
                    <div className="clear"></div>


                    <div className="grid_6">
                        <div className="box round" style={{ minHeight: 120, marginBottom: 5 }}>
                            <h2>Ložisko motoru DE</h2>
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_lozisko_de_typ} widthLeft={150} typ="TEXT" onChange={e => this.setState({ vent_lozisko_de_typ: e.target.value })} />
                            <EditorHodnotyForm popis="Mazivo:" hodnota={this.state.vent_lozisko_de_mazivo} widthLeft={150} typ="TEXT" onChange={e => this.setState({ vent_lozisko_de_mazivo: e.target.value })} />
                            <EditorHodnotyForm popis="Interval domaz.[hod]:" hodnota={this.state.vent_lozisko_de_interval_domazavani} widthLeft={150} typ="CISLO" onChange={e => this.setState({ vent_lozisko_de_interval_domazavani: e.target.value })} />
                        </div>
                    </div>

                    <div className="grid_6">
                        <div className="box round" style={{ minHeight: 120, marginBottom: 10 }}>
                            <h2>Ložisko motoru NDE</h2>
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.vent_lozisko_nde_typ} widthLeft={150} typ="TEXT" onChange={e => this.setState({ vent_lozisko_nde_typ: e.target.value })} />
                            <EditorHodnotyForm popis="Mazivo:" hodnota={this.state.vent_lozisko_nde_mazivo} widthLeft={150} typ="TEXT" onChange={e => this.setState({ vent_lozisko_nde_mazivo: e.target.value })} />
                            <EditorHodnotyForm popis="Interval domaz.[hod]:" hodnota={this.state.vent_lozisko_nde_interval_domazavani} widthLeft={150} typ="CISLO" onChange={e => this.setState({ vent_lozisko_nde_interval_domazavani: e.target.value })} />
                        </div>
                    </div>
                    <div className="clear" />
                    <div className="grid_12">
                        <EditorHodnotyForm popis="Ložiska motoru - poznámka:" hodnota={this.state.vent_loziska_motoru_poznamka} widthLeft={180} typ="TEXTAREA" onChange={e => this.setState({ vent_loziska_motoru_poznamka: e.target.value })} />
                    </div>
                    <div className="clear" />

                    <div className="form_udaj_container">
                        <div className="left" style={{ width: 120 }}> </div>
                        <div className="right">
                            <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                            <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                        </div>
                    </div>

                </div>

            </form>
        );
    }
}
