import React from 'react';
import CacheData from "../CacheData"
import { fetchWrapper } from "../FetchWrapper";

import { v4 as uuidv4 } from 'uuid';

import { EditorHodnotyForm } from "./EditorHodnotyForm";
import { FormControlLabel, Checkbox } from '@mui/material';

import { Global } from "./Global"
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

const emptyItemVybCiselnik = { id: Global.GuidEmpty, sepvc_popis: "-", sepvc_hodinova_sazba: 0 };

export class ServisVyjezdPotrebneVybaveniForm extends React.Component {
    constructor(props) {
        super(props);

        const bValidCiselnikId = props.dataObject && Global.JeGuidValid(props.dataObject.sepvc_id);

        if (props.dataObject) this.state = {
            sepv_id: props.dataObject.sepv_id,
            sepv_poznamka: props.dataObject.sepv_poznamka,
            sepv_mnozstvi_hodiny: props.dataObject.sepv_mnozstvi_hodiny,
            sepv_mnozstvi_minuty: props.dataObject.sepv_mnozstvi_minuty,
            sepv_hodinova_sazba: props.dataObject.sepv_hodinova_sazba,
            sepvc_id: bValidCiselnikId ? props.dataObject.sepvc_id : Global.GuidEmpty,
            sepvc_popis: bValidCiselnikId ? props.dataObject.sepvc_popis : "-",
            sepvc_hodinova_sazba: bValidCiselnikId ? props.dataObject.sepvc_hodinova_sazba : 0,
            sepv_offlineStav: props.dataObject.sepv_offlineStav,

            bNovy: false,
            bHromadneZadani: false,
            dataPotrebneVybaveniCiselnik: null
        }
        else this.state = {
            sepv_id: Global.GuidEmpty,
            sepv_poznamka: "",
            sepv_mnozstvi_hodiny: 0,
            sepv_mnozstvi_minuty: 0,
            sepv_hodinova_sazba: 0,
            sepvc_id: Global.GuidEmpty,
            sepvc_popis: "-",
            sepvc_hodinova_sazba: 0,
            sepv_offlineStav: 0,

            bNovy: true,
            bHromadneZadani: false,
            dataPotrebneVybaveniCiselnik: null
        }

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.onFiltrHromadneZadaniChecked = this.onFiltrHromadneZadaniChecked.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
    }

    onParamaterChange(event) {
        if (event.target.name == "sepv_poznamka") this.setState({ sepv_poznamka: event.target.value });
        else if (event.target.name == "sepv_hodinova_sazba") this.setState({ sepv_hodinova_sazba: event.target.value });
        else if (event.target.name == "sepv_mnozstvi_hodiny") this.setState({ sepv_mnozstvi_hodiny: event.target.value });
        else if (event.target.name == "sepv_mnozstvi_minuty") this.setState({ sepv_mnozstvi_minuty: event.target.value });
        else if (event.target.name == "sepvc_id") {
            this.setState({ sepvc_id: event.target.value, sepvc_popis: event.objectSelected.sepvc_popis, sepvc_hodinova_sazba: event.objectSelected.sepvc_hodinova_sazba, sepv_hodinova_sazba: event.objectSelected.sepvc_hodinova_sazba });
        }
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();

        const zarizeniSevy = this.props.sevyData.servisZarizeni.find(v => v.seve_zarizeni.vent_id == this.props.zarizeni.vent_id);
        if (zarizeniSevy == null) this.zavritDialog();
    }

    inicializace() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisPotrebneVybaveniCiselnik() : fetchWrapper.VratServisPotrebneVybaveniCiselnikOffline())
            .then(data => {

                const dataPotrebneVybaveniCiselnikNew = [emptyItemVybCiselnik];
                if (this.state.sepvc_id && this.state.sepvc_id != Global.GuidEmpty) {
                    if (!data.find(vc => vc.id == this.state.sepvc_id)) {
                        dataPotrebneVybaveniCiselnikNew.push({
                            id: this.state.sepvc_id,
                            sepvc_hodinova_sazba: this.state.sepvc_hodinova_sazba,
                            sepvc_popis: this.state.sepvc_popis
                        });
                    }
                }
                dataPotrebneVybaveniCiselnikNew.push(...data);

                this.setState({ dataPotrebneVybaveniCiselnik: dataPotrebneVybaveniCiselnikNew });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník vybavení: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    formSubmit(event) {
        event.preventDefault();

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const dataObject = {
            sepv_id: (!jeOfflineRezim || (jeOfflineRezim && !this.state.bNovy)) ? this.state.sepv_id : uuidv4(),
            sepv_mnozstvi_hodiny: this.state.sepv_mnozstvi_hodiny,
            sepv_mnozstvi_minuty: this.state.sepv_mnozstvi_minuty,
            sepv_poznamka: Global.textAreaOpravaPredUlozeni(this.state.sepv_poznamka),
            sepv_hodinova_sazba: this.state.sepv_hodinova_sazba,
            sepvc_id: this.state.sepvc_id,
            sepvc_hodinova_sazba: this.state.sepvc_hodinova_sazba,
            sepvc_popis: this.state.sepvc_popis,
            sevy_id: this.props.sevyData.sevy_id,
            vent_id: this.props.zarizeni.vent_id,
            sepv_offlineStav: this.state.sepv_offlineStav
        }


        let chyba = "";
        if (!(dataObject.sepv_mnozstvi_hodiny > 0) && !(dataObject.sepv_mnozstvi_minuty > 0)) chyba += "Není zadáno množství. ";
        if (dataObject.sepv_poznamka.trim() == "" && !Global.JeGuidValid(dataObject.sepvc_id)) chyba += "Není zadána poznámka. ";

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        if (this.state.bNovy) {
            LoadingSpinner.open();

            if (this.state.bHromadneZadani) {
                fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisPotrebneVybaveniHromadne() : fetchWrapper.NovyServisPotrebneVybaveniHromadneOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
            else {
                fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisPotrebneVybaveni() : fetchWrapper.NovyServisPotrebneVybaveniOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
        else {
            LoadingSpinner.open();

            if (this.state.bHromadneZadani) {
                fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisPotrebneVybaveniHromadne() : fetchWrapper.EditServisPotrebneVybaveniHromadneOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
            else {
                fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisPotrebneVybaveni() : fetchWrapper.EditServisPotrebneVybaveniOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false, null);
    }

    onFiltrHromadneZadaniChecked() {
        const novyStav = !this.state.bHromadneZadani;

        this.setState({ bHromadneZadani: novyStav });
    }


    render() {
        return (
            <form onSubmit={this.formSubmit}>
                <h1>Speciální vybavení - {this.state.bNovy ? "nový záznam" : "upravit záznam"}</h1>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}>Vybrané zařízení: </div>
                    <div className="righttext">
                        {this.state.bHromadneZadani ? "Všechna zařízení" : this.props.zarizeni.vent_vyrobni_cislo + " - " + this.props.zarizeni.vent_popis}
                    </div>
                </div>

                <div className="form_udaj_container">
                    {this.state.dataPotrebneVybaveniCiselnik ?
                        <EditorHodnotyForm popis="Vybavení z číselníku:" hodnota={this.state.sepvc_id} widthLeft={140} klicID="id" klicPopis="sepvc_popis" widthLeft={140} typ="COMBOBOX" dataCiselnik={this.state.dataPotrebneVybaveniCiselnik} onChange={e => this.setState({ sepvc_id: e.target.value, sepvc_popis: e.objectSelected.sepvc_popis, sepvc_hodinova_sazba: e.objectSelected.sepvc_hodinova_sazba, sepv_hodinova_sazba: e.objectSelected.sepvc_hodinova_sazba })} />
                        : <img src="./img/loading16.gif" height={30} />
                    }
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.sepv_poznamka} widthLeft={140} typ="TEXTAREA" onChange={e => this.setState({ sepv_poznamka: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Hod.sazba [CZK]:" hodnota={this.state.sepv_hodinova_sazba} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ sepv_hodinova_sazba: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Množství [hod]:" hodnota={this.state.sepv_mnozstvi_hodiny} widthLeft={140} typ="CISLO" onChange={e => this.setState({ sepv_mnozstvi_hodiny: e.target.value })} />
                    <EditorHodnotyForm popis="Množství [min]:" hodnota={this.state.sepv_mnozstvi_minuty} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ sepv_mnozstvi_minuty: e.target.value })} />
                </div>


                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                        <FormControlLabel style={{ display: 'inline-flex', marginLeft: 10 }}
                            name="filtrHromadneZadani"
                            control={<Checkbox
                                checked={this.state.bHromadneZadani}
                                onClick={this.onFiltrHromadneZadaniChecked} />}
                            label="Hromadný zápis" />
                    </div>
                </div>
            </form>
        );
    }
}