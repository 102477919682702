import React from 'react';
import styles from './SMapy.module.css';

import MessageBox from "../MessageBox/MessageBox";
import LoadingSpinner from "../Loader/LoadingSpinner";

export class SMapy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMapLoaded: false,
        }
        this.scriptLoaded = this.scriptLoaded.bind(this);
        this.onMapApiLoaded = this.onMapApiLoaded.bind(this);
    }

    scriptLoaded() {
        // Nastavíme asynchronní zpracování
        window.Loader.async = true;
        // Po načtení map nastavíme state a vykreslíme mapu
        window.Loader.load(
            null,
            null,
            this.onMapApiLoaded
        );
    }

    componentDidMount() {
        const scriptTag = "map-loader-script";

        // Pokud ještě nebyl script tag vložen
        if (!document.getElementById(scriptTag)) {
            
            // Vytvoříme script tag s odkazem na SMap API
            const script = document.createElement("script");
            script.src = "https://api.mapy.cz/loader.js";
            script.id = "map-loader-script";
            script.async = true;
            script.onload = () => this.scriptLoaded();
     
            document.body.append(script);
        }
        else this.setState({ isMapLoaded: true });
    }


    onMapApiLoaded() {
        this.setState({ isMapLoaded: true });
    }

    render() {
        //console.log("RENDER MAPY: " + this.props.mistoAdresa);

        if (this.props.mistoAdresa == null || this.props.mistoAdresa == undefined || this.props.mistoAdresa.trim() == "") {
            
            return (<div id="wrapper"><div className="info_zprava">Není nastavena žádná adresa.</div></div>);
        }
        else {

            return (
                <div id="wrapper">
                    {this.state.isMapLoaded && this.props.mistoAdresa
                        ? <Map mistoAdresa={this.props.mistoAdresa} zpracovatDataAdresyZMapy={this.props.zpracovatDataAdresyZMapy} />
                        : <img style={{ margin: "auto", display: "block" }} src="./img/loading.gif" />
                    }
                </div>
            );
        }
    }
}

class Map extends React.Component {
    constructor(props) {
        super(props);

        this.state = { stavMapy: 0, error: null, resultTrasa: null, posledniAdresa: "" };

        this.reloadMapy = this.reloadMapy.bind(this);
        this.VratCasTrasy = this.VratCasTrasy.bind(this);
    }

    componentDidMount() {
        this.reloadMapy();
    }

    componentDidUpdate(prevProps) {
        this.reloadMapy();
    }

    reloadMapy() {
        const adresa = this.props.mistoAdresa;       
        const firma = "";

        if (adresa != this.state.posledniAdresa) this.setState({ posledniAdresa: adresa, error: null });
        else return;

        //console.log("HLEDANÁ ADRESA: " + adresa);

        const setResults = (result) => { this.setState({ resultTrasa: result }); };

        const zmenitStavMapy = (stav, err) => { this.setState({ stavMapy: stav, error: err }) };

        const odeslatDataAdresy = (adresaObj) => { this.props.zpracovatDataAdresyZMapy(adresaObj); };



        new window.SMap.Geocoder(adresa, function (geocoder) {
            const results = geocoder.getResults()[0].results;

            if (!results || results.length == 0) {
                /*MessageBox.open({
                    title: "Chyba",
                    content: <p>Adresa {adresa} nebyla nalezena.</p>,
                    buttons: [
                        { name: "OK", handler: () => "ok" }
                    ]
                });*/

                console.log("Nepodařilo se najít adresu na mapě.");
                zmenitStavMapy(-1, "Nepodařilo se najít adresu na mapě.");
                return;
            }

            const bodCil = results[0].coords;


            const adresaObj = {
                semi_gps: bodCil.toWGS84(2).reverse().join(" "),
            }
            odeslatDataAdresy(adresaObj);


            //nacteni kompletni adresy
            var callback = function (gc) {
                const resultAdresa = gc.getResults();
                console.log(resultAdresa);
                if (resultAdresa) {
                    const adresaObj = {
                        semi_misto: "",
                        semi_psc: "",
                        semi_ulice: "",
                        semi_stat: "",
                    }

                    let bPSCNalezeno = false;
                    for (let i = 0; i < resultAdresa.items.length; i++)
                    {
                        if (resultAdresa.items[i].type == "muni" || resultAdresa.items[i].type == "osmm") adresaObj.semi_misto = resultAdresa.items[i].name;                        
                        if (resultAdresa.items[i].type == "stre" ||resultAdresa.items[i].type == "addr" || resultAdresa.items[i].type == "osms") adresaObj.semi_ulice = resultAdresa.items[i].name;
                        if (resultAdresa.items[i].type == "coun" || resultAdresa.items[i].type == "osmc") adresaObj.semi_stat = resultAdresa.items[i].name;                        
                        if (resultAdresa.items[i].type == "osma") {
                            adresaObj.semi_psc = resultAdresa.items[i].name;
                            bPSCNalezeno = true;
                        }
                    }

                    if (!bPSCNalezeno) {
                        const resPSC = resultAdresa.label.match(/.*(\d\d\d\s*\d\d).*/);

                        if (resPSC && resPSC.length > 1) {
                            adresaObj.semi_psc = resPSC[1];
                            bPSCNalezeno = true;
                        }
                    }

                    if (!bPSCNalezeno) {
                        zmenitStavMapy(-1, "Zadání adresy vyžaduje upřesnění.");
                        return;
                    }

                    odeslatDataAdresy(adresaObj);
                }
            };

            var geocoder = new window.SMap.Geocoder.Reverse(bodCil, callback);


            //kresleni mapy
            const stred = window.SMap.Coords.fromWGS84(bodCil.x, bodCil.y);
            const mapa = new window.SMap(window.JAK.gel("mapa"), stred, 12);
            mapa.addDefaultLayer(window.SMap.DEF_BASE).enable();
            mapa.addDefaultControls();

            const layer = new window.SMap.Layer.Marker();
            mapa.addLayer(layer);
            layer.enable();

            const geometryLayer = new window.SMap.Layer.Geometry();
            mapa.addLayer(geometryLayer).enable();


            //trasa
            const planRoute = (route) => {
                const resultRoute = route.getResults();
                //vykresleni trasy do mapy (musi se povolit geometry v window.SMap.Route.route volani na true)
                //const g = new window.SMap.Geometry(window.SMap.GEOMETRY_POLYLINE, null, result.geometry);
                // geometryLayer.addGeometry(g);
                setResults(resultRoute);

                const adresaObj = {
                    semi_vzdalenost_km: Math.round((resultRoute.length / 1000))
                }
                odeslatDataAdresy(adresaObj);

            }

            const coordsTrasa = [
                window.SMap.Coords.fromWGS84(bodCil.x, bodCil.y),
                window.SMap.Coords.fromWGS84(14.0099450, 49.0013808)
            ];

            window.SMap.Route.route(coordsTrasa, {
                geometry: false
            }).then(planRoute);

            //znacka
            const znacka = window.JAK.mel("div");
            const obrazek = window.JAK.mel("img", { src: window.SMap.CONFIG.img + "/marker/drop-red.png" });
            znacka.appendChild(obrazek);

            const popisek = window.JAK.mel("div", {}, { fontSize: "10px", lineHeight: "12px", position: "absolute", left: "23px", top: "2px", textAlign: "center", height: "22px", color: "black", fontWeight: "bold" });
            popisek.innerHTML = adresa;
            znacka.appendChild(popisek);


            const c = new window.SMap.Card();
            //c.getHeader().innerHTML = "";
            //c.getFooter().innerHTML = "";

            //c.getBody().style.margin = "5px 0px";
            //c.getBody().innerHTML += "<strong>" + adresa + "</strong>";


            const options = {
                title: adresa,
                close: false,
                url: znacka
            };
            const marker = new window.SMap.Marker(stred, "myMarker", options);
            //marker.decorate(window.SMap.Marker.Feature.Card, c);
            layer.addMarker(marker);

            zmenitStavMapy(1, null);
        });
    }

    VratCasTrasy(casMinuty) {
        var hodiny = Math.floor(casMinuty / 60);
        var minuty = casMinuty % 60;

        if (hodiny > 0) {
            if (minuty > 0) return hodiny + " hod " + minuty + " min";
            else return hodiny + " hod";
        }
        else return minuty + " min";
    }

    render() {
        if (this.state.stavMapy == -2) return <></>;

        return (
            <>    
                {this.state.stavMapy == 0 && <img style={{ margin: "auto", display: "block" }} src="./img/loading.gif" />}

                <div className={styles.mapcontainer}>
                    {this.state.stavMapy == -1 && <div className={styles.error_hledani + " error_zprava"} >{this.state.error}</div>}

                    <div className={styles.mapresults}>
                        <ul>
                            {this.state.resultTrasa &&
                                <li>Cesta z Prachatic:<br /><p>⏩{Math.round(this.state.resultTrasa.length / 1000)}km, 🕓{this.VratCasTrasy(Math.round(this.state.resultTrasa.time / 60))}</p> </li>}
                        </ul>
                    </div>
                    <div id="mapa" className={styles.map} />                    
                </div>
            </>
            
        );
    }
}
//ReactDOM.render(<SMapy />, document.querySelector("#app"))