import React from 'react';
import AuthService from "../AuthService";
import { QrReader } from 'react-qr-reader';
import { fetchWrapper } from "../FetchWrapper";
import { Radio, RadioGroup, FormControlLabel, TextField } from '@mui/material';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";
import CacheData from "../CacheData";


function getCamera() {
    let stream = null;
    try {
        stream = navigator.getUserMedia({ audio: false, video: true },
            (stream) => {
                /*const video = document.querySelector('video');
                video.srcObject = stream;
                video.onloadedmetadata = (e) => {
                    video.play();
                };*/

                console.log("Permision getCamera OK");
                return "OK";
            },
            (err) => {
                console.error(`The following error occurred: ${err.name}`);
            });       
        
    } catch (err) {
        /* handle the error */
        console.log("Permision getCamera CHYBA" + err);
        return "" + err;
    }
}


export class FormLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { login: "", password: "", typLog: "UZ", QR: "", kameraChyba: "" };

        this.handleLogin = this.handleLogin.bind(this);
        this.onTypPrihlaseni = this.onTypPrihlaseni.bind(this);
        this.QRScanError = this.QRScanError.bind(this);
        this.QRScanSuccess = this.QRScanSuccess.bind(this);
    }



    handleLogin = async (e) => {
        e.preventDefault();

        if (this.state.typLog === "UZ") {
            LoadingSpinner.open();

            try {
                await AuthService.login(this.state.login, this.state.password)
                    .then(() => {
                        //refresh posledniho nastavenho vyjezdu
                        const detailServisVyjezdVychozi = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
                        if (detailServisVyjezdVychozi) {
                            fetchWrapper.get(fetchWrapper.VratServisniVyjezd(detailServisVyjezdVychozi.sevy_id))
                                .then(dataVyjezd => {
                                    console.log(dataVyjezd);
                                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", dataVyjezd);
                                    window.dispatchEvent(new Event('sevyStorageUpdate'));
                                })
                                .catch(error => {
                                    console.log("Nepodařilo se načíst data výjezdu ID=" + detailServisVyjezdVychozi.sevy_id + ":" + error);
                                    CacheData.DeleteData(AuthService.getCurrentUser(), "servisvyjezd");
                                });
                        }

                        LoadingSpinner.close();
                        this.props.navigate('/', { replace: true });
                    },
                        (error) => {
                            console.log(error);

                            LoadingSpinner.close();
                            MessageBox.open({
                                title: "Chyba",
                                content: <p>Nesprávné přihlašovací údaje nebo problém s připojením.<br />{error.message}</p>,
                                buttons: [
                                    { name: "OK", handler: () => "OK" }
                                ]
                            });
                        }
                    );
            } catch (err) {
                LoadingSpinner.close();
                console.log(err);

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Chyba: {err}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            }
        }
        else {
            this.QRScanSuccess(this.state.QR);
        }

    };



    onTypPrihlaseni(val) {
        if (val == "QR") {
            const res = getCamera();
            if (res != "OK") this.setState({ kameraChyba: res });
        }

        this.setState({ typLog: val });
    }


    QRScanError = (err) => {
        console.log(err);
        this.setState({ kameraChyba: err });
    }

    QRScanSuccess = (result) => {
        if (result) {
            this.setState({ kameraChyba: "" });

            fetchWrapper.get(fetchWrapper.VratZarizeniVyrobniCislo(result)).then(
                dataZar => {
                    if (dataZar.length === 1) { //pouze jedno zarizeni nalezeno => rovnou otevrit detail
                        this.props.navigate("/zarizeni-nahled/" + dataZar[0].vent_id);

                    }
                    else {
                        this.setState({ kameraChyba: "Zařízení s výrobním číslem " + result + " neexistuje." });
                    }
                }
            );
        }
    }



    render() {
        const QRPreviewStyle = {
            height: 320,
            width: 320,
        }

        return (
            <div>
                <div className='centerdiv-parent'>
                    <div className='centerdiv'>
                        <form onSubmit={this.handleLogin}>
                            <h2 style={{ textAlign:"center" }}>Přihlášení</h2>

                            <div className='odsazeni_bottom10 odsazeni_top10'>
                                <RadioGroup
                                    row
                                    value={this.state.typLog}
                                    onChange={(e, value) => this.onTypPrihlaseni(value)}
                                    name="TypPrihlaseni"
                                    style={{ display: "inline"}}
                                >
                                    <FormControlLabel size="small" value="UZ" control={<Radio size="small" />} label="Jako uživatel" />
                                    <FormControlLabel size="small" value="QR" control={<Radio size="small" />} label="QR kód" />
                                </RadioGroup>
                            </div>

                            {this.state.typLog == "UZ" && <div>
                                <div className='odsazeni_bottom10 odsazeni_top10'>
                                    <TextField
                                        size="small"
                                        placeholder="přihlašovací jméno"
                                        onChange={e => this.setState({ login: e.target.value })}
                                        value={this.state.login}
                                        sx={{ width: "100%" }}
                                        onFocus={event => {
                                            if (!this.props.disabled) event.target.select();
                                        }}
                                    />
                                </div>

                                <div className='odsazeni_bottom10'>
                                    <TextField
                                        size="small"
                                        placeholder="heslo"
                                        onChange={e => this.setState({ password: e.target.value })}
                                        value={this.state.password}
                                        sx={{ width: "100%" }}
                                        onFocus={event => {
                                            if (!this.props.disabled) event.target.select();
                                        }}
                                        type="password"
                                    />
                                </div>

                                <button className="form_button" type="submit" style={{ width: "100%" }}>Přihlásit se</button>
                            </div>}

                            {this.state.typLog == "QR" && <div>
                                <QrReader
                                    delay={300}
                                    onError={this.QRScanError}
                                    onResult={this.QRScanSuccess}
                                    style={QRPreviewStyle}
                                    constraints={{
                                        facingMode: 'environment'
                                    }}
                                />
                                {<div style={{ color: "red" }}>{this.state.kameraChyba}</div>}
                                <div className='odsazeni_bottom10 odsazeni_top10'>
                                    <TextField
                                        size="small"
                                        placeholder="Kód ručně"
                                        onChange={e => this.setState({ QR: e.target.value })}
                                        value={this.state.QR}
                                        sx={{ width: "100%" }}
                                        onFocus={event => {
                                            if (!this.props.disabled) event.target.select();
                                        }}
                                    />

                                    <button className="form_button" type="submit" style={{ width: "100%", marginTop: 5 }}>Najít zařízení</button>
                                </div>
                            </div>}


                        </form>
                    </div>
                </div>
            </div>
        );
    }

}