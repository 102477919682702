import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import AuthService from "./AuthService";
import { FormLogin } from './components/FormLogin';
import './custom.css';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import { PrivateRoute, DefaultRoute } from "./PrivateRoute";

import MessageBox from "./components/MessageBox/MessageBox";
import { Home } from "./components/Home";
import { Uzivatele } from "./components/Uzivatele";
import { ZarizeniList } from "./components/ZarizeniList";
import { ServisVyjezd } from "./components/ServisVyjezd";
import { ZarizeniNahled } from "./components/ZarizeniNahled";

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Navbar from './components/Menu/Navbar';

/*let serviceWorker = null;
function onSWUpdate(registration) {
    MessageBox.open({
        title: "Dotaz",
        content: <p>Detekov�na nov� verze aplikace {window.config.VERSION}! Po potvrzen� okna prob�hne jej� instalace.</p>,
        buttons: [
            { name: "Potvrdit", handler: () => "ano" },
        ]
    });

    serviceWorker = registration.waiting;
    console.log(serviceWorker);
    updateSW();
}

function updateSW() {
    console.log("UPDATE START");

    if (!serviceWorker) return
    // Add listener for state change of service worker
    serviceWorker.onstatechange = () => {
        if (serviceWorker?.state === "activated" &&
            navigator.serviceWorker.controller) {
            // Reload page if waiting was successfully skipped
            window.location.reload();
            console.log("�PAGE RELOAD UPDATE");
        }
    }
    serviceWorker.postMessage({ type: "SKIP_WAITING" });
    console.log("UPDATE KONEC");
}

function onSWSuccess() {
    console.log("App installed as a PWA.")
}*/


function App() {
    const navigate = useNavigate();

    const logout = () => {
        AuthService.logout();
        navigate("/");
    }


    return (
        <div className="container_12">
            <Header title="Servis" logout={logout} />
            <div className="clear" />
            <Navbar />
            <div className="clear" />
            <main>
                <Routes>
                    <Route key={true} exact path='/' element={<PrivateRoute />}>
                        <Route key={true} exact path='/' element={<Home navigate={navigate} />} />
                    </Route>

                    <Route exact path='/uzivatele' element={<PrivateRoute />}>
                        <Route exact path='/uzivatele' element={<Uzivatele />} />
                    </Route>

                    <Route path='/zarizeni' element={<PrivateRoute />}>
                        <Route path='/zarizeni' element={<ZarizeniList />} />
                    </Route>

                    <Route path='/servisvyjezd' element={<PrivateRoute />}>
                        <Route path='/servisvyjezd' element={<ServisVyjezd navigate={navigate} />} />
                    </Route>

                    <Route path="/login" element={<FormLogin navigate={navigate} />} />

                    <Route path="/zarizeni-nahled/:id" element={<ZarizeniNahled />} />

                    <Route path="/*" element={<DefaultRoute />} >
                    </Route>
                </Routes>
            </main>
            <div className="clear" />
            <Footer />
        </div>
    );
}

export default App;
