import React from 'react';
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";
import { EditorHodnotyForm } from "./EditorHodnotyForm"

import { v4 as uuidv4 } from 'uuid';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

export class ServisVyjezdMaterialForm extends React.Component {
    constructor(props) {
        super(props);

        if (props.dataObject) {
            this.state = {
                sema_id: props.dataObject.sema_id,
                sema_popis: props.dataObject.sema_popis,
                sema_mj: props.dataObject.sema_mj,
                sema_mnozstvi: props.dataObject.sema_mnozstvi,
                sema_katalog_id: props.dataObject.sema_katalog_id,
                artikly_ArtiklKod: props.dataObject.artikly_ArtiklKod,
                artikly_ArtiklNazev: props.dataObject.artikly_ArtiklNazev,
                sema_spotrebovane_mnozstvi: props.dataObject.sema_spotrebovane_mnozstvi,
                sema_offlineStav: props.dataObject.sema_offlineStav,

                bNovy: false,

                sema_katalog_itemSel: null,
                dataMaterialy: null
            }
        }
        else this.state = {
            sema_id: Global.GuidEmpty,
            sema_popis: "",
            sema_mj: 0,
            sema_mnozstvi: 0,
            sema_katalog_id: Global.GuidEmpty,
            artikly_ArtiklKod: "",
            artikly_ArtiklNazev: "",
            sema_spotrebovane_mnozstvi: 0,
            sema_offlineStav: 0,

            bNovy: true,

            sema_katalog_itemSel: null,
            dataMaterialy: null
        }

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.materialChanged = this.materialChanged.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
    }

    onParamaterChange(event) {
        if (event.target.name == "sema_popis") this.setState({ sema_popis: event.target.value });
        else if (event.target.name == "sema_mj") this.setState({ sema_mj: event.target.value });
        else if (event.target.name == "sema_mnozstvi") this.setState({ sema_mnozstvi: event.target.value });
        else if (event.target.name == "sema_spotrebovane_mnozstvi") this.setState({ sema_spotrebovane_mnozstvi: event.target.value });
    }

    inicializace() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisMaterialKatalog() : fetchWrapper.VratServisMaterialKatalogOffline())
            .then(data => {

                const dataMaterialyNew = [];
                if (this.state.sema_katalog_id && this.state.sema_katalog_id != Global.GuidEmpty) {
                    if (!data.find(mc => mc.id == this.state.sema_katalog_id)) {
                        dataMaterialyNew.push({
                            id: this.state.sema_katalog_id,
                            nazev: this.state.artikly_ArtiklNazev,
                            kod: this.state.artikly_ArtiklKod,
                            jednotka: "ks"
                        });
                    }
                }
                dataMaterialyNew.push(...data);

                const sema_katalog_itemSelNew = dataMaterialyNew.find(item => item.id == this.state.sema_katalog_id);

                this.setState({ dataMaterialy: dataMaterialyNew, sema_katalog_itemSel: sema_katalog_itemSelNew });
            })
            .catch(error => {
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst katalog materiálu: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();

        const zarizeniSevy = this.props.sevyData.servisZarizeni.find(v => v.seve_zarizeni.vent_id == this.props.zarizeni.vent_id);
        if (zarizeniSevy == null) this.zavritDialog();
    }

    formSubmit(event) {
        event.preventDefault();

        let chyba = "";
        if ("" + this.state.sema_popis == "") chyba = chyba + " Nebyl zadán popis.";
        if (!(this.state.sema_mnozstvi > 0)) chyba = chyba + " Nebylo zadáno množství.";
        if (!(this.state.sema_spotrebovane_mnozstvi > 0)) chyba = chyba + " Nebylo zadáno spotřebované množství.";

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }


        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const dataObject = {
            sema_id: (!jeOfflineRezim || (jeOfflineRezim && !this.state.bNovy)) ? this.state.sema_id : uuidv4(),
            sema_popis: this.state.sema_popis,
            sema_mj: this.state.sema_mj,
            sema_mnozstvi: this.state.sema_mnozstvi,
            sema_spotrebovane_mnozstvi: this.state.sema_spotrebovane_mnozstvi,
            sema_katalog_id: this.state.sema_katalog_id,
            sevy_id: this.props.sevyData.sevy_id,
            vent_id: this.props.zarizeni.vent_id,
            artikly_ArtiklKod: this.state.artikly_ArtiklKod,
            artikly_ArtiklNazev: this.state.artikly_ArtiklNazev,
            sema_offlineStav: this.state.sema_offlineStav
        }

        if (this.state.bNovy) {
            LoadingSpinner.open();

            fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisMaterial() : fetchWrapper.NovyServisMaterialOffline(), dataObject)
                .then(data => {
                    LoadingSpinner.close();

                    this.props.zavritDialog(true, null);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else {
            LoadingSpinner.open();

            fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisMaterial() : fetchWrapper.EditServisMaterialOffline(), dataObject)
                .then(data => {
                    LoadingSpinner.close();

                    this.props.zavritDialog(true, null);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false, null);
    }

    materialChanged(event, item) {
        let jedn = this.state.sema_mj;
        if (item.jednotka == "ks") jedn = 0;
        else if (item.jednotka == "bm") jedn = 2;
        else if (item.jednotka == "km") jedn = 3;
        else if (item.jednotka == "set") jedn = 5;
        else if (item.jednotka == "sada") jedn = 6;
        else if (item.jednotka == "kplt") jedn = 8;
        else if (item.jednotka == "l") jedn = 11;
        else if (item.jednotka == "-") jedn = 10;
        else if (item.jednotka == "mm") jedn = 12;
        else if (item.jednotka == "paleta") jedn = 13;
        else if (item.jednotka == "kg") jedn = 14;


        let nazev = this.state.sema_popis;
        if (item.nazev != "") nazev = item.nazev;

        this.setState({ sema_katalog_id: item.id, artikly_ArtiklKod: item.kod, artikly_ArtiklNazev: item.nazev, sema_popis: nazev, sema_mj: jedn });
    }


    render() {

        return (
            <form onSubmit={this.formSubmit}>
                <h1>Spotřebovaný materiál - {this.state.bNovy ? "nový záznam" : "upravit záznam"}</h1>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}>Vybrané zařízení: </div>
                    <div className="righttext">
                        {this.props.zarizeni.vent_vyrobni_cislo + " - " + this.props.zarizeni.vent_popis}
                    </div>
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Popis:" hodnota={this.state.sema_popis} widthLeft={140} stylesRight={{ maxWidth: 450 }} typ="TEXT" onChange={e => this.setState({ sema_popis: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="MJ:" hodnota={this.state.sema_mj} klicID="kod" klicPopis="popis" dataCiselnik={Global.EnumJednotkaCiselnik()} widthLeft={140} typ="COMBOBOX" onChange={e => this.setState({ sema_mj: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Množství:" hodnota={this.state.sema_mnozstvi} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ sema_mnozstvi: e.target.value })} />
                </div>
                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Spotřebované množství:" hodnota={this.state.sema_spotrebovane_mnozstvi} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ sema_spotrebovane_mnozstvi: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}>Položka v katalogu: </div>
                    <div className="right">
                        {this.state.dataMaterialy ?
                            <Autocomplete
                                disablePortal
                                defaultValue={this.state.sema_katalog_itemSel}
                                getOptionLabel={(option) => option.kod + " --- " + option.nazev}
                                options={this.state.dataMaterialy}
                                sx={{ width: "100%", maxWidth: 600 }}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={this.materialChanged}
                            />
                            :
                            <img src="./img/loading16.gif" height={30} />
                        }
                    </div>
                </div>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                    </div>
                </div>
            </form>
        );
    }
}
