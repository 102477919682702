import React from 'react';
import ReactCameraControl from './ReactCameraControl';
import styles from './CameraFeed.module.css'
import { EditorHodnotyForm } from "./EditorHodnotyForm";

import LoadingSpinner from "./Loader/LoadingSpinner";


export class CameraFeed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bKameraOK: false, rezim: "KAMERA", popis: "foto", imgData: null, vybranaKamera: "environment", seznamKamer: [{ typ: "user", label: "Přední kamera" }, { typ: "environment", label: "Zadní kamera" } ] }
        //this.canvas = null;

        this.takePhoto = this.takePhoto.bind(this);

        this.zahoditFoto = this.zahoditFoto.bind(this);
        this.nahratFoto = this.nahratFoto.bind(this);
        this.zmenaKamery = this.zmenaKamery.bind(this);
    }





    async componentDidMount() {
        /*if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
        } else {
            // List cameras and microphones.
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const kamery = devices.filter(d => d.kind == "videoinput")
                    if (kamery.length > 0) this.setState({ seznamKamer: kamery, vybranaKameraId: kamery[0].deviceId });
                    else this.setState({ seznamKamer: [], vybranaKameraId: null })
                })
                .catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                });
        }*/
    }


    takePhoto(imgBlob) {
        this.setState({ imgData: imgBlob, rezim: "FOTO" });

        let ctx = this.canvas.getContext('2d');


        const img = new Image();
        img.onload = (event) => {
            URL.revokeObjectURL(event.target.src); // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.
            this.canvas.width = img.width;
            this.canvas.height = img.height;
            console.log("" + img.width + "x" + img.height);

            ctx.drawImage(event.target, 0, 0);
        }
        img.src = URL.createObjectURL(imgBlob);


        /*
         this.img.src = URL.createObjectURL(imgBlob)
         this.img.onload = () => {
             URL.revokeObjectURL(this.src)
         }*/
    }

    zmenaKamery() {

    }

    zahoditFoto() {
        this.setState({ rezim: "KAMERA" });
    }

    nahratFoto() {
        LoadingSpinner.open();
        const imgFile = this.canvas.toDataURL('image/png');

        const img = new Image();
        img.onload = (event) => {            
            URL.revokeObjectURL(event.target.src); // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.

            var canvas = document.createElement('canvas');  // Dynamically Create a Canvas Element

            canvas.width = img.width;  // Set the width of the Canvas
            canvas.height = img.height;  // Set the height of the Canvas
            var ctx = canvas.getContext("2d");  // Get the "context" of the canvas 
            ctx.drawImage(img, 0, 0, img.width, img.height);  // Draw your image to the canvas

            var imgFile = canvas.toDataURL("image/png");

            this.props.noveFoto(imgFile, this.state.popis);
            this.setState({ rezim: "KAMERA" });

            LoadingSpinner.close();

            //document.getElementsByTagName.removeChild(canvas);
        }
        img.src = URL.createObjectURL(this.state.imgData);
    };

    render() {

        return (<div>
            <div className={styles.mainContainer}>
                
                    <div className={styles.selectCameraContainer}>
                    { this.state.rezim == "KAMERA" &&
                        <EditorHodnotyForm popis="" hodnota={this.state.vybranaKamera} widthLeft={0} typ="COMBOBOX" klicID="typ" klicPopis="label" dataCiselnik={this.state.seznamKamer} onChange={e => this.setState({ vybranaKamera: e.target.value })} />
                    }
                    </div>               

                <ReactCameraControl ref={cam => { this.camera = cam }} takePhoto={this.takePhoto} video={{ facingMode: this.state.vybranaKamera }} >
                    <div className={styles.viewContainer} style={{ display: (this.state.rezim == "FOTO") ? 'block' : 'none' }}>
                        <canvas className={styles.captureImage} ref={(ref) => { this.canvas = ref }} />
                        {/*<img className={styles.captureImage}
                                    ref={img => { this.img = img }}
                                    alt='' />*/}
                    </div>
                </ReactCameraControl>
            </div>

            <div className={styles.fotoContainer} style={{ display: (this.state.rezim == "FOTO") ? 'block' : 'none' }}>
                <strong>Popis:</strong> <input type="text" value={this.state.popis} onChange={e => this.setState({ popis: e.target.value })} style={{ marginRight: 10, marginBottom: 10 }} />

                <button onClick={this.nahratFoto} className="form_button" style={{ marginRight: 30 }}>Uložit foto</button>
                <button onClick={this.zahoditFoto} className="form_button">Zahodit</button>
            </div>
        </div >
        );

        /*
        return (  
            <>
                <div className="c-camera-feed grid_12" style={{ marginTop: 10 }}>

                    <div style={{ display: (this.state.bKameraOK ? "block" : "none") }}>
                            <div className="c-camera-feed__viewer" style={{ maxWidth: 800,display: (this.state.rezim == "KAMERA") ? 'block' : 'none' }}>
                                <video ref={ref => (this.videoPlayer = ref)} className="videoPanel" width="100%" heigh="360" onClick={this.takePhoto} />
                                <div className="clear" />
                            <div style={{ marginTop: 10 }}>
                                {this.state.zoomEnabled && <input type="range" className="slider" style={{ width: 150, marginRight: 10 }} onInput={(event) => { this.nastavitZoom(event.target.value); }} /> }
                                <button onClick={this.takePhoto} className="form_button">Vyfotit</button>
                                </div>
                            </div>
                    </div>

                    <div className="c-camera-feed__stage" style={{ display: (this.state.rezim == "FOTO") ? 'block' : 'none' }}>
                        <canvas width="640" height="480" ref={(ref) => { this.canvas = ref }} />
                                <div className="clear" />

                                <div style={{ marginTop: 10 }}>
                            <strong>Popis:</strong> <input type="text" value={this.state.popis} onChange={e => this.setState({ popis: e.target.value })} style={{ marginRight: 10,marginBottom:10 }} />

                                    <button onClick={this.nahratFoto} className="form_button" style={{ marginRight: 30 }}>Uložit foto</button>
                                    <button onClick={this.zahoditFoto} className="form_button">Zahodit</button>
                                </div>
                            </div>
                        
                     </div>
                    { !this.state.bKameraOK && <div className="error_zprava">Kamera nebyla nalezena.</div> }
            </>
        );*/
    }
}

