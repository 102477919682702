import React from 'react';
import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService"
import CacheData from "../CacheData"
import { ZarizeniProvozniHodnotyList } from "./ZarizeniProvozniHodnotyList";
import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { SMapy } from "./SMapy/SMapy"

import { Global } from "./Global";


const sestavitAdresuProMapy = (ulice, psc, misto) => {
    let adresa = "";
    if (ulice) { adresa = adresa + ulice; }
    if (psc) {
        if (adresa != "") adresa = adresa +"," + psc;
        else adresa = psc;
    }
    if (misto) {
        if (adresa != "") adresa = adresa + "," + misto;
        else adresa = misto;
    }

    return adresa;
}


export class ZarizeniDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zobrazenyPanel: "VYPIS",
            technListPDF_URL: null,
            technListEx: false,
            semi_misto: null,
            mistoAdresa: ""
        };
        this.AktualizovatAdresu = this.AktualizovatAdresu.bind(this);
        this.AktualizovatTechnListOdkaz = this.AktualizovatTechnListOdkaz.bind(this);
        this.vlozitAdresuZVyjezdu = this.vlozitAdresuZVyjezdu.bind(this);
        this.zpracovatDataAdresyZMapy = this.zpracovatDataAdresyZMapy.bind(this);
        this.otevritTechnList = this.otevritTechnList.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.blobPdfFromBase64String = this.blobPdfFromBase64String.bind(this);
    }


    componentDidMount() {
        this.AktualizovatTechnListOdkaz();
        this.AktualizovatAdresu();
        window.addEventListener('reloadData', this.reloadData);
    }
    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.zarizeniData.vent_id != this.props.zarizeniData.vent_id
            || prevProps.zarizeniData.semi_misto.semi_id != this.props.zarizeniData.semi_misto.semi_id) {
            if (prevProps.zarizeniData.vent_id != this.props.zarizeniData.vent_id) this.AktualizovatTechnListOdkaz();
            this.AktualizovatAdresu();
        }
    }

    reloadData() {
        this.AktualizovatTechnListOdkaz();
    }


    AktualizovatTechnListOdkaz() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;
        const offlineRequest = jeOfflineRezim ? { vent_id: this.props.zarizeniData.vent_id } : null;

        this.setState({ technListEx: false, technListPDF_URL: null, zobrazenyPanel: "VYPIS" })
        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratZarizeniTechnListExist(this.props.zarizeniData.vent_id) : fetchWrapper.VratZarizeniTechnListExistOffline(), offlineRequest)
            .then(data => { this.setState({ technListEx: data }); })
            .catch(error => {
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst informace o technickém listu: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    AktualizovatAdresu() {
        this.setState({
            semi_misto: this.props.zarizeniData.semi_misto,
            mistoAdresa: sestavitAdresuProMapy(this.props.zarizeniData.semi_misto.semi_ulice, this.props.zarizeniData.semi_misto.semi_psc, this.props.zarizeniData.semi_misto.semi_misto)
        });
    }

    vlozitAdresuZVyjezdu = async (e, cell) => {
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete aktualizovat místo servisu v zařízení?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result != "ano") return;

        LoadingSpinner.open();
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const ventUpdate = structuredClone(this.props.zarizeniData);
        ventUpdate.semi_misto = this.props.sevyData.semi_misto;

        fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditZarizeni() : fetchWrapper.EditZarizeniOffline(), ventUpdate)
            .then(data => {
                LoadingSpinner.close();

                this.props.zarizeniData.semi_misto = this.props.sevyData.semi_misto;
                if (jeOfflineRezim && this.props.seveZarizeniData.seve_offlineStav == 0) this.props.seveZarizeniData.seve_offlineStav = 2;
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);

                this.AktualizovatAdresu();
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    zpracovatDataAdresyZMapy() {

    }

    otevritTechnList() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;
        const offlineRequest = jeOfflineRezim ? { vent_id: this.props.zarizeniData.vent_id } : null;

        if (!jeOfflineRezim) {
            LoadingSpinner.open();

            fetchWrapper.fileLoad(fetchWrapper.VratZarizeniTechnList(this.props.zarizeniData.vent_id))
                .then(fileData => {
                    LoadingSpinner.close();

                    this.setState({ zobrazenyPanel: "PDF", technListPDF_URL: window.URL.createObjectURL(fileData) });
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst dokument: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else {
            LoadingSpinner.open();

            fetchWrapper.get(fetchWrapper.VratZarizeniTechnListOffline(), offlineRequest)
                .then(data => {
                    LoadingSpinner.close();

                    this.setState({ zobrazenyPanel: "PDF", technListPDF_URL: window.URL.createObjectURL(this.blobPdfFromBase64String(data.docDataBase64PDF)) });
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst dokument: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    blobPdfFromBase64String = base64String => {
        const byteArray = Uint8Array.from(
            atob(base64String)
                .split('')
                .map(char => char.charCodeAt(0))
        );
        return new Blob([byteArray], { type: 'application/pdf' });
    };


    render() {

        const themeGrid = createTheme({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 500,
                    md: 700,
                    lg: 1000,
                    xl: 1200
                }
            }
        });

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        return (

            <>
                <div className='grid_12'>
                    <h1>{this.props.nadpis}
                        {this.props.zobrazitTlacitkoZpet && <button className="form_button" onClick={this.props.zavritDetailEvent} style={{ marginLeft: 30 }}>Zpět</button>}
                        {this.state.technListEx && this.state.zobrazenyPanel == "VYPIS" && <button className="form_button" style={{ marginLeft: 15 }} onClick={this.otevritTechnList}>Technický list</button>}
                        {this.state.zobrazenyPanel == "PDF" && <button className="form_button" style={{ marginLeft: 15 }} onClick={e => this.setState({ zobrazenyPanel: "VYPIS" })}>Zavřít technický list</button>}
                    </h1>
                </div>
                <div className="clear" />

                {this.state.zobrazenyPanel == "PDF" && this.state.technListPDF_URL &&
                    <>
                        <BrowserView>
                            <div className='grid_12' style={{ marginTop: 15, marginRight: 10 }}>
                                <iframe id="iframepdf" style={{ width: "100%", aspectRatio: "1.414" }} src={this.state.technListPDF_URL}></iframe>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="grid_12" style={{ maxWidth: 500 }}>
                                <a href={this.state.technListPDF_URL} target="_blank"><button className="form_button" style={{ marginTop: 12, width: "100%" }}>Stáhnout dokument PDF</button></a>
                            </div>
                            <div className="clear"></div>
                        </MobileView>
                    </>
                }

                <div style={{ display: (this.state.zobrazenyPanel == "VYPIS") ? "block" : "none" }}>
                    <div className='grid_12' styles={{ paddingLeft: 10 }}>
                        <ThemeProvider theme={themeGrid}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className='grid_box'>
                                            <div className="box round" style={{ position: "relative", minHeight: 515 }}>
                                                <h2>Základní údaje</h2>
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={Global.EnumZarizeniTyp(this.props.zarizeniData.vent_typ)} />
                                                <ObjektDetailHodnotaRadek popis="Výrobní číslo:" hodnota={this.props.zarizeniData.vent_vyrobni_cislo} />
                                                <ObjektDetailHodnotaRadek popis="Typové označení:" hodnota={this.props.zarizeniData.vent_popis} />
                                                <ObjektDetailHodnotaRadek popis="Popis zařízení:" hodnota={this.props.zarizeniData.vent_popis_zarizeni} />
                                                <ObjektDetailHodnotaRadek popis="Výrobce:" hodnota={this.props.zarizeniData.vent_vyrobce} />
                                                <ObjektDetailHodnotaRadek popis="Rok výroby:" hodnota={this.props.zarizeniData.vent_rok_vyroby} />
                                                <ObjektDetailHodnotaRadek popis="Termín záruky:" hodnota={Global.toDateTimeStr(new Date(this.props.zarizeniData.vent_termin_zaruky))} />
                                                <br />
                                                <br />
                                                {this.state.semi_misto && <ObjektDetailHodnotaRadek popis="Adresa:" hodnota={this.state.semi_misto.semi_adresa} />}
                                                {this.props.sevyData && this.state.semi_misto && this.state.semi_misto.semi_id != this.props.sevyData.semi_misto.semi_id &&
                                                    <div className="error_zprava" style={{ marginLeft: 160 }}>(Adresa výjezdu a adresa zařízení se liší.)
                                                        {this.props.bAkce && <div><button className="form_button" style={{ marginTop: 4 }} onClick={this.vlozitAdresuZVyjezdu}>Vložit adresu z výjezdu</button></div>}
                                                    </div>
                                                }

                                                <br />
                                                <br />
                                                <ObjektDetailHodnotaRadek popis="Poznámka:" typ="TEXTAREA" hodnota={this.props.zarizeniData.vent_ostatni_vybaveni_poznamka} />
                                                <div style={{ width: 70, height: 120, position: "absolute", right: 5, top: 35 }}>

                                                </div>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className='grid_box'>
                                            <div className="box round" style={{ minHeight: 515 }}>
                                                <h2>Mapa místa servisu</h2>
                                                {!jeOfflineRezim && <SMapy mistoAdresa={this.state.mistoAdresa} zpracovatDataAdresyZMapy={this.zpracovatDataAdresyZMapy} />}
                                                {jeOfflineRezim && <div id="wrapper"><div className="info_zprava">Nelze zobrazit mapu v offline režimu.</div></div>}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Box width="100%" />



                                    <Grid item xs={12} md={6} lg={4}>
                                        <div className="grid_box">
                                            <div className="box round" style={{ minHeight: 240 }}>
                                                <h2>Ventilátor</h2>
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={Global.EnumVentilatorTyp(this.props.zarizeniData.vent_typ_ventilatoru)} />
                                                <ObjektDetailHodnotaRadek popis="Uložení:" hodnota={this.props.zarizeniData.vent_ulozeni} />
                                                <ObjektDetailHodnotaRadek popis="Uspořádání:" hodnota={this.props.zarizeniData.vent_usporadani} />
                                                <ObjektDetailHodnotaRadek popis="Min. otáčky [1/min]:" hodnota={this.props.zarizeniData.vent_min_otacky} />
                                                <ObjektDetailHodnotaRadek popis="Max. otáčky [1/min]:" hodnota={this.props.zarizeniData.vent_max_otacky} />
                                                <ObjektDetailHodnotaRadek popis="Hmotnost [kg]:" hodnota={this.props.zarizeniData.vent_hmotnost_kg} />
                                                <ObjektDetailHodnotaRadek popis="Průtok [m3/s]:" hodnota={this.props.zarizeniData.vent_prutok_m3s} />
                                                <ObjektDetailHodnotaRadek popis="Tlak  [Pa]:" hodnota={this.props.zarizeniData.vent_tlak_pa} />
                                                <ObjektDetailHodnotaRadek popis="Provozní teplota  [°C]:" hodnota={this.props.zarizeniData.vent_provozni_teplota} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <div className="grid_box">
                                            <div className="box round" style={{ minHeight: 240 }}>
                                                <h2>Motor</h2>
                                                <ObjektDetailHodnotaRadek popis="Výrobce:" hodnota={this.props.zarizeniData.vent_motor_vyrobce} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={this.props.zarizeniData.vent_motor_typ} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="Výkon [kW]:" hodnota={this.props.zarizeniData.vent_motor_prikon_kw} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="Frekvence [Hz]:" hodnota={this.props.zarizeniData.vent_hz} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="Otáčky motoru [1/min]:" hodnota={this.props.zarizeniData.vent_otacky_motoru} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="Napětí [V]:" hodnota={this.props.zarizeniData.vent_motor_napeti} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="Proud [A]:" hodnota={this.props.zarizeniData.vent_motor_proud} widthLeft={140} />
                                                <ObjektDetailHodnotaRadek popis="FM:" hodnota={this.props.zarizeniData.vent_b_fm ? "ANO" : "NE"} widthLeft={140} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        {Global.VratEnumUsporadani(this.props.zarizeniData.vent_usporadani) == 1 &&
                                            <div className="grid_box">
                                                <div className="box round" style={{ minHeight: 240 }}>
                                                    <h2>Řemen</h2>
                                                    <ObjektDetailHodnotaRadek popis="Typ řemenu:" hodnota={this.props.zarizeniData.vent_remen_typ} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Počet řemenů:" hodnota={this.props.zarizeniData.vent_remen_pocet} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Délka řemenu LW:" hodnota={this.props.zarizeniData.vent_remen_delka} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Profil řemenice:" hodnota={Global.EnumProfilRemenice(this.props.zarizeniData.vent_remenice_profil)} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Řemenice hnací:" hodnota={this.props.zarizeniData.vent_remenice_hnaci} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Řemenice hnaná:" hodnota={this.props.zarizeniData.vent_remenice_hnana} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Řemen - frekvence [Hz]:" hodnota={this.props.zarizeniData.vent_remen_hz} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Průhyb řemenu [mm]:" hodnota={this.props.zarizeniData.vent_remen_pruhyb} widthLeft={160} />
                                                    <ObjektDetailHodnotaRadek popis="Řemen - testovací síla [N]:" hodnota={this.props.zarizeniData.vent_remen_test_sila} widthLeft={160} />
                                                </div>
                                            </div>
                                        }

                                        {Global.VratEnumUsporadani(this.props.zarizeniData.vent_usporadani) == 0 &&
                                            <div className="grid_box">
                                                <div className="box round" style={{ height: 65 }}>
                                                    <h2>Spojka</h2>
                                                    <ObjektDetailHodnotaRadek popis="Typ spojky:" hodnota={this.props.zarizeniData.vent_spojka_typ} widthLeft={150} />
                                                    <ObjektDetailHodnotaRadek popis="Provedení spojky:" hodnota={Global.EnumSpojkaProvedeni(this.props.zarizeniData.vent_spojka_provedeni)} widthLeft={150} />
                                                </div>
                                            </div>
                                        }
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="grid_box" >
                                            <div className="box round" style={{ height: 140 }}>
                                                <h2>Ložisko ventilátoru pevné</h2>
                                                <ObjektDetailHodnotaRadek popis="Výrobce:" hodnota={this.props.zarizeniData.vent_lozisko_pevne_vyrobce} />
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={this.props.zarizeniData.vent_lozisko_pevne_typ} />
                                                <ObjektDetailHodnotaRadek popis="Mazivo:" hodnota={this.props.zarizeniData.vent_lozisko_pevne_mazivo} />
                                                <ObjektDetailHodnotaRadek popis="Zb. rad. vůle [mm]:" hodnota={this.props.zarizeniData.vent_lozisko_pevne_zb_rad_vule} />
                                                <ObjektDetailHodnotaRadek popis="Interval domaz.[hod]:" hodnota={this.props.zarizeniData.vent_lozisko_pevne_interval_domazavani} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="grid_box">
                                            <div className="box round" style={{ height: 140 }}>
                                                <h2>Ložisko ventilátoru volné</h2>
                                                <ObjektDetailHodnotaRadek popis="Výrobce:" hodnota={this.props.zarizeniData.vent_lozisko_volne_vyrobce} />
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={this.props.zarizeniData.vent_lozisko_volne_typ} />
                                                <ObjektDetailHodnotaRadek popis="Mazivo:" hodnota={this.props.zarizeniData.vent_lozisko_volne_mazivo} />
                                                <ObjektDetailHodnotaRadek popis="Zb. rad. vůle [mm]:" hodnota={this.props.zarizeniData.vent_lozisko_volne_zb_rad_vule} />
                                                <ObjektDetailHodnotaRadek popis="Interval domaz.[hod]:" hodnota={this.props.zarizeniData.vent_lozisko_volne_interval_domazavani} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="grid_box">
                                            <div className="box round" style={{ height: 90, marginBottom: 5 }}>
                                                <h2>Ložisko motoru DE</h2>
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={this.props.zarizeniData.vent_lozisko_de_typ} widthLeft={150} />
                                                <ObjektDetailHodnotaRadek popis="Mazivo:" hodnota={this.props.zarizeniData.vent_lozisko_de_mazivo} widthLeft={150} />
                                                <ObjektDetailHodnotaRadek popis="Interval domaz.[hod]:" hodnota={this.props.zarizeniData.vent_lozisko_de_interval_domazavani} widthLeft={150} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="grid_box">
                                            <div className="box round" style={{ height: 90, marginBottom: 10 }}>
                                                <h2>Ložisko motoru NDE</h2>
                                                <ObjektDetailHodnotaRadek popis="Typ:" hodnota={this.props.zarizeniData.vent_lozisko_nde_typ} widthLeft={150} />
                                                <ObjektDetailHodnotaRadek popis="Mazivo:" hodnota={this.props.zarizeniData.vent_lozisko_nde_mazivo} widthLeft={150} />
                                                <ObjektDetailHodnotaRadek popis="Interval domaz.[hod]:" hodnota={this.props.zarizeniData.vent_lozisko_nde_interval_domazavani} widthLeft={150} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Box width="100%" />
                                    <Grid item xs={12} lg={12}>
                                        <div className="grid_box">
                                            <div>Ložiska motoru - poznámka:</div>
                                            <ObjektDetailHodnotaRadek popis="" typ="TEXTAREA" hodnota={this.props.zarizeniData.vent_loziska_motoru_poznamka} widthLeft={0} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ThemeProvider>
                    </div>
                    <div className="clear" />


                    {this.props.zobrazitMereni && <ZarizeniProvozniHodnotyList zarizeniData={this.props.zarizeniData} data={this.props.zarizeniData.provozniHodnoty} dataVyjezdu={[]} bAkce={this.props.bAkce} bFiltrMereni={false} />}
                </div>
            </>
        );
    }
}
