import React from 'react';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator } from 'react-tabulator';

import AuthService from "../AuthService";
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";

import { Global } from "./Global"
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

export class OnlineRezimPrechodForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataOfflineZmeny: null,
            dataOfflineFoto: null
        }

        this.tabOfflineZmeny = React.createRef();

        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
        this.zahoditZmeny = this.zahoditZmeny.bind(this);
        this.vratDataIdsTabulkyNevnorene = this.vratDataIdsTabulkyNevnorene.bind(this);
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();
    }

    inicializace() {
        let sevy = this.props.sevyData;

        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratServisFotoOffline())
            .then(data => {

                let childrenServisTechnici = [];
                for (let i = 0; i < sevy.servisTechnici.length; i++) {
                    let st = sevy.servisTechnici[i];

                    if (st.sete_offlineStav != 0) childrenServisTechnici.push({ id: st.sete_id, typZaznamu: "Servisní technik", popis: st.prijmeniJmeno, stav: st.sete_offlineStav });
                }

                let childrenServisTechniciDeleted = [];
                for (let i = 0; i < sevy.servisTechniciDeleted.length; i++) {
                    let st = sevy.servisTechniciDeleted[i];

                    if (st.sete_offlineStav != 0) childrenServisTechniciDeleted.push({ id: st.sete_id, typZaznamu: "Servisní technik", popis: st.prijmeniJmeno, stav: st.sete_offlineStav });
                }

                let childrenServisPrace = [];
                for (let i = 0; i < sevy.servisPrace.length; i++) {
                    let sp = sevy.servisPrace[i];

                    if (sp.sepr_offlineStav != 0) childrenServisPrace.push({ id: sp.sepr_id, typZaznamu: "Servisní práce", vent_id: sp.vent_id, popis: Global.toDateStr(new Date(sp.sepr_datum)) + " - " + sp.spp_popis, stav: sp.sepr_offlineStav });
                }

                let childrenServisPraceDeleted = [];
                for (let i = 0; i < sevy.servisPraceDeleted.length; i++) {
                    let sp = sevy.servisPraceDeleted[i];

                    if (sp.sepr_offlineStav != 0) childrenServisPraceDeleted.push({ id: sp.sepr_id, typZaznamu: "Servisní práce", vent_id: sp.vent_id, popis: Global.toDateStr(new Date(sp.sepr_datum)) + " - " + sp.spp_popis, stav: sp.sepr_offlineStav });
                }

                let childrenServisMaterial = [];
                for (let i = 0; i < sevy.servisMaterial.length; i++) {
                    let sm = sevy.servisMaterial[i];

                    if (sm.sema_offlineStav != 0) childrenServisMaterial.push({ id: sm.sema_id, typZaznamu: "Spotřebovaný materiál", vent_id: sm.vent_id, popis: sm.artikly_ArtiklKod + " - " + sm.artikly_ArtiklNazev, stav: sm.sema_offlineStav });
                }

                let childrenServisMaterialDeleted = [];
                for (let i = 0; i < sevy.servisMaterialDeleted.length; i++) {
                    let sm = sevy.servisMaterialDeleted[i];

                    if (sm.sema_offlineStav != 0) childrenServisMaterialDeleted.push({ id: sm.sema_id, typZaznamu: "Spotřebovaný materiál", vent_id: sm.vent_id, popis: sm.artikly_ArtiklKod + " - " + sm.artikly_ArtiklNazev, stav: sm.sema_offlineStav });
                }

                let childrenServisPotrebneVybaveni = [];
                for (let i = 0; i < sevy.servisPotrebneVybaveni.length; i++) {
                    let sv = sevy.servisPotrebneVybaveni[i];

                    if (sv.sepv_offlineStav != 0) childrenServisPotrebneVybaveni.push({ id: sv.sepv_id, typZaznamu: "Speciální vybavení", vent_id: sv.vent_id, popis: sv.sepvc_popis, stav: sv.sepv_offlineStav });
                }

                let childrenServisPotrebneVybaveniDeleted = [];
                for (let i = 0; i < sevy.servisPotrebneVybaveniDeleted.length; i++) {
                    let sv = sevy.servisPotrebneVybaveniDeleted[i];

                    if (sv.sepv_offlineStav != 0) childrenServisPotrebneVybaveniDeleted.push({ id: sv.sepv_id, typZaznamu: "Speciální vybavení", vent_id: sv.vent_id, popis: sv.sepvc_popis, stav: sv.sepv_offlineStav });
                }

                let childrenServisOdpracovaneHodiny = [];
                for (let i = 0; i < sevy.servisOdpracovaneHodiny.length; i++) {
                    let sh = sevy.servisOdpracovaneHodiny[i];

                    if (sh.soh_offlineStav != 0) childrenServisOdpracovaneHodiny.push({ id: sh.soh_id, typZaznamu: "Odpracované hodiny", vent_id: sh.vent_id, popis: sh.soh_technik_PrijmeniJmeno, stav: sh.soh_offlineStav });
                }

                let childrenServisOdpracovaneHodinyDeleted = [];
                for (let i = 0; i < sevy.servisOdpracovaneHodinyDeleted.length; i++) {
                    let sh = sevy.servisOdpracovaneHodinyDeleted[i];

                    if (sh.soh_offlineStav != 0) childrenServisOdpracovaneHodinyDeleted.push({ id: sh.soh_id, typZaznamu: "Odpracované hodiny", vent_id: sh.vent_id, popis: sh.soh_technik_PrijmeniJmeno, stav: sh.soh_offlineStav });
                }

                let childrenServisZarizeni = [];
                for (let i = 0; i < sevy.servisZarizeni.length; i++) {
                    let sz = sevy.servisZarizeni[i];

                    let childrenServisPraceZarizeni = childrenServisPrace.filter(sp => sp.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPrace = childrenServisPrace.filter(sp => sp.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisPraceDeletedZarizeni = childrenServisPraceDeleted.filter(sp => sp.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPraceDeleted = childrenServisPraceDeleted.filter(sp => sp.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisMaterialZarizeni = childrenServisMaterial.filter(sm => sm.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisMaterial = childrenServisMaterial.filter(sm => sm.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisMaterialDeletedZarizeni = childrenServisMaterialDeleted.filter(sm => sm.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisMaterialDeleted = childrenServisMaterialDeleted.filter(sm => sm.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisPotrebneVybaveniZarizeni = childrenServisPotrebneVybaveni.filter(sv => sv.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPotrebneVybaveni = childrenServisPotrebneVybaveni.filter(sv => sv.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisPotrebneVybaveniDeletedZarizeni = childrenServisPotrebneVybaveniDeleted.filter(sv => sv.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPotrebneVybaveniDeleted = childrenServisPotrebneVybaveniDeleted.filter(sv => sv.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisOdpracovaneHodinyZarizeni = childrenServisOdpracovaneHodiny.filter(sh => sh.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisOdpracovaneHodiny = childrenServisOdpracovaneHodiny.filter(sh => sh.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisOdpracovaneHodinyDeletedZarizeni = childrenServisOdpracovaneHodinyDeleted.filter(sh => sh.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisOdpracovaneHodinyDeleted = childrenServisOdpracovaneHodinyDeleted.filter(sh => sh.vent_id != sz.seve_zarizeni.vent_id);

                    let childrenProvozniHodnoty = [];
                    for (var p = 0; p < sz.seve_zarizeni.provozniHodnoty.length; p++) {
                        let ph = sz.seve_zarizeni.provozniHodnoty[p];

                        if (ph.vph_offlineStav != 0) childrenProvozniHodnoty.push({ id: ph.vph_id, typZaznamu: "Provozní hodnoty", vent_id: ph.vent_id, popis: Global.toDateTimeStr(new Date(ph.vph_datum)) + " - " + ph.vph_kontroloval_PrijmeniJmeno, stav: ph.vph_offlineStav });
                    }

                    let childrenProvozniHodnotyDeleted = [];
                    for (var p = 0; p < sz.seve_zarizeni.provozniHodnotyDeleted.length; p++) {
                        let ph = sz.seve_zarizeni.provozniHodnotyDeleted[p];

                        if (ph.vph_offlineStav != 0) childrenProvozniHodnotyDeleted.push({ id: ph.vph_id, typZaznamu: "Provozní hodnoty", vent_id: ph.vent_id, popis: Global.toDateTimeStr(new Date(ph.vph_datum)) + " - " + ph.vph_kontroloval_PrijmeniJmeno, stav: ph.vph_offlineStav });
                    }

                    let childrenZarizeni = [].concat(childrenServisPraceZarizeni, childrenServisPraceDeletedZarizeni,
                        childrenServisMaterialZarizeni, childrenServisMaterialDeletedZarizeni, childrenServisPotrebneVybaveniZarizeni,
                        childrenServisPotrebneVybaveniDeletedZarizeni, childrenServisOdpracovaneHodinyZarizeni, childrenServisOdpracovaneHodinyDeletedZarizeni,
                        childrenProvozniHodnoty, childrenProvozniHodnotyDeleted);

                    if (sz.seve_offlineStav != 0 || childrenZarizeni.length > 0) {
                        if (childrenZarizeni.length > 0) childrenServisZarizeni.push({ id: sz.seve_id, typZaznamu: "Servisované zařízení", vent_id: sz.seve_zarizeni.vent_id, popis: sz.seve_zarizeni.vent_vyrobni_cislo + " - " + sz.seve_zarizeni.vent_popis, stav: sz.seve_offlineStav, _children: childrenZarizeni });
                        else childrenServisZarizeni.push({ id: sz.seve_id, typZaznamu: "Servisované zařízení", vent_id: sz.seve_zarizeni.vent_id, popis: sz.seve_zarizeni.vent_vyrobni_cislo + " - " + sz.seve_zarizeni.vent_popis, stav: sz.seve_offlineStav });
                    }
                }

                let childrenServisZarizeniDeleted = [];
                for (let i = 0; i < sevy.servisZarizeniDeleted.length; i++) {
                    let sz = sevy.servisZarizeniDeleted[i];

                    let childrenServisPraceZarizeni = childrenServisPrace.filter(sp => sp.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPrace = childrenServisPrace.filter(sp => sp.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisPraceDeletedZarizeni = childrenServisPraceDeleted.filter(sp => sp.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPraceDeleted = childrenServisPraceDeleted.filter(sp => sp.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisMaterialZarizeni = childrenServisMaterial.filter(sm => sm.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisMaterial = childrenServisMaterial.filter(sm => sm.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisMaterialDeletedZarizeni = childrenServisMaterialDeleted.filter(sm => sm.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisMaterialDeleted = childrenServisMaterialDeleted.filter(sm => sm.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisPotrebneVybaveniZarizeni = childrenServisPotrebneVybaveni.filter(sv => sv.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPotrebneVybaveni = childrenServisPotrebneVybaveni.filter(sv => sv.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisPotrebneVybaveniDeletedZarizeni = childrenServisPotrebneVybaveniDeleted.filter(sv => sv.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisPotrebneVybaveniDeleted = childrenServisPotrebneVybaveniDeleted.filter(sv => sv.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisOdpracovaneHodinyZarizeni = childrenServisOdpracovaneHodiny.filter(sh => sh.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisOdpracovaneHodiny = childrenServisOdpracovaneHodiny.filter(sh => sh.vent_id != sz.seve_zarizeni.vent_id);
                    let childrenServisOdpracovaneHodinyDeletedZarizeni = childrenServisOdpracovaneHodinyDeleted.filter(sh => sh.vent_id == sz.seve_zarizeni.vent_id);
                    childrenServisOdpracovaneHodinyDeleted = childrenServisOdpracovaneHodinyDeleted.filter(sh => sh.vent_id != sz.seve_zarizeni.vent_id);

                    let childrenProvozniHodnoty = [];
                    for (var p = 0; p < sz.seve_zarizeni.provozniHodnoty.length; p++) {
                        let ph = sz.seve_zarizeni.provozniHodnoty[p];

                        if (ph.vph_offlineStav != 0) childrenProvozniHodnoty.push({ id: ph.vph_id, typZaznamu: "Provozní hodnoty", vent_id: ph.vent_id, popis: Global.toDateTimeStr(new Date(ph.vph_datum)) + " - " + ph.vph_kontroloval_PrijmeniJmeno, stav: ph.vph_offlineStav });
                    }

                    let childrenProvozniHodnotyDeleted = [];
                    for (var p = 0; p < sz.seve_zarizeni.provozniHodnotyDeleted.length; p++) {
                        let ph = sz.seve_zarizeni.provozniHodnotyDeleted[p];

                        if (ph.vph_offlineStav != 0) childrenProvozniHodnotyDeleted.push({ id: ph.vph_id, typZaznamu: "Provozní hodnoty", vent_id: ph.vent_id, popis: Global.toDateTimeStr(new Date(ph.vph_datum)) + " - " + ph.vph_kontroloval_PrijmeniJmeno, stav: ph.vph_offlineStav });
                    }

                    let childrenZarizeniDeleted = [].concat(childrenServisPraceZarizeni, childrenServisPraceDeletedZarizeni,
                        childrenServisMaterialZarizeni, childrenServisMaterialDeletedZarizeni, childrenServisPotrebneVybaveniZarizeni,
                        childrenServisPotrebneVybaveniDeletedZarizeni, childrenServisOdpracovaneHodinyZarizeni, childrenServisOdpracovaneHodinyDeletedZarizeni,
                        childrenProvozniHodnoty, childrenProvozniHodnotyDeleted);

                    if (sz.seve_offlineStav != 0 || childrenZarizeniDeleted.length > 0) {
                        if (childrenZarizeniDeleted.length > 0) childrenServisZarizeniDeleted.push({ id: sz.seve_id, typZaznamu: "Servisované zařízení", vent_id: sz.seve_zarizeni.vent_id, popis: sz.seve_zarizeni.vent_vyrobni_cislo + " - " + sz.seve_zarizeni.vent_popis, stav: sz.seve_offlineStav, _children: childrenZarizeniDeleted });
                        else childrenServisZarizeniDeleted.push({ id: sz.seve_id, typZaznamu: "Servisované zařízení", vent_id: sz.seve_zarizeni.vent_id, popis: sz.seve_zarizeni.vent_vyrobni_cislo + " - " + sz.seve_zarizeni.vent_popis, stav: sz.seve_offlineStav });
                    }
                }

                let childrenVyjezdu = [].concat(childrenServisTechnici, childrenServisTechniciDeleted, childrenServisZarizeni, childrenServisZarizeniDeleted,
                    childrenServisPrace, childrenServisPraceDeleted, childrenServisMaterial, childrenServisMaterialDeleted, childrenServisPotrebneVybaveni,
                    childrenServisPotrebneVybaveniDeleted, childrenServisOdpracovaneHodiny, childrenServisOdpracovaneHodinyDeleted);

                let sevyRadek = null;
                if (sevy.sevy_offlineStav != 0 || childrenVyjezdu.length > 0) {
                    if (childrenVyjezdu.length > 0) sevyRadek = [{ id: sevy.sevy_id, typZaznamu: "Servisní výjezd", popis: sevy.semi_misto.semi_adresa, stav: sevy.sevy_offlineStav, _children: childrenVyjezdu }];
                    else sevyRadek = [{ id: sevy.sevy_id, typZaznamu: "Servisní výjezd", popis: sevy.semi_misto.semi_adresa, stav: sevy.sevy_offlineStav }];
                }

                this.setState({
                    dataOfflineZmeny: sevyRadek,
                    dataOfflineFoto: data
                });

                LoadingSpinner.close();
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst offline data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    formSubmit = async (event) => {
        event.preventDefault();

        let result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete synchronizovat offline data?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result != "ano") return;

        let chyba = "";
        let dataIdsNevnorene = null;
        if (this.tabOfflineZmeny.current) {
            dataIdsNevnorene = this.vratDataIdsTabulkyNevnorene(this.tabOfflineZmeny.current.getData());
        }

        if ((!dataIdsNevnorene || dataIdsNevnorene.length <= 0) && (!this.state.dataOfflineFoto || this.state.dataOfflineFoto.length <= 0)) {
            chyba += "Nebyly vybrány žádné změny pro synchronizaci. ";
        }

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        const sevyDataEdit = structuredClone(this.props.sevyData);

        sevyDataEdit.sevy_datum_odjezd_prach = Global.toISOString(new Date(sevyDataEdit.sevy_datum_odjezd_prach));
        sevyDataEdit.sevy_datum_prijezd_servis = Global.toISOString(new Date(sevyDataEdit.sevy_datum_prijezd_servis));
        sevyDataEdit.sevy_datum_zahajeni_praci = Global.toISOString(new Date(sevyDataEdit.sevy_datum_zahajeni_praci));
        sevyDataEdit.sevy_datum_ukonceni_praci = Global.toISOString(new Date(sevyDataEdit.sevy_datum_ukonceni_praci));
        sevyDataEdit.sevy_datum_odjezd_servis = Global.toISOString(new Date(sevyDataEdit.sevy_datum_odjezd_servis));
        sevyDataEdit.sevy_datum_prijezd_prach = Global.toISOString(new Date(sevyDataEdit.sevy_datum_prijezd_prach));
        sevyDataEdit.sevy_datum_prejezd_hranic_tam = Global.toISOString(new Date(sevyDataEdit.sevy_datum_prejezd_hranic_tam));
        sevyDataEdit.sevy_datum_prejezd_hranic_zpet = Global.toISOString(new Date(sevyDataEdit.sevy_datum_prejezd_hranic_zpet));
        // sevyDataEdit.sevy_interni_poznamka = sevyDataEdit.sevy_interni_poznamka;

        sevyDataEdit.servisTechnici = dataIdsNevnorene ? sevyDataEdit.servisTechnici.filter(st => dataIdsNevnorene.includes(st.sete_id)) : [];
        sevyDataEdit.servisTechniciDeleted = dataIdsNevnorene ? sevyDataEdit.servisTechniciDeleted.filter(st => dataIdsNevnorene.includes(st.sete_id)) : [];
        sevyDataEdit.servisPrace = dataIdsNevnorene ? sevyDataEdit.servisPrace.filter(sp => dataIdsNevnorene.includes(sp.sepr_id)) : [];
        sevyDataEdit.servisPraceDeleted = dataIdsNevnorene ? sevyDataEdit.servisPraceDeleted.filter(sp => dataIdsNevnorene.includes(sp.sepr_id)) : [];
        sevyDataEdit.servisMaterial = dataIdsNevnorene ? sevyDataEdit.servisMaterial.filter(sm => dataIdsNevnorene.includes(sm.sema_id)) : [];
        sevyDataEdit.servisMaterialDeleted = dataIdsNevnorene ? sevyDataEdit.servisMaterialDeleted.filter(sm => dataIdsNevnorene.includes(sm.sema_id)) : [];
        sevyDataEdit.servisPotrebneVybaveni = dataIdsNevnorene ? sevyDataEdit.servisPotrebneVybaveni.filter(pv => dataIdsNevnorene.includes(pv.sepv_id)) : [];
        sevyDataEdit.servisPotrebneVybaveniDeleted = dataIdsNevnorene ? sevyDataEdit.servisPotrebneVybaveniDeleted.filter(pv => dataIdsNevnorene.includes(pv.sepv_id)) : [];
        sevyDataEdit.servisOdpracovaneHodiny = dataIdsNevnorene ? sevyDataEdit.servisOdpracovaneHodiny.filter(sh => dataIdsNevnorene.includes(sh.soh_id)) : [];
        sevyDataEdit.servisOdpracovaneHodinyDeleted = dataIdsNevnorene ? sevyDataEdit.servisOdpracovaneHodinyDeleted.filter(sh => dataIdsNevnorene.includes(sh.soh_id)) : [];
        sevyDataEdit.servisZarizeni = dataIdsNevnorene ? sevyDataEdit.servisZarizeni.filter(sz => dataIdsNevnorene.includes(sz.seve_id)) : [];
        for (let i = 0; i < sevyDataEdit.servisZarizeni.length; i++) {
            let zarizeni = sevyDataEdit.servisZarizeni[i].seve_zarizeni;
            zarizeni.provozniHodnoty = dataIdsNevnorene ? zarizeni.provozniHodnoty.filter(ph => dataIdsNevnorene.includes(ph.vph_id)) : [];
            zarizeni.provozniHodnotyDeleted = dataIdsNevnorene ? zarizeni.provozniHodnotyDeleted.filter(ph => dataIdsNevnorene.includes(ph.vph_id)) : [];
        }
        sevyDataEdit.servisZarizeniDeleted = dataIdsNevnorene ? sevyDataEdit.servisZarizeniDeleted.filter(sz => dataIdsNevnorene.includes(sz.seve_id)) : [];
        for (let i = 0; i < sevyDataEdit.servisZarizeniDeleted.length; i++) {
            let zarizeniDeleted = sevyDataEdit.servisZarizeniDeleted[i].seve_zarizeni;
            zarizeniDeleted.provozniHodnoty = dataIdsNevnorene ? zarizeniDeleted.provozniHodnoty.filter(ph => dataIdsNevnorene.includes(ph.vph_id)) : [];
            zarizeniDeleted.provozniHodnotyDeleted = dataIdsNevnorene ? zarizeniDeleted.provozniHodnotyDeleted.filter(ph => dataIdsNevnorene.includes(ph.vph_id)) : [];
        }

        const dataFotoList = {
            servisFoto: this.state.dataOfflineFoto ? this.state.dataOfflineFoto : []
        }

        LoadingSpinner.open();

        fetchWrapper.put(fetchWrapper.EditServisniVyjezdZamek(this.props.sevyData.sevy_id, false))
            .then(data1 => {

                Promise.all([
                    fetchWrapper.put(fetchWrapper.SynchronizujServisVyjezdOfflineData(), sevyDataEdit),
                    fetchWrapper.put(fetchWrapper.SynchronizujServisFotoOfflineFoto(), dataFotoList)])
                    .then(data2 => {
                        LoadingSpinner.close();

                        CacheData.DeleteData(null, "offlinerezim");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisZamestnanciTechniciData");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisPraceCiselnikData");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisMaterialKatalogData");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisPotrebneVybaveniCiselnikData");
                        CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisVyjezdFoto");
                        CacheData.IndexedDBDeleteData("OfflineDataTechnListy", AuthService.getCurrentUser(), "tcfServisVyjezdTechnickeListy");

                        this.props.zavritDialog(true);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [{ name: "OK", handler: () => "OK" }]
                        });
                    });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [{ name: "OK", handler: () => "OK" }]
                });
            });
    }

    vratDataIdsTabulkyNevnorene(data) {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            let dataObjekt = data[i];
            if (dataObjekt.id) {
                arr.push(dataObjekt.id);
                if (dataObjekt._children) {
                    arr = arr.concat(this.vratDataIdsTabulkyNevnorene(dataObjekt._children));
                }
            }
        }
        return arr;
    }

    zahoditZmeny = async (e, cell) => {
        let result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete zahodit všechny změny provedené v offline režimu?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result != "ano") return;

        result = await MessageBox.open({
            title: "Varování",
            content: <p>Všechny změny provedené v offline režimu budou vymazány. Offline režim bude ukončen.</p>,
            buttons: [
                { name: "OK", handler: () => "ok" },
                { name: "Zrušit", handler: () => "zrusit" }
            ]
        });

        if (result != "ok") return;

        LoadingSpinner.open();

        fetchWrapper.put(fetchWrapper.EditServisniVyjezdZamek(this.props.sevyData.sevy_id, false))
            .then(data => {
                LoadingSpinner.close();

                CacheData.DeleteData(null, "offlinerezim");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisZamestnanciTechniciData");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisPraceCiselnikData");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisMaterialKatalogData");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisPotrebneVybaveniCiselnikData");
                CacheData.IndexedDBDeleteData("OfflineData", AuthService.getCurrentUser(), "tcfServisVyjezdFoto");
                CacheData.IndexedDBDeleteData("OfflineDataTechnListy", AuthService.getCurrentUser(), "tcfServisVyjezdTechnickeListy");

                this.props.zavritDialog(true);
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [{ name: "OK", handler: () => "OK" }]
                });
            });
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }


    render() {

        console.log(this.state.dataOfflineZmeny);
        console.log(this.state.dataOfflineFoto);

        const tabOfflineZmenyColumns = [
            { field: 'typZaznamu', title: 'Typ záznamu', width: '25%', headerSort: true },
            { field: 'popis', title: 'Popis', width: '60%', headerSort: true },
            {
                field: 'stav', title: 'Změna', width: '15%', headerSort: true, formatter: function (cell, formatterParams, onRendered) {
                    return Global.EnumOfflineStav(cell.getValue());
                }
            }
        ];

        return (
            <form onSubmit={this.formSubmit}>
                <h1>Synchronizace změn v offline režimu &nbsp; <button type="button" className="form_button" onClick={this.zahoditZmeny}>Zahodit změny</button></h1>

                <div className="grid_12" style={{ display: 'block' }}>
                    <h2>Změny</h2>
                    {this.state.dataOfflineZmeny && this.state.dataOfflineZmeny.length > 0 ?
                        <ReactTabulator
                            onRef={(r) => (this.tabOfflineZmeny = r)}
                            data={this.state.dataOfflineZmeny}
                            columns={tabOfflineZmenyColumns}
                            layout={'fitDataStretch'}
                            initialSort={[{ column: "typZaznamu", dir: "asc" }]}
                            style={{ maxHeight: 500 }}
                            options={{ dataTree: true, dataTreeStartExpanded: true }}
                        />
                        :
                        <div>Žádné změny.</div>
                    }
                </div>
                <div className="clear" />

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Synchronizovat</button>
                    </div>
                </div>
            </form>
        );
    }
}