import React from 'react';
import { Combobox } from "./Combobox";
import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService";

import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { FormControlLabel, Checkbox } from '@mui/material';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from './Loader/LoadingSpinner';


const moneyZamestnanceEmpty = { prijmeniJmeno: "", user_ID: Global.GuidEmpty, id: Global.GuidEmpty };

export class UzivateleForm extends React.Component {
    constructor(props) {
        super(props);

       
        if (props.drUzivatel) this.state = {
            uziv_id: props.drUzivatel.uziv_id,
            uziv_jmeno: props.drUzivatel.uziv_jmeno,
            uziv_email: props.drUzivatel.uziv_email,
            uziv_login: props.drUzivatel.uziv_login,
            uziv_roleAdmin: props.drUzivatel.uziv_role.includes("A"),
            uziv_roleTechnik: props.drUzivatel.uziv_role.includes("T"),
            uziv_pin: "",
            uziv_pinPotvrzeni: "",
            uziv_inicialy: props.drUzivatel.uziv_inicialy,
            uziv_money_prac_id: props.drUzivatel.uziv_money_prac_id,
            uziv_money_id: Global.GuidEmpty,
            uziv_posledni_prihlaseni: props.drUzivatel.uziv_posledni_prihlaseni,

            dataMoneyZamestnanci: null,
            bNovy: false
        };
        else this.state = {
            uziv_id: -1,
            uziv_jmeno: "",
            uziv_email: "",
            uziv_login: "",
            uziv_roleAdmin: false,
            uziv_roleTechnik: false,
            uziv_pin: "",
            uziv_pinPotvrzeni: "",
            uziv_inicialy: "",
            uziv_money_prac_id: Global.GuidEmpty,
            uziv_money_id: Global.GuidEmpty,
            uziv_posledni_prihlaseni: "1900-01-01T00:00:00",

            dataMoneyZamestnanci: null,
            bNovy: true
        };

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.inicializace = this.inicializace.bind(this);
        this.reloadData = this.reloadData.bind(this);

    }

    onParamaterChange(event) {
        if (event.target.name == "formUzMoney") {
            let jmeno = this.state.uziv_jmeno;
            if (event.objectSelected.prijmeniJmeno.trim() != "") jmeno = event.objectSelected.prijmeniJmeno.trim();

            this.setState({ uziv_money_prac_id: event.target.value, uziv_money_id: event.objectSelected.user_ID, uziv_jmeno: jmeno });
        }

    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();
    }

    inicializace() {

        this.setState({ loading: true });
        fetchWrapper.get(fetchWrapper.VratZamestnance())
            .then(data => {
                const dataMoneyZamestnanciNew = [moneyZamestnanceEmpty];
                dataMoneyZamestnanciNew.push(...data);

                this.setState({ dataMoneyZamestnanci: dataMoneyZamestnanciNew});
            })
            .catch(error => {
                this.setState({ loading: false });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník pracovníků Money: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    formSubmit(event) {
        event.preventDefault();

        let uziv_role = "";
        if (this.state.uziv_roleAdmin) uziv_role += "A";
        if (this.state.uziv_roleTechnik) uziv_role += "T";

        let chyba = "";
        if (uziv_role == "") chyba = chyba + " Není nastavena žádná role.";
        if (this.state.uziv_login == "") chyba = chyba + " Nebyl nastaven login.";
        if (this.state.uziv_jmeno == "") chyba = chyba + " Nebylo nastaven jméno.";
        if (this.state.uziv_inicialy == "") chyba = chyba + " Nebyly nastaveny iniciály.";

        console.log(this.state.uziv_pin + " x " + this.state.uziv_pinPotvrzeni);
        if (this.state.uziv_pin != "" || this.state.uziv_pinPotvrzeni != "") {
            if (this.state.uziv_pin == "") chyba = chyba + " Nebylo nastaveno heslo.";
            if (this.state.uziv_pin != this.state.uziv_pinPotvrzeni) chyba = chyba + " Hesla se neshodují.";
        }
        else if (this.state.bNovy) chyba = chyba + " Nebylo nastaveno heslo.";


        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p>{chyba}</p>,
                buttons: [
                    { name: "OK", handler: () => "OK" }
                ]
            });

            return;
        }

       
        const uzData = {
            uziv_id: this.state.uziv_id,
            uziv_jmeno: this.state.uziv_jmeno,
            uziv_login: this.state.uziv_login,
            uziv_email: this.state.uziv_email,
            uziv_pin: this.state.uziv_pin,
            uziv_role: uziv_role,
            uziv_inicialy: this.state.uziv_inicialy,
            uziv_money_prac_id: this.state.uziv_money_prac_id,
            uziv_money_id: this.state.uziv_money_id,
            uziv_posledni_prihlaseni: this.state.uziv_posledni_prihlaseni
        };

        LoadingSpinner.open();
        if (this.state.uziv_id > 0) {
            fetchWrapper.put(fetchWrapper.EditUzivatele(), uzData).then(data => {
                LoadingSpinner.close();

                const user = AuthService.getCurrentUser();
                if (user && uzData.uziv_id == user.uziv_id) {
                    MessageBox.open({
                        title: "Zpráva",
                        content: <p>Byl upraven aktuálně přihlášený uživatel. Změny se promítnou až po opětovném přihlášení. </p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                }

                this.props.zavritDialog(true); 
            }).catch(error => {
                LoadingSpinner.close();
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se uložit data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
        }
        else {
            fetchWrapper.post(fetchWrapper.NovyUzivatele(), uzData).then(data => {
                LoadingSpinner.close(); this.props.zavritDialog(true);
            })
                .catch(error => {
                LoadingSpinner.close();
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se uložit data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }



    render() {
        const nadpis = (this.state.bNovy ? "Nový uživatel" : "Upravit uživatele");

        return (
            <form onSubmit={this.formSubmit}>
                <h1>{nadpis}</h1>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}>Money pracovník: </div>
                    <div className="right">
                        {this.state.dataMoneyZamestnanci ?
                            <Combobox klicID="id" klicPopis="prijmeniJmeno" ID={this.state.uziv_money_prac_id} name="formUzMoney" data={this.state.dataMoneyZamestnanci} onChange={this.onParamaterChange} />
                            :
                            <img src="./img/loading16.gif" style={{ height: 16 }} />
                        }
                    </div>
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Jméno:" hodnota={this.state.uziv_jmeno} widthLeft={120} stylesRight={{maxWidth: 300}}  typ="TEXT" onChange={e => this.setState({ uziv_jmeno: e.target.value })} />
                </div>
                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Iniciály:" hodnota={this.state.uziv_inicialy} widthLeft={120} stylesRight={{ maxWidth: 80 }} typ="INICIALY" onChange={e => this.setState({ uziv_inicialy: e.target.value })} />
                </div>
                

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Login:" hodnota={this.state.uziv_login} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ uziv_login: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Email:" hodnota={this.state.uziv_email} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ uziv_email: e.target.value })} />
                </div>

                <div className="odsazeni_bottom10 odsazeni_top10">
                    
                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Heslo:" hodnota={this.state.uziv_pin} widthLeft={120} stylesRight={{ maxWidth: 200 }} typ="PASSWORD" onChange={e => this.setState({ uziv_pin: e.target.value })} />
                    </div>

                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Heslo znovu:" hodnota={this.state.uziv_pinPotvrzeni} widthLeft={120} stylesRight={{ maxWidth: 200 }} typ="PASSWORD" onChange={e => this.setState({ uziv_pinPotvrzeni: e.target.value })} />
                    </div>
                </div>
                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}>Role: </div>
                    <div className="right">
                        <FormControlLabel
                            name="roleAdmin"
                            control={<Checkbox
                                checked={this.state.uziv_roleAdmin}
                                onClick={(event) => {
                                    this.setState({ uziv_roleAdmin: !this.state.uziv_roleAdmin });
                                }} />}
                            label="Administrátor" />

                        <FormControlLabel
                            name="roleTechnik"
                            control={<Checkbox
                                checked={this.state.uziv_roleTechnik}
                                onClick={(event) => {
                                    this.setState({ uziv_roleTechnik: !this.state.uziv_roleTechnik });
                                }} />}
                            label="Technik" />
                    </div>
                </div>



                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                    </div>
                </div>
            </form>
        );
    }
}