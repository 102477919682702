import { useState, useEffect } from 'react';

function useLongPress(callback, ms = 300) {
    const [startLongPress, setStartLongPress] = useState(false);

    function pressEvent() {

    }

    useEffect(() => {
        let intervalID;
        if (startLongPress) {
            intervalID = setInterval(callback, ms);
        } else {
            clearInterval(intervalID);
        }

        return () => {
            clearInterval(intervalID);
        };
    }, [callback, ms, startLongPress]);

    return {
        onMouseDown: () => setStartLongPress(true),
        onMouseUp: () =>  setStartLongPress(false),
        onMouseLeave: () => setStartLongPress(false),
        onTouchStart: () => setStartLongPress(true),
        onTouchEnd: () => setStartLongPress(false),
    };
}


export function TouchButton(props) {
    const backspaceLongPress = useLongPress(props.longPressCallback, 100);

    return (
        <div className={props.classCss} {...backspaceLongPress} style={{ backgroundImage: 'url(' + props.ikona + ')' }}></div>
    );
};
