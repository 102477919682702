import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import styles from './ServisVyjezdDetail.module.css'


import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "./MuiTheme";

import { Radio, RadioGroup, FormControlLabel, Checkbox, Stack, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'

import { fetchWrapper } from "../FetchWrapper";


import { Global } from "./Global"

import AuthService from "../AuthService";

import LoadingSpinner from './Loader/LoadingSpinner';
import MessageBox from "./MessageBox/MessageBox";



class FormServisVyjezdyHledani extends React.Component {
    constructor(props) {
        super(props);
        let filtrOd = null;
        let filtrDo = null;

        try {
            if (sessionStorage.getItem("servisvyjezd_filtr_od")) filtrOd = new Date(sessionStorage.getItem("servisvyjezd_filtr_od"));
            if (sessionStorage.getItem("servisvyjezd_filtr_do")) filtrDo = new Date(sessionStorage.getItem("servisvyjezd_filtr_do"));
        } catch { }


        if (filtrOd == null) filtrOd = new Date();

        if (filtrDo == null) {
            filtrDo = new Date();
            filtrDo.setDate(filtrOd.getDate() + 14);
        }

        this.state = { filtrDatumOd: filtrOd, filtrDatumDo: filtrDo, filtrStav: 1, filtrPouzeVedouciPrihlaseny: true };

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.onTxbChange = this.onTxbChange.bind(this);
        this.onFiltrStavChange = this.onFiltrStavChange.bind(this);

    }

    onTxbChange(event) {
        this.setState({
            m_hodnota: event.target.value
        });
    }

    onParamaterChange(event) {
        if (event.target.name == "filtrFiltrPrihlaseny") {
            this.setState({
                filtrPouzeVedouciPrihlaseny: !this.state.filtrPouzeVedouciPrihlaseny
            });
        }
    }

    formSubmit(event) {
        event.preventDefault();

        sessionStorage.setItem("servisvyjezd_filtr_od", Global.toISOString(this.state.filtrDatumOd));
        sessionStorage.setItem("servisvyjezd_filtr_do", Global.toISOString(this.state.filtrDatumDo));

        let uziv_money_prac_id = "00000000-0000-0000-0000-000000000000";
        let uziv_roleAdmin = false;
        const user = AuthService.getCurrentUser();
        if (user) {
            if (user.uziv_money_prac_id != undefined) uziv_money_prac_id = user.uziv_money_prac_id;
            else uziv_money_prac_id = "11111111-1111-1111-1111-111111111111"; //prihlaseny uzivatel nema zadne money ID => nastaveni nesmyslu

            uziv_roleAdmin = user.uziv_role.includes("A");
        }

        LoadingSpinner.open();
        fetchWrapper.get(fetchWrapper.VratServisniVyjezdy(this.state.filtrDatumOd, this.state.filtrDatumDo, this.state.filtrStav, uziv_money_prac_id, this.state.filtrPouzeVedouciPrihlaseny, uziv_roleAdmin))
            .then(data => { LoadingSpinner.close(); this.props.setDataServVyjezdy(data); })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    onFiltrStavChange(event) {
        this.setState({ filtrStav: event.currentTarget.value });
    }

    render() {


        return (
            <form onSubmit={this.formSubmit}>
                <ThemeProvider theme={MuiTheme}>
                    <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>

                        <strong>Stav: </strong>
                        <RadioGroup
                            row
                            value={this.state.filtrStav}
                            onChange={(e, value) => this.setState({ filtrStav: value })}
                            name="parStav"
                            style={{ display: "inline", marginLeft: 5 }}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Připravené" />
                            <FormControlLabel value="2" control={<Radio />} label="Dokončené" />
                            <FormControlLabel value="-1" control={<Radio />} label="Všechny" />
                        </RadioGroup>


                        <FormControlLabel
                            name="filtrFiltrPrihlaseny"
                            control={<Checkbox
                                checked={this.state.filtrPouzeVedouciPrihlaseny}
                                onClick={(event) => {
                                    this.setState({ filtrPouzeVedouciPrihlaseny: !this.state.filtrPouzeVedouciPrihlaseny });
                                }} />}
                            label="Pouze servisní výjezdy s přihlášeným uživatelem" />


                        <div className="odsazeni_bottom10">
                            <div className={styles.hledani_parametr}>
                                <Stack
                                    direction="row"
                                    justifyContent="left"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <div>
                                        <span style={{ lineHeight: "32px", fontWeight: "bold", paddingRight: 4 }}>Období  od:</span>

                                        <DesktopDatePicker
                                            inputFormat="dd.MM.yyyy"
                                            value={this.state.filtrDatumOd}
                                            onChange={date => this.setState({ filtrDatumOd: date })}
                                            renderInput={(params) => <TextField {...params} />}
                                            inputProps={{
                                                style: {
                                                    padding: 5,
                                                    width: 85,
                                                    minWidth: 85
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <span style={{ lineHeight: "32px", fontWeight: "bold", paddingRight:4 }}>do:</span>
                                        <DesktopDatePicker
                                            inputFormat="dd.MM.yyyy"
                                            value={this.state.filtrDatumDo}
                                            onChange={date => this.setState({ filtrDatumDo: date })}
                                            renderInput={(params) => <TextField {...params} />}
                                            inputProps={{
                                                style: {
                                                    padding: 5,
                                                    width: 85,
                                                    minWidth: 85
                                                }
                                            }}
                                        />
                                    </div>
                                </Stack>
                            </div>
                        </div>

                        <div className={styles.hledani_zadani}>
                            <div className={styles.right}><button className="form_button" type="submit">Filtrovat </button></div>

                        </div>
                    </LocalizationProvider>
                </ThemeProvider>
            </form>
        );
    }
}






export class ServisVyjezdList extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dataServVyjezdy: [] };

        this.rowClick = this.rowClick.bind(this);
        this.setDataServVyjezdy = this.setDataServVyjezdy.bind(this);

        this.tabSevyColumns = [
            /*{
                field: 'sevy_id', title: '', width: 65, headerSort: false,
                formatter: reactFormatter(
                    <ButtonVyberVyjezdu vyberSevyDetail={this.vyberDetail} popis="Detail" />
                )
            },*/
            {
                field: 'sevy_datum', title: 'Datum výjezdu', width: 130, headerSort: true, formatter: function (cell, formatterParams, onRendered) {

                    return Global.toDateStr(new Date(cell.getValue()));
                }
            },
            {
                field: 'sevy_stav', title: 'Stav', width: 130, headerSort: true, formatter: function (cell, formatterParams, onRendered) {
                    //cell - the cell component
                    //formatterParams - parameters set for the column
                    //onRendered - function to call when the formatter has been rendered
                    if (cell.getValue() == 0) return "Založený";
                    else if (cell.getValue() == 1) return "Připravený";
                    else if (cell.getValue() == 2) return "Dokončený";
                    return "-";
                }
            },
            { field: 'sevy_vedouci_technik_PrijmeniJmeno', title: 'Vedoucí', width: 200, headerSort: true },
            { field: 'semi_misto.semi_adresa', title: 'Místo servisu', width: 400, headerSort: true },
            { field: 'poza_cislo', title: 'Zakázka', width: 150, headerSort: true },

        ];
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        /* if (nextProps.dataSevy !== undefined && nextProps.dataSevy.length === 1)
             this.setState({ vybranyDetailSevy: nextProps.dataSevy[0] });
         else this.setState({ vybranyDetailSevy: undefined });*/
    }


    rowClick(e, row) {
        e.preventDefault();
        this.props.vyberDetail(row.getData());
    }

    setDataServVyjezdy(dataSevy) {
        this.setState({ dataServVyjezdy: dataSevy });
    }

    render() {
        return (
            <div>
                <div className="grid_auto">
                    <div className="box round first">
                        <h2>Filtr</h2>
                        <div >
                            <FormServisVyjezdyHledani setDataServVyjezdy={this.setDataServVyjezdy} />
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div className="clear"></div>
                <div>
                    <h3>Servisní výjezdy:</h3>
                    <div style={{ height: 400, width: '100%' }}>
                        <ReactTabulator
                            data={this.state.dataServVyjezdy}
                            columns={this.tabSevyColumns}
                            layout={'fitDataStretch'}
                            height={'100%'}
                            events={{ rowClick: this.rowClick }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}