import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

export class Combobox extends React.Component {
    constructor(props) {
        super(props);

        this.onParamaterChange = this.onParamaterChange.bind(this);
    }

    onParamaterChange(event) {
        const objSel = this.props.data.find((dr) => {
            return dr[this.props.klicID] === event.target.value;
        });

        event.objectSelected = objSel;
        this.props.onChange(event);
    }

    nastavitVolby() {
        return this.props.data.map((dr) => {
            return <MenuItem value={dr[this.props.klicID]}>{dr[this.props.klicPopis]}</MenuItem>;
        });
    }


    render() {
        if (this.props.bEmptyOption) {
            return (
                <FormControl sx={{ minWidth: (this.props.minWidth ? this.props.minWidth : 140), width: (this.props.fill ? "100%" : "auto") }}>
                    <Select
                        name={this.props.name }
                        defaultValue={""}
                        value={this.props.ID}
                        onChange={this.onParamaterChange}
                        size="small"
                        displayEmpty
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                        <MenuItem value="" default></MenuItem>
                        {this.nastavitVolby()}
                       
                    </Select>
                </FormControl>
            );
        }
        else {
            return (
                <FormControl sx={{ minWidth: (this.props.minWidth ? this.props.minWidth : 140), width: (this.props.fill ? "100%" : "auto") }}>
                    <Select
                        name={this.props.name}
                        value={this.props.ID}
                        onChange={this.onParamaterChange}
                        size="small"
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                        {this.nastavitVolby()}
                    </Select>
                </FormControl>
            );
        }
    }
}