import React from 'react';
import { ReactTabulator } from 'react-tabulator';

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";



export class ServisVyjezdOdpracovaneHodinyList extends React.Component {
    constructor(props) {
        super(props);

        this.novyZaznam = this.novyZaznam.bind(this);
        this.upravitZaznam = this.upravitZaznam.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
    }

    novyZaznam() {
        this.props.otevritDialogZaznam("ODPRACHODINY");
    }

    upravitZaznam(e, cell) {
        this.props.otevritDialogZaznam("ODPRACHODINY", cell.getRow().getData());
    }

    smazatZaznam = async (e, cell) => {
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraný záznam?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") this.props.smazatZaznam("ODPRACHODINY", cell.getRow().getData());
    }


    render() {

        let cellContextMenu = [];

        if (this.props.bAkce) {
            cellContextMenu = [
                {
                    label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                    action: this.novyZaznam
                },
                {
                    label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                    action: this.upravitZaznam
                },
                {
                    label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                    action: this.smazatZaznam
                }
            ];
        }


        const tabServOdpracovaneHodiny = [
            { field: 'soh_technik_PrijmeniJmeno', title: 'Servisní technik', width: 300, headerSort: true, contextMenu: cellContextMenu },
            { field: 'soh_mnozstvi_hodiny', title: 'Množství [hod]', width: 150, headerSort: true, contextMenu: cellContextMenu },
            { field: 'soh_mnozstvi_minuty', title: 'Množství [min]', width: 150, headerSort: true, contextMenu: cellContextMenu },
            { field: 'soh_poznamka', title: 'Poznámka', width: 400, headerSort: true, contextMenu: cellContextMenu }
        ];


        return <div>

            <h2>Odpracované hodiny {this.props.bAkce && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.novyZaznam}>Nový záznam</button>} </h2>
            <ReactTabulator
                data={this.props.data}
                columns={tabServOdpracovaneHodiny}
                layout={'fitDataStretch'}
                height={'200px'}
                selectable={1}
            />
        </div>;
    }
}
            