import React from 'react';  
import AuthService from "../AuthService";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator, MenuModule } from 'react-tabulator';
import * as dayjs from 'dayjs';

import { fetchWrapper } from "../FetchWrapper";

import { UzivateleForm } from "./UzivateleForm";
import { Global } from "./Global";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from './Loader/LoadingSpinner';



class TabulkaUzivatelu extends React.Component {
    constructor(props) {
        super(props);

        this.tabRef = React.createRef();

        this.tabRenderComplete = this.tabRenderComplete.bind(this);

        this.uzivatelNew = this.uzivatelNew.bind(this);
        this.uzivatelEdit = this.uzivatelEdit.bind(this);
        this.uzivatelDelete = this.uzivatelDelete.bind(this);
    }


    tabRenderComplete() {

    }

    uzivatelNew(e, cell) {
        this.props.OtevritDialogForm();
    }

    uzivatelEdit(e, cell) {
        this.props.OtevritDialogForm(cell.getRow().getData());
    }

    uzivatelDelete = async (e, cell) => {   
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraný záznam?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") {
            LoadingSpinner.open();
            fetchWrapper.del(fetchWrapper.VymazUzivatele(cell.getRow().getData().uziv_id))
                .then(data => {
                    LoadingSpinner.close(); this.props.ReloadData();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se aktualizovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    render() {
        const cellContextMenu = [
            {
                label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                action: this.uzivatelNew
            },
            {
                label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                action: this.uzivatelEdit
            },
            {
                label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                action: this.uzivatelDelete
            }
        ];

        const columns = [
            { field: 'uziv_jmeno', title: 'Jméno', width: 200, contextMenu: cellContextMenu },
            { field: 'uziv_login', title: 'Login', width: 100, contextMenu: cellContextMenu },
            { field: 'uziv_role', title: 'Role', width: 200, contextMenu: cellContextMenu, formatter: function (cell, formatterParams, onRendered) {
                    //cell - the cell component
                    //formatterParams - parameters set for the column
                    //onRendered - function to call when the formatter has been rendered  
                    let role = "";
                    if (cell.getValue().includes("A")) role += "Administrátor";
                    if (cell.getValue().includes("T")) {
                        if (role !== "") role += ", "; 
                        role += "Technik";
                    }

                    return role;
                },
            },
            {
                field: 'uziv_posledni_prihlaseni', title: 'Poslední přihlášení', width: 200, headerSort: false, contextMenu: cellContextMenu,
                formatter: function (cell, formatterParams, onRendered) {
                    if (cell.getValue() === undefined) return "";

                    if (dayjs(cell.getValue()).format('DD/MM/YYYY') === "01/01/1900") return "";
                    return dayjs(cell.getValue()).format('D.M.YYYY HH:mm:ss');
                }
            },
            {
                field: 'uziv_money_prac_id', title: 'Money uživatel nastaven', width: 100, headerSort: false, contextMenu: cellContextMenu,
                formatter: function (cell, formatterParams, onRendered) {
                    if (Global.JeGuidValid(cell.getValue()) && Global.GuidEmpty != cell.getValue()) return "ANO";
                    else return "NE";
                }
            }
            
        ];


        return (
            <>
                <div style={{ height: 400, width: '100%' }}>
                    <ReactTabulator
                        onRef={(r) => (this.tabRef = r)}
                        data={this.props.data}
                        columns={columns}
                        layout={'fitDataStretch'}
                        height={'100%'}
                        selectable={1}
                        events={{ renderComplete: this.tabRenderComplete }}
                    />
                </div>
            </>
        );
    }
  }



export class Uzivatele extends React.Component {
    constructor(props) {
        super(props);

        this.state = { currentUser: null, bAktivni: false, dataEdit: null, dataUzivatele: [] };

        this.otevritDialogForm = this.otevritDialogForm.bind(this);
        this.zavritDialogForm = this.zavritDialogForm.bind(this);
        this.novyZaznam = this.novyZaznam.bind(this);
        this.reloadData = this.reloadData.bind(this);
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        this.setState({ currentUser: user });
        if (user && user.uziv_role.includes("A")) {
            this.reloadData();
        }

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }


    otevritDialogForm(data) {
        this.setState({ dataEdit: data, bAktivni: true })
    }

    zavritDialogForm(bZmena) {
        this.setState({ dataEdit: null, bAktivni: false })
        if (bZmena) this.reloadData();
    }

    novyZaznam() {
        this.otevritDialogForm();
    }

    reloadData() {
        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratUzivatele())
            .then(data => {
                LoadingSpinner.close();
                this.setState({ dataUzivatele: data });
            })
            .catch(error => {
                LoadingSpinner.close();

                this.setState({ dataUzivatele: [] });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });       
    }

    render() {
        return (
            <>
                {this.state.currentUser && this.state.currentUser.uziv_role.includes("A") &&
                    <div className="grid_12 odsazeni_bottom10">
                        <div style={{ display: this.state.bAktivni ? 'none' : 'block' }}>
                            <h2>Uživatelé <button className="form_button" style={{ marginLeft: 20 }} onClick={this.novyZaznam}>Nový záznam</button></h2>
                            {this.state.dataUzivatele && <TabulkaUzivatelu data={this.state.dataUzivatele} OtevritDialogForm={this.otevritDialogForm} ReloadData={this.reloadData} />}                            
                        </div>

                        <div className='clear'></div>
                        {this.state.bAktivni && <UzivateleForm dataUzivatele={this.state.dataUzivatele}  drUzivatel={this.state.dataEdit} zavritDialog={this.zavritDialogForm} />}
                    </div>
                }
                {(!this.state.currentUser || !this.state.currentUser.uziv_role.includes("A")) &&
                    <div className="grid_12 odsazeni_bottom10 odsazeni_top10">
                        <div className="error_zprava" >Nemáte oprávnění pro přístup!</div>
                    </div>
                }
            </>
        );
    }
  } 