import React from 'react';
import styles from './EditorHodnotyForm.module.css'
import { Combobox } from './Combobox'
import { Global } from './Global'

import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const PanelHodnoty = ({ children, props }) => {
    return (
        <div className={styles.detail_udaj_container} style={props.stylesParent}>
            <div className={styles.left} style={{ width: props.widthLeft }}>{props.popis} </div>
            <div className={styles.right} style={props.stylesRight} >
                {children}
            </div>
        </div>);
}

export class EditorHodnotyForm extends React.Component {
    constructor(props) {
        super(props);
        /*this.rgxDesetinnyCisla = /^[+-]?([0-9]*[\\.,])?[0-9]*$/;
        this.rgxCelaCisla = /^[+-]?[0-9]*$/;*/
    }


    render() {
        if (this.props.typ === "DATETIME") {
            return (
                <PanelHodnoty props={this.props}>
                    <DesktopDateTimePicker renderInput={(params) => <TextField {...params} />}
                        inputFormat="dd.MM.yyyy HH:mm"
                        value={this.props.hodnota}
                        onChange={this.props.onChange}
                        onOpen={() => { }}
                        ampm={false}
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                        componentsProps={{
                            actionBar: {
                                actions: ["today"]

                            }
                        }}
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "CHECKBOX") {
            return (
                <PanelHodnoty props={this.props}>
                    <Checkbox
                        checked={this.props.hodnota}
                        disabled={this.props.disabled}
                        onClick={(event) => {
                            event.target.checked = !this.props.hodnota;
                            this.props.onChange(event);
                        }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        style={{ padding: 4 }}
                    />
                </PanelHodnoty >

            );

        }
        else if (this.props.typ === "JEDNZRYCHLENI") {
            return (
                <PanelHodnoty props={this.props}>
                    <FormControl sx={{ minWidth: 100 }}>
                        <Select value={this.props.hodnota} onChange={this.props.onChange} size="small" inputProps={{ MenuProps: { disableScrollLock: true } }}>
                            <MenuItem value="m.s-2">m.s-2</MenuItem>
                            <MenuItem value="g">g</MenuItem>
                        </Select>
                    </FormControl>
                </PanelHodnoty >
            );
        }


        else if (this.props.typ === "TYPULOZENI") {
            return (
                <PanelHodnoty props={this.props}>
                    <FormControl sx={{ minWidth: 140 }}>
                        <Select value={this.props.hodnota} onChange={this.props.onChange} size="small" sx={{ minWidth: 100 }} inputProps={{ MenuProps: { disableScrollLock: true } }}>
                            <MenuItem value="Nezadáno">Nezadáno</MenuItem>
                            <MenuItem value="Pevné">Pevné</MenuItem>
                            <MenuItem value="Pružné">Pružné</MenuItem>
                        </Select>
                    </FormControl>
                </PanelHodnoty >
            );
        }

        else if (this.props.typ === "TYPVENTILATORU") {
            return (
                <PanelHodnoty props={this.props}>
                    <Combobox klicID="kod" klicPopis="popis" ID={this.props.hodnota} name={this.props.name} minWidth={140} bEmptyOption={false} data={Global.EnumVentilatorTypCiselnik()} onChange={this.props.onChange} />
                </PanelHodnoty >
            );
        }


        else if (this.props.typ === "VENTUSPORADANI") {
            return (
                <PanelHodnoty props={this.props}>
                    <FormControl sx={{ minWidth: 140 }}>
                        <Select value={this.props.hodnota} onChange={this.props.onChange} size="small" inputProps={{ MenuProps: { disableScrollLock: true } }}>
                            <MenuItem value="ARR. 1">ARR. 1</MenuItem>
                            <MenuItem value="ARR. 3">ARR. 3</MenuItem>
                            <MenuItem value="ARR. 4">ARR. 4</MenuItem>
                            <MenuItem value="ARR. 7">ARR. 7</MenuItem>
                            <MenuItem value="ARR. 8">ARR. 8</MenuItem>
                            <MenuItem value="ARR. 9">ARR. 9</MenuItem>
                            <MenuItem value="ARR. 10">ARR. 10</MenuItem>
                            <MenuItem value="Napřímo">Napřímo</MenuItem>
                            <MenuItem value="Na spojku">Na spojku</MenuItem>
                            <MenuItem value="Na řemen">Na řemen</MenuItem>
                        </Select>
                    </FormControl>
                </PanelHodnoty >
            );
        }


        else if (this.props.typ === "CISLO") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        onKeyPress={(event) => {
                            if (!/[0-9\\-]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        type="number"
                        inputProps={{ inputMode: 'numeric' }}
                        label=""
                        size="small"
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                    />
                </PanelHodnoty>
            );
        }
        else if (this.props.typ === "CISLOD") {

            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        onKeyPress={(event) => {
                            /*if (event.key == ",") {
                                //event.preventDefault();
                                console.log(event.target.value + "." + "0");

                                this.props.onChange({ target: { value: parseFloat(event.target.value + ".0")  } });
                            }*/

                            //if (!(event.target.value + event.key).match(this.rgxDesetinnyCisla)) event.preventDefault();
                        }}
                        onPaste={(event) => {
                            event.preventDefault();

                            let val = event.clipboardData.getData("text");
                            if (val) {
                                val = val.replace(",", ".");
                                if (val.match(this.rgxDesetinnyCisla)) {
                                    console.log(val);
                                    console.log(parseFloat(val));
                                    this.props.onChange({ target: { value: parseFloat(val) } })
                                }
                            }
                        }}
                        type="number"
                        inputProps={{ /*lang: "en-US",*/ style: { textAlign: 'right' }, inputMode: 'numeric' }}
                        label=""
                        size="small"
                        onChange={(e) => { this.props.onChange(e) }}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                        sx={{ textAlign: "right" } }
                    />

                    {/*<input type="text"
                            className={styles.formHodnCislo}
                            onKeyPress={(event) => {
                                if (!/[0-9\\.\\-]/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if (event.key == "." && event.target.value.includes(".")) { //jiz obsahuje tecku
                                    event.preventDefault();
                                }
                                else if (event.key == "-" && event.target.value.length != 0) { //jiz obsahuje tecku
                                    event.preventDefault();
                                }
                            }}
                            value={this.props.hodnota}
                            onChange={this.props.onChange}
                            disabled={(this.props.disabled) ? "disabled" : ""}
                        />*/}
                </PanelHodnoty >
            );
        }
        else if (this.props.typ === "CISLODMERENI") {

            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        
                        onPaste={(event) => {
                            event.preventDefault();

                            let val = event.clipboardData.getData("text");
                            if (val) {
                                val = val.replace(",", ".");
                                if (val.match(this.rgxDesetinnyCisla)) {
                                    console.log(val);
                                    console.log(parseFloat(val));
                                    this.props.onChange({ target: { value: parseFloat(val) } })
                                }
                            }
                        }}
                        type="number"
                        inputProps={{ /*lang: "en-US",*/ style: { textAlign: 'right' }, inputMode: 'numeric' }}
                        label=""
                        size="small"
                        onChange={(e) => { this.props.onChange(e) }}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                        sx={{ textAlign: "right" }}
                    />
                    {this.props.bTlacitkoMereni && <img src="/img/measure.png" style={{ width: 40, height: 40, cursor: "pointer" }} onClick={(e) => this.props.onMereniHodnoty(e)} />}
                </PanelHodnoty >
            );
        }
        else if (this.props.typ === "PSC") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        onKeyPress={(event) => {
                            if (!/[0-9]\\s/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        type="number"
                        inputProps={{ inputMode: 'numeric' }}
                        size="small"
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        maxLength={5}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }} />
                </PanelHodnoty>
            );
        }


        else if (this.props.typ === "TEXT") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        size="small"
                        onChange={this.props.onChange}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        sx={{ width: "100%" }}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "INICIALY") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        size="small"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={this.props.onChange}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        sx={{ width: "100%" }}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                        inputProps={{ maxLength: 4 }}
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "PASSWORD") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        size="small"
                        onChange={this.props.onChange}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        sx={{ width: "100%" }}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                        type="password"
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "TEXTAREA") {
            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right}>
                        <TextField
                            size="small"
                            multiline={true}
                            minRows={this.props.minRows ? this.props.minRows : 3}
                            onChange={this.props.onChange}
                            value={this.props.hodnota}
                            disabled={this.props.disabled}
                            sx={{ width: "100%" }}
                            onFocus={event => {
                                if (!this.props.disabled) event.target.select();
                            }}
                        />
                    </div>
                </div>
            );
        }

        else if (this.props.typ === "POZNAMKA") {
            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left_ta} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right_ta}>
                        <TextField
                            size="small"
                            multiline={true}
                            minRows={this.props.minRows ? this.props.minRows : 3}
                            onChange={this.props.onChange}
                            value={this.props.hodnota}
                            disabled={this.props.disabled}
                            sx={{ width: "100%" }}
                            onFocus={event => {
                                if (!this.props.disabled) event.target.select();
                            }}
                        />
                    </div>
                </div>
            );
        }

        else if (this.props.typ === "COMBOBOX") {
            return (
                <PanelHodnoty props={this.props}>
                    {this.props.dataCiselnik && <Combobox klicID={this.props.klicID} klicPopis={this.props.klicPopis} ID={this.props.hodnota} fill={this.props.fill} minWidth={this.props.minWidth} name={this.props.name} bEmptyOption={false} data={this.props.dataCiselnik} onChange={this.props.onChange} />}
                </PanelHodnoty>
            );
        }



        return (
            <PanelHodnoty props={this.props}>
                {this.props.hodnota}
            </PanelHodnoty>
        );
    }
}