import React from 'react';
import { ReactTabulator } from 'react-tabulator';


import { Global } from "./Global"
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";



export class ServisVyjezdMaterialList extends React.Component {
    constructor(props) {
        super(props);

        this.novyZaznam = this.novyZaznam.bind(this);
        this.upravitZaznam = this.upravitZaznam.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
    }

    novyZaznam() {
        this.props.otevritDialogZaznam("MATERIAL");
    }

    upravitZaznam(e, cell) {
        this.props.otevritDialogZaznam("MATERIAL", cell.getRow().getData());
    }

    smazatZaznam = async (e, cell) => {
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraný záznam?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") this.props.smazatZaznam("MATERIAL", cell.getRow().getData());
    }


    render() {

        let cellContextMenu = [];

        if (this.props.bAkce) {
            cellContextMenu = [
                {
                    label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                    action: this.novyZaznam
                },
                {
                    label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                    action: this.upravitZaznam
                },
                {
                    label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                    action: this.smazatZaznam
                }
            ];
        }


        const tabServMaterial = [
            { field: 'sema_popis', title: 'Popis', width: 200, headerSort: true, contextMenu: cellContextMenu },
            { field: 'sema_mnozstvi', title: 'Množství', width: 120, headerSort: true, contextMenu: cellContextMenu },
            { field: 'sema_spotrebovane_mnozstvi', title: 'Spotř.množství', width: 140, headerSort: true, contextMenu: cellContextMenu },
            {
                field: 'sema_mj', title: 'MJ', width: 50, headerSort: false, contextMenu: cellContextMenu, formatter: function (cell, formatterParams, onRendered) {
                    return Global.EnumJednotka(cell.getValue());
                }
            },
            { field: 'artikly_ArtiklKod', title: 'Katalog - kód', width: 120, headerSort: true, contextMenu: cellContextMenu },
            { field: 'artikly_ArtiklNazev', title: 'Katalog -popis', width: 200, headerSort: true, contextMenu: cellContextMenu }
        ];


        return <div>

            <h2>Spotřebovaný materiál {this.props.bAkce && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.novyZaznam}>Nový záznam</button>}</h2>
            <ReactTabulator
                data={this.props.data}
                columns={tabServMaterial}
                layout={'fitDataStretch'}
                height={'200px'}
                selectable={1}
            />
        </div>;
    }
}
            