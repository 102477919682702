import AuthService from './AuthService';
import Global from './components/Global';
import CacheData from './CacheData';
import { v4 as uuidv4 } from 'uuid';

export const API_URL = window.TCFSERVISAPI

export const fetchWrapper = {
    get,
    getBezCache,
    post,
    put,
    del: del,
    fileLoad,
    fileLoadPost,

    VratZamestnance,
    VratZamestnanceServisniTechniky,
    VratZamestnanceServisniTechnikyOffline,

    VymazUzivatele,
    NovyUzivatele,
    EditUzivatele,
    VratUzivatele,
    VratServisniVyjezdy,
    VratProtokolServisniVyjezdy,
    VratServisniVyjezd,
    EditServisniVyjezd,
    EditServisniVyjezdOffline,
    EditServisniVyjezdZamek,
    NovyServisVyjezdMisto,
    NovyServisVyjezdMistoZahajeni,
    NovyServisVyjezdMistoUkonceni,
    VratServisVyjezdMista,
    VratProvozniHodnotyProtokol,
    NovyVentilatorProvozniHodnoty,
    NovyVentilatorProvozniHodnotyOffline,
    EditVentilatorProvozniHodnoty,
    EditVentilatorProvozniHodnotyOffline,
    VratZarizeni,
    VratZarizeniOffline,
    EditZarizeni,
    EditZarizeniOffline,
    VratZarizeniDetail,
    VratZarizeniPopis,
    VratZarizeniUmisteni,
    VratZarizeniVyrobniCislo,

    VratZarizeniTechnListExist,
    VratZarizeniTechnListExistOffline,
    VratZarizeniTechnList,
    VratZarizeniTechnListOffline,
    VratServisPraceCiselnik,
    VratServisPraceCiselnikOffline,
    NovyServisPrace,
    NovyServisPraceOffline,
    NovyServisPraceHromadne,
    NovyServisPraceHromadneOffline,
    EditServisPrace,
    EditServisPraceOffline,
    EditServisPraceHromadne,
    EditServisPraceHromadneOffline,
    VratServisPotrebneVybaveniCiselnik,
    VratServisPotrebneVybaveniCiselnikOffline,
    NovyServisPotrebneVybaveni,
    NovyServisPotrebneVybaveniOffline,
    NovyServisPotrebneVybaveniHromadne,
    NovyServisPotrebneVybaveniHromadneOffline,
    EditServisPotrebneVybaveni,
    EditServisPotrebneVybaveniOffline,
    EditServisPotrebneVybaveniHromadne,
    EditServisPotrebneVybaveniHromadneOffline,
    NovyServisOdpracovaneHodiny,
    NovyServisOdpracovaneHodinyOffline,
    NovyServisOdpracovaneHodinyHromadne,
    NovyServisOdpracovaneHodinyHromadneOffline,
    EditServisOdpracovaneHodiny,
    EditServisOdpracovaneHodinyOffline,
    EditServisOdpracovaneHodinyHromadne,
    EditServisOdpracovaneHodinyHromadneOffline,
    VratServisMaterialKatalog,
    VratServisMaterialKatalogOffline,
    NovyServisMaterial,
    NovyServisMaterialOffline,
    EditServisMaterial,
    EditServisMaterialOffline,
    NovyServisVyjezdProtokol,    
    VratServisMaterial,
    VratServisMaterialOffline,
    VratServisPotrebneVybaveni,
    VratServisPotrebneVybaveniOffline,
    VratServisPrace,
    VratServisPraceOffline,
    VratServisOdpracovaneHodiny,
    VratServisOdpracovaneHodinyOffline,
    VratZarizeniProvozniHodnoty,
    VratZarizeniProvozniHodnotyOffline,
    VratServisovanaZarizeni,
    VratServisovanaZarizeniOffline,
    VratServisniTechnici,
    VratServisniTechniciOffline,
    VratServisFotoOffline,
    VratServisPreddefinovaneFraze,
    VymazServisMaterial,
    VymazServisMaterialOffline,
    VymazServisPotrebneVybaveni,
    VymazServisPotrebneVybaveniOffline,
    VymazServisPrace,
    VymazServisPraceOffline,
    VymazServisOdpracovaneHodiny,
    VymazServisOdpracovaneHodinyOffline,
    VymazServisProvozniHodnoty,
    VymazServisProvozniHodnotyOffline,
    VymazServisovaneZarizeni,
    VymazServisovaneZarizeniOffline,
    VymazServisniTechnici,
    VymazServisniTechniciOffline,
    NovyServisniTechnici,
    NovyServisniTechniciOffline,
    NovyServisFoto,
    NovyServisFotoOffline,
    NovyServisPreddefinovaneFraze,
    VratZarizeniCiselnikJednoduchy,
    VratZarizeniCiselnikJednoduchyOffline,
    VratZarizeniCiselnikKomplexni,
    NovyServisovaneZarizeni,
    NovyServisovaneZarizeniOffline,
    EditServisovaneZarizeni,
    EditServisovaneZarizeniOffline,
    NovyProvozniHodnotyProtokol,
    SchvalitServisVyjezdProtokol,
    UlozitServisVyjezdProtokol,
    VratServisVyjezdTechnListy,
    SynchronizujServisVyjezdOfflineData,
    SynchronizujServisFotoOfflineFoto,
    VratServisFotoGallery,
    VratServisFotoGalleryOriginal,
    VratServisFotoGalleryThumbnail,
    VymazServisFotoPriloha,

    VratVlastnosti
};

function get(url, offlineRequest) {
    if (url == "tcfServisTechniciData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisTechnici; dataObjektDeleted = zaznam.servisTechniciDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisZamestnanciTechniciData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZamestnanciTechniciData").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisServisovanaZarizeniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisZarizeni; dataObjektDeleted = zaznam.servisZarizeniDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisZarizeniData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData").then(zaznam => {
            let dataObjekt = null, dataData = null;
            if (zaznam) { dataData = zaznam.data; if (dataData) dataObjekt = dataData.find(z => z.vent_id == offlineRequest.vent_id); }

            if (dataObjekt && dataData) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisZarizeniCiselnikJednoduchyData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisPraceData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisPrace; dataObjektDeleted = zaznam.servisPraceDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPraceCiselnikData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisPraceCiselnikData").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisMaterialData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisMaterial; dataObjektDeleted = zaznam.servisMaterialDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisMaterialKatalogData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisMaterialKatalogData").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisPotrebneVybaveniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisPotrebneVybaveni; dataObjektDeleted = zaznam.servisPotrebneVybaveniDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPotrebneVybaveniCiselnikData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisPotrebneVybaveniCiselnikData").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisOdpracovaneHodinyData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisOdpracovaneHodiny; dataObjektDeleted = zaznam.servisOdpracovaneHodinyDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisProvozniHodnotyZarizeniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null, dataZarizeni = null;
        if (zaznam) { dataZarizeni = zaznam.servisZarizeni.find(sz => sz.seve_zarizeni.vent_id == offlineRequest.vent_id); if (dataZarizeni) { dataObjekt = dataZarizeni.seve_zarizeni.provozniHodnoty; dataObjektDeleted = dataZarizeni.seve_zarizeni.provozniHodnotyDeleted; } }

        if (dataObjekt && dataObjektDeleted && dataZarizeni) {
            return Promise.resolve(dataObjekt.concat(dataObjektDeleted));
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisVyjezdTechnickeListyExists") {
        return CacheData.IndexedDBGetData("OfflineDataTechnListy", AuthService.getCurrentUser(), "tcfServisVyjezdTechnickeListy").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                let bodyIndex = dataObjekt.findIndex(tl => tl.vent_id == offlineRequest.vent_id);
                return Promise.resolve(bodyIndex >= 0);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisVyjezdTechnickeListy") {
        return CacheData.IndexedDBGetData("OfflineDataTechnListy", AuthService.getCurrentUser(), "tcfServisVyjezdTechnickeListy").then(zaznam => {
            let dataObjekt = null, dataData = null;
            if (zaznam) { dataData = zaznam.data; if (dataData) dataObjekt = dataData.find(tl => tl.vent_id == offlineRequest.vent_id); }

            if (dataObjekt && dataData) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisVyjezdFoto") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisVyjezdFoto").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                return Promise.resolve(dataObjekt);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(url)
        };

        return fetch(url, requestOptions).then(handleResponse);
    }
}

function handleResponseOffline(response) {
    if (response) {
        return response.data;
    }

    return response;
}

function getBezCache(url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' },
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, offlineRequest) {
    if (url == "tcfServisTechniciData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisTechnici;

        if (dataObjekt) {
            let bodyIndex = dataObjekt.findIndex(t => t.sete_prac_id == body.sete_prac_id);
            if (bodyIndex >= 0) {
                return Promise.reject("Servisní technik je již do servisního výjezdu přiřazen.");
            }
            if (body.sete_b_vedouci) {
                bodyIndex = dataObjekt.findIndex(t => t.sete_b_vedouci);
                if (bodyIndex >= 0) {
                    return Promise.reject("Vedoucí servisní technik je již do servisního výjezdu přiřazen.");
                }
            }
            dataObjekt.push(body);
            body.sete_offlineStav = 1;
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(body);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisServisovanaZarizeniData") {
        return Promise.all([
            CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData"),
            CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData")]).then(zaznamCiselniky => {
                let dataCiselnikKomplexni = null;
                if (zaznamCiselniky) dataCiselnikKomplexni = zaznamCiselniky[0].data;
                let dataCiselnikJednoduchy = null;
                if (zaznamCiselniky) dataCiselnikJednoduchy = zaznamCiselniky[1].data;

                const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
                let dataObjekt = null;
                if (zaznam) dataObjekt = zaznam.servisZarizeni;

                if (dataObjekt && dataCiselnikKomplexni && dataCiselnikJednoduchy) {
                    if (offlineRequest.bNovyVent) {
                        let bodyIndexCiselnikKomplexni = dataCiselnikKomplexni.findIndex(z => z.vent_vyrobni_cislo == body.seve_zarizeni.vent_vyrobni_cislo);
                        let bodyIndexCiselnikJednoduchy = dataCiselnikJednoduchy.findIndex(z => z.vent_vyrobni_cislo == body.seve_zarizeni.vent_vyrobni_cislo);
                        if (bodyIndexCiselnikKomplexni >= 0 && bodyIndexCiselnikJednoduchy >= 0) {
                            return Promise.reject("Zařízení se stejným výrobním číslem již existuje.");
                        }

                        if (bodyIndexCiselnikKomplexni < 0 && bodyIndexCiselnikJednoduchy < 0) {
                            let bodyCopyCiselnikKomplexni = structuredClone(body.seve_zarizeni);
                            dataCiselnikKomplexni.push(bodyCopyCiselnikKomplexni);
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselniky[0].data);

                            let bodyCopyCiselnikJednoduchy = {
                                vent_id: body.seve_zarizeni.vent_id,
                                vent_typ: body.seve_zarizeni.vent_typ,
                                vent_popis: body.seve_zarizeni.vent_popis,
                                vent_vyrobce: body.seve_zarizeni.vent_vyrobce,
                                vent_vyrobni_cislo: body.seve_zarizeni.vent_vyrobni_cislo,
                                vent_vyza_id: body.seve_zarizeni.vent_vyza_id,
                                semi_id: body.seve_zarizeni.semi_misto.semi_id
                            };
                            dataCiselnikJednoduchy.push(bodyCopyCiselnikJednoduchy);
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData", zaznamCiselniky[1].data);
                        }
                    }
                    if (!offlineRequest.bNovyVent) {
                        let bodyIndexZarizeni = dataObjekt.findIndex(sz => sz.seve_zarizeni.vent_id == body.seve_zarizeni.vent_id);
                        if (bodyIndexZarizeni >= 0) {
                            return Promise.reject("Zařízení je již do servisního výjezdu přiřazeno.");
                        }

                        let bodyIndexCiselnikKomplexni = dataCiselnikKomplexni.findIndex(z => z.vent_id == body.seve_zarizeni.vent_id);
                        let bodyIndexCiselnikJednoduchy = dataCiselnikJednoduchy.findIndex(z => z.vent_id == body.seve_zarizeni.vent_id);

                        if (bodyIndexCiselnikKomplexni >= 0 && bodyIndexCiselnikJednoduchy >= 0) {
                            let bodyCopyCiselnikKomplexni = structuredClone(body.seve_zarizeni);
                            dataCiselnikKomplexni[bodyIndexCiselnikKomplexni] = bodyCopyCiselnikKomplexni;
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselniky[0].data);

                            let bodyCopyCiselnikJednoduchy = {
                                vent_id: body.seve_zarizeni.vent_id,
                                vent_typ: body.seve_zarizeni.vent_typ,
                                vent_popis: body.seve_zarizeni.vent_popis,
                                vent_vyrobce: body.seve_zarizeni.vent_vyrobce,
                                vent_vyrobni_cislo: body.seve_zarizeni.vent_vyrobni_cislo,
                                vent_vyza_id: body.seve_zarizeni.vent_vyza_id,
                                semi_id: body.seve_zarizeni.semi_misto.semi_id
                            };
                            dataCiselnikJednoduchy[bodyIndexCiselnikJednoduchy] = bodyCopyCiselnikJednoduchy;
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData", zaznamCiselniky[1].data);
                        }
                    }
                    dataObjekt.push(body);
                    body.seve_offlineStav = 1;
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                    return Promise.resolve(body);
                }
                return Promise.reject("ERROR CACHE");
            });
    }
    else if (url == "tcfServisPraceData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisPrace;

        if (dataObjekt) {
            dataObjekt.push(body);
            body.sepr_offlineStav = 1;
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(body);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPraceHromadneData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisPrace;

        if (dataObjekt) {
            for (let i = 0; i < body.servisZarizeni.length; i++) {
                let bodyCopy = structuredClone(body);
                bodyCopy.sepr_id = uuidv4();
                bodyCopy.vent_id = body.servisZarizeni[i].seve_zarizeni.vent_id;
                bodyCopy.servisZarizeni = null;

                dataObjekt.push(bodyCopy);
                bodyCopy.sepr_offlineStav = 1;
            }
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(dataObjekt);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisMaterialData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisMaterial;

        if (dataObjekt) {
            dataObjekt.push(body);
            body.sema_offlineStav = 1;
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(body);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPotrebneVybaveniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisPotrebneVybaveni;

        if (dataObjekt) {
            dataObjekt.push(body);
            body.sepv_offlineStav = 1;
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(body);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPotrebneVybaveniHromadneData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataZarizeni = null;
        if (zaznam) { dataObjekt = zaznam.servisPotrebneVybaveni; dataZarizeni = zaznam.servisZarizeni; }

        if (dataObjekt && dataZarizeni) {
            let casObjekt = null;
            if (dataZarizeni.length > 0) {
                casObjekt = Global.VratRovnomerneRozdelenyCas(body.sepv_mnozstvi_hodiny, body.sepv_mnozstvi_minuty, dataZarizeni.length);
            }
            const objektMnozstviHodiny = casObjekt ? casObjekt.hodiny : 0;
            const objektMnozstviMinuty = casObjekt ? Math.round((casObjekt.minuty + Number.EPSILON) * 100) / 100 : 0;
            for (let i = 0; i < dataZarizeni.length; i++) {
                let bodyCopy = structuredClone(body);
                bodyCopy.sepv_id = uuidv4();
                bodyCopy.vent_id = dataZarizeni[i].seve_zarizeni.vent_id;
                bodyCopy.sepv_mnozstvi_hodiny = objektMnozstviHodiny;
                bodyCopy.sepv_mnozstvi_minuty = objektMnozstviMinuty;

                dataObjekt.push(bodyCopy);
                bodyCopy.sepv_offlineStav = 1;
            }
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(dataObjekt);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisOdpracovaneHodinyData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisOdpracovaneHodiny;

        if (dataObjekt) {
            dataObjekt.push(body);
            body.soh_offlineStav = 1;
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(body);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisOdpracovaneHodinyHromadneData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataZarizeni = null;
        if (zaznam) { dataObjekt = zaznam.servisOdpracovaneHodiny; dataZarizeni = zaznam.servisZarizeni; }

        if (dataObjekt && dataZarizeni) {
            let casObjekt = null;
            if (dataZarizeni.length > 0) {
                casObjekt = Global.VratRovnomerneRozdelenyCas(body.soh_mnozstvi_hodiny, body.soh_mnozstvi_minuty, dataZarizeni.length);
            }
            const objektMnozstviHodiny = casObjekt ? casObjekt.hodiny : 0;
            const objektMnozstviMinuty = casObjekt ? Math.round((casObjekt.minuty + Number.EPSILON) * 100) / 100 : 0;
            for (let i = 0; i < dataZarizeni.length; i++) {
                let bodyCopy = structuredClone(body);
                bodyCopy.soh_id = uuidv4();
                bodyCopy.vent_id = dataZarizeni[i].seve_zarizeni.vent_id;
                bodyCopy.soh_mnozstvi_hodiny = objektMnozstviHodiny;
                bodyCopy.soh_mnozstvi_minuty = objektMnozstviMinuty;

                dataObjekt.push(bodyCopy);
                bodyCopy.soh_offlineStav = 1;
            }
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(dataObjekt);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisProvozniHodnotyZarizeniData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData").then(zaznamCiselnikKomplexni => {
            let dataCiselnikKomplexni = null, dataZarizeniCiselnikKomplexni = null;
            if (zaznamCiselnikKomplexni) { dataZarizeniCiselnikKomplexni = zaznamCiselnikKomplexni.data.find(z => z.vent_id == body.vent_id); if (dataZarizeniCiselnikKomplexni) dataCiselnikKomplexni = dataZarizeniCiselnikKomplexni.provozniHodnoty }

            const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
            let dataObjekt = null, dataZarizeni = null;
            if (zaznam) { dataZarizeni = zaznam.servisZarizeni.find(sz => sz.seve_zarizeni.vent_id == body.vent_id); if (dataZarizeni) dataObjekt = dataZarizeni.seve_zarizeni.provozniHodnoty; }

            if (dataCiselnikKomplexni && dataZarizeniCiselnikKomplexni) {
                let bodyCopyCiselnikKomplexni = structuredClone(body);
                dataCiselnikKomplexni.push(bodyCopyCiselnikKomplexni);
                bodyCopyCiselnikKomplexni.vph_offlineStav = 1;
                dataZarizeniCiselnikKomplexni.vent_usporadani = bodyCopyCiselnikKomplexni.vent_usporadani;
                dataZarizeniCiselnikKomplexni.vent_ulozeni = bodyCopyCiselnikKomplexni.vph_typ_ulozeni;
                CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselnikKomplexni.data);
            }
            if (dataObjekt && dataZarizeni) {
                dataObjekt.push(body);
                body.vph_offlineStav = 1;
                dataZarizeni.seve_zarizeni.vent_usporadani = body.vent_usporadani;
                dataZarizeni.seve_zarizeni.vent_ulozeni = body.vph_typ_ulozeni;
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(body);
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else if (url == "tcfServisVyjezdFoto") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisVyjezdFoto").then(zaznam => {
            let dataObjekt = null;
            if (zaznam) dataObjekt = zaznam.data;

            if (dataObjekt) {
                let sefo_nazev_zdroj_bezporadi = body.sefo_nazev_zdroj.substring(0, body.sefo_nazev_zdroj.lastIndexOf('.'));
                let sefo_nazev_zdroj_pripona = body.sefo_nazev_zdroj.split('.').pop();

                if (sefo_nazev_zdroj_pripona && sefo_nazev_zdroj_bezporadi && sefo_nazev_zdroj_pripona != sefo_nazev_zdroj_bezporadi) {
                    sefo_nazev_zdroj_pripona = '.' + sefo_nazev_zdroj_pripona;
                    let indexNoveFoto = 1;

                    let bodyPosledni = [...dataObjekt].sort(function (a, b) {
                        return b.sefo_datum - a.sefo_datum
                    }).find(sf => sf.sefo_nazev_zdroj.startsWith(sefo_nazev_zdroj_bezporadi + '_'));

                    if (bodyPosledni) {
                        let posledniCisloVNazvu = bodyPosledni.sefo_nazev_zdroj.substring(0, bodyPosledni.sefo_nazev_zdroj.lastIndexOf('.'));
                        posledniCisloVNazvu = posledniCisloVNazvu.substring(posledniCisloVNazvu.lastIndexOf('_') + 1);
                        indexNoveFoto = parseInt(posledniCisloVNazvu) + 1;
                    }

                    if (Number.isInteger(indexNoveFoto)) {
                        dataObjekt.push(body);
                        body.sefo_nazev_zdroj = sefo_nazev_zdroj_bezporadi + '_' + indexNoveFoto + sefo_nazev_zdroj_pripona;
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisVyjezdFoto", zaznam.data);
                        return Promise.resolve(body);
                    }
                }
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else {
        const requestOptions = {
            method: 'POST',

            headers: { 'Content-Type': 'application/json', ...authHeader(url) },
            body: JSON.stringify(body)
        };

        return fetch(url, requestOptions).then(handleResponse);
    }
}

function put(url, body, offlineRequest) {
    if (url == "tcfServisVyjezdData") {
        return Promise.resolve(body);
    }
    else if (url == "tcfServisServisovanaZarizeniData") {
        return Promise.all([
            CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData"),
            CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData")]).then(zaznamCiselniky => {
                let dataCiselnikKomplexni = null;
                if (zaznamCiselniky) dataCiselnikKomplexni = zaznamCiselniky[0].data;
                let dataCiselnikJednoduchy = null;
                if (zaznamCiselniky) dataCiselnikJednoduchy = zaznamCiselniky[1].data;

                const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
                let dataObjekt = null;
                if (zaznam) dataObjekt = zaznam.servisZarizeni;

                if (dataObjekt && dataCiselnikKomplexni && dataCiselnikJednoduchy) {
                    if (offlineRequest.bNovyVent) {
                        let bodyIndexCiselnikKomplexni = dataCiselnikKomplexni.findIndex(z => z.vent_vyrobni_cislo == body.seve_zarizeni.vent_vyrobni_cislo);
                        let bodyIndexCiselnikJednoduchy = dataCiselnikJednoduchy.findIndex(z => z.vent_vyrobni_cislo == body.seve_zarizeni.vent_vyrobni_cislo);
                        if (bodyIndexCiselnikKomplexni >= 0 && bodyIndexCiselnikJednoduchy >= 0) {
                            return Promise.reject("Zařízení se stejným výrobním číslem již existuje.");
                        }

                        if (bodyIndexCiselnikKomplexni < 0 && bodyIndexCiselnikJednoduchy < 0) {
                            let bodyCopyCiselnikKomplexni = structuredClone(body.seve_zarizeni);
                            dataCiselnikKomplexni.push(bodyCopyCiselnikKomplexni);
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselniky[0].data);

                            let bodyCopyCiselnikJednoduchy = {
                                vent_id: body.seve_zarizeni.vent_id,
                                vent_typ: body.seve_zarizeni.vent_typ,
                                vent_popis: body.seve_zarizeni.vent_popis,
                                vent_vyrobce: body.seve_zarizeni.vent_vyrobce,
                                vent_vyrobni_cislo: body.seve_zarizeni.vent_vyrobni_cislo,
                                vent_vyza_id: body.seve_zarizeni.vent_vyza_id,
                                semi_id: body.seve_zarizeni.semi_misto.semi_id
                            };
                            dataCiselnikJednoduchy.push(bodyCopyCiselnikJednoduchy);
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData", zaznamCiselniky[1].data);
                        }
                    }
                    if (!offlineRequest.bNovyVent) {
                        let bodyIndexZarizeni = dataObjekt.findIndex(sz => sz.seve_zarizeni.vent_id == body.seve_zarizeni.vent_id && sz.seve_id != body.seve_id);
                        if (bodyIndexZarizeni >= 0) {
                            return Promise.reject("Zařízení je již do servisního výjezdu přiřazeno.");
                        }

                        let bodyIndexCiselnikKomplexni = dataCiselnikKomplexni.findIndex(z => z.vent_id == body.seve_zarizeni.vent_id);
                        let bodyIndexCiselnikJednoduchy = dataCiselnikJednoduchy.findIndex(z => z.vent_id == body.seve_zarizeni.vent_id);

                        if (bodyIndexCiselnikKomplexni >= 0 && bodyIndexCiselnikJednoduchy >= 0) {
                            let bodyCopyCiselnikKomplexni = structuredClone(body.seve_zarizeni);
                            dataCiselnikKomplexni[bodyIndexCiselnikKomplexni] = bodyCopyCiselnikKomplexni;
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselniky[0].data);

                            let bodyCopyCiselnikJednoduchy = {
                                vent_id: body.seve_zarizeni.vent_id,
                                vent_typ: body.seve_zarizeni.vent_typ,
                                vent_popis: body.seve_zarizeni.vent_popis,
                                vent_vyrobce: body.seve_zarizeni.vent_vyrobce,
                                vent_vyrobni_cislo: body.seve_zarizeni.vent_vyrobni_cislo,
                                vent_vyza_id: body.seve_zarizeni.vent_vyza_id,
                                semi_id: body.seve_zarizeni.semi_misto.semi_id
                            };
                            dataCiselnikJednoduchy[bodyIndexCiselnikJednoduchy] = bodyCopyCiselnikJednoduchy;
                            CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData", zaznamCiselniky[1].data);
                        }
                    }
                    let bodyIndex = dataObjekt.findIndex(sz => sz.seve_id == body.seve_id);
                    if (bodyIndex >= 0) {
                        dataObjekt[bodyIndex] = body;
                        if (body.seve_offlineStav == 0) body.seve_offlineStav = 2;
                        CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                        return Promise.resolve(body);
                    }
                }
                return Promise.reject("ERROR CACHE");
            });
    }
    else if (url == "tcfServisZarizeniData") {
        return Promise.all([
            CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData"),
            CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData")]).then(zaznamCiselniky => {
                let dataCiselnikKomplexni = null;
                if (zaznamCiselniky) dataCiselnikKomplexni = zaznamCiselniky[0].data;
                let dataCiselnikJednoduchy = null;
                if (zaznamCiselniky) dataCiselnikJednoduchy = zaznamCiselniky[1].data;

                if (dataCiselnikKomplexni && dataCiselnikJednoduchy) {
                    let bodyIndexCiselnikKomplexni = dataCiselnikKomplexni.findIndex(z => z.vent_id == body.vent_id);
                    let bodyIndexCiselnikJednoduchy = dataCiselnikJednoduchy.findIndex(z => z.vent_id == body.vent_id);

                    if (bodyIndexCiselnikKomplexni >= 0 && bodyIndexCiselnikJednoduchy >= 0) {
                        dataCiselnikKomplexni[bodyIndexCiselnikKomplexni] = body;
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselniky[0].data);

                        let bodyCopyCiselnikJednoduchy = {
                            vent_id: body.vent_id,
                            vent_typ: body.vent_typ,
                            vent_popis: body.vent_popis,
                            vent_vyrobce: body.vent_vyrobce,
                            vent_vyrobni_cislo: body.vent_vyrobni_cislo,
                            vent_vyza_id: body.vent_vyza_id,
                            semi_id: body.semi_misto.semi_id
                        };
                        dataCiselnikJednoduchy[bodyIndexCiselnikJednoduchy] = bodyCopyCiselnikJednoduchy;
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData", zaznamCiselniky[1].data);
                    }
                    return Promise.resolve(body);
                }
                return Promise.reject("ERROR CACHE");
            });
    }
    else if (url == "tcfServisPraceData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisPrace;

        if (dataObjekt) {
            let bodyIndex = dataObjekt.findIndex(p => p.sepr_id == body.sepr_id);
            if (bodyIndex >= 0) {
                dataObjekt[bodyIndex] = body;
                if (body.sepr_offlineStav == 0) body.sepr_offlineStav = 2;
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(body);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPraceHromadneData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisPrace;

        if (dataObjekt) {
            for (let i = 0; i < dataObjekt.length; i++) {
                if ((dataObjekt[i].spp_id == body.spp_id || dataObjekt[i].sepr_id == body.sepr_id) && body.servisZarizeni.some(sz => sz.seve_zarizeni.vent_id == dataObjekt[i].vent_id)) {
                    let bodyCopy = structuredClone(body);
                    bodyCopy.sepr_id = dataObjekt[i].sepr_id;
                    bodyCopy.vent_id = dataObjekt[i].vent_id;
                    bodyCopy.servisZarizeni = null;
                    bodyCopy.sepr_offlineStav = dataObjekt[i].sepr_offlineStav;

                    dataObjekt[i] = bodyCopy;
                    if (bodyCopy.sepr_offlineStav == 0) bodyCopy.sepr_offlineStav = 2;
                }
            }
            for (let i = 0; i < body.servisZarizeni.length; i++) {
                if (!dataObjekt.some(p => (p.spp_id == body.spp_id || p.sepr_id == body.sepr_id) && body.servisZarizeni[i].seve_zarizeni.vent_id == p.vent_id)) {
                    let bodyCopy = structuredClone(body);
                    bodyCopy.sepr_id = uuidv4();
                    bodyCopy.vent_id = body.servisZarizeni[i].seve_zarizeni.vent_id;
                    bodyCopy.servisZarizeni = null;

                    dataObjekt.push(bodyCopy);
                    bodyCopy.sepr_offlineStav = 1;
                }
            }
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(dataObjekt);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisMaterialData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisMaterial;

        if (dataObjekt) {
            let bodyIndex = dataObjekt.findIndex(m => m.sema_id == body.sema_id);
            if (bodyIndex >= 0) {
                dataObjekt[bodyIndex] = body;
                if (body.sema_offlineStav == 0) body.sema_offlineStav = 2;
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(body);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPotrebneVybaveniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisPotrebneVybaveni;

        if (dataObjekt) {
            let bodyIndex = dataObjekt.findIndex(v => v.sepv_id == body.sepv_id);
            if (bodyIndex >= 0) {
                dataObjekt[bodyIndex] = body;
                if (body.sepv_offlineStav == 0) body.sepv_offlineStav = 2;
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(body);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPotrebneVybaveniHromadneData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataZarizeni = null;
        if (zaznam) { dataObjekt = zaznam.servisPotrebneVybaveni; dataZarizeni = zaznam.servisZarizeni; }

        if (dataObjekt && dataZarizeni) {
            const dataVybaveni = dataObjekt.filter(v => v.sepvc_id == body.sepvc_id || v.sepv_id == body.sepv_id);
            const dataVybaveniPocet = dataVybaveni.length + dataZarizeni.filter(sz => !dataVybaveni.some(v => v.vent_id == sz.seve_zarizeni.vent_id)).length;
            let casObjekt = null;
            if (dataVybaveniPocet > 0) {
                casObjekt = Global.VratRovnomerneRozdelenyCas(body.sepv_mnozstvi_hodiny, body.sepv_mnozstvi_minuty, dataVybaveniPocet);
            }
            const objektMnozstviHodiny = casObjekt ? casObjekt.hodiny : 0;
            const objektMnozstviMinuty = casObjekt ? Math.round((casObjekt.minuty + Number.EPSILON) * 100) / 100 : 0;
            for (let i = 0; i < dataObjekt.length; i++) {
                if ((dataObjekt[i].sepvc_id == body.sepvc_id || dataObjekt[i].sepv_id == body.sepv_id) && dataZarizeni.some(sz => sz.seve_zarizeni.vent_id == dataObjekt[i].vent_id)) {
                    let bodyCopy = structuredClone(body);
                    bodyCopy.sepv_id = dataObjekt[i].sepv_id;
                    bodyCopy.sepv_mnozstvi_hodiny = objektMnozstviHodiny;
                    bodyCopy.sepv_mnozstvi_minuty = objektMnozstviMinuty;
                    bodyCopy.vent_id = dataObjekt[i].vent_id;
                    bodyCopy.sepv_offlineStav = dataObjekt[i].sepv_offlineStav;

                    dataObjekt[i] = bodyCopy;
                    if (bodyCopy.sepv_offlineStav == 0) bodyCopy.sepv_offlineStav = 2;
                }
            }
            for (let i = 0; i < dataZarizeni.length; i++) {
                if (!dataObjekt.some(v => (v.sepvc_id == body.sepvc_id || v.sepv_id == body.sepv_id) && dataZarizeni[i].seve_zarizeni.vent_id == v.vent_id)) {
                    let bodyCopy = structuredClone(body);
                    bodyCopy.sepv_id = uuidv4();
                    bodyCopy.vent_id = dataZarizeni[i].seve_zarizeni.vent_id;
                    bodyCopy.sepv_mnozstvi_hodiny = objektMnozstviHodiny;
                    bodyCopy.sepv_mnozstvi_minuty = objektMnozstviMinuty;

                    dataObjekt.push(bodyCopy);
                    bodyCopy.sepv_offlineStav = 1;
                }
            }
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(dataObjekt);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisOdpracovaneHodinyData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null;
        if (zaznam) dataObjekt = zaznam.servisOdpracovaneHodiny;

        if (dataObjekt) {
            let bodyIndex = dataObjekt.findIndex(h => h.soh_id == body.soh_id);
            if (bodyIndex >= 0) {
                dataObjekt[bodyIndex] = body;
                if (body.soh_offlineStav == 0) body.soh_offlineStav = 2;
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(body);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisOdpracovaneHodinyHromadneData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataZarizeni = null;
        if (zaznam) { dataObjekt = zaznam.servisOdpracovaneHodiny; dataZarizeni = zaznam.servisZarizeni; }

        if (dataObjekt && dataZarizeni) {
            const dataHodiny = dataObjekt.filter(h => h.soh_technik_id == body.soh_technik_id || h.soh_id == body.soh_id);
            const dataHodinyPocet = dataHodiny.length + dataZarizeni.filter(sz => !dataHodiny.some(h => h.vent_id == sz.seve_zarizeni.vent_id)).length;
            let casObjekt = null;
            if (dataHodinyPocet > 0) {
                casObjekt = Global.VratRovnomerneRozdelenyCas(body.soh_mnozstvi_hodiny, body.soh_mnozstvi_minuty, dataHodinyPocet);
            }
            const objektMnozstviHodiny = casObjekt ? casObjekt.hodiny : 0;
            const objektMnozstviMinuty = casObjekt ? Math.round((casObjekt.minuty + Number.EPSILON) * 100) / 100 : 0;
            for (let i = 0; i < dataObjekt.length; i++) {
                if ((dataObjekt[i].soh_technik_id == body.soh_technik_id || dataObjekt[i].soh_id == body.soh_id) && dataZarizeni.some(sz => sz.seve_zarizeni.vent_id == dataObjekt[i].vent_id)) {
                    let bodyCopy = structuredClone(body);
                    bodyCopy.soh_id = dataObjekt[i].soh_id;
                    bodyCopy.soh_mnozstvi_hodiny = objektMnozstviHodiny;
                    bodyCopy.soh_mnozstvi_minuty = objektMnozstviMinuty;
                    bodyCopy.vent_id = dataObjekt[i].vent_id;
                    bodyCopy.soh_offlineStav = dataObjekt[i].soh_offlineStav;

                    dataObjekt[i] = bodyCopy;
                    if (bodyCopy.soh_offlineStav == 0) bodyCopy.soh_offlineStav = 2;
                }
            }
            for (let i = 0; i < dataZarizeni.length; i++) {
                if (!dataObjekt.some(h => (h.soh_technik_id == body.soh_technik_id || h.soh_id == body.soh_id) && dataZarizeni[i].seve_zarizeni.vent_id == h.vent_id)) {
                    let bodyCopy = structuredClone(body);
                    bodyCopy.soh_id = uuidv4();
                    bodyCopy.vent_id = dataZarizeni[i].seve_zarizeni.vent_id;
                    bodyCopy.soh_mnozstvi_hodiny = objektMnozstviHodiny;
                    bodyCopy.soh_mnozstvi_minuty = objektMnozstviMinuty;

                    dataObjekt.push(bodyCopy);
                    bodyCopy.soh_offlineStav = 1;
                }
            }
            CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
            return Promise.resolve(dataObjekt);
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisProvozniHodnotyZarizeniData") {
        return CacheData.IndexedDBGetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData").then(zaznamCiselnikKomplexni => {
            let dataCiselnikKomplexni = null, dataZarizeniCiselnikKomplexni = null;
            if (zaznamCiselnikKomplexni) { dataZarizeniCiselnikKomplexni = zaznamCiselnikKomplexni.data.find(z => z.vent_id == body.vent_id); if (dataZarizeniCiselnikKomplexni) dataCiselnikKomplexni = dataZarizeniCiselnikKomplexni.provozniHodnoty }

            const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
            let dataObjekt = null, dataZarizeni = null;
            if (zaznam) { dataZarizeni = zaznam.servisZarizeni.find(sz => sz.seve_zarizeni.vent_id == body.vent_id); if (dataZarizeni) dataObjekt = dataZarizeni.seve_zarizeni.provozniHodnoty; }

            if (dataCiselnikKomplexni && dataZarizeniCiselnikKomplexni) {
                let bodyIndexCiselnikKomplexni = dataCiselnikKomplexni.findIndex(ph => ph.vph_id == body.vph_id);
                if (bodyIndexCiselnikKomplexni >= 0) {
                    let bodyCopyCiselnikKomplexni = structuredClone(body);
                    bodyCopyCiselnikKomplexni.vph_offlineStav = dataCiselnikKomplexni[bodyIndexCiselnikKomplexni].vph_offlineStav;
                    dataCiselnikKomplexni[bodyIndexCiselnikKomplexni] = bodyCopyCiselnikKomplexni;
                    if (bodyCopyCiselnikKomplexni.vph_offlineStav == 0) bodyCopyCiselnikKomplexni.vph_offlineStav = 2;
                    dataZarizeniCiselnikKomplexni.vent_usporadani = bodyCopyCiselnikKomplexni.vent_usporadani;
                    dataZarizeniCiselnikKomplexni.vent_ulozeni = bodyCopyCiselnikKomplexni.vph_typ_ulozeni;
                    CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", zaznamCiselnikKomplexni.data);
                }
            }
            if (dataObjekt && dataZarizeni) {
                let bodyIndex = dataObjekt.findIndex(ph => ph.vph_id == body.vph_id);
                if (bodyIndex >= 0) {
                    dataObjekt[bodyIndex] = body;
                    if (body.vph_offlineStav == 0) body.vph_offlineStav = 2;
                    dataZarizeni.seve_zarizeni.vent_usporadani = body.vent_usporadani;
                    dataZarizeni.seve_zarizeni.vent_ulozeni = body.vph_typ_ulozeni;
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                    return Promise.resolve(body);
                }
            }
            return Promise.reject("ERROR CACHE");
        });
    }
    else {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', ...authHeader(url) },
            body: JSON.stringify(body)
        };
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// prefixed with underscored because delete is a reserved word in javascript
function del(url, offlineRequest) {
    if (url == "tcfServisTechniciData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisTechnici; dataObjektDeleted = zaznam.servisTechniciDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            let bodyVzor = dataObjekt.find(t => t.sete_id == offlineRequest.sete_id);
            if (bodyVzor) {
                zaznam.servisTechnici = dataObjekt.filter(t => t.sete_id != bodyVzor.sete_id);
                if (bodyVzor.sete_offlineStav != 1) { bodyVzor.sete_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(true);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisServisovanaZarizeniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null, dataPrace = null, dataMaterial = null, dataVybaveni = null, dataHodiny = null;
        if (zaznam) { dataObjekt = zaznam.servisZarizeni; dataObjektDeleted = zaznam.servisZarizeniDeleted; dataPrace = zaznam.servisPrace; dataMaterial = zaznam.servisMaterial; dataVybaveni = zaznam.servisPotrebneVybaveni; dataHodiny = zaznam.servisOdpracovaneHodiny; }

        if (dataObjekt && dataObjektDeleted && dataPrace && dataMaterial && dataVybaveni && dataHodiny) {
            let bodyVzor = dataObjekt.find(sz => sz.seve_id == offlineRequest.seve_id);
            if (bodyVzor) {
                let dataIndex = dataPrace.findIndex(p => p.vent_id == bodyVzor.seve_zarizeni.vent_id);
                if (dataIndex >= 0) {
                    return Promise.reject("Záznam servisovaného zařízení musí mít prázdné seznamy prací, odpracovaných hodin, speciálního vybavení, spotřebovaného materiálu, provozních hodnot.");
                }
                dataIndex = dataMaterial.findIndex(m => m.vent_id == bodyVzor.seve_zarizeni.vent_id);
                if (dataIndex >= 0) {
                    return Promise.reject("Záznam servisovaného zařízení musí mít prázdné seznamy prací, odpracovaných hodin, speciálního vybavení, spotřebovaného materiálu, provozních hodnot.");
                }
                dataIndex = dataVybaveni.findIndex(v => v.vent_id == bodyVzor.seve_zarizeni.vent_id);
                if (dataIndex >= 0) {
                    return Promise.reject("Záznam servisovaného zařízení musí mít prázdné seznamy prací, odpracovaných hodin, speciálního vybavení, spotřebovaného materiálu, provozních hodnot.");
                }
                dataIndex = dataHodiny.findIndex(h => h.vent_id == bodyVzor.seve_zarizeni.vent_id);
                if (dataIndex >= 0) {
                    return Promise.reject("Záznam servisovaného zařízení musí mít prázdné seznamy prací, odpracovaných hodin, speciálního vybavení, spotřebovaného materiálu, provozních hodnot.");
                }
                const dataHodnoty = bodyVzor.seve_zarizeni.provozniHodnoty;
                if (dataHodnoty) {
                    dataIndex = dataHodnoty.findIndex(ph => ph.vent_id == bodyVzor.seve_zarizeni.vent_id && ph.sevy_id == zaznam.sevy_id);
                    if (dataIndex >= 0) {
                        return Promise.reject("Záznam servisovaného zařízení musí mít prázdné seznamy prací, odpracovaných hodin, speciálního vybavení, spotřebovaného materiálu, provozních hodnot.");
                    }
                    zaznam.servisZarizeni = dataObjekt.filter(sz => sz.seve_id != bodyVzor.seve_id);
                    if (bodyVzor.seve_offlineStav != 1) { bodyVzor.seve_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                    return Promise.resolve(true);
                }
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPraceData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisPrace; dataObjektDeleted = zaznam.servisPraceDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            let bodyVzor = dataObjekt.find(p => p.sepr_id == offlineRequest.sepr_id);
            if (bodyVzor) {
                zaznam.servisPrace = dataObjekt.filter(p => p.sepr_id != bodyVzor.sepr_id);
                if (bodyVzor.sepr_offlineStav != 1) { bodyVzor.sepr_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(true);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisMaterialData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisMaterial; dataObjektDeleted = zaznam.servisMaterialDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            let bodyVzor = dataObjekt.find(m => m.sema_id == offlineRequest.sema_id);
            if (bodyVzor) {
                zaznam.servisMaterial = dataObjekt.filter(m => m.sema_id != bodyVzor.sema_id);
                if (bodyVzor.sema_offlineStav != 1) { bodyVzor.sema_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(true);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisPotrebneVybaveniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisPotrebneVybaveni; dataObjektDeleted = zaznam.servisPotrebneVybaveniDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            let bodyVzor = dataObjekt.find(v => v.sepv_id == offlineRequest.sepv_id);
            if (bodyVzor) {
                zaznam.servisPotrebneVybaveni = dataObjekt.filter(v => v.sepv_id != bodyVzor.sepv_id);
                if (bodyVzor.sepv_offlineStav != 1) { bodyVzor.sepv_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(true);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisOdpracovaneHodinyData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null;
        if (zaznam) { dataObjekt = zaznam.servisOdpracovaneHodiny; dataObjektDeleted = zaznam.servisOdpracovaneHodinyDeleted; }

        if (dataObjekt && dataObjektDeleted) {
            let bodyVzor = dataObjekt.find(h => h.soh_id == offlineRequest.soh_id);
            if (bodyVzor) {
                zaznam.servisOdpracovaneHodiny = dataObjekt.filter(h => h.soh_id != bodyVzor.soh_id);
                if (bodyVzor.soh_offlineStav != 1) { bodyVzor.soh_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(true);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else if (url == "tcfServisProvozniHodnotyZarizeniData") {
        const zaznam = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        let dataObjekt = null, dataObjektDeleted = null, dataZarizeni = null;
        if (zaznam) { dataZarizeni = zaznam.servisZarizeni.find(sz => sz.seve_zarizeni.vent_id == offlineRequest.vent_id); if (dataZarizeni) { dataObjekt = dataZarizeni.seve_zarizeni.provozniHodnoty; dataObjektDeleted = dataZarizeni.seve_zarizeni.provozniHodnotyDeleted; } }

        if (dataObjekt && dataObjektDeleted && dataZarizeni) {
            let bodyVzor = dataObjekt.find(ph => ph.vph_id == offlineRequest.vph_id);
            if (bodyVzor) {
                dataZarizeni.seve_zarizeni.provozniHodnoty = dataObjekt.filter(ph => ph.vph_id != bodyVzor.vph_id);
                if (bodyVzor.vph_offlineStav != 1) { bodyVzor.vph_offlineStav = 3; dataObjektDeleted.push(bodyVzor); }
                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", zaznam);
                return Promise.resolve(true);
            }
        }
        return Promise.reject("ERROR CACHE");
    }
    else {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader(url)
        };
        return fetch(url, requestOptions).then(handleResponse);
    }
}

function fileLoad(url) {

    const headers = authHeader(url);
    headers["Content-Type"] = 'application/pdf';


    return fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((response) => response.blob())
        .then((file) => {
            /*
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([file]),
            );            
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `FileName.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);*/

            return file;
        });
}

function fileLoadPost(url, body) {

    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    })
        .then((response) => response.blob())
        .then((file) => {
            return file;
        });
}

// helper functions
function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url   
    const userLogged = AuthService.getCurrentUser();
    const isLoggedIn = userLogged && userLogged.accessToken;

    /*const isApiUrl = url.startsWith(publicRuntimeConfig.apiUrl);*/
    if (isLoggedIn/* && isApiUrl*/) {
        return { Authorization: `Bearer ${userLogged.accessToken}`, "KlientApiVerze": window.KLIENT_API_VERZE };
    } else {
        return {};
    }
}

function handleResponse(response) {

    return response.text().then(text => {
        if (response.status == 400) {

            const error = response.statusText + ": " + text;
            return Promise.reject(error);
        }

        const data = text && JSON.parse(text);
        const user = AuthService.getCurrentUser();

        if (!response.ok) {
            if ([401, 403].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                AuthService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function VratZamestnance() {
    return API_URL + "Zamestnanec";
}

function VratZamestnanceServisniTechniky() {
    return API_URL + "Zamestnanec/ServisniTechnici/";
}

function VratZamestnanceServisniTechnikyOffline() {
    return "tcfServisZamestnanciTechniciData";
}

function VymazUzivatele(uziv_id) {
    return API_URL + "tcfuzivatel/" + uziv_id;
}

function VratUzivatele() {
    return API_URL + "tcfUzivatel";
}

function NovyUzivatele() {
    return API_URL + "tcfuzivatel";
}

function EditUzivatele() {
    return API_URL + "tcfuzivatel";
}

function VratServisniVyjezdy(dateOd, dateDo, filtrStav, uziv_money_prac_id, pouzeVedouciPrihlaseny, uziv_roleAdmin) {
    return API_URL + "tcfServisVyjezd/" + Global.toISOString(dateOd) + "/" + Global.toISOString(dateDo) + "/" + filtrStav + "/" + uziv_money_prac_id + "/" + pouzeVedouciPrihlaseny + "/" + uziv_roleAdmin;
}

function VratProtokolServisniVyjezdy(sevy_id, sevy_podzakazka_id, sevy_datumDo) {
    return API_URL + "tcfServisVyjezd/protokolservisnivyjezdy/" + sevy_id + "/" + sevy_podzakazka_id + "/" + Global.toISOString(sevy_datumDo);
}

function VratServisniVyjezd(sevy_id) {
    return API_URL + "tcfServisVyjezd/" + sevy_id;
}

function EditServisniVyjezd() {
    return API_URL + "tcfServisVyjezd";
}

function EditServisniVyjezdOffline() {
    return "tcfServisVyjezdData";
}

function EditServisniVyjezdZamek(sevy_id, sevy_locked) {
    return API_URL + "tcfServisVyjezd/zamek/" + sevy_id + "/" + sevy_locked;
}

function NovyServisVyjezdMisto() {
    return API_URL + "tcfServisVyjezd/noveServisniMisto";
}

function NovyServisVyjezdMistoZahajeni() {
    return API_URL + "tcfServisVyjezd/noveServisniMistoZahajeni";
}

function NovyServisVyjezdMistoUkonceni() {
    return API_URL + "tcfServisVyjezd/noveServisniMistoUkonceni";
}

function VratServisVyjezdMista() {
    return API_URL + "tcfServisMista";
}

function VratProvozniHodnotyProtokol(vph_id, bReload) {
    return API_URL + "tcfZarizeni/provoznihodnotyprotokol/" + vph_id + "/" + bReload;
}


function NovyVentilatorProvozniHodnoty() {
    return API_URL + "tcfVentilatoryProvozniHodnoty";
}

function NovyVentilatorProvozniHodnotyOffline() {
    return "tcfServisProvozniHodnotyZarizeniData";
}

function EditVentilatorProvozniHodnoty() {
    return API_URL + "tcfVentilatoryProvozniHodnoty";
}

function EditVentilatorProvozniHodnotyOffline() {
    return "tcfServisProvozniHodnotyZarizeniData";
}

function VratZarizeni(vent_id) {
    return API_URL + "tcfZarizeni/" + vent_id
}

function VratZarizeniOffline() {
    return "tcfServisZarizeniData";
}

function EditZarizeni() {
    return API_URL + "tcfZarizeni";
}

function EditZarizeniOffline() {
    return "tcfServisZarizeniData";
}

function VratZarizeniDetail(vent_id) {
    return API_URL + "tcfZarizeni/detail/" + vent_id;
}



function VratZarizeniPopis(hodnota) {
    return API_URL + "tcfZarizeni/Popis/" + hodnota;
}


function VratZarizeniUmisteni(hodnota) {
    return API_URL + "tcfZarizeni/Umisteni/" + hodnota;
}


function VratZarizeniVyrobniCislo(hodnota) {
    return API_URL + "tcfZarizeni/VyrobniCislo/" + hodnota;
}


function VratZarizeniTechnListExist(vent_id) {
    return API_URL + "tcfZarizeni/technlistexist/" + vent_id;
}

function VratZarizeniTechnListExistOffline() {
    return "tcfServisVyjezdTechnickeListyExists";
}

function VratZarizeniTechnList(vent_id) {
    return API_URL + "tcfZarizeni/technlist/" + vent_id;
}

function VratZarizeniTechnListOffline() {
    return "tcfServisVyjezdTechnickeListy";
}

function VratServisPraceCiselnik() {
    return API_URL + "tcfServisVyjezdPrace/ciselnik";
}

function VratServisPraceCiselnikOffline() {
    return "tcfServisPraceCiselnikData";
}

function NovyServisPrace() {
    return API_URL + "tcfServisVyjezdPrace";
}

function NovyServisPraceOffline() {
    return "tcfServisPraceData";
}

function NovyServisPraceHromadne() {
    return API_URL + "tcfServisVyjezdPrace/hromadne";
}

function NovyServisPraceHromadneOffline() {
    return "tcfServisPraceHromadneData";
}

function EditServisPrace() {
    return API_URL + "tcfServisVyjezdPrace";
}

function EditServisPraceOffline() {
    return "tcfServisPraceData";
}

function EditServisPraceHromadne() {
    return API_URL + "tcfServisVyjezdPrace/hromadne";
}

function EditServisPraceHromadneOffline() {
    return "tcfServisPraceHromadneData";
}

function VratServisPotrebneVybaveniCiselnik() {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni/ciselnik";
}

function VratServisPotrebneVybaveniCiselnikOffline() {
    return "tcfServisPotrebneVybaveniCiselnikData";
}

function NovyServisPotrebneVybaveni() {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni";
}

function NovyServisPotrebneVybaveniOffline() {
    return "tcfServisPotrebneVybaveniData";
}

function NovyServisPotrebneVybaveniHromadne() {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni/hromadne";
}

function NovyServisPotrebneVybaveniHromadneOffline() {
    return "tcfServisPotrebneVybaveniHromadneData";
}

function EditServisPotrebneVybaveni() {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni";
}

function EditServisPotrebneVybaveniOffline() {
    return "tcfServisPotrebneVybaveniData";
}

function EditServisPotrebneVybaveniHromadne() {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni/hromadne";
}

function EditServisPotrebneVybaveniHromadneOffline() {
    return "tcfServisPotrebneVybaveniHromadneData";
}

function NovyServisOdpracovaneHodiny() {
    return API_URL + "tcfServisVyjezdOdpracovaneHodiny";
}

function NovyServisOdpracovaneHodinyOffline() {
    return "tcfServisOdpracovaneHodinyData";
}

function NovyServisOdpracovaneHodinyHromadne() {
    return API_URL + "tcfServisVyjezdOdpracovaneHodiny/hromadne";
}

function NovyServisOdpracovaneHodinyHromadneOffline() {
    return "tcfServisOdpracovaneHodinyHromadneData";
}

function EditServisOdpracovaneHodiny() {
    return API_URL + "tcfServisVyjezdOdpracovaneHodiny";
}

function EditServisOdpracovaneHodinyOffline() {
    return "tcfServisOdpracovaneHodinyData";
}

function EditServisOdpracovaneHodinyHromadne() {
    return API_URL + "tcfServisVyjezdOdpracovaneHodiny/hromadne";
}

function EditServisOdpracovaneHodinyHromadneOffline() {
    return "tcfServisOdpracovaneHodinyHromadneData";
}

function VratServisMaterialKatalog() {
    return API_URL + "tcfServisVyjezdMaterial/katalog";
}

function VratServisMaterialKatalogOffline() {
    return "tcfServisMaterialKatalogData";
}

function NovyServisMaterial() {
    return API_URL + "tcfServisVyjezdMaterial";
}

function NovyServisMaterialOffline() {
    return "tcfServisMaterialData";
}

function EditServisMaterial() {
    return API_URL + "tcfServisVyjezdMaterial";
}

function EditServisMaterialOffline() {
    return "tcfServisMaterialData";
}

function NovyServisVyjezdProtokol() {
    return API_URL + "tcfServisVyjezd/protokol";
}

function VratServisMaterial(sevy_id) {
    return API_URL + "tcfServisVyjezdMaterial/" + sevy_id;
}

function VratServisMaterialOffline() {
    return "tcfServisMaterialData";
}

function VratServisPotrebneVybaveni(sevy_id) {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni/" + sevy_id;
}

function VratServisPotrebneVybaveniOffline() {
    return "tcfServisPotrebneVybaveniData";
}

function VratServisPrace(sevy_id) {
    return API_URL + "tcfServisVyjezdPrace/" + sevy_id;
}

function VratServisPraceOffline() {
    return "tcfServisPraceData";
}

function VratServisOdpracovaneHodiny(sevy_id) {
    return API_URL + "tcfServisVyjezdOdpracovaneHodiny/" + sevy_id;
}

function VratServisOdpracovaneHodinyOffline() {
    return "tcfServisOdpracovaneHodinyData";
}

function VratZarizeniProvozniHodnoty(vent_id) {
    return API_URL + "tcfZarizeni/provoznihodnoty/" + vent_id;
}

function VratZarizeniProvozniHodnotyOffline() {
    return "tcfServisProvozniHodnotyZarizeniData";
}

function VratServisovanaZarizeni(sevy_id) {
    return API_URL + "tcfServisovaneZarizeni/" + sevy_id;
}

function VratServisovanaZarizeniOffline() {
    return "tcfServisServisovanaZarizeniData";
}

function VratServisniTechnici(sevy_id) {
    return API_URL + "tcfServisVyjezdTechnici/" + sevy_id;
}

function VratServisniTechniciOffline() {
    return "tcfServisTechniciData";
}

function VratServisFotoOffline() {
    return "tcfServisVyjezdFoto";
}

function VratServisPreddefinovaneFraze(uziv_money_prac_id) {
    return API_URL + "tcfServisPreddefinovaneFraze/" + uziv_money_prac_id;
}

function VymazServisMaterial(sema_id) {
    return API_URL + "tcfServisVyjezdMaterial/" + sema_id;
}

function VymazServisMaterialOffline() {
    return "tcfServisMaterialData";
}

function VymazServisPotrebneVybaveni(sepv_id) {
    return API_URL + "tcfServisVyjezdPotrebneVybaveni/" + sepv_id;
}

function VymazServisPotrebneVybaveniOffline() {
    return "tcfServisPotrebneVybaveniData";
}

function VymazServisPrace(sepr_id) {
    return API_URL + "tcfServisVyjezdPrace/" + sepr_id;
}

function VymazServisPraceOffline() {
    return "tcfServisPraceData";
}

function VymazServisOdpracovaneHodiny(soh_id) {
    return API_URL + "tcfServisVyjezdOdpracovaneHodiny/" + soh_id;
}

function VymazServisOdpracovaneHodinyOffline() {
    return "tcfServisOdpracovaneHodinyData";
}

function VymazServisProvozniHodnoty(vph_id) {
    return API_URL + "tcfVentilatoryProvozniHodnoty/" + vph_id;
}

function VymazServisProvozniHodnotyOffline() {
    return "tcfServisProvozniHodnotyZarizeniData";
}

function VymazServisovaneZarizeni(seve_id) {
    return API_URL + "tcfServisovaneZarizeni/" + seve_id;
}

function VymazServisovaneZarizeniOffline() {
    return "tcfServisServisovanaZarizeniData";
}

function VymazServisniTechnici(sete_id) {
    return API_URL + "tcfServisVyjezdTechnici/" + sete_id;
}

function VymazServisniTechniciOffline() {
    return "tcfServisTechniciData";
}

function NovyServisniTechnici() {
    return API_URL + "tcfServisVyjezdTechnici";
}

function NovyServisniTechniciOffline() {
    return "tcfServisTechniciData";
}

function NovyServisFoto() {
    return API_URL + "tcfServisVyjezd/foto";
}

function NovyServisFotoOffline() {
    return "tcfServisVyjezdFoto";
}

function NovyServisPreddefinovaneFraze() {
    return API_URL + "tcfServisPreddefinovaneFraze";
}

function VratZarizeniCiselnikJednoduchy(semi_id) {
    return API_URL + "tcfZarizeni/ciselnikjednoduchy/" + semi_id;
}

function VratZarizeniCiselnikJednoduchyOffline() {
    return "tcfServisZarizeniCiselnikJednoduchyData";
}

function VratZarizeniCiselnikKomplexni(semi_id) {
    return API_URL + "tcfZarizeni/ciselnikkomplexni/" + semi_id;
}

function NovyServisovaneZarizeni() {
    return API_URL + "tcfServisovaneZarizeni";
}

function NovyServisovaneZarizeniOffline() {
    return "tcfServisServisovanaZarizeniData";
}

function EditServisovaneZarizeni() {
    return API_URL + "tcfServisovaneZarizeni";
}

function EditServisovaneZarizeniOffline() {
    return "tcfServisServisovanaZarizeniData";
}

function NovyProvozniHodnotyProtokol(vph_id) {
    return API_URL + "tcfZarizeni/provoznihodnotyprotokolulozit/" + vph_id
}

function SchvalitServisVyjezdProtokol() {
    return API_URL + "tcfServisVyjezd/protokolschvalit";
}

function UlozitServisVyjezdProtokol() {
    return API_URL + "tcfServisVyjezd/protokolulozit";
}

function VratServisVyjezdTechnListy(sevy_id) {
    return API_URL + "tcfServisVyjezd/technlist/" + sevy_id;
}

function SynchronizujServisVyjezdOfflineData() {
    return API_URL + "tcfServisVyjezd/offlineData";
}

function SynchronizujServisFotoOfflineFoto() {
    return API_URL + "tcfServisFoto/offlineFoto";
}

function VratServisFotoGallery(vent_id, att_datumOd, att_datumDo) {
    return API_URL + "tcfServisFoto/gallery/" + vent_id + "/" + att_datumOd + "/" + att_datumDo;
}

function VratServisFotoGalleryOriginal(att_id) {
    return API_URL + "tcfServisFoto/galleryoriginal/" + att_id;
}

function VratServisFotoGalleryThumbnail(att_id) {
    return API_URL + "tcfServisFoto/gallerythumbnail/" + att_id;
}

function VymazServisFotoPriloha(att_id) {
    return API_URL + "tcfServisFoto/prilohafoto/" + att_id;
}

function VratVlastnosti() {
    return API_URL + "vlastnosti";
}