import React from 'react';
import { Combobox } from "./Combobox";
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";
import { EditorHodnotyForm } from "./EditorHodnotyForm"
import { FormControlLabel, Checkbox } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";
import Global from './Global';

export class ServisVyjezdOdpracovaneHodinyForm extends React.Component {
    constructor(props) {
        super(props);

        const novyPrvniTechnik = (props.dataTechnici && props.dataTechnici.length > 0) ? props.dataTechnici[0] : null;

        if (props.dataObject) this.state = {
            soh_id: props.dataObject.soh_id,
            soh_mnozstvi_hodiny: props.dataObject.soh_mnozstvi_hodiny,
            soh_mnozstvi_minuty: props.dataObject.soh_mnozstvi_minuty,
            soh_poznamka: props.dataObject.soh_poznamka,
            soh_technik_id: props.dataObject.soh_technik_id,
            soh_technik_OsCislo: props.dataObject.soh_technik_OsCislo,
            soh_technik_PrijmeniJmeno: props.dataObject.soh_technik_PrijmeniJmeno,
            soh_offlineStav: props.dataObject.soh_offlineStav,

            bNovy: false,
            bHromadneZadani: false,
            dataTechniciCiselnik: null
        }
        else this.state = {
            soh_id: Global.GuidEmpty,
            soh_mnozstvi_hodiny: 0,
            soh_mnozstvi_minuty: 0,
            soh_poznamka: "",
            soh_technik_id: novyPrvniTechnik ? novyPrvniTechnik.sete_prac_id : Global.GuidEmpty,
            soh_technik_OsCislo: novyPrvniTechnik ? novyPrvniTechnik.osCislo : "",
            soh_technik_PrijmeniJmeno: novyPrvniTechnik ? novyPrvniTechnik.prijmeniJmeno : "",
            soh_offlineStav: 0,

            bNovy: true,
            bHromadneZadani: false,
            dataTechniciCiselnik: null
        }

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.onFiltrHromadneZadaniChecked = this.onFiltrHromadneZadaniChecked.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
    }

    onParamaterChange(event) {
        if (event.target.name == "soh_mnozstvi_hodiny") this.setState({ soh_mnozstvi_hodiny: event.target.value });
        else if (event.target.name == "soh_mnozstvi_minuty") this.setState({ soh_mnozstvi_minuty: event.target.value });
        else if (event.target.name == "soh_poznamka") this.setState({ soh_poznamka: event.target.value });
        else if (event.target.name == "soh_technik_id") {
            this.setState({ soh_technik_id: event.target.value, soh_technik_OsCislo: event.objectSelected.osCislo, soh_technik_PrijmeniJmeno: event.objectSelected.prijmeniJmeno });
        }
    }

    inicializace() {

        const dataTechniciCiselnikNew = [];
        if (this.state.soh_technik_id && this.state.soh_technik_id != Global.GuidEmpty) {
            if (!this.props.dataTechnici.find(st => st.sete_prac_id == this.state.soh_technik_id)) {
                dataTechniciCiselnikNew.push({
                    sete_id: Global.GuidEmpty,
                    sete_b_vedouci: false,
                    sete_prac_id: this.state.soh_technik_id,
                    osCislo: this.state.soh_technik_OsCislo,
                    prijmeniJmeno: this.state.soh_technik_PrijmeniJmeno,
                    sevy_id: this.props.sevyData.sevy_id,
                    sete_offlineStav: 0
                });
            }
        }
        dataTechniciCiselnikNew.push(...this.props.dataTechnici);

        this.setState({ dataTechniciCiselnik: dataTechniciCiselnikNew });
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();

        const zarizeniSevy = this.props.sevyData.servisZarizeni.find(v => v.seve_zarizeni.vent_id == this.props.zarizeni.vent_id);
        if (zarizeniSevy == null) {
            this.zavritDialog();
            return;
        }
    }


    formSubmit(event) {
        event.preventDefault();

        let chyba = "";
        if (!(this.state.soh_mnozstvi_hodiny > 0) && !(this.state.soh_mnozstvi_minuty > 0)) chyba = chyba + " Nebyl zadán odpracovaný čas.";
        if (!Global.JeGuidValid(this.state.soh_technik_id)) chyba = chyba + " Nebyl vybrán servisní technik.";

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }


        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const dataObject = {
            soh_id: (!jeOfflineRezim || (jeOfflineRezim && !this.state.bNovy)) ? this.state.soh_id : uuidv4(),
            soh_mnozstvi_hodiny: this.state.soh_mnozstvi_hodiny,
            soh_mnozstvi_minuty: this.state.soh_mnozstvi_minuty,
            soh_poznamka: this.state.soh_poznamka,
            soh_technik_id: this.state.soh_technik_id,
            soh_technik_OsCislo: this.state.soh_technik_OsCislo,
            soh_technik_PrijmeniJmeno: this.state.soh_technik_PrijmeniJmeno,
            sevy_id: this.props.sevyData.sevy_id,
            vent_id: this.props.zarizeni.vent_id,
            soh_offlineStav: this.state.soh_offlineStav
        }

        if (this.state.bNovy) {
            LoadingSpinner.open();

            if (this.state.bHromadneZadani) {
                fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisOdpracovaneHodinyHromadne() : fetchWrapper.NovyServisOdpracovaneHodinyHromadneOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
            else {
                fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisOdpracovaneHodiny() : fetchWrapper.NovyServisOdpracovaneHodinyOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
        else {
            LoadingSpinner.open();

            if (this.state.bHromadneZadani) {
                fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisOdpracovaneHodinyHromadne() : fetchWrapper.EditServisOdpracovaneHodinyHromadneOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
            else {
                fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisOdpracovaneHodiny() : fetchWrapper.EditServisOdpracovaneHodinyOffline(), dataObject)
                    .then(data => {
                        LoadingSpinner.close();

                        this.props.zavritDialog(true, null);
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se zpracovat data: {error}</p>,
                            buttons: [
                                { name: "OK", handler: () => "OK" }
                            ]
                        });
                    });
            }
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false, null);
    }

    onFiltrHromadneZadaniChecked() {
        const novyStav = !this.state.bHromadneZadani;

        this.setState({ bHromadneZadani: novyStav });
    }


    render() {

        return (
            <form onSubmit={this.formSubmit}>
                <h1>Odpracované hodiny - {this.state.bNovy ? "nový záznam" : "upravit záznam"}</h1>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}>Vybrané zařízení: </div>
                    <div className="righttext">
                        {this.state.bHromadneZadani ? "Všechna zařízení" : this.props.zarizeni.vent_popis}
                    </div>
                </div>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}>Servisní technik: </div>
                    <div className="right">
                        {this.state.dataTechniciCiselnik && <Combobox klicID="sete_prac_id" klicPopis="prijmeniJmeno" ID={this.state.soh_technik_id} name="soh_technik_id" bEmptyOption={false} data={this.state.dataTechniciCiselnik} onChange={this.onParamaterChange} />}
                    </div>
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.soh_poznamka} widthLeft={140} typ="TEXTAREA" onChange={e => this.setState({ soh_poznamka: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Množství [hod]:" hodnota={this.state.soh_mnozstvi_hodiny} widthLeft={140} typ="CISLO" onChange={e => this.setState({ soh_mnozstvi_hodiny: e.target.value })} />
                    <EditorHodnotyForm popis="Množství [min]:" hodnota={this.state.soh_mnozstvi_minuty} widthLeft={140} typ="CISLOD" onChange={e => this.setState({ soh_mnozstvi_minuty: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 140 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                        <FormControlLabel style={{ display: 'inline-flex', marginLeft: 10 }}
                            name="filtrHromadneZadani"
                            control={<Checkbox
                                checked={this.state.bHromadneZadani}
                                onClick={this.onFiltrHromadneZadaniChecked} />}
                            label="Hromadný zápis" />
                    </div>
                </div>
            </form>
        );
    }
}