import React from 'react';
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";

import { v4 as uuidv4 } from 'uuid';

import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { Global } from "./Global"
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

const emptyItemVybCiselnik = { id: Global.GuidEmpty, prijmeniJmeno: "-", osCislo: "" };

export class ServisVyjezdTechniciForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sete_id: Global.GuidEmpty,
            sete_prac_id: Global.GuidEmpty,
            prijmeniJmeno: "-",
            osCislo: "",
            sete_b_vedouci: false,
            sete_offlineStav: 0,

            dataTechniciCiselnik: null
        }

        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);
    }

    componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();
    }

    inicializace() {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratZamestnanceServisniTechniky() : fetchWrapper.VratZamestnanceServisniTechnikyOffline())
            .then(data => {

                const dataTechniciCiselnikNew = [emptyItemVybCiselnik];
                dataTechniciCiselnikNew.push(...data);

                this.setState({ dataTechniciCiselnik: dataTechniciCiselnikNew })
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník servisních techniků: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    formSubmit(event) {
        event.preventDefault();

        let chyba = "";
        if (!Global.JeGuidValid(this.state.sete_prac_id)) chyba += "Není vybrán servisní technik.";

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }


        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const dataObject = {
            sete_id: !jeOfflineRezim ? this.state.sete_id : uuidv4(),
            sete_b_vedouci: this.state.sete_b_vedouci,
            sete_prac_id: this.state.sete_prac_id,
            osCislo: this.state.osCislo,
            prijmeniJmeno: this.state.prijmeniJmeno,
            sevy_id: this.props.sevyData.sevy_id,
            sete_offlineStav: this.props.sete_offlineStav
        }

        LoadingSpinner.open();

        fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisniTechnici() : fetchWrapper.NovyServisniTechniciOffline(), dataObject)
            .then(data => {
                LoadingSpinner.close();

                this.props.zavritDialog(true);
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }


    render() {

        return (
            <form onSubmit={this.formSubmit}>
                <h1>Servisní technici - nový záznam</h1>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Servisní technik:" typ="COMBOBOX" klicID="id" klicPopis="prijmeniJmeno" hodnota={this.state.sete_prac_id} name="sete_prac_id" dataCiselnik={this.state.dataTechniciCiselnik} widthLeft={220} onChange={e => this.setState({ sete_prac_id: e.target.value, prijmeniJmeno: e.objectSelected.prijmeniJmeno, osCislo: e.objectSelected.osCislo })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Vedoucí servisní technik:" hodnota={this.state.sete_b_vedouci} typ="CHECKBOX" widthLeft={210} onChange={e => this.setState({ sete_b_vedouci: e.target.checked })} />
                </div>

                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                    </div>
                </div>
            </form>
        );
    }
}