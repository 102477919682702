import React from 'react';
import AuthService from "../AuthService";
import { fetchWrapper } from "../FetchWrapper";
import styles from './Header.module.css'

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import CacheData from '../CacheData';



export class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rezimUdrzbaStav: "ZADNA",
            rezimUdrzbaDatumCas: null
        };

        this.udrzbaInterval = null;

        this.logout = this.logout.bind(this);
        this.testUdrzba = this.testUdrzba.bind(this);
        this.updateVerze = this.updateVerze.bind(this);
    }


    logout(e) {
        e.preventDefault();
        this.props.logout();
    }

    componentDidMount() {
        this.testUdrzba();

        this.udrzbaInterval = setInterval(() => {
            this.testUdrzba();
        }, 5000);
    }

    updateVerze = async (e, cell) => {
        const result = await MessageBox.open({
            title: "Zpráva",
            content: <p>Byla detekována nová verze klientské aplikace. Aplikace bude aktualizována. </p>,
            buttons: [
                { name: "OK", handler: () => "OK" }
            ]
        });
        
        this.props.logout();
        window.location.reload(true);
    }

    testUdrzba() {
        fetchWrapper.get(fetchWrapper.VratVlastnosti())
            .then(vlastnosti => {
                if (vlastnosti.verze_klient != window.KLIENT_API_VERZE) {
                    console.log("NOVÁ VERZE DETEKOVÁNA " + vlastnosti.verze_klient);
                    this.updateVerze();
                }
            })
            .catch(error => {
                console.log("ERROR - Nepodařilo se ověřit klientskou aplikaci: " + error);
                /*MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });*/
            });


        fetchWrapper.getBezCache("/udrzba.json")
            .then(dataUdrzba => {
                if (dataUdrzba.nastavena) {
                    const rezimUdrzbaDatumCas = new Date(dataUdrzba.termin);

                    const datumCasNow = new Date();

                    var diff = rezimUdrzbaDatumCas - datumCasNow;
                    var minutes = Math.floor((diff / 1000) / 60);

                    if (minutes > 60) this.setState({ rezimUdrzbaDatumCas: rezimUdrzbaDatumCas, rezimUdrzbaStav: "PLAN" });
                    else if (minutes > 2) this.setState({ rezimUdrzbaDatumCas: rezimUdrzbaDatumCas, rezimUdrzbaStav: "ZACNE" });
                    else {
                        const user = AuthService.getCurrentUser();
                        if (user) AuthService.logout();

                        MessageBox.open({
                            title: "Varování",
                            content: <p><img src="./img/maintenance-64.png" /><p className={styles.udrzbaBezi}>Pozor! Probíhá údržba. Aplikace je dočasně nedostupná.</p></p>,
                            buttons: [

                            ],
                            bSkrytCloseBtn: true
                        });
                        this.setState({ rezimUdrzbaDatumCas: rezimUdrzbaDatumCas, rezimUdrzbaStav: "PROBIHA" });
                    }

                }
                else {
                    const predchoziStav = this.state.rezimUdrzbaStav;

                    this.setState({ rezimUdrzbaStav: "ZADNA" });

                    if (predchoziStav == "PROBIHA") {
                        window.location.reload(true);                        
                    }
                }

            })
            .catch(error => {
                console.log("Nepodařilo se načíst soubor s informacemi o údržbách: " + error);
                /*MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });*/
            }); 

    }

    componentWillUnmount() {
        if (this.udrzbaInterval) clearInterval(this.udrzbaInterval);
        this.udrzbaInterval = null;
    }

    render() {
        const user = AuthService.getCurrentUser();
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        return (
            <div className="grid_12 header-repeat" >
                <div className="branding">
                    <div className="floatleft">
                        <img src={"/img/logotcf.png"} alt="Logo" className="header-logo" width="160" height="40" />
                        <div className="header-text">{this.props.title}</div>
                    </div>
                    {user && (
                        <div className="floatright">
                            <div className="floatright marginleft10">
                                <ul className="inline-ul floatleft">
                                    <li>{user.uziv_jmeno}</li>
                                    {!jeOfflineRezim && <li><a href="#" onClick={this.logout}>Odhlásit</a></li>}
                                </ul>
                                <br />
                                <span className="small grey"></span>
                            </div>
                            <div className="floatright marginleft15"><img src="/img/img-profile.jpg" alt="Profile Pic" width="20" height="20" /></div>
                            <div className="floatright">
                                {!jeOfflineRezim && <div className={styles.onlinerezim}>ONLINE</div>}
                                {jeOfflineRezim && <div className={styles.offlinerezim}>OFFLINE</div>}
                            </div>
                            <div className="clear" />

                            <div className="floatright marginleft10">
                                {this.state.rezimUdrzbaStav == "PLAN" && <div className={styles.udrzbainfo}>Je naplánovaná údržba aplikace na {Global.toDateTimeStr(this.state.rezimUdrzbaDatumCas)}.</div>}
                                {this.state.rezimUdrzbaStav == "ZACNE" && <div className={styles.udrzbavarovani}>Údržba naplánovaná na {Global.toDateTimeStr(this.state.rezimUdrzbaDatumCas)} zanedlouho začne. Neuložená práce bude ztracena.</div>}
                                {this.state.rezimUdrzbaStav == "PROBIHA" && <div className={styles.udrzbavarovani}>Probíhá údržba!!!</div>}
                            </div>
                        </div>
                    )}
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}