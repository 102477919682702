import React from 'react';
import { ServisVyjezdDetail } from './ServisVyjezdDetail';
import { ServisVyjezdList } from "./ServisVyjezdList";

import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService"
import CacheData from "../CacheData"

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";


export class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dataServVyjezdy: [], detailServisVyjezd: null };

        this.zavritDetailEvent = this.zavritDetailEvent.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.vyberServisnihoVyjezdu = this.vyberServisnihoVyjezdu.bind(this);
    }

    componentDidMount() {
        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        let sevy_idVychozi = -1;
        const detailServisVyjezdVychozi = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        if (detailServisVyjezdVychozi) sevy_idVychozi = detailServisVyjezdVychozi.sevy_id;

        if (this.state.detailServisVyjezd) {
            if (sevy_idVychozi == this.state.detailServisVyjezd.sevy_id) return;

            this.vyberServisnihoVyjezdu(this.state.detailServisVyjezd);
        }
    }

    zavritDetailEvent() {
        this.setState({ detailServisVyjezd: null });
    }

    vyberServisnihoVyjezdu(drSevy) {
        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratServisniVyjezd(drSevy.sevy_id))
            .then(data => {
                LoadingSpinner.close(); this.setState({ detailServisVyjezd: data }) })
            .catch(error => {
                LoadingSpinner.close();
                this.setState({ detailServisVyjezd: null });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            }); 
    }

    render() {
        let sevy_idVychozi = -1;
        const detailServisVyjezdVychozi = CacheData.GetData(AuthService.getCurrentUser(), "servisvyjezd");
        if (detailServisVyjezdVychozi) sevy_idVychozi = detailServisVyjezdVychozi.sevy_id;

        return (
            <>
                <div className="grid_12 odsazeni_bottom50" style={{ display: this.state.detailServisVyjezd ? 'none' : 'block' }}>
                    <ServisVyjezdList vyberDetail={this.vyberServisnihoVyjezdu} />
                </div>

                {this.state.detailServisVyjezd && <ServisVyjezdDetail navigate={this.props.navigate} sevyData = { this.state.detailServisVyjezd } zavritDetailEvent={this.zavritDetailEvent} bDetailVychozi={sevy_idVychozi == this.state.detailServisVyjezd.sevy_id} bTlacitkoZpet={true} />}
            </>
        );
    }
}



