import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import { reactFormatter } from 'react-tabulator/lib/Utils.js?version=4.0';
import styles from './ServisVyjezdDetail.module.css'


import { DialogActions, Button, TextField } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ServisVyjezdTechniciList } from "./ServisVyjezdTechniciList";
import { ServisVyjezdDetailZarizeni } from "./ServisVyjezdDetailZarizeni";
import { ServisMistoForm } from "./ServisMistoForm";
import { ServisVyjezdTechniciForm } from "./ServisVyjezdTechniciForm";
import { OnlineRezimPrechodForm } from "./OnlineRezimPrechodForm";
import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";
import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { OdkazTechnList } from "./ServisVyjezdDetailZarizeni";
import AuthService from "../AuthService";
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";


import { SMapy } from "./SMapy/SMapy"

import { Global } from "./Global";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";




const tabServZarizeni = [
    {
        field: 'seve_zarizeni.vent_typ', title: 'Typ zařízení', width: 200, headerSort: true, formatter: function (cell, formatterParams, onRendered) {
            if (cell.getValue() == 0) return "Ventilátor";
            else return "Jiné";
        }
    },
    { field: 'seve_zarizeni.vent_vyrobni_cislo', title: 'Výr.číslo', width: 100, sorter: "number", headerSort: true },
    { field: 'seve_zarizeni.vent_popis', title: 'Typové označení', width: 400, headerSort: true },
    { field: 'seve_zarizeni.vent_popis_zarizeni', title: 'Popis zařízení', width: 400, headerSort: true },
    { field: 'seve_popis', title: 'Poznámka', width: 400, headerSort: true }
];


class DetailRadek extends React.Component {
    constructor(props) {
        super(props);

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    onCheckboxChange(event) {
        this.props.onCheckboxChange(!this.props.datetimeChecked);
    }



    render() {
        const MyActionBar = ({
            onAccept,
            onCancel,
            onClear,
            onSetToday,
        }) => {

            return (
                <DialogActions>
                    <Button onClick={onClear}> Reset </Button>
                    <Button onClick={onCancel}> Storno </Button>
                    <Button onClick={onAccept}> Potvrdit </Button>
                </DialogActions>
            );
        };

        if (this.props.typ === "DATETIME") {
            if (this.props.editable) {
                return (
                    <div className={styles.detail_udaj_container}>
                        <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                        <div className={styles.rightdatetime}>
                            <DesktopDateTimePicker renderInput={(params) => <TextField {...params} />}
                                inputFormat="dd.MM.yyyy HH:mm"
                                value={this.props.hodnota}
                                onChange={this.props.onChange}
                                ampm={false}
                                inputProps={{
                                    style: {
                                        padding: 5
                                    }
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: ["today"]

                                    }
                                }}
                            />
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className={styles.detail_udaj_container}>
                        <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                        <div className={styles.right}>{this.props.hodnota.getFullYear() > 1753 ? Global.toDateTimeStr(this.props.hodnota) : ""}</div>
                    </div>
                );
            }
        }
        else if (this.props.typ === "DATETIMECH") {
            if (this.props.editable) {
                return (
                    <div className={styles.detail_udaj_container}>
                        <div className={styles.left} style={{ width: this.props.widthLeft }}> {this.props.popis} <input type="checkbox" onChange={this.onCheckboxChange} defaultChecked={this.props.datetimeChecked} /></div>
                        <div className={styles.rightdatetime}>
                            {this.props.datetimeChecked && <DesktopDateTimePicker
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="dd.MM.yyyy HH:mm"
                                ampm={false}
                                value={this.props.hodnota}
                                onChange={this.props.onChange}
                                inputProps={{
                                    style: {
                                        padding: 5
                                    }
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: ["today"]
                                    }
                                }}
                            />}
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className={styles.detail_udaj_container}>
                        <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                        <div className={styles.right}>{this.props.hodnota.getFullYear() > 1753 ? Global.toDateTimeStr(this.props.hodnota) : ""}</div>
                    </div>
                );
            }
        }



        return (
            <div className={styles.detail_udaj_container}>
                <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                <div className={styles.right}>{this.props.hodnota}</div>
            </div>
        );
    }
}














export class ServisVyjezdDetail extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.navigate);
        const sevy_datum_prejezd_hranic_zpet = new Date(props.sevyData.sevy_datum_prejezd_hranic_zpet);
        const sevy_datum_prejezd_hranic_tam = new Date(props.sevyData.sevy_datum_prejezd_hranic_tam);

        this.state = {
            zobrazenyPanel: "ZARIZENI",
            zobrazitDetailZarizeni: false,
            bEditData: false,
            editDataZaznamEdit: null,
            editDataTyp: null,
            vybraneZarizeniID: null,

            sevy_datum_odjezd_prach: new Date(props.sevyData.sevy_datum_odjezd_prach),
            sevy_datum_prijezd_servis: new Date(props.sevyData.sevy_datum_prijezd_servis),
            sevy_datum_zahajeni_praci: new Date(props.sevyData.sevy_datum_zahajeni_praci),
            sevy_datum_ukonceni_praci: new Date(props.sevyData.sevy_datum_ukonceni_praci),
            sevy_datum_odjezd_servis: new Date(props.sevyData.sevy_datum_odjezd_servis),
            sevy_datum_prijezd_prach: new Date(props.sevyData.sevy_datum_prijezd_prach),
            sevy_datum_prejezd_hranic_tam: sevy_datum_prejezd_hranic_tam,
            sevy_datum_prejezd_hranic_zpet: sevy_datum_prejezd_hranic_zpet,
            sevy_datum_prejezd_hranic_tam_checked: sevy_datum_prejezd_hranic_tam.getFullYear() > 1753,
            sevy_datum_prejezd_hranic_zpet_checked: sevy_datum_prejezd_hranic_zpet.getFullYear() > 1753,
            sevy_interni_poznamka: props.sevyData.sevy_interni_poznamka,
            servisTechnici: props.sevyData.servisTechnici,

            zmenaMistaServisu: false,
            zmenaMistaZahajeni: false,
            zmenaMistaUkonceni: false,

            mistoServisuAdresa: (props.sevyData.semi_misto.semi_ulice + ", " + props.sevyData.semi_misto.semi_psc + ", " + props.sevyData.semi_misto.semi_misto)
        };

        this.nastavitVyjezdJakoAktivni = this.nastavitVyjezdJakoAktivni.bind(this);
        this.prejitDoOfflineRezimu = this.prejitDoOfflineRezimu.bind(this);
        this.zavritDetailEvent = this.zavritDetailEvent.bind(this);
        this.zobrazitPanel = this.zobrazitPanel.bind(this);
        this.zarizeniVyber = this.zarizeniVyber.bind(this);
        this.zavritDetailZarizeniEvent = this.zavritDetailZarizeniEvent.bind(this);
        this.zrusitVyjezdJakoAktivni = this.zrusitVyjezdJakoAktivni.bind(this);
        this.prejitDoOnlineRezimu = this.prejitDoOnlineRezimu.bind(this);
        this.upravitServisVyjezd = this.upravitServisVyjezd.bind(this);
        this.editData = this.editData.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
        this.zavritDialogZaznam = this.zavritDialogZaznam.bind(this);
        this.prejitNaDetailZarizeni = this.prejitNaDetailZarizeni.bind(this);
        this.otevritDialogZaznam = this.otevritDialogZaznam.bind(this);
        this.zmenitMistoServisu = this.zmenitMistoServisu.bind(this);
        this.zmenitMistoZahajeni = this.zmenitMistoZahajeni.bind(this);
        this.zmenitMistoUkonceni = this.zmenitMistoUkonceni.bind(this);
        this.zavritDialogMistoServis = this.zavritDialogMistoServis.bind(this);
        this.zavritDialogMistoZahajeni = this.zavritDialogMistoZahajeni.bind(this);
        this.zavritDialogMistoUkonceni = this.zavritDialogMistoUkonceni.bind(this);
        this.navigovatNaMapyCz = this.navigovatNaMapyCz.bind(this);
        this.zpracovatDataAdresyZMapy = this.zpracovatDataAdresyZMapy.bind(this);
    }

    componentDidMount() {
        window.addEventListener('aktivniServisVyjezdNaHlavniStranku', this.zavritDetailZarizeniEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('aktivniServisVyjezdNaHlavniStranku', this.zavritDetailZarizeniEvent)
    }

    componentDidUpdate(prevProps) {
        if (this.props.sevyData.sevy_datum_odjezd_prach !== prevProps.sevyData.sevy_datum_odjezd_prach
            || this.props.sevyData.sevy_datum_prijezd_servis !== prevProps.sevyData.sevy_datum_prijezd_servis
            || this.props.sevyData.sevy_datum_zahajeni_praci !== prevProps.sevyData.sevy_datum_zahajeni_praci
            || this.props.sevyData.sevy_datum_ukonceni_praci !== prevProps.sevyData.sevy_datum_ukonceni_praci
            || this.props.sevyData.sevy_datum_odjezd_servis !== prevProps.sevyData.sevy_datum_odjezd_servis
            || this.props.sevyData.sevy_datum_prijezd_prach !== prevProps.sevyData.sevy_datum_prijezd_prach
            || this.props.sevyData.sevy_datum_prejezd_hranic_tam !== prevProps.sevyData.sevy_datum_prejezd_hranic_tam
            || this.props.sevyData.sevy_datum_prejezd_hranic_zpet !== prevProps.sevyData.sevy_datum_prejezd_hranic_zpet
            || this.props.sevyData.sevy_interni_poznamka !== prevProps.sevyData.sevy_interni_poznamka) {

            const sevy_datum_prejezd_hranic_zpet = new Date(this.props.sevyData.sevy_datum_prejezd_hranic_zpet);
            const sevy_datum_prejezd_hranic_tam = new Date(this.props.sevyData.sevy_datum_prejezd_hranic_tam);

            this.setState({
                sevy_datum_odjezd_prach: new Date(this.props.sevyData.sevy_datum_odjezd_prach),
                sevy_datum_prijezd_servis: new Date(this.props.sevyData.sevy_datum_prijezd_servis),
                sevy_datum_zahajeni_praci: new Date(this.props.sevyData.sevy_datum_zahajeni_praci),
                sevy_datum_ukonceni_praci: new Date(this.props.sevyData.sevy_datum_ukonceni_praci),
                sevy_datum_odjezd_servis: new Date(this.props.sevyData.sevy_datum_odjezd_servis),
                sevy_datum_prijezd_prach: new Date(this.props.sevyData.sevy_datum_prijezd_prach),
                sevy_datum_prejezd_hranic_tam: sevy_datum_prejezd_hranic_tam,
                sevy_datum_prejezd_hranic_zpet: sevy_datum_prejezd_hranic_zpet,
                sevy_datum_prejezd_hranic_tam_checked: sevy_datum_prejezd_hranic_tam.getFullYear() > 1753,
                sevy_datum_prejezd_hranic_zpet_checked: sevy_datum_prejezd_hranic_zpet.getFullYear() > 1753,
                sevy_interni_poznamka: this.props.sevyData.sevy_interni_poznamka,
                servisTechnici: this.props.sevyData.servisTechnici,

                mistoServisuAdresa: (this.props.sevyData.semi_misto.semi_ulice + ", " + this.props.sevyData.semi_misto.semi_psc + ", " + this.props.sevyData.semi_misto.semi_misto)
            });
        }
    }

    nastavitVyjezdJakoAktivni(event) {
        event.preventDefault();

        CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);
        window.dispatchEvent(new Event('sevyStorageUpdate'));
        this.props.navigate("/servisvyjezd");
    }

    zrusitVyjezdJakoAktivni(event) {
        event.preventDefault();

        CacheData.DeleteData(AuthService.getCurrentUser(), "servisvyjezd");
        window.dispatchEvent(new Event('sevyStorageUpdate'));
        this.props.navigate("/");
    }

    prejitDoOfflineRezimu(event) {
        event.preventDefault();

        let upozorneni = "";

        if (!this.props.sevyData.semi_misto || !Global.JeGuidValid(this.props.sevyData.semi_misto.semi_id)) {
            upozorneni += "Nelze přejít do offline režimu. Není vybráno místo servisu.";
        }

        if (upozorneni != "") {
            MessageBox.open({
                title: "Upozornění",
                content: <p> {upozorneni}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        LoadingSpinner.open();

        fetchWrapper.put(fetchWrapper.EditServisniVyjezdZamek(this.props.sevyData.sevy_id, true))
            .then(data1 => {

                Promise.all([
                    fetchWrapper.get(fetchWrapper.VratZamestnanceServisniTechniky()),
                    fetchWrapper.get(fetchWrapper.VratZarizeniCiselnikJednoduchy(this.props.sevyData.semi_misto.semi_id)),
                    fetchWrapper.get(fetchWrapper.VratZarizeniCiselnikKomplexni(this.props.sevyData.semi_misto.semi_id)),
                    fetchWrapper.get(fetchWrapper.VratServisPraceCiselnik()),
                    fetchWrapper.get(fetchWrapper.VratServisMaterialKatalog()),
                    fetchWrapper.get(fetchWrapper.VratServisPotrebneVybaveniCiselnik()),
                    fetchWrapper.get(fetchWrapper.VratServisVyjezdTechnListy(this.props.sevyData.sevy_id))])
                    .then(data2 => {
                        LoadingSpinner.close();

                        CacheData.SetData(null, "offlinerezim", 1);

                        CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);
                        window.dispatchEvent(new Event('sevyStorageUpdate'));

                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZamestnanciTechniciData", data2[0]);
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikJednoduchyData", data2[1]);
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisZarizeniCiselnikKomplexniData", data2[2]);
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisPraceCiselnikData", data2[3]);
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisMaterialKatalogData", data2[4]);
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisPotrebneVybaveniCiselnikData", data2[5]);
                        CacheData.IndexedDBSetData("OfflineData", AuthService.getCurrentUser(), "tcfServisVyjezdFoto", []);
                        CacheData.IndexedDBSetData("OfflineDataTechnListy", AuthService.getCurrentUser(), "tcfServisVyjezdTechnickeListy", data2[6]);

                        this.props.navigate("/servisvyjezd");
                    })
                    .catch(error => {
                        LoadingSpinner.close();

                        MessageBox.open({
                            title: "Chyba",
                            content: <p>Nepodařilo se přejít do offline režimu: {error}</p>,
                            buttons: [{ name: "OK", handler: () => "OK" }]
                        });
                    });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [{ name: "OK", handler: () => "OK" }]
                });
            });
    }

    prejitDoOnlineRezimu(event) {
        event.preventDefault();

        this.otevritDialogZaznam("ONLINEPRECHOD", null);
    }

    zavritDetailEvent(event) {
        event.preventDefault();
        this.props.zavritDetailEvent();
    }

    zobrazitPanel(event) {
        event.preventDefault();

        this.setState({ zobrazenyPanel: event.target.value });
    }

    zarizeniVyber = (e, row) => {

        this.setState({ zobrazitDetailZarizeni: true, vybraneZarizeniID: row.getData().seve_zarizeni.vent_id });
        window.scrollTo(0, 0);
    }

    prejitNaDetailZarizeni() {
        let vent_id = "";
        if (this.props.sevyData.servisZarizeni.length > 0) vent_id = this.props.sevyData.servisZarizeni[0].seve_zarizeni.vent_id;

        this.setState({ zobrazitDetailZarizeni: true, vybraneZarizeniID: vent_id });
        window.scrollTo(0, 0);
    }

    zavritDetailZarizeniEvent() {
        this.setState({ zobrazitDetailZarizeni: false, vybraneZarizeniID: null })
    }

    otevritDialogZaznam(typZaznamu, zaznamEdit) {
        this.setState({ bEditData: true, editDataZaznamEdit: zaznamEdit, editDataTyp: typZaznamu });
    }

    zavritDialogZaznam(bZmena) {
        if (bZmena) this.reloadEditovanaData(this.state.editDataTyp);

        this.setState({ bEditData: false, editDataTyp: "", editDataZaznamEdit: null });
    }

    reloadEditovanaData(typZaznamu) {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        LoadingSpinner.open();

        if (typZaznamu == "ONLINEPRECHOD") {
            fetchWrapper.get(fetchWrapper.VratServisniVyjezd(this.props.sevyData.sevy_id))
                .then(dataVyjezd => {
                    LoadingSpinner.close();

                    CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", dataVyjezd);
                    window.dispatchEvent(new Event('sevyStorageUpdate'));

                    window.dispatchEvent(new Event('reloadData'));

                    this.props.navigate("/servisvyjezd");
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (typZaznamu == "TECHNICI") {
            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisniTechnici(this.props.sevyData.sevy_id) : fetchWrapper.VratServisniTechniciOffline())
                .then(data => {
                    LoadingSpinner.close();

                    this.props.sevyData.servisTechnici = data.filter(t => t.sete_offlineStav != 3);
                    this.props.sevyData.servisTechniciDeleted = data.filter(t => t.sete_offlineStav == 3);
                    let servisTechniciVyjezd = this.props.sevyData.servisTechnici;
                    this.setState({ servisTechnici: servisTechniciVyjezd });

                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else { console.log(typZaznamu); }
    }

    ulozitServisVyjezdDoPameti() {
        CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);
    }

    smazatZaznam(typZaznamu, zaznamDel) {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;
        const offlineRequest = jeOfflineRezim ? { sete_id: zaznamDel.sete_id } : null;

        let URLDelete = "";
        if (typZaznamu == "TECHNICI")
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisniTechnici(zaznamDel.sete_id) : fetchWrapper.VymazServisniTechniciOffline();

        LoadingSpinner.open();

        fetchWrapper.del(URLDelete, offlineRequest)
            .then(data => {
                LoadingSpinner.close();

                this.reloadEditovanaData(typZaznamu);
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data:<br /> {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    upravitServisVyjezd() {
        LoadingSpinner.open();

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        let upozorneni = "";
        const sevyDataEdit = structuredClone(this.props.sevyData);

        if (this.state.sevy_datum_prejezd_hranic_tam_checked
            && this.state.sevy_datum_prejezd_hranic_tam < this.state.sevy_datum_odjezd_prach) {
            upozorneni += "Přejezd hranic - tam nemůže proběhnout dříve než [Místo zahájení] - Odjezd.";
        }

        if (this.state.sevy_datum_prejezd_hranic_tam_checked
            && this.state.sevy_datum_prijezd_servis < this.state.sevy_datum_prejezd_hranic_tam) {
            upozorneni += "Servis - Příjezd nemůže proběhnout dříve než Přejezd hranic - tam.";
        }

        if (this.state.sevy_datum_prijezd_servis < this.state.sevy_datum_odjezd_prach) {
            upozorneni += "Servis - Příjezd nemůže proběhnout dříve než [Místo zahájení] - Odjezd.";
        }

        if (this.state.sevy_datum_zahajeni_praci < this.state.sevy_datum_prijezd_servis) {
            upozorneni += "Zahájení prací nemůže proběhnout dříve než Servis - Příjezd.";
        }

        if (this.state.sevy_datum_ukonceni_praci < this.state.sevy_datum_zahajeni_praci) {
            upozorneni += "Ukončení prací nemůže proběhnout dříve než Zahájení prací.";
        }

        if (this.state.sevy_datum_odjezd_servis < this.state.sevy_datum_ukonceni_praci) {
            upozorneni += "Servis - Odjezd nemůže proběhnout dříve než Ukončení prací.";
        }

        if (this.state.sevy_datum_prejezd_hranic_zpet_checked
            && this.state.sevy_datum_prejezd_hranic_zpet < this.state.sevy_datum_odjezd_servis) {
            upozorneni += "Přejezd hranic - zpět nemůže proběhnout dříve než Servis - Odjezd.";
        }

        if (this.state.sevy_datum_prejezd_hranic_zpet_checked
            && this.state.sevy_datum_prijezd_prach < this.state.sevy_datum_prejezd_hranic_zpet) {
            upozorneni += "[Místo ukončení] - Příjezd nemůže proběhnout dříve než Přejezd hranic - zpět.";
        }

        if (this.state.sevy_datum_prijezd_prach < this.state.sevy_datum_odjezd_servis) {
            upozorneni += "[Místo ukončení] - Příjezd nemůže proběhnout dříve než Servis - Odjezd.";
        }

        if (upozorneni != "") {
            MessageBox.open({
                title: "Upozornění",
                content: <p> {upozorneni}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            LoadingSpinner.close();
            return;
        }

        sevyDataEdit.sevy_datum_odjezd_prach = this.state.sevy_datum_odjezd_prach.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.sevy_datum_odjezd_prach);
        sevyDataEdit.sevy_datum_prijezd_servis = this.state.sevy_datum_prijezd_servis.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.sevy_datum_prijezd_servis);

        sevyDataEdit.sevy_datum_zahajeni_praci = this.state.sevy_datum_zahajeni_praci.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.sevy_datum_zahajeni_praci);
        sevyDataEdit.sevy_datum_ukonceni_praci = this.state.sevy_datum_ukonceni_praci.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.sevy_datum_ukonceni_praci);

        sevyDataEdit.sevy_datum_odjezd_servis = this.state.sevy_datum_odjezd_servis.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.sevy_datum_odjezd_servis);
        sevyDataEdit.sevy_datum_prijezd_prach = this.state.sevy_datum_prijezd_prach.getFullYear() <= 1753 ? "1753-01-01T00:00:00" : Global.toISOString(this.state.sevy_datum_prijezd_prach);

        sevyDataEdit.sevy_datum_prejezd_hranic_tam = this.state.sevy_datum_prejezd_hranic_tam_checked && this.state.sevy_datum_prejezd_hranic_tam.getFullYear() > 1753 ? Global.toISOString(this.state.sevy_datum_prejezd_hranic_tam) : "1753-01-01T00:00:00";
        sevyDataEdit.sevy_datum_prejezd_hranic_zpet = this.state.sevy_datum_prejezd_hranic_zpet_checked && this.state.sevy_datum_prejezd_hranic_zpet.getFullYear() > 1753 ? Global.toISOString(this.state.sevy_datum_prejezd_hranic_zpet) : "1753-01-01T00:00:00";

        sevyDataEdit.sevy_interni_poznamka = this.state.sevy_interni_poznamka;

        sevyDataEdit.servisPrace = [];
        sevyDataEdit.servisPraceDeleted = [];
        sevyDataEdit.servisMaterial = [];
        sevyDataEdit.servisMaterialDeleted = [];
        sevyDataEdit.servisPotrebneVybaveni = [];
        sevyDataEdit.servisPotrebneVybaveniDeleted = [];
        sevyDataEdit.servisZarizeni = [];
        sevyDataEdit.servisZarizeniDeleted = [];
        sevyDataEdit.servisTechnici = [];
        sevyDataEdit.servisTechniciDeleted = [];
        sevyDataEdit.servisOdpracovaneHodiny = [];
        sevyDataEdit.servisOdpracovaneHodinyDeleted = [];

        fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditServisniVyjezd() : fetchWrapper.EditServisniVyjezdOffline(), sevyDataEdit)
            .then(data => {
                LoadingSpinner.close();

                this.props.sevyData.sevy_datum_odjezd_prach = Global.dateToStr(this.state.sevy_datum_odjezd_prach);
                this.props.sevyData.sevy_datum_prijezd_servis = Global.dateToStr(this.state.sevy_datum_prijezd_servis);
                this.props.sevyData.sevy_datum_zahajeni_praci = Global.dateToStr(this.state.sevy_datum_zahajeni_praci);
                this.props.sevyData.sevy_datum_ukonceni_praci = Global.dateToStr(this.state.sevy_datum_ukonceni_praci);
                this.props.sevyData.sevy_datum_odjezd_servis = Global.dateToStr(this.state.sevy_datum_odjezd_servis);
                this.props.sevyData.sevy_datum_prijezd_prach = Global.dateToStr(this.state.sevy_datum_prijezd_prach);
                this.props.sevyData.sevy_datum_prejezd_hranic_tam = this.state.sevy_datum_prejezd_hranic_tam_checked ? Global.dateToStr(this.state.sevy_datum_prejezd_hranic_tam) : "1753-01-01T00:00:00";
                this.props.sevyData.sevy_datum_prejezd_hranic_zpet = this.state.sevy_datum_prejezd_hranic_zpet_checked ? Global.dateToStr(this.state.sevy_datum_prejezd_hranic_zpet) : "1753-01-01T00:00:00";
                this.props.sevyData.sevy_interni_poznamka = this.state.sevy_interni_poznamka;
                if (jeOfflineRezim && this.props.sevyData.sevy_offlineStav == 0) this.props.sevyData.sevy_offlineStav = 2;

                CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Změny uloženy.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se aktualizovat výjezd: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    editData(parametr, data) {
        if (parametr == "sevy_datum_odjezd_prach") {

            this.setState({ sevy_datum_odjezd_prach: data });

            if (this.state.sevy_datum_prijezd_servis.getFullYear() <= 1753) this.setState({ sevy_datum_prijezd_servis: data });
            if (this.state.sevy_datum_zahajeni_praci.getFullYear() <= 1753) this.setState({ sevy_datum_zahajeni_praci: data });
            if (this.state.sevy_datum_ukonceni_praci.getFullYear() <= 1753) this.setState({ sevy_datum_ukonceni_praci: data });
            if (this.state.sevy_datum_odjezd_servis.getFullYear() <= 1753) this.setState({ sevy_datum_odjezd_servis: data });
            if (this.state.sevy_datum_prijezd_prach.getFullYear() <= 1753) this.setState({ sevy_datum_prijezd_prach: data });
            if (this.state.sevy_datum_prejezd_hranic_tam.getFullYear() <= 1753 && this.state.sevy_datum_prejezd_hranic_tam_checked) this.setState({ sevy_datum_prejezd_hranic_tam: data });
            if (this.state.sevy_datum_prejezd_hranic_zpet.getFullYear() <= 1753 && this.state.sevy_datum_prejezd_hranic_zpet_checked) this.setState({ sevy_datum_prejezd_hranic_zpet: data });
        }
        if (parametr == "sevy_datum_prijezd_servis") this.setState({ sevy_datum_prijezd_servis: data });
        if (parametr == "sevy_datum_zahajeni_praci") this.setState({ sevy_datum_zahajeni_praci: data });
        if (parametr == "sevy_datum_ukonceni_praci") this.setState({ sevy_datum_ukonceni_praci: data });

        if (parametr == "sevy_datum_odjezd_servis") this.setState({ sevy_datum_odjezd_servis: data });
        if (parametr == "sevy_datum_prijezd_prach") this.setState({ sevy_datum_prijezd_prach: data });

        if (parametr == "sevy_datum_prejezd_hranic_tam") this.setState({ sevy_datum_prejezd_hranic_tam: data });
        if (parametr == "sevy_datum_prejezd_hranic_zpet") this.setState({ sevy_datum_prejezd_hranic_zpet: data });

        if (parametr == "sevy_interni_poznamka") this.setState({ sevy_interni_poznamka: data });
    }

    zmenitMistoServisu() {
        this.setState({ zmenaMistaServisu: true });
    }

    zavritDialogMistoServis(bZmena) {
        this.setState({ zmenaMistaServisu: false });
        if (bZmena) {
            window.dispatchEvent(new Event('sevyStorageUpdate'));

            const adresa = this.props.sevyData.semi_misto.semi_ulice + ", " + this.props.sevyData.semi_misto.semi_psc + ", " + this.props.sevyData.semi_misto.semi_misto;
            this.setState({ mistoServisuAdresa: adresa });
        }
    }

    zmenitMistoZahajeni() {
        this.setState({ zmenaMistaZahajeni: true });
    }

    zavritDialogMistoZahajeni(bZmena) {
        this.setState({ zmenaMistaZahajeni: false });
        if (bZmena) {
            window.dispatchEvent(new Event('sevyStorageUpdate'));
        }
    }

    zmenitMistoUkonceni() {
        this.setState({ zmenaMistaUkonceni: true });
    }

    zavritDialogMistoUkonceni(bZmena) {
        this.setState({ zmenaMistaUkonceni: false });
        if (bZmena) {
            window.dispatchEvent(new Event('sevyStorageUpdate'));
        }
    }

    navigovatNaMapyCz() {
        const URLMapy = window.MAPYCZURL;

        if (!URLMapy || !URLMapy.includes("zem_sirka") || !URLMapy.includes("zem_delka")) {
            MessageBox.open({
                title: "Upozornění",
                content: <p>Pozor! Nevalidní URL adresa. Zkontrolujte hodnotu klíče 'MAPYCZURL' v konfiguračním souboru aplikace.</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        const souradnice = this.props.sevyData.semi_misto.semi_gps.trim().split(" ");
        let sirka = souradnice.length > 0 ? souradnice[0] : "";
        let delka = souradnice.length > 1 ? souradnice[1] : "";

        if (sirka == "" || delka == "") {
            MessageBox.open({
                title: "Upozornění",
                content: <p>Pozor! Nevalidně zadané GPS souřadnice místa servisu.</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }


        let stupneMinutySekundy = sirka.split(/[°'"]/);
        let stupne = stupneMinutySekundy.length > 0 ? Number(Global.removeNonNumericChars(stupneMinutySekundy[0])) : 0;
        if (isNaN(stupne)) stupne = 0;
        let minuty = stupneMinutySekundy.length > 1 ? Number(Global.removeNonNumericChars(stupneMinutySekundy[1])) : 0;
        if (isNaN(minuty)) minuty = 0;
        let sekundy = stupneMinutySekundy.length > 2 ? Number(Global.removeNonNumericChars(stupneMinutySekundy[2])) : 0;
        if (isNaN(sekundy)) sekundy = 0;
        let strana = stupneMinutySekundy.length > 3 ? stupneMinutySekundy[3] : "N";

        sirka = (stupne + (minuty / 60) + (sekundy / 3600)) + '' + strana;

        stupneMinutySekundy = delka.split(/[°'"]/);
        stupne = stupneMinutySekundy.length > 0 ? Number(Global.removeNonNumericChars(stupneMinutySekundy[0])) : 0;
        if (isNaN(stupne)) stupne = 0;
        minuty = stupneMinutySekundy.length > 1 ? Number(Global.removeNonNumericChars(stupneMinutySekundy[1])) : 0;
        if (isNaN(minuty)) minuty = 0;
        sekundy = stupneMinutySekundy.length > 2 ? Number(Global.removeNonNumericChars(stupneMinutySekundy[2])) : 0;
        if (isNaN(sekundy)) sekundy = 0;
        strana = stupneMinutySekundy.length > 3 ? stupneMinutySekundy[3] : "E";

        delka = (stupne + (minuty / 60) + (sekundy / 3600)) + '' + strana;

        window.open(URLMapy.replace("zem_sirka", sirka).replace("zem_delka", delka), "_blank");
    }

    zpracovatDataAdresyZMapy(adresaObj) { }

    render() {
        console.log(this.props);

        let sevy_stav = "";
        if (this.props.sevyData.sevy_stav == 0) sevy_stav = "Založený";
        else if (this.props.sevyData.sevy_stav == 1) sevy_stav = "Připravený";
        else if (this.props.sevyData.sevy_stav == 2) sevy_stav = "Dokončený";

        const user = AuthService.getCurrentUser();
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const bMoznostNastavitAktualniVyjezd = (user && user.uziv_money_prac_id == this.props.sevyData.sevy_vedouci_technik_id && this.props.sevyData.sevy_stav == 1);

        return (
            <>
                {this.props.sevyData &&
                    <div>
                        {this.state.zmenaMistaServisu && <ServisMistoForm mistoTyp="SERVIS" zavritDialog={this.zavritDialogMistoServis} sevyData={this.props.sevyData} mistoData={this.props.sevyData.semi_misto} />}

                        {this.state.zmenaMistaZahajeni && <ServisMistoForm mistoTyp="ZAHAJENI" zavritDialog={this.zavritDialogMistoZahajeni} sevyData={this.props.sevyData} mistoData={this.props.sevyData.semi_misto_zahajeni} />}

                        {this.state.zmenaMistaUkonceni && <ServisMistoForm mistoTyp="UKONCENI" zavritDialog={this.zavritDialogMistoUkonceni} sevyData={this.props.sevyData} mistoData={this.props.sevyData.semi_misto_ukonceni} />}

                        {this.state.bEditData && this.state.editDataTyp === "TECHNICI" &&
                            <div className="odsazeni_bottom10">
                                <div className="grid_12">
                                    <ServisVyjezdTechniciForm sevyData={this.props.sevyData} zavritDialog={this.zavritDialogZaznam} />
                                </div>
                                <div className="clear" />
                            </div>
                        }

                        {this.state.bEditData && this.state.editDataTyp === "ONLINEPRECHOD" &&
                            <div className="odsazeni_bottom10">
                                <div className="grid_12">
                                    <OnlineRezimPrechodForm sevyData={this.props.sevyData} zavritDialog={this.zavritDialogZaznam} />
                                </div>
                                <div className="clear" />
                            </div>
                        }

                        <div style={{ display: (this.state.zmenaMistaServisu || this.state.zmenaMistaZahajeni || this.state.zmenaMistaUkonceni || this.state.bEditData ? "none" : "block") }} >
                            <div className="grid_12">
                                {!this.state.zobrazitDetailZarizeni &&
                                    <div>
                                        {!this.props.bDetailVychozi && <h1>Servisní výjezd {this.props.bTlacitkoZpet && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.zavritDetailEvent} >Zpět</button>} {bMoznostNastavitAktualniVyjezd && <button className="form_button" onClick={this.nastavitVyjezdJakoAktivni} >Nastavit jako aktuální výjezd</button>}</h1>}
                                        {this.props.bDetailVychozi && <h1>Aktuální servisní výjezd {!jeOfflineRezim && <span>{this.props.bTlacitkoZpet && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.zavritDetailEvent} >Zpět</button>} <button className="form_button" onClick={this.zrusitVyjezdJakoAktivni} >Zrušit jako aktuální výjezd</button> <button className="form_button" onClick={this.prejitDoOfflineRezimu} >Přejít do offline režimu</button></span>}{jeOfflineRezim && <button className="form_button" onClick={this.prejitDoOnlineRezimu} >Přejít do online režimu</button>}</h1>}
                                    </div>
                                }
                            </div>
                            <div className="clear" />

                            {!this.state.zobrazitDetailZarizeni &&
                                <ThemeProvider theme={MuiTheme}>
                                    <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                                        <div className="odsazeni_bottom10">

                                            <div className="grid_5">
                                                <div className="box round" style={{ minHeight: 560 }}>
                                                    <h2>Základní údaje</h2>
                                                    <div className="grid_5">
                                                        <DetailRadek popis="Stav:" hodnota={sevy_stav} widthLeft={100} />
                                                    </div>
                                                    <div className="grid_7">
                                                        <DetailRadek popis="Datum výjezdu:" hodnota={Global.toDateStr(new Date(this.props.sevyData.sevy_datum))} widthLeft={130} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Servisní zakázka:" hodnota={this.props.sevyData.poza_cislo} widthLeft={100} />
                                                    </div>
                                                    <div className="clear" />


                                                    <br />
                                                    <div className="grid_12">
                                                        <DetailRadek popis="Zákazník:" widthLeft={100} hodnota={this.props.sevyData.zaka_firma_Nazev} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Kontaktní osoba:" widthLeft={100} hodnota={this.props.sevyData.sevy_kontaktni_osoba} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Telefon:" widthLeft={100} hodnota={this.props.sevyData.sevy_telefon_kontakt_os} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Email:" widthLeft={100} hodnota={this.props.sevyData.sevy_email_kontakt_os} />
                                                    </div>
                                                    <div className="clear" />

                                                    <br />
                                                    <div className="grid_12">
                                                        <DetailRadek popis="Vedoucí technik:" widthLeft={100} hodnota={this.props.sevyData.sevy_vedouci_technik_PrijmeniJmeno} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Místo zahájení:" widthLeft={100} hodnota={this.props.sevyData.semi_misto_zahajeni.semi_misto} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Místo ukončení:" widthLeft={100} hodnota={this.props.sevyData.semi_misto_ukonceni.semi_misto} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Způsob dopravy:" widthLeft={100} hodnota={this.props.sevyData.sevy_zpusob_dopravy_Zpusob} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Navazující způsob dopravy:" widthLeft={100} hodnota={this.props.sevyData.sevy_navazujici_zpusob_dopravy_Zpusob} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Vozidlo - SPZ:" widthLeft={100} hodnota={this.props.sevyData.sevy_vozidlo_SPZ} />
                                                    </div>
                                                    <div className="clear" />

                                                    <br />
                                                    <div className="grid_12">
                                                        <DetailRadek popis="Místo servisu:" hodnota={this.props.sevyData.semi_misto.semi_adresa} widthLeft={100} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="Vzdálenost [km]:" widthLeft={100} hodnota={this.props.sevyData.semi_misto.semi_vzdalenost_km} />
                                                    </div>
                                                    <div className="clear" />

                                                    <div className="grid_12">
                                                        <DetailRadek popis="GPS:" widthLeft={100} hodnota={this.props.sevyData.semi_misto.semi_gps} />
                                                    </div>
                                                    <div className="clear" />
                                                    <br />

                                                    {this.props.bDetailVychozi && !jeOfflineRezim && <button className="form_button" onClick={this.zmenitMistoServisu} style={{ marginLeft: 10, marginBottom: 5, minWidth: 190 }}>Změnit místo servisu</button>}

                                                    {this.props.bDetailVychozi && !jeOfflineRezim && <button className="form_button" onClick={this.zmenitMistoZahajeni} style={{ marginLeft: 10, marginBottom: 5, minWidth: 190 }}>Změnit místo zahájení</button>}

                                                    {this.props.bDetailVychozi && !jeOfflineRezim && <button className="form_button" onClick={this.zmenitMistoUkonceni} style={{ marginLeft: 10, marginBottom: 5, minWidth: 190 }}>Změnit místo ukončení</button>}

                                                </div>

                                            </div>
                                            <div className="grid_7">
                                                <div className="box round" style={{ minHeight: 560 }}>
                                                    <h2>Mapa místa servisu</h2>
                                                    {!jeOfflineRezim && <SMapy mistoAdresa={this.state.mistoServisuAdresa} zpracovatDataAdresyZMapy={this.zpracovatDataAdresyZMapy} />}
                                                    {!jeOfflineRezim && <button className="form_button" onClick={this.navigovatNaMapyCz} style={{ marginTop: 9, marginBottom: 5 }}>Navigace Mapy.cz</button>}
                                                    {jeOfflineRezim && <div id="wrapper"><div className="info_zprava">Nelze zobrazit mapu v offline režimu.</div></div>}
                                                </div>
                                            </div>

                                            <div className="clear" />


                                            <div className="grid_12">
                                                <div className="box round">
                                                    <h2>Časové údaje a poznámky{this.props.bDetailVychozi && <button style={{ marginLeft: 30 }} onClick={this.upravitServisVyjezd}>Uložit změny</button>}</h2>
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} md={6} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="[Místo zahájení] - Odjezd:" widthLeft={155} hodnota={this.state.sevy_datum_odjezd_prach} typ="DATETIME" editable={this.props.bDetailVychozi} onChange={data => this.editData("sevy_datum_odjezd_prach", data)} />
                                                            </Grid>

                                                            <Grid item xs={12} md={6} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="Přejezd hranic - tam:" widthLeft={155}
                                                                    hodnota={this.state.sevy_datum_prejezd_hranic_tam}
                                                                    typ="DATETIMECH" editable={this.props.bDetailVychozi}
                                                                    onChange={data => this.editData("sevy_datum_prejezd_hranic_tam", data)}
                                                                    datetimeChecked={this.state.sevy_datum_prejezd_hranic_tam_checked}
                                                                    onCheckboxChange={checked => this.setState({ sevy_datum_prejezd_hranic_tam_checked: checked, sevy_datum_prejezd_hranic_tam: (checked ? this.state.sevy_datum_prejezd_hranic_tam : new Date("1753-01-01T00:00:00")) })}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="Servis - Příjezd:" widthLeft={155} hodnota={this.state.sevy_datum_prijezd_servis} typ="DATETIME" editable={this.props.bDetailVychozi} onChange={data => this.editData("sevy_datum_prijezd_servis", data)} />
                                                            </Grid>

                                                            <Box width="100%" />

                                                            <Grid item xs={12} md={12} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="Zahájení práce:" widthLeft={155} hodnota={this.state.sevy_datum_zahajeni_praci} typ="DATETIME" editable={this.props.bDetailVychozi} onChange={data => this.editData("sevy_datum_zahajeni_praci", data)} />
                                                            </Grid>

                                                            <Grid item xs={12} md={12} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="Ukončení práce:" widthLeft={155} hodnota={this.state.sevy_datum_ukonceni_praci} typ="DATETIME" editable={this.props.bDetailVychozi} onChange={data => this.editData("sevy_datum_ukonceni_praci", data)} />
                                                            </Grid>

                                                            <Box width="100%" />

                                                            <Grid item xs={12} md={6} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="Servis - Odjezd:" widthLeft={155} hodnota={this.state.sevy_datum_odjezd_servis} typ="DATETIME" editable={this.props.bDetailVychozi} onChange={data => this.editData("sevy_datum_odjezd_servis", data)} />
                                                            </Grid>

                                                            <Grid item xs={12} md={6} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="Přejezd hranic - zpět:" widthLeft={155}
                                                                    hodnota={this.state.sevy_datum_prejezd_hranic_zpet}
                                                                    typ="DATETIMECH"
                                                                    editable={this.props.bDetailVychozi}
                                                                    onChange={data => this.editData("sevy_datum_prejezd_hranic_zpet", data)}
                                                                    datetimeChecked={this.state.sevy_datum_prejezd_hranic_zpet_checked}
                                                                    onCheckboxChange={checked => this.setState({ sevy_datum_prejezd_hranic_zpet_checked: checked, sevy_datum_prejezd_hranic_zpet: (checked ? this.state.sevy_datum_prejezd_hranic_zpet : new Date("1753-01-01T00:00:00")) })}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6} lg={4} style={{ width: 360 }}>
                                                                <DetailRadek popis="[Místo ukončení] - Příjezd:" widthLeft={155} hodnota={this.state.sevy_datum_prijezd_prach} typ="DATETIME" editable={this.props.bDetailVychozi} onChange={data => this.editData("sevy_datum_prijezd_prach", data)} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>

                                                    <br />

                                                    <div>Předběžný rozsah požadovaných prací (obecně):
                                                        <ObjektDetailHodnotaRadek popis="" typ="TEXTAREA" hodnota={this.props.sevyData.sevy_pozadovany_rozsah_praci} />
                                                    </div>

                                                    <div> Interní poznámka:
                                                        {this.props.bDetailVychozi ?
                                                            <EditorHodnotyForm popis="" hodnota={this.state.sevy_interni_poznamka} widthLeft={0} typ="TEXTAREA" onChange={e => this.editData("sevy_interni_poznamka", e.target.value)} />
                                                            :
                                                            <ObjektDetailHodnotaRadek popis="" typ="TEXTAREA" hodnota={this.state.sevy_interni_poznamka} />
                                                        }
                                                    </div>

                                                    <div> Interní poznámky z minulosti:
                                                        <ObjektDetailHodnotaRadek popis="" typ="TEXTAREA" radky="6" hodnota={this.props.sevyData.sevy_interni_poznamky_historicke} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clear" />

                                            <div className="grid_12">
                                                <button className={styles.button_sekce} onClick={this.zobrazitPanel} value="TECHNICI">Servisní technici</button>
                                                <button className={styles.button_sekce} onClick={this.zobrazitPanel} style={{ marginRight: 20 }} value="ZARIZENI">Servisovaná zařízení</button>

                                                <button className="form_button" onClick={this.prejitNaDetailZarizeni} style={{ marginTop: 5 }} >Přejít na detail servisovaných zařízení</button>
                                            </div>
                                            <div className="clear" />

                                            {this.state.zobrazenyPanel === "ZARIZENI" &&
                                                <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                                                    <h2>Servisovaná zařízení</h2>
                                                    <ReactTabulator
                                                        data={this.props.sevyData.servisZarizeni}
                                                        columns={tabServZarizeni}
                                                        layout={'fitDataStretch'}
                                                        height={'200px'}
                                                        events={{ rowClick: this.zarizeniVyber }}
                                                        initialSort={[{ column: "seve_zarizeni.vent_vyrobni_cislo", dir: "asc" }]}
                                                    />
                                                </div>
                                            }

                                            {this.state.zobrazenyPanel === "TECHNICI" &&
                                                <div className="grid_12">
                                                    <ServisVyjezdTechniciList
                                                        sevy_id={this.props.sevyData.sevy_id}
                                                        data={this.state.servisTechnici}
                                                        otevritDialogZaznam={this.otevritDialogZaznam}
                                                        smazatZaznam={this.smazatZaznam}
                                                        bAkce={this.props.bDetailVychozi}
                                                    />
                                                </div>
                                            }

                                            <div className="clear" />
                                        </div>
                                    </LocalizationProvider>
                                </ThemeProvider>
                            }

                            {this.state.zobrazitDetailZarizeni &&
                                <ServisVyjezdDetailZarizeni bAkce={this.props.bDetailVychozi} sevyData={this.props.sevyData} vybraneZarizeniID={this.state.vybraneZarizeniID} zavritDetail={this.zavritDetailZarizeniEvent} />}
                        </div>
                    </div>
                }
            </>
        );
    }
}