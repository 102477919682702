import React from 'react';
import styles from './ProvozniHodnotyDokument.module.css'
import SignatureCanvas from 'react-signature-canvas'

import { fetchWrapper } from "../FetchWrapper";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


export class ProvozniHodnotyDokument extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailZakaznik: props.sevyData.sevy_email_kontakt_os,
            bOdeslatEmail: ("" + props.sevyData.sevy_email_kontakt_os != ""),
        };

        this.sigCanvas = React.createRef();

        this.podpisReset = this.podpisReset.bind(this);
        this.potvrditAOdeslat = this.potvrditAOdeslat.bind(this);
        this.zmenaVolbyOdeslatEmail = this.zmenaVolbyOdeslatEmail.bind(this);
    }

    podpisReset() {
        this.sigCanvas.clear();
    }

    zmenaVolbyOdeslatEmail() {
        this.setState({ bOdeslatEmail : !this.state.bOdeslatEmail });
    }

    potvrditAOdeslat() {
        const podpisImg = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');

        const dokumentData = { podpisIMGbase64PNG: podpisImg, bOdeslatEmail: this.state.bOdeslatEmail, email: this.props.sevyData.sevy_email_kontakt_os }

        LoadingSpinner.open();
        fetchWrapper.post(fetchWrapper.NovyProvozniHodnotyProtokol(this.props.vphData.vph_id), dokumentData)
            .then(data => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Potvrzený protokol provozních hodnot byl úspěšně uložen.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    render() {
        return (
            <>
                <BrowserView>
                    <iframe id="iframepdf" style={{ width: "100%", aspectRatio: "1.414", marginTop: 15 }} src={window.URL.createObjectURL(this.props.dataDokumentPDF) + ""}></iframe>                    
                </BrowserView>
                <MobileView>
                    <div className="grid_12" style={{ maxWidth: 500, marginLeft: 0 }}>
                        <a href={window.URL.createObjectURL(this.props.dataDokumentPDF)} target="_blank"><button className="form_button" style={{ marginTop: 20, width: "100%" }}>Stáhnout dokument PDF</button></a>
                    </div>
                    <div className="clear"></div>                   
                </MobileView>

                {/*<h2>Podpis</h2>
                <div className={styles.sigCanvas + " grid_6"}>
                    <SignatureCanvas
                        penColor='blue'
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        ref={(ref) => { this.sigCanvas = ref }} />
                </div>
                <div className="grid_3">
                    <button className="form_button" style={{ width: 200 }} onClick={this.podpisReset}>Smazat podpis</button><br />
                    <button className="form_button odsazeni_top10 odsazeni_bottom10" style={{ width: 200 }} onClick={this.potvrditAOdeslat}>Potvrdit a uložit dokument s podpisem</button><br />
                    {this.state.emailZakaznik && <div><input type="checkbox" defaultChecked={this.state.bOdeslatEmail} onClick={this.zmenaVolbyOdeslatEmail }></input>Odeslat kopii zákazníkovi na email {this.state.emailZakaznik}</div>}

                </div>
                <div className="clear" />*/
                }
            </>
        );
    }

}