import React from 'react';
import { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import AuthService from "../AuthService";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator } from 'react-tabulator';
import { fetchWrapper } from "../FetchWrapper";
import { ZarizeniDetail } from './ZarizeniDetail';

import { Stack, Select, MenuItem, FormControl, TextField } from '@mui/material';

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";


class FormZarizeniHledani extends React.Component {
    constructor(props) {
        super(props);
        this.state = { m_hodnota: '', m_parametr: 'VC' };

        this.onParamaterChange = this.onParamaterChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.onTxbChange = this.onTxbChange.bind(this);
        this.reloadData = this.reloadData.bind(this);

    }

    onTxbChange(event) {
        this.setState({
            m_hodnota: event.target.value
        });
    }

    onParamaterChange(event) {
        this.setState({
            m_parametr: event.target.value
        });
    }


    componentDidMount() {
        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }


    reloadData() {
        this.formSubmit(null);
    }

    formSubmit(event) {
        if (event) event.preventDefault();

        if (("" + this.state.m_hodnota).trim() == "") return;

        let urlAPI = "";
        if (this.state.m_parametr === "TO") urlAPI = fetchWrapper.VratZarizeniPopis(this.state.m_hodnota);
        else if (this.state.m_parametr === "M") urlAPI = fetchWrapper.VratZarizeniUmisteni(this.state.m_hodnota);
        else urlAPI = fetchWrapper.VratZarizeniVyrobniCislo(this.state.m_hodnota);

        LoadingSpinner.open();

        fetchWrapper.get(urlAPI)
            .then(dataZar => {
                LoadingSpinner.close();

                this.props.setDataVentilatory(dataZar, event == null);
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }



    render() {
        return (
            <form onSubmit={this.formSubmit}>

                <div style={{ float: "left", lineHeight: "40px" }} >
                    <span><strong>Dle parametru:&nbsp;</strong></span>


                    <FormControl sx={{ minWidth: 230, paddingBottom: "10px" }}>
                        <Select value={this.state.m_parametr} onChange={e => this.setState({ m_parametr: e.target.value })} size="small" sx={{ minWidth: 100 }} inputProps={{ MenuProps: { disableScrollLock: true } }}>
                            <MenuItem value="VC">Výrobní číslo</MenuItem>
                            <MenuItem value="TO">Typové označení</MenuItem>
                            <MenuItem value="M">Umístění</MenuItem>
                        </Select>
                    </FormControl>
                    &nbsp;
                    <TextField
                        size="small"
                        onChange={e => this.setState({ m_hodnota: e.target.value })}
                        value={this.props.m_hodnota}
                        sx={{ minWidth: 200 }}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                    />

                    <button className="form_button" type="submit" style={{ marginLeft: 7 }}>Hledat</button>
                </div>
            </form>
        );
    }
}


/*class ButtonVyberVentilatoru extends React.Component {
  constructor(props) {
    super(props);

    this.btnClick = this.btnClick.bind(this);
  }


  btnClick = () => {
      this.props.vyberVentDetail(this.props.cell._cell.row.data);
  };


  render() {
    return (<button onClick={() => this.btnClick()}>Vybrat</button>);
  }
}*/



class VysledkyVentilatory extends React.Component {
    constructor(props) {
        super(props);

        this.state = { zobrazenyPanel: "DEFAULT", dataVent: [], vybranyDetailVent: null };

        this.rowClick = this.rowClick.bind(this);
        this.zavritDetailEvent = this.zavritDetailEvent.bind(this);
        this.setDataVentilatory = this.setDataVentilatory.bind(this);
        this.nacistDetailZarizeni = this.nacistDetailZarizeni.bind(this);

        this.tabVentilatoryColumns = [
            { field: 'vent_vyrobni_cislo', title: 'Výrobní číslo', width: 130, headerSort: true, sorter: "number" },
            { field: 'vent_popis', title: 'Typové označení', width: 200, headerSort: true },
            { field: 'vent_popis_zarizeni', title: 'Popis zařízení', width: 200, headerSort: true },
            { field: 'semi_misto.semi_adresa', title: 'Umístění', width: 300, headerSort: true }
            /*{ field: 'vent_id', title: '', width: 130, headerSort:false, formatter: reactFormatter(<ButtonVyberVentilatoru vyberVentDetail={this.vyberVentDetail} />) },*/
        ];
    }

    rowClick = (e, row) => {
        LoadingSpinner.open();

        this.nacistDetailZarizeni(row.getData().vent_id);
    };

    nacistDetailZarizeni(vent_id) {
        fetchWrapper.get(fetchWrapper.VratZarizeniDetail(vent_id))
            .then(data => {
                LoadingSpinner.close();

                this.setState({ zobrazenyPanel: "DETAIL", vybranyDetailVent: data });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    zavritDetailEvent() {
        this.setState({ zobrazenyPanel: "LIST", vybranyDetailVent: null });
    }

    setDataVentilatory(dataVentNew, bReload) {
        if (bReload) {
            const vent_idSelPosledni = this.state.vybranyDetailVent ? this.state.vybranyDetailVent.vent_id : null;

            const ventEx = dataVentNew.find(v => v.vent_id == vent_idSelPosledni);
            if (ventEx) {
                this.setState({ dataVent: dataVentNew, vybranyDetailVent: null });

                this.nacistDetailZarizeni(vent_idSelPosledni);
            }
        }
        else {
            if (dataVentNew.length == 1) {
                this.setState({ dataVent: dataVentNew, vybranyDetailVent: null });

                this.nacistDetailZarizeni(dataVentNew[0].vent_id);
            }
            else this.setState({ zobrazenyPanel: "LIST", dataVent: dataVentNew, vybranyDetailVent: null });
        }
    }

    render() {

        return (
            <div className="grid_12 odsazeni_bottom50">
                <div className="grid_auto">
                    <div className="box round first">
                        <h2>Hledání zařízení</h2>
                        <div className="block">
                            <FormZarizeniHledani setDataVentilatory={this.setDataVentilatory} />
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div className="clear"></div>

                <div>
                    {this.state.zobrazenyPanel === "DEFAULT" && <div style={{ height: 400, width: '100%' }}></div>}
                    {this.state.zobrazenyPanel === "DETAIL" && this.state.vybranyDetailVent &&
                        <ZarizeniDetail
                            seveZarizeniData={null}
                            zarizeniData={this.state.vybranyDetailVent}
                            zobrazitMereni={true}
                            zobrazitTlacitkoZpet={this.state.dataVent.length > 1}
                            bAkce={false}
                            zavritDetailEvent={this.zavritDetailEvent}
                            nadpis={"Vybrané zařízení - " + this.state.vybranyDetailVent.vent_popis} />
                    }
                    {this.state.zobrazenyPanel === "LIST" && <div>
                        <h3>Nalezená zařízení:</h3>
                        <div style={{ height: 400, width: '100%' }}>
                            <ReactTabulator
                                data={this.state.dataVent}
                                columns={this.tabVentilatoryColumns}
                                layout={'fitDataStretch'}
                                height={'100%'}
                                events={{ rowClick: this.rowClick }}
                                initialSort={[{ column: "vent_vyrobni_cislo", dir: "asc" }]}
                            />
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}





export function ZarizeniList() {

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser) return <Navigate to="/" />;
    }, []);

    return (
        <>
            <VysledkyVentilatory />
        </>
    );
} 