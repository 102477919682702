import React from 'react';
import { ReactTabulator } from 'react-tabulator';

import { Global } from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";


export class ServisVyjezdPraceList extends React.Component {
    constructor(props) {
        super(props);

        this.novyZaznam = this.novyZaznam.bind(this);
        this.upravitZaznam = this.upravitZaznam.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
    }

    novyZaznam() {
        this.props.otevritDialogZaznam("PRACE");
    }

    upravitZaznam(e, cell) {
        this.props.otevritDialogZaznam("PRACE", cell.getRow().getData());
    }

    smazatZaznam = async (e, cell) => {
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraný záznam?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") this.props.smazatZaznam("PRACE", cell.getRow().getData());
    }


    render() {

        let cellContextMenu = [];

        if (this.props.bAkce) {
            cellContextMenu = [
                {
                    label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                    action: this.novyZaznam
                },
                {
                    label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                    action: this.upravitZaznam
                },
                {
                    label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                    action: this.smazatZaznam
                }
            ];
        }


        const tabServPrace = [
            {
                field: 'sepr_datum', title: 'Datum', width: 100, headerSort: true, contextMenu: cellContextMenu, formatter: function (cell, formatterParams, onRendered) {
                    return Global.toDateStr(new Date(cell.getValue()));
                }
            },
            {field: 'spp_skupina', title: 'Skupina', width: 180, headerSort: true, contextMenu: cellContextMenu,
                formatter: function (cell, formatterParams, onRendered) {
                    return Global.EnumSkupinaServisnichPraci(cell.getValue());
                }
            },
            { field: 'spp_popis', title: 'Popis', width: 300, headerSort: true, contextMenu: cellContextMenu },
            { field: 'sepr_poznamka', title: 'Poznámka', width: 350, headerSort: true, contextMenu: cellContextMenu }
        ];

        return <div>           
            <h2>Servisní práce {this.props.bAkce && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.novyZaznam}>Nový záznam</button>} </h2>
            <ReactTabulator
                data={this.props.data}
                columns={tabServPrace}
                layout={'fitDataStretch'}
                height={'200px'}
                selectable={1}
            />
        </div>;
    }
}
            