import React from 'react';
import styles from './ObjektDetailHodnotaRadek.module.css'
import DateTimePicker from 'react-datetime-picker';

export class ObjektDetailHodnotaRadek extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        if (this.props.typ === "DATETIME") {
            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right}><DateTimePicker format={"dd.MM.y HH:mm"} value={new Date()} /></div>
                </div>
            );
        }
        else if (this.props.typ === "TEXTAREA") {
            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right}>
                        <textarea value={this.props.hodnota} readOnly={true} rows={(this.props.radky) ? this.props.radky : "4"} style={{ resize: "none" }} />
                    </div>
                </div>
            );
        }
        else if (this.props.typ === "CHECKBOX") {

            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right}>{(this.props.hodnota) ? "ANO" : "NE"}</div>
                </div>
            );

        }


        return (
            <div className={styles.detail_udaj_container}>
                <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                <div className={styles.right}>{this.props.hodnota}</div>
            </div>
        );
    }
}
