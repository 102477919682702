import React from 'react'
import PropTypes from 'prop-types'
import styles from './ReactCameraControl.module.css'
import { TouchButton } from './TouchButton';

import IkonaMinus from '../ikony/minus.png'
import IkonaPlus from '../ikony/plus.png'

class ReactCameraControl extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            videoTrack: null,
            capabilities: null,
            zoom: 0,
            kameraEnabled: false
        }

        this.zoomCamera = this.zoomCamera.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.takePhoto = this.takePhoto.bind(this);
        this.nacteniKamery = this.nacteniKamery.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.video.facingMode !== prevProps.video.facingMode) {
            this.state.videoTrack.stop();
            this.video.srcObject = null;
            this.setState({ videoTrack: null, kameraEnabled: false });
            this.nacteniKamery();
        }
    }

    async componentDidMount() {
        this.nacteniKamery();
    }

    async nacteniKamery() {
        const { video, audio } = this.props;

        if (navigator.mediaDevices) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video, audio })
                const tracks = stream.getVideoTracks();
                const videoTrack = tracks[tracks.length - 1];

                // Before crbug.com/711524 is fixed, this is needed to retrieve capabilities.
                await this.sleep(1000)
                const capabilities = videoTrack.getCapabilities()

                /*if (video.facingMode && video.facingMode == "environment" && 'focusMode' in capabilities) {
                    await videoTrack.applyConstraints({ advanced: [{ focusMode: "continuous" }] })
                }*/

                this.setState({
                    videoTrack : videoTrack,
                    capabilities : capabilities,
                });

                this.video.srcObject = stream
                await this.video.play();
                this.setState({ kameraEnabled: true });
            } catch (e) {
                console.error(e)
            }
        } else {
            console.error('Check browser compatibility - Chrome > 57')
        }
    }

    async sleep(ms = 0) {
        return new Promise(resolve => { setTimeout(resolve, ms) })
    }

    capture() {
        return new window.ImageCapture(this.state.videoTrack).takePhoto();
    }

    async takePhoto() {
        const img = await new window.ImageCapture(this.state.videoTrack).takePhoto();
        this.props.takePhoto(img);
    }

    async zoom(zoomValue) {
        const { videoTrack, capabilities } = this.state
        if ('zoom' in capabilities) {
            await videoTrack.applyConstraints({ advanced: [{ zoom: zoomValue }] })
            this.setState({
                videoTrack
            })
        }
    }

    zoomCamera(bOut) {
        let zoomNew = bOut ? (this.state.zoom - this.state.capabilities.zoom.step) : (this.state.zoom + this.state.capabilities.zoom.step);

        if (zoomNew > this.state.capabilities.zoom.max) zoomNew = this.state.capabilities.zoom.max;
        if (zoomNew < this.state.capabilities.zoom.min) zoomNew = this.state.capabilities.zoom.min;

        if (zoomNew == this.state.zoom) return;

        this.setState({ zoom: zoomNew });
        this.zoom(zoomNew);
    }

    zoomIn() {
        this.zoomCamera(false);
    }

    zoomOut() {
        this.zoomCamera(true);
    }

    handleClick(event) {
        if (event.detail == 2) {
            this.takePhoto();
        }
    }

    render() {
        if (this.state.videoTrack === null) {
            return (
                <div style={{ width: "100%", textAlign: "left" }}>
                    Loading...
                </div>
            )
        }

        /*<input
                        type='range'
                        hidden={!hasZoom}
                        min={hasZoom ? capabilities.zoom.min : 0}
                        max={hasZoom ? capabilities.zoom.max : 0}
                        step={hasZoom ? capabilities.zoom.step : 0}
                        value={hasZoom ? settings.zoom : 0}
                        onChange={e => this.zoom(e)} /> */

        const { videoTrack, capabilities } = this.state;
        const settings = videoTrack.getSettings();
        const hasZoom = 'zoom' in capabilities;

        return (
            <div className={styles.preview} onClick={this.handleClick}>
                {this.props.children}
                <div className={styles.slider}>
                    {this.state.kameraEnabled &&
                        <div className={styles.captureContainer} onClick={(e) => { e.stopPropagation(); }}>
                            {hasZoom && <TouchButton classCss={styles.btnZoomOut} longPressCallback={this.zoomOut} ikona={IkonaMinus} />}
                            <div className={styles.btnCapture} onClick={this.takePhoto}> </div>
                            {hasZoom && <TouchButton classCss={styles.btnZoomIn} longPressCallback={this.zoomIn} ikona={IkonaPlus} />}
                        </div>
                    }
                    
                </div>
                <video className={styles.video} ref={video => { this.video = video }} />
            </div>
        )
    }
}

ReactCameraControl.propTypes = {
    audio: PropTypes.bool,
    video: PropTypes.bool,
    children: PropTypes.element
}

ReactCameraControl.defaultProps = {
    audio: false,
    video: true,
    style: {},
    children: null
}


export default ReactCameraControl;