import React from 'react';
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";
import { EditorHodnotyForm } from "./EditorHodnotyForm";
import AuthService from "../AuthService";

import { v4 as uuidv4 } from 'uuid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

import Global from "./Global";

export class ZarizeniProvozniHodnotyForm extends React.Component {
    constructor(props) {
        super(props);

        this.inputFile = React.createRef();

        this.mericiZarizeniInterval = null;

        const user = AuthService.getCurrentUser();

        let vph_kontroloval_idDefault = Global.GuidEmpty;
        let vph_kontroloval_PrijmeniJmenoDefault = "";

        if (props.dataTechnici.length > 0) {
            const pracSel = props.dataTechnici.find(t => t.sete_prac_id == user.uziv_money_prac_id);

            if (pracSel) {
                vph_kontroloval_idDefault = pracSel.sete_prac_id;
                vph_kontroloval_PrijmeniJmenoDefault = pracSel.prijmeniJmeno;
            }
            else {
                vph_kontroloval_idDefault = props.dataTechnici[0].sete_prac_id;
                vph_kontroloval_PrijmeniJmenoDefault = props.dataTechnici[0].prijmeniJmeno;
            }
        }

        if (props.dataObject) {
            this.state = {
                vph_id: props.dataObject.vph_id,
                vent_id: this.props.zarizeni.vent_id,
                vent_usporadani: Global.VratUsporadaniKorekce(this.props.zarizeni.vent_usporadani),
                vent_usporadaniEnum: Global.VratEnumUsporadani(this.props.zarizeni.vent_usporadani),
                vph_datum: new Date(props.dataObject.vph_datum),
                vph_misto: props.dataObject.vph_misto,

                vph_napnuti_remene: props.dataObject.vph_napnuti_remene,
                vph_presazeni_os: props.dataObject.vph_presazeni_os,
                vph_proud_faze_u: props.dataObject.vph_proud_faze_u,
                vph_proud_faze_v: props.dataObject.vph_proud_faze_v,
                vph_proud_faze_w: props.dataObject.vph_proud_faze_w,
                vph_proud_sdruz_zatizeni_hodnota: props.dataObject.vph_proud_sdruz_zatizeni_hodnota,
                vph_proudove_zatizeni_motoru: props.dataObject.vph_proudove_zatizeni_motoru,
                vph_proud_sdruz_zatizeni: props.dataObject.vph_proud_sdruz_zatizeni,

                vph_typ_ulozeni: props.dataObject.vph_typ_ulozeni,
                vph_uhlovy_zlom: props.dataObject.vph_uhlovy_zlom,
                vph_vyrovnani_spojky: props.dataObject.vph_vyrovnani_spojky,
                vph_zkusebni_otacky: props.dataObject.vph_zkusebni_otacky,
                vph_zrychleni_jednotka: props.dataObject.vph_zrychleni_jednotka,
                vph_poznamka: props.dataObject.vph_poznamka,

                vph_lozisko_horizont1_vibrace: props.dataObject.vph_lozisko_horizont1_vibrace,
                vph_lozisko_vert1_vibrace: props.dataObject.vph_lozisko_vert1_vibrace,
                vph_lozisko_axial1_vibrace: props.dataObject.vph_lozisko_axial1_vibrace,
                vph_lozisko_horizont2_vibrace: props.dataObject.vph_lozisko_horizont2_vibrace,
                vph_lozisko_vert2_vibrace: props.dataObject.vph_lozisko_vert2_vibrace,
                vph_lozisko_axial2_vibrace: props.dataObject.vph_lozisko_axial2_vibrace,
                vph_lozisko_horizont3_vibrace: props.dataObject.vph_lozisko_horizont3_vibrace,
                vph_lozisko_vert3_vibrace: props.dataObject.vph_lozisko_vert3_vibrace,
                vph_lozisko_axial3_vibrace: props.dataObject.vph_lozisko_axial3_vibrace,
                vph_lozisko_horizont4_vibrace: props.dataObject.vph_lozisko_horizont4_vibrace,
                vph_lozisko_vert4_vibrace: props.dataObject.vph_lozisko_vert4_vibrace,
                vph_lozisko_axial4_vibrace: props.dataObject.vph_lozisko_axial4_vibrace,
                vph_lozisko_1_teplota: props.dataObject.vph_lozisko_1_teplota,

                vph_lozisko_1_zrychleni: props.dataObject.vph_lozisko_1_zrychleni,
                vph_lozisko_2_teplota: props.dataObject.vph_lozisko_2_teplota,
                vph_lozisko_2_zrychleni: props.dataObject.vph_lozisko_2_zrychleni,
                vph_lozisko_3_teplota: props.dataObject.vph_lozisko_3_teplota,
                vph_lozisko_3_zrychleni: props.dataObject.vph_lozisko_3_zrychleni,
                vph_lozisko_4_teplota: props.dataObject.vph_lozisko_4_teplota,
                vph_lozisko_4_zrychleni: props.dataObject.vph_lozisko_4_zrychleni,
                vph_spojka_mezera: props.dataObject.vph_spojka_mezera,
                vph_presazeni_os_y: props.dataObject.vph_presazeni_os_y,
                vph_uhlovy_zlom_y: props.dataObject.vph_uhlovy_zlom_y,

                vph_kontroloval_id: Global.JeGuidValid(props.dataObject.vph_kontroloval_id) ? props.dataObject.vph_kontroloval_id : vph_kontroloval_idDefault,
                vph_kontroloval_PrijmeniJmeno: Global.JeGuidValid(props.dataObject.vph_kontroloval_id) ? props.dataObject.vph_kontroloval_PrijmeniJmeno : vph_kontroloval_PrijmeniJmenoDefault,
                vph_frekvence_napnuti_remene: props.dataObject.vph_frekvence_napnuti_remene,
                vph_offlineStav: props.dataObject.vph_offlineStav,

                bNovy: false,
                dataSevyTechnici: null,

                stavMericihoZarizeni: false,
                stavMericihoZarizeniZprava: ""
            }
        }
        else if (props.dataObjectKopieZdroj) {
            this.state = {
                vph_id: Global.GuidEmpty,
                vent_id: this.props.zarizeni.vent_id,
                vent_usporadani: Global.VratUsporadaniKorekce(this.props.zarizeni.vent_usporadani),
                vent_usporadaniEnum: Global.VratEnumUsporadani(this.props.zarizeni.vent_usporadani),
                vph_datum: new Date(props.dataObjectKopieZdroj.vph_datum),
                vph_misto: props.dataObjectKopieZdroj.vph_misto,

                vph_napnuti_remene: props.dataObjectKopieZdroj.vph_napnuti_remene,
                vph_presazeni_os: props.dataObjectKopieZdroj.vph_presazeni_os,
                vph_proud_faze_u: props.dataObjectKopieZdroj.vph_proud_faze_u,
                vph_proud_faze_v: props.dataObjectKopieZdroj.vph_proud_faze_v,
                vph_proud_faze_w: props.dataObjectKopieZdroj.vph_proud_faze_w,
                vph_proud_sdruz_zatizeni_hodnota: props.dataObjectKopieZdroj.vph_proud_sdruz_zatizeni_hodnota,
                vph_proudove_zatizeni_motoru: props.dataObjectKopieZdroj.vph_proudove_zatizeni_motoru,
                vph_proud_sdruz_zatizeni: props.dataObjectKopieZdroj.vph_proud_sdruz_zatizeni,

                vph_typ_ulozeni: props.dataObjectKopieZdroj.vph_typ_ulozeni,
                vph_uhlovy_zlom: props.dataObjectKopieZdroj.vph_uhlovy_zlom,
                vph_vyrovnani_spojky: props.dataObjectKopieZdroj.vph_vyrovnani_spojky,
                vph_zkusebni_otacky: props.dataObjectKopieZdroj.vph_zkusebni_otacky,
                vph_zrychleni_jednotka: props.dataObjectKopieZdroj.vph_zrychleni_jednotka,
                vph_poznamka: props.dataObjectKopieZdroj.vph_poznamka,

                vph_lozisko_horizont1_vibrace: props.dataObjectKopieZdroj.vph_lozisko_horizont1_vibrace,
                vph_lozisko_vert1_vibrace: props.dataObjectKopieZdroj.vph_lozisko_vert1_vibrace,
                vph_lozisko_axial1_vibrace: props.dataObjectKopieZdroj.vph_lozisko_axial1_vibrace,
                vph_lozisko_horizont2_vibrace: props.dataObjectKopieZdroj.vph_lozisko_horizont2_vibrace,
                vph_lozisko_vert2_vibrace: props.dataObjectKopieZdroj.vph_lozisko_vert2_vibrace,
                vph_lozisko_axial2_vibrace: props.dataObjectKopieZdroj.vph_lozisko_axial2_vibrace,
                vph_lozisko_horizont3_vibrace: props.dataObjectKopieZdroj.vph_lozisko_horizont3_vibrace,
                vph_lozisko_vert3_vibrace: props.dataObjectKopieZdroj.vph_lozisko_vert3_vibrace,
                vph_lozisko_axial3_vibrace: props.dataObjectKopieZdroj.vph_lozisko_axial3_vibrace,
                vph_lozisko_horizont4_vibrace: props.dataObjectKopieZdroj.vph_lozisko_horizont4_vibrace,
                vph_lozisko_vert4_vibrace: props.dataObjectKopieZdroj.vph_lozisko_vert4_vibrace,
                vph_lozisko_axial4_vibrace: props.dataObjectKopieZdroj.vph_lozisko_axial4_vibrace,
                vph_lozisko_1_teplota: props.dataObjectKopieZdroj.vph_lozisko_1_teplota,

                vph_lozisko_1_zrychleni: props.dataObjectKopieZdroj.vph_lozisko_1_zrychleni,
                vph_lozisko_2_teplota: props.dataObjectKopieZdroj.vph_lozisko_2_teplota,
                vph_lozisko_2_zrychleni: props.dataObjectKopieZdroj.vph_lozisko_2_zrychleni,
                vph_lozisko_3_teplota: props.dataObjectKopieZdroj.vph_lozisko_3_teplota,
                vph_lozisko_3_zrychleni: props.dataObjectKopieZdroj.vph_lozisko_3_zrychleni,
                vph_lozisko_4_teplota: props.dataObjectKopieZdroj.vph_lozisko_4_teplota,
                vph_lozisko_4_zrychleni: props.dataObjectKopieZdroj.vph_lozisko_4_zrychleni,
                vph_spojka_mezera: props.dataObjectKopieZdroj.vph_spojka_mezera,
                vph_presazeni_os_y: props.dataObjectKopieZdroj.vph_presazeni_os_y,
                vph_uhlovy_zlom_y: props.dataObjectKopieZdroj.vph_uhlovy_zlom_y,

                vph_kontroloval_id: Global.JeGuidValid(props.dataObjectKopieZdroj.vph_kontroloval_id) ? props.dataObjectKopieZdroj.vph_kontroloval_id : vph_kontroloval_idDefault,
                vph_kontroloval_PrijmeniJmeno: Global.JeGuidValid(props.dataObjectKopieZdroj.vph_kontroloval_id) ? props.dataObjectKopieZdroj.vph_kontroloval_PrijmeniJmeno : vph_kontroloval_PrijmeniJmenoDefault,
                vph_frekvence_napnuti_remene: props.dataObjectKopieZdroj.vph_frekvence_napnuti_remene,
                vph_offlineStav: 0,

                bNovy: true,
                dataSevyTechnici: null,

                stavMericihoZarizeni: false,
                stavMericihoZarizeniZprava: ""
            }
        }
        else {
            this.state = {
                vph_id: Global.GuidEmpty,
                vent_id: this.props.zarizeni.vent_id,
                vent_usporadani: Global.VratUsporadaniKorekce(this.props.zarizeni.vent_usporadani),
                vent_usporadaniEnum: Global.VratEnumUsporadani("" + this.props.zarizeni.vent_usporadani),
                vph_datum: new Date(),
                vph_misto: 3,

                vph_napnuti_remene: false,
                vph_presazeni_os: 0,
                vph_proud_faze_u: 0,
                vph_proud_faze_v: 0,
                vph_proud_faze_w: 0,
                vph_proud_sdruz_zatizeni_hodnota: 0,
                vph_proudove_zatizeni_motoru: false,
                vph_proud_sdruz_zatizeni: false,

                vph_typ_ulozeni: this.props.zarizeni.vent_ulozeni, // TODO textove? spravna vychozi
                vph_uhlovy_zlom: 0,
                vph_vyrovnani_spojky: false,
                vph_zkusebni_otacky: 0,
                vph_zrychleni_jednotka: "m.s-2", // TODO  spravna vychozi
                vph_poznamka: "",

                vph_lozisko_horizont1_vibrace: 0,
                vph_lozisko_vert1_vibrace: 0,
                vph_lozisko_axial1_vibrace: 0,
                vph_lozisko_horizont2_vibrace: 0,
                vph_lozisko_vert2_vibrace: 0,
                vph_lozisko_axial2_vibrace: 0,
                vph_lozisko_horizont3_vibrace: 0,
                vph_lozisko_vert3_vibrace: 0,
                vph_lozisko_axial3_vibrace: 0,
                vph_lozisko_horizont4_vibrace: 0,
                vph_lozisko_vert4_vibrace: 0,
                vph_lozisko_axial4_vibrace: 0,
                vph_lozisko_1_teplota: 0,

                vph_lozisko_1_zrychleni: 0,
                vph_lozisko_2_teplota: 0,
                vph_lozisko_2_zrychleni: 0,
                vph_lozisko_3_teplota: 0,
                vph_lozisko_3_zrychleni: 0,
                vph_lozisko_4_teplota: 0,
                vph_lozisko_4_zrychleni: 0,
                vph_spojka_mezera: 0,
                vph_presazeni_os_y: 0,
                vph_uhlovy_zlom_y: 0,

                vph_kontroloval_id: vph_kontroloval_idDefault,
                vph_kontroloval_PrijmeniJmeno: vph_kontroloval_PrijmeniJmenoDefault,
                vph_frekvence_napnuti_remene: 0,
                vph_offlineStav: 0,

                bNovy: true,
                dataSevyTechnici: null,

                stavMericihoZarizeni: false,
                stavMericihoZarizeniZprava: ""
            };
        }

        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);

        this.nahratSoubor = this.nahratSoubor.bind(this);
        this.vybratSoubor = this.vybratSoubor.bind(this);
        this.zpracovatSoubor = this.zpracovatSoubor.bind(this);

        this.reloadData = this.reloadData.bind(this);
        this.inicializace = this.inicializace.bind(this);

        this.stavPripojeniMericiZarizeni = this.stavPripojeniMericiZarizeni.bind(this);

        this.noveMereniHodnoty = this.noveMereniHodnoty.bind(this);
    }

    componentDidMount() {
        this.inicializace();

        this.stavPripojeniMericiZarizeni();

        this.mericiZarizeniInterval = setInterval(() => {
            this.stavPripojeniMericiZarizeni();
        }, 800);

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData);

        if (this.mericiZarizeniInterval) clearInterval(this.mericiZarizeniInterval);
        this.mericiZarizeniInterval = null;
    }

    stavPripojeniMericiZarizeni() {

        fetchWrapper.get(window.PROVHODNOTYZARIZENIURL + "stav")
            .then(data => {

                if (data.stav) {
                    this.setState({ stavMericihoZarizeni: true, stavMericihoZarizeniZprava: "Připraveno pro měření" });

                    if (this.mericiZarizeniInterval) clearInterval(this.mericiZarizeniInterval);
                    this.mericiZarizeniInterval = null;
                }
                else this.setState({ stavMericihoZarizeni: false, stavMericihoZarizeniZprava: "Čekání na měřící zařízení..." });

            })
            .catch(error => {
                LoadingSpinner.close();

                this.setState({ stavMericihoZarizeni: false });
                this.setState({ stavMericihoZarizeniZprava: "Čekání na měřící zařízení..." });
            });
    }

    reloadData() {
        this.inicializace();

        const zarizeniSevy = this.props.sevyData.servisZarizeni.find(v => v.seve_zarizeni.vent_id == this.props.zarizeni.vent_id);
        if (zarizeniSevy == null) this.zavritDialog();
    }

    inicializace() {

        const dataSevyTechniciNew = [];
        if (this.state.vph_kontroloval_id && this.state.vph_kontroloval_id != Global.GuidEmpty) {
            if (!this.props.dataTechnici.find(st => st.sete_prac_id == this.state.vph_kontroloval_id)) {
                dataSevyTechniciNew.push({
                    sete_id: Global.GuidEmpty,
                    sete_b_vedouci: false,
                    sete_prac_id: this.state.vph_kontroloval_id,
                    osCislo: "",
                    prijmeniJmeno: this.state.vph_kontroloval_PrijmeniJmeno,
                    sevy_id: this.props.sevyData.sevy_id,
                    sete_offlineStav: 0
                });
            }
        }
        dataSevyTechniciNew.push(...this.props.dataTechnici);

        this.setState({ dataSevyTechnici: dataSevyTechniciNew });
    }

    formSubmit(event) {
        event.preventDefault();

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const dataObject = {
            vph_id: (!jeOfflineRezim || (jeOfflineRezim && !this.state.bNovy)) ? this.state.vph_id : uuidv4(),
            vent_id: this.props.zarizeni.vent_id,
            vent_usporadani: this.state.vent_usporadani,
            vph_datum: Global.toISOString(this.state.vph_datum),
            vph_misto: this.state.vph_misto,

            vph_napnuti_remene: this.state.vph_napnuti_remene,
            vph_presazeni_os: this.state.vph_presazeni_os ? this.state.vph_presazeni_os : 0,
            vph_proud_faze_u: this.state.vph_proud_faze_u ? this.state.vph_proud_faze_u : 0,
            vph_proud_faze_v: this.state.vph_proud_faze_v ? this.state.vph_proud_faze_v : 0,
            vph_proud_faze_w: this.state.vph_proud_faze_w ? this.state.vph_proud_faze_w : 0,
            vph_proud_sdruz_zatizeni_hodnota: this.state.vph_proud_sdruz_zatizeni_hodnota ? this.state.vph_proud_sdruz_zatizeni_hodnota : 0,
            vph_proudove_zatizeni_motoru: this.state.vph_proudove_zatizeni_motoru,
            vph_proud_sdruz_zatizeni: this.state.vph_proud_sdruz_zatizeni,

            vph_typ_ulozeni: this.state.vph_typ_ulozeni,
            vph_uhlovy_zlom: this.state.vph_uhlovy_zlom ? this.state.vph_uhlovy_zlom : 0,
            vph_vyrovnani_spojky: this.state.vph_vyrovnani_spojky,
            vph_zkusebni_otacky: this.state.vph_zkusebni_otacky ? this.state.vph_zkusebni_otacky : 0,
            vph_zrychleni_jednotka: this.state.vph_zrychleni_jednotka,
            vph_poznamka: Global.textAreaOpravaPredUlozeni(this.state.vph_poznamka),

            vph_lozisko_horizont1_vibrace: this.state.vph_lozisko_horizont1_vibrace ? this.state.vph_lozisko_horizont1_vibrace : 0,
            vph_lozisko_vert1_vibrace: this.state.vph_lozisko_vert1_vibrace ? this.state.vph_lozisko_vert1_vibrace : 0,
            vph_lozisko_axial1_vibrace: this.state.vph_lozisko_axial1_vibrace ? this.state.vph_lozisko_axial1_vibrace : 0,
            vph_lozisko_horizont2_vibrace: this.state.vph_lozisko_horizont2_vibrace ? this.state.vph_lozisko_horizont2_vibrace : 0,
            vph_lozisko_vert2_vibrace: this.state.vph_lozisko_vert2_vibrace ? this.state.vph_lozisko_vert2_vibrace : 0,
            vph_lozisko_axial2_vibrace: this.state.vph_lozisko_axial2_vibrace ? this.state.vph_lozisko_axial2_vibrace : 0,
            vph_lozisko_horizont3_vibrace: this.state.vph_lozisko_horizont3_vibrace ? this.state.vph_lozisko_horizont3_vibrace : 0,
            vph_lozisko_vert3_vibrace: this.state.vph_lozisko_vert3_vibrace ? this.state.vph_lozisko_vert3_vibrace : 0,
            vph_lozisko_axial3_vibrace: this.state.vph_lozisko_axial3_vibrace ? this.state.vph_lozisko_axial3_vibrace : 0,
            vph_lozisko_horizont4_vibrace: this.state.vph_lozisko_horizont4_vibrace ? this.state.vph_lozisko_horizont4_vibrace : 0,
            vph_lozisko_vert4_vibrace: this.state.vph_lozisko_vert4_vibrace ? this.state.vph_lozisko_vert4_vibrace : 0,
            vph_lozisko_axial4_vibrace: this.state.vph_lozisko_axial4_vibrace ? this.state.vph_lozisko_axial4_vibrace : 0,

            vph_lozisko_1_teplota: this.state.vph_lozisko_1_teplota ? this.state.vph_lozisko_1_teplota : 0,
            vph_lozisko_1_zrychleni: this.state.vph_lozisko_1_zrychleni ? this.state.vph_lozisko_1_zrychleni : 0,
            vph_lozisko_2_teplota: this.state.vph_lozisko_2_teplota ? this.state.vph_lozisko_2_teplota : 0,
            vph_lozisko_2_zrychleni: this.state.vph_lozisko_2_zrychleni ? this.state.vph_lozisko_2_zrychleni : 0,
            vph_lozisko_3_teplota: this.state.vph_lozisko_3_teplota ? this.state.vph_lozisko_3_teplota : 0,
            vph_lozisko_3_zrychleni: this.state.vph_lozisko_3_zrychleni ? this.state.vph_lozisko_3_zrychleni : 0,
            vph_lozisko_4_teplota: this.state.vph_lozisko_4_teplota ? this.state.vph_lozisko_4_teplota : 0,
            vph_lozisko_4_zrychleni: this.state.vph_lozisko_4_zrychleni ? this.state.vph_lozisko_4_zrychleni : 0,
            vph_spojka_mezera: this.state.vph_spojka_mezera ? this.state.vph_spojka_mezera : 0,
            vph_presazeni_os_y: this.state.vph_presazeni_os_y ? this.state.vph_presazeni_os_y : 0,
            vph_uhlovy_zlom_y: this.state.vph_uhlovy_zlom_y ? this.state.vph_uhlovy_zlom_y : 0,

            vph_kontroloval_id: this.state.vph_kontroloval_id,
            vph_kontroloval_PrijmeniJmeno: this.state.vph_kontroloval_PrijmeniJmeno,
            sevy_id: this.props.sevyData.sevy_id,
            vph_frekvence_napnuti_remene: this.state.vph_frekvence_napnuti_remene ? this.state.vph_frekvence_napnuti_remene : 0,
            vph_offlineStav: this.state.vph_offlineStav
        }

        if (this.state.vent_usporadaniEnum != 1) dataObject.vph_napnuti_remene = false;
        if (this.state.vent_usporadaniEnum != 0) dataObject.vph_vyrovnani_spojky = false;

        if (!dataObject.vph_napnuti_remene) {
            dataObject.vph_frekvence_napnuti_remene = 0;
        }

        if (!dataObject.vph_proudove_zatizeni_motoru) {
            dataObject.vph_proud_sdruz_zatizeni = false;
            dataObject.vph_proud_faze_u = 0;
            dataObject.vph_proud_faze_v = 0;
            dataObject.vph_proud_faze_w = 0;
        }

        if (!dataObject.vph_proud_sdruz_zatizeni) {
            dataObject.vph_proud_sdruz_zatizeni_hodnota = 0;
        }
        else {
            dataObject.vph_proud_faze_u = 0;
            dataObject.vph_proud_faze_v = 0;
            dataObject.vph_proud_faze_w = 0;
        }

        if (!dataObject.vph_vyrovnani_spojky) {
            dataObject.vph_presazeni_os = 0;
            dataObject.vph_spojka_mezera = 0;
            dataObject.vph_uhlovy_zlom = 0;
            dataObject.vph_presazeni_os_y = 0;
            dataObject.vph_uhlovy_zlom_y = 0;
        }


        let chyba = "";
        if (!Global.JeGuidValid(dataObject.vph_kontroloval_id)) chyba = chyba + " Není nastaven pracovník, který kontrolu provedl."

        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p> {chyba}</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        if (this.state.bNovy) {
            LoadingSpinner.open();

            fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyVentilatorProvozniHodnoty() : fetchWrapper.NovyVentilatorProvozniHodnotyOffline(), dataObject)
                .then(data => {
                    LoadingSpinner.close();

                    this.props.zarizeni.vent_usporadani = this.state.vent_usporadani;
                    this.props.zarizeni.vent_ulozeni = this.state.vph_typ_ulozeni;

                    this.props.zavritDialog(true, null);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else {
            LoadingSpinner.open();

            fetchWrapper.put(!jeOfflineRezim ? fetchWrapper.EditVentilatorProvozniHodnoty() : fetchWrapper.EditVentilatorProvozniHodnotyOffline(), dataObject)
                .then(data => {
                    LoadingSpinner.close();

                    this.props.zarizeni.vent_usporadani = this.state.vent_usporadani;
                    this.props.zarizeni.vent_ulozeni = this.state.vph_typ_ulozeni;

                    this.props.zavritDialog(true, null);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false, null);
    }

    vybratSoubor() {
        this.inputFile.current.click();
    }

    nahratSoubor(event) {
        if (event.target.files.length < 1) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            this.zpracovatSoubor(e);
        };
        reader.readAsText(event.target.files[0]);
    }

    zpracovatSoubor(e) {
        const text = (e.target.result);

        let iRow = 0;
        text.split("\n").forEach(r => {
            if (iRow != 0) {
                const radkaArr = r.split(",");
                if (radkaArr.length == 2) {
                    const klic = radkaArr[0];
                    const value = parseFloat(radkaArr[1]);

                    if (klic == "L1Hmm") this.setState({ vph_lozisko_horizont1_vibrace: value });
                    if (klic == "L1Vmm") this.setState({ vph_lozisko_vert1_vibrace: value });
                    if (klic == "L1Amm") this.setState({ vph_lozisko_axial1_vibrace: value });
                    if (klic == "L1Hg") this.setState({ vph_lozisko_1_zrychleni: value });

                    if (klic == "L2Hmm") this.setState({ vph_lozisko_horizont2_vibrace: value });
                    if (klic == "L2Vmm") this.setState({ vph_lozisko_vert2_vibrace: value });
                    if (klic == "L2Amm") this.setState({ vph_lozisko_axial2_vibrace: value });
                    if (klic == "L2Hg") this.setState({ vph_lozisko_2_zrychleni: value });

                    if (klic == "L3Hmm") this.setState({ vph_lozisko_horizont3_vibrace: value });
                    if (klic == "L3Vmm") this.setState({ vph_lozisko_vert3_vibrace: value });
                    if (klic == "L3Amm") this.setState({ vph_lozisko_axial3_vibrace: value });
                    if (klic == "L3Hg") this.setState({ vph_lozisko_3_zrychleni: value });

                    if (klic == "L4Hmm") this.setState({ vph_lozisko_horizont4_vibrace: value });
                    if (klic == "L4Vmm") this.setState({ vph_lozisko_vert4_vibrace: value });
                    if (klic == "L4Amm") this.setState({ vph_lozisko_axial4_vibrace: value });
                    if (klic == "L4Hg") this.setState({ vph_lozisko_4_zrychleni: value });
                }
            }

            iRow++;
        });

        this.setState({ vph_zrychleni_jednotka: "g" });

        MessageBox.open({
            title: "Zpráva",
            content: <p>Soubor byl načten.</p>,
            buttons: [
                { name: "OK", handler: () => "OK" }
            ]
        });
    }

    noveMereniHodnoty(kodHodnoty) {
        LoadingSpinner.open();

        fetchWrapper.get(window.PROVHODNOTYZARIZENIURL + "stav")
            .then(dataStav => {
                console.log(dataStav);
                if (dataStav.stav) //stav je OK
                {
                    fetchWrapper.get(window.PROVHODNOTYZARIZENIURL + "mereni")
                        .then(data => {
                            LoadingSpinner.close();
                            console.log(data);
                            if (data.error) {
                                MessageBox.open({
                                    title: "Chyba",
                                    content: <p>Nepodařilo se provést měření: {data.error}</p>,
                                    buttons: [
                                        { name: "OK", handler: () => "OK" }
                                    ]
                                });
                            }
                            else {

                                let mm = 0;
                                let g = 0;

                                data.forEach(val => {
                                    if (val.mm) mm = val.mm;
                                    if (val.g) g = val.g;
                                });

                                if (kodHodnoty == "L1Hmm") this.setState({ vph_lozisko_horizont1_vibrace: mm, vph_lozisko_1_zrychleni: g, vph_zrychleni_jednotka: "g" });
                                if (kodHodnoty == "L1Vmm") this.setState({ vph_lozisko_vert1_vibrace: mm });
                                if (kodHodnoty == "L1Amm") this.setState({ vph_lozisko_axial1_vibrace: mm });

                                if (kodHodnoty == "L2Hmm") this.setState({ vph_lozisko_horizont2_vibrace: mm, vph_lozisko_2_zrychleni: g, vph_zrychleni_jednotka: "g" });
                                if (kodHodnoty == "L2Vmm") this.setState({ vph_lozisko_vert2_vibrace: mm });
                                if (kodHodnoty == "L2Amm") this.setState({ vph_lozisko_axial2_vibrace: mm });

                                if (kodHodnoty == "L3Hmm") this.setState({ vph_lozisko_horizont3_vibrace: mm, vph_lozisko_3_zrychleni: g, vph_zrychleni_jednotka: "g" });
                                if (kodHodnoty == "L3Vmm") this.setState({ vph_lozisko_vert3_vibrace: mm });
                                if (kodHodnoty == "L3Amm") this.setState({ vph_lozisko_axial3_vibrace: mm });

                                if (kodHodnoty == "L4Hmm") this.setState({ vph_lozisko_horizont4_vibrace: mm, vph_lozisko_4_zrychleni: g, vph_zrychleni_jednotka: "g" });
                                if (kodHodnoty == "L4Vmm") this.setState({ vph_lozisko_vert4_vibrace: mm });
                                if (kodHodnoty == "L4Amm") this.setState({ vph_lozisko_axial4_vibrace: mm });
                            }
                        })
                        .catch(error => {
                            LoadingSpinner.close();

                            MessageBox.open({
                                title: "Chyba",
                                content: <p>Nepodařilo se provést měření: {error}</p>,
                                buttons: [
                                    { name: "OK", handler: () => "OK" }
                                ]
                            });
                        });
                }
                else //chyba stav
                {
                    LoadingSpinner.close();
                    this.setState({ stavMericihoZarizeni: false, stavMericihoZarizeniZprava: "Čekání na měřící zařízení..." });

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se provést měření. Zařízení bylo pravděpodobně odpojeno.</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });

                    this.mericiZarizeniInterval = setInterval(() => {
                        this.stavPripojeniMericiZarizeni();
                    }, 800);
                }

            })
            .catch(error => {
                LoadingSpinner.close();

                this.setState({ stavMericihoZarizeni: false });
                this.setState({ stavMericihoZarizeniZprava: "Čekání na měřící zařízení..." });
            });
    }


    render() {

        return (
            <div>
                <h1>{this.state.bNovy ? "Nový záznam měření" : "Upravit záznam měření"}  <button className="form_button" style={{ marginLeft: 10, marginRight: 20 }} onClick={this.vybratSoubor}>Ložiska - Import ze souboru</button>
                    <div className={this.state.stavMericihoZarizeni ? "info_zprava" : "error_zprava"} style={{ display: "inline", fontSize: 14 }}>{this.state.stavMericihoZarizeniZprava}</div>
                </h1>

                <input type='file' id='file' ref={this.inputFile} style={{ display: 'none' }} onChange={this.nahratSoubor} onClick={(e) => e.target.value = null} />
                <form onSubmit={this.formSubmit}>

                    <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                        <div className='grid_12'>
                            <div style={{ border: "solid", borderWidth: 1, borderColor: "#204562", position: "relative", paddingTop: 15, paddingBottom: 15 }}>
                                <div className='grid_8'>
                                    <EditorHodnotyForm popis="Datum a čas:" hodnota={this.state.vph_datum} widthLeft={140} stylesRight={{ width: 190 }} typ="DATETIME" onChange={date => this.setState({ vph_datum: date })} />
                                </div>
                                <div className="clear" />

                                <div className='grid_6'>
                                    {this.state.vph_misto == 3 && <EditorHodnotyForm popis="Kontroloval:" typ="COMBOBOX" klicID="sete_prac_id" klicPopis="prijmeniJmeno" hodnota={this.state.vph_kontroloval_id} name="vph_kontroloval_id" dataCiselnik={this.state.dataSevyTechnici} widthLeft={140} onChange={e => this.setState({ vph_kontroloval_id: e.target.value, vph_kontroloval_PrijmeniJmeno: e.objectSelected.prijmeniJmeno })} />}
                                </div>
                                <div className='grid_4'>

                                </div>
                                <div className="clear" />


                                <div className='grid_6'>
                                    <EditorHodnotyForm popis="Typ uložení:" hodnota={this.state.vph_typ_ulozeni} widthLeft={140} typ="TYPULOZENI" onChange={e => this.setState({ vph_typ_ulozeni: e.target.value })} />
                                </div>
                                <div className='grid_6'>
                                    <EditorHodnotyForm popis="Uspořádání:" hodnota={this.state.vent_usporadani} widthLeft={140} typ="VENTUSPORADANI" onChange={e => this.setState({ vent_usporadani: e.target.value, vent_usporadaniEnum: Global.VratEnumUsporadani(e.target.value) })} />
                                </div>
                                <div className="clear" />

                                <div className='grid_5'>

                                </div>
                                <div className='grid_4'>
                                    <EditorHodnotyForm popis="Zkušební otáčky [RPM]:" hodnota={this.state.vph_zkusebni_otacky} widthLeft={140} typ="CISLO" onChange={e => this.setState({ vph_zkusebni_otacky: e.target.value })} />
                                </div>
                                <div className="clear" />


                                <div className="grid_12 odsazeni_top10 odsazeni_bottom10" style={{ backgroundColor: "grey", height: 1 }} >
                                </div>
                                <div className="clear" />

                                <div className='grid_6'>
                                    <EditorHodnotyForm popis="Jednotka zrychleni:" hodnota={this.state.vph_zrychleni_jednotka} widthLeft={200} typ="JEDNZRYCHLENI" onChange={e => this.setState({ vph_zrychleni_jednotka: e.target.value })} />
                                </div>
                                <div className="clear" />


                                <div className="grid_12 odsazeni_top10">
                                    <div className="grid_6">
                                        <EditorHodnotyForm popis={<span style={{ fontWeight: "bold" }}>Ložisko 1</span>} hodnota="" widthLeft={200} />
                                        <EditorHodnotyForm popis="Teplota [°C]:" hodnota={this.state.vph_lozisko_1_teplota} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_1_teplota: e.target.value })} />
                                        <EditorHodnotyForm popis={"Zrychlení [" + this.state.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vph_lozisko_1_zrychleni} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_1_zrychleni: e.target.value })} />
                                        <EditorHodnotyForm popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_horizont1_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_horizont1_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L1Hmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                        <EditorHodnotyForm popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_vert1_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_vert1_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L1Vmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                        <EditorHodnotyForm popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_axial1_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_axial1_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L1Amm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                    </div>
                                    <div className="grid_6">
                                        <EditorHodnotyForm popis={<span style={{ fontWeight: "bold" }}>Ložisko 2</span>} hodnota="" widthLeft={200} />
                                        <EditorHodnotyForm popis="Teplota [°C]:" hodnota={this.state.vph_lozisko_2_teplota} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_2_teplota: e.target.value })} />
                                        <EditorHodnotyForm popis={"Zrychlení [" + this.state.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vph_lozisko_2_zrychleni} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_2_zrychleni: e.target.value })} />
                                        <EditorHodnotyForm popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_horizont2_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_horizont2_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L2Hmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                        <EditorHodnotyForm popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_vert2_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_vert2_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L2Vmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                        <EditorHodnotyForm popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_axial2_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_axial2_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L2Amm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                    </div>
                                    <div className="clear" />

                                    {(this.state.vent_usporadaniEnum == 0 || this.state.vent_usporadaniEnum == 1) &&
                                        <div className="odsazeni_top10">
                                            <div className="grid_6">
                                                <EditorHodnotyForm popis={<span style={{ fontWeight: "bold" }}>Ložisko 3</span>} hodnota="" widthLeft={200} />
                                                <EditorHodnotyForm popis="Teplota [°C]:" hodnota={this.state.vph_lozisko_3_teplota} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_3_teplota: e.target.value })} />
                                                <EditorHodnotyForm popis={"Zrychlení [" + this.state.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vph_lozisko_3_zrychleni} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_3_zrychleni: e.target.value })} />
                                                <EditorHodnotyForm popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_horizont3_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_horizont3_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L3Hmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                                <EditorHodnotyForm popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_vert3_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_vert3_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L3Vmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                                <EditorHodnotyForm popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_axial3_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_axial3_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L3Amm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                            </div>

                                            <div className="grid_6">
                                                <EditorHodnotyForm popis={<span style={{ fontWeight: "bold" }}>Ložisko 4</span>} hodnota="" widthLeft={200} />
                                                <EditorHodnotyForm popis="Teplota [°C]:" hodnota={this.state.vph_lozisko_4_teplota} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_4_teplota: e.target.value })} />
                                                <EditorHodnotyForm popis={"Zrychlení [" + this.state.vph_zrychleni_jednotka + "]:"} hodnota={this.state.vph_lozisko_4_zrychleni} widthLeft={200} typ="CISLOD" onChange={e => this.setState({ vph_lozisko_4_zrychleni: e.target.value })} />
                                                <EditorHodnotyForm popis="Horizontální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_horizont4_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_horizont4_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L4Hmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                                <EditorHodnotyForm popis="Vertikální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_vert4_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_vert4_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L4Vmm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                                <EditorHodnotyForm popis="Axiální - vibrace [mm/s]:" hodnota={this.state.vph_lozisko_axial4_vibrace} widthLeft={200} typ="CISLODMERENI" onChange={e => this.setState({ vph_lozisko_axial4_vibrace: e.target.value })} onMereniHodnoty={e => this.noveMereniHodnoty("L4Amm")} bTlacitkoMereni={this.state.stavMericihoZarizeni} />
                                            </div>
                                            <div className="clear" />
                                        </div>
                                    }

                                </div>
                                <div className="clear" />

                                <div className="grid_12 odsazeni_top10">
                                    <div className="grid_6">
                                        <EditorHodnotyForm popis="Napnutí řemene:" hodnota={this.state.vph_napnuti_remene && this.state.vent_usporadaniEnum == 1} typ="CHECKBOX" widthLeft={200} disabled={this.state.vent_usporadaniEnum != 1} onChange={e => this.setState({ vph_napnuti_remene: e.target.checked })} />
                                        <EditorHodnotyForm popis="Frekvence napnutí řemene [Hz]:" hodnota={this.state.vph_frekvence_napnuti_remene} widthLeft={200} typ="CISLOD" disabled={!(this.state.vph_napnuti_remene && this.state.vent_usporadaniEnum == 1)} onChange={e => this.setState({ vph_frekvence_napnuti_remene: e.target.value })} />
                                        <EditorHodnotyForm popis="Proudové zatížení motoru:" hodnota={this.state.vph_proudove_zatizeni_motoru} typ="CHECKBOX" widthLeft={200} onChange={e => { console.log(e); this.setState({ vph_proudove_zatizeni_motoru: e.target.checked }) }} />
                                        <EditorHodnotyForm popis="Sdružené zatížení:" hodnota={this.state.vph_proud_sdruz_zatizeni && this.state.vph_proudove_zatizeni_motoru} typ="CHECKBOX" widthLeft={200} disabled={!this.state.vph_proudove_zatizeni_motoru} onChange={e => { this.setState({ vph_proud_sdruz_zatizeni: e.target.checked }) }} />
                                        <EditorHodnotyForm popis="Proud sdruženého zatížení [A]:" hodnota={this.state.vph_proud_sdruz_zatizeni_hodnota} typ="CISLOD" widthLeft={200} disabled={!(this.state.vph_proud_sdruz_zatizeni && this.state.vph_proudove_zatizeni_motoru)} onChange={e => this.setState({ vph_proud_sdruz_zatizeni_hodnota: e.target.value })} />
                                        <EditorHodnotyForm popis="Proud fáze U [A]:" hodnota={this.state.vph_proud_faze_u} typ="CISLOD" widthLeft={200} disabled={!this.state.vph_proudove_zatizeni_motoru || this.state.vph_proud_sdruz_zatizeni} onChange={e => this.setState({ vph_proud_faze_u: e.target.value })} />
                                        <EditorHodnotyForm popis="Proud fáze V [A]:" hodnota={this.state.vph_proud_faze_v} typ="CISLOD" widthLeft={200} disabled={!this.state.vph_proudove_zatizeni_motoru || this.state.vph_proud_sdruz_zatizeni} onChange={e => this.setState({ vph_proud_faze_v: e.target.value })} />
                                        <EditorHodnotyForm popis="Proud fáze W [A]:" hodnota={this.state.vph_proud_faze_w} typ="CISLOD" widthLeft={200} disabled={!this.state.vph_proudove_zatizeni_motoru || this.state.vph_proud_sdruz_zatizeni} onChange={e => this.setState({ vph_proud_faze_w: e.target.value })} />
                                    </div>
                                    <div className="grid_6">
                                        <EditorHodnotyForm popis="Vyrovnání spojky:" hodnota={this.state.vph_vyrovnani_spojky && this.state.vent_usporadaniEnum == 0} typ="CHECKBOX" widthLeft={200} disabled={this.state.vent_usporadaniEnum != 0} onChange={e => this.setState({ vph_vyrovnani_spojky: e.target.checked })} />
                                        <EditorHodnotyForm popis="Přesazení os u spojky - X [mm]:" hodnota={this.state.vph_presazeni_os} widthLeft={200} typ="CISLOD" disabled={!(this.state.vph_vyrovnani_spojky && this.state.vent_usporadaniEnum == 0)} onChange={e => this.setState({ vph_presazeni_os: e.target.value })} />
                                        <EditorHodnotyForm popis="Přesazení os u spojky - Y [mm]:" hodnota={this.state.vph_presazeni_os_y} widthLeft={200} typ="CISLOD" disabled={!(this.state.vph_vyrovnani_spojky && this.state.vent_usporadaniEnum == 0)} onChange={e => this.setState({ vph_presazeni_os_y: e.target.value })} />
                                        <EditorHodnotyForm popis="Úhlový zlom u spojky - X[mm]:" hodnota={this.state.vph_uhlovy_zlom} widthLeft={200} typ="CISLOD" disabled={!(this.state.vph_vyrovnani_spojky && this.state.vent_usporadaniEnum == 0)} onChange={e => this.setState({ vph_uhlovy_zlom: e.target.value })} />
                                        <EditorHodnotyForm popis="Úhlový zlom u spojky - Y[mm]:" hodnota={this.state.vph_uhlovy_zlom_y} widthLeft={200} typ="CISLOD" disabled={!(this.state.vph_vyrovnani_spojky && this.state.vent_usporadaniEnum == 0)} onChange={e => this.setState({ vph_uhlovy_zlom_y: e.target.value })} />
                                        <EditorHodnotyForm popis="Mezera u spojky [mm]:" hodnota={this.state.vph_spojka_mezera} typ="CISLOD" widthLeft={200} disabled={!(this.state.vph_vyrovnani_spojky && this.state.vent_usporadaniEnum == 0)} onChange={e => this.setState({ vph_spojka_mezera: e.target.value })} />
                                    </div>
                                </div>
                                <div className="clear" />

                                <div className="grid_12 odsazeni_top10">
                                    <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.vph_poznamka} widthLeft={60} typ="TEXTAREA" onChange={e => this.setState({ vph_poznamka: e.target.value })} />
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </LocalizationProvider>
                    <div className="clear" />
                    <div className="form_udaj_container">
                        <div className="left" style={{ width: 120 }}> </div>
                        <div className="right">
                            <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                            <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}