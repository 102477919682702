import React from "react";

const defaultProps = {
    title: "ModalTitle",
    onClose: () => { }
};

export default function Modal(props) {
    const { title, onClose, bCloseBtn } = { ...defaultProps, ...props };
    return (
        <div className="modal__backdrop d-flex justify-content-center align-items-center">
            <div className="modal__wrapper">
                {bCloseBtn && <div className="modal__close" onClick={onClose}>
                    <img src="./img/close.png" />
                </div>}
                <div className="modal__title">
                    <h2>{title}</h2>
                </div>
                <div className="modal__content">{props.children}</div>
            </div>
        </div>
    );
}
