import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import { reactFormatter } from 'react-tabulator/lib/Utils.js?version=4.0';
import styles from './ServisVyjezdDetailZarizeni.module.css'

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";

import { CameraFeed } from './CameraFeed';
import { ZarizeniDetail } from './ZarizeniDetail';
import { ZarizeniProvozniHodnotyList } from "./ZarizeniProvozniHodnotyList";
import { ZarizeniProvozniHodnotyForm } from './ZarizeniProvozniHodnotyForm';
import { ServisVyjezdMaterialList } from "./ServisVyjezdMaterialList";
import { ServisVyjezdPraceList } from "./ServisVyjezdPraceList";
import { ServisVyjezdPotrebneVybaveniList } from "./ServisVyjezdPotrebneVybaveniList";

import { ServisVyjezdPotrebneVybaveniForm } from './ServisVyjezdPotrebneVybaveniForm';
import { ServisVyjezdPraceForm } from './ServisVyjezdPraceForm';
import { ServisVyjezdMaterialForm } from './ServisVyjezdMaterialForm';

import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService"
import CacheData from "../CacheData"

import { v4 as uuidv4 } from 'uuid';

import { ServisVyjezdOdpracovaneHodinyList } from './ServisVyjezdOdpracovaneHodinyList';
import { ServisVyjezdOdpracovaneHodinyForm } from './ServisVyjezdOdpracovaneHodinyForm';
import { ServisniListDokument } from "./ServisniListDokument";

import { ServisovaneZarizeniForm } from './ServisovaneZarizeniForm';

import { Select, MenuItem, FormControl, FormControlLabel, Checkbox } from '@mui/material';

import { Global } from './Global';
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

import { format } from 'date-fns'


export class OdkazTechnList extends React.Component {
    constructor(props) {
        super(props);

        this.btnClick = this.btnClick.bind(this);
    }


    btnClick = () => {
        this.props.clickEvent(this.props.cell._cell.row.data);
    };


    render() {
        const url = this.props.cell._cell.value;

        if (url) return (<a href={url} target='_blank' rel='noopener noreferrer'>TECHN</a>);
        else return <></>;
    }
}







export class ServisVyjezdDetailZarizeni extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zobrazenyPanel: "VLASTNOSTI",
            vybraneSeveZarizeni: undefined,
            vybraneZarizeniMaterial: [],
            vybraneZarizeniPrace: [],
            vybraneZarizeniSpecVybaveni: [],
            vybraneZarizeniOdpracovaneHodiny: [],
            vybraneZarizeniProvHodn: [],
            vybraneZarizeniProvHodnVyjezdu: [],
            bEditData: false,
            editDataZaznamEdit: null,
            editDataZaznamKopieZdroj: null,
            editDataTyp: null,
            servisniListBlob: null,
            servisniListJazyk: "CS",
            servisniListVyjezdy: [],
            servisniListVyjezdyVybraneIds: [this.props.sevyData.sevy_id],
            servisniListSchvaleno: false,
            isLoading: false,
            servisFotoGallery: [],
            bServisFotoFiltrVyjezd: false
        };

        this.tabServZarizeni = React.createRef();
        this.tabServListVyjezdy = React.createRef();
        this.imgGalleryServisFoto = React.createRef();

        this.zobrazitPanel = this.zobrazitPanel.bind(this);
        this.tabServZarizeniRowClick = this.tabServZarizeniRowClick.bind(this);
        this.vybratVychoziZarizeni = this.vybratVychoziZarizeni.bind(this);
        this.vybratVyjezdyServisnihoListu = this.vybratVyjezdyServisnihoListu.bind(this);
        this.nastavitDataVybranehoZarizeni = this.nastavitDataVybranehoZarizeni.bind(this);
        this.otevritDialogZaznam = this.otevritDialogZaznam.bind(this);
        this.otevritDialogZaznamKopie = this.otevritDialogZaznamKopie.bind(this);
        this.zavritDialogZaznam = this.zavritDialogZaznam.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);
        this.ulozitServisVyjezdDoPameti = this.ulozitServisVyjezdDoPameti.bind(this);
        this.reloadEditovanaData = this.reloadEditovanaData.bind(this);
        this.pridatZarizeni = this.pridatZarizeni.bind(this);
        this.noveFoto = this.noveFoto.bind(this);
        this.onFiltrServisFotoVyjezdChecked = this.onFiltrServisFotoVyjezdChecked.bind(this);
        this.nacistServisFotoGallery = this.nacistServisFotoGallery.bind(this);
        this.smazatServisFotoPriloha = this.smazatServisFotoPriloha.bind(this);

        this.zarizeniNovyZaznam = this.zarizeniNovyZaznam.bind(this);
        this.zarizeniNovyZaznamKopie = this.zarizeniNovyZaznamKopie.bind(this);
        this.zarizeniUpravitZaznam = this.zarizeniUpravitZaznam.bind(this);
        this.zarizeniSmazatZaznam = this.zarizeniSmazatZaznam.bind(this);

        this.generovatPDFServisniList = this.generovatPDFServisniList.bind(this);
        this.generovatPDFServisniListDleJazyka = this.generovatPDFServisniListDleJazyka.bind(this);
        this.aktualizaceServisnihoListuPDF = this.aktualizaceServisnihoListuPDF.bind(this);
    }


    nastavitDataVybranehoZarizeni(dataVybrZarizeni) {
        let servisPraceVent = this.props.sevyData.servisPrace.filter(sm => sm.vent_id == dataVybrZarizeni.seve_zarizeni.vent_id);
        let servisOdpracovaneHodinyVent = this.props.sevyData.servisOdpracovaneHodiny.filter(sm => sm.vent_id == dataVybrZarizeni.seve_zarizeni.vent_id);
        let servisMaterialVent = this.props.sevyData.servisMaterial.filter(sm => sm.vent_id == dataVybrZarizeni.seve_zarizeni.vent_id);
        let servisSpecVybaveniVent = this.props.sevyData.servisPotrebneVybaveni.filter(sm => sm.vent_id == dataVybrZarizeni.seve_zarizeni.vent_id);
        let servisProvHodnVent = dataVybrZarizeni.seve_zarizeni.provozniHodnoty;
        let servisProvHodnVyjezduVent = dataVybrZarizeni.seve_zarizeni.provozniHodnoty.filter(ph => ph.sevy_id == this.props.sevyData.sevy_id);

        this.setState({
            vybraneSeveZarizeni: dataVybrZarizeni,
            vybraneZarizeniMaterial: servisMaterialVent,
            vybraneZarizeniSpecVybaveni: servisSpecVybaveniVent,
            vybraneZarizeniPrace: servisPraceVent,
            vybraneZarizeniOdpracovaneHodiny: servisOdpracovaneHodinyVent,
            vybraneZarizeniProvHodn: servisProvHodnVent,
            vybraneZarizeniProvHodnVyjezdu: servisProvHodnVyjezduVent
        });

        if (this.state.zobrazenyPanel == "PROHLIZECFOTO") {
            this.nacistServisFotoGallery(this.state.bServisFotoFiltrVyjezd);
        }
    }

    tabServZarizeniRowClick = (e, row) => {
        this.nastavitDataVybranehoZarizeni(row.getData());
    };

    zobrazitPanel(event) {
        event.preventDefault();

        if (event.target.value == "SERVLIST") {
            if (this.state.servisniListBlob) this.setState({ zobrazenyPanel: event.target.value });
            else this.generovatPDFServisniList();
        }
        else if (event.target.value == "PROHLIZECFOTO") {
            this.nacistServisFotoGallery(this.state.bServisFotoFiltrVyjezd);
        }
        else {
            this.setState({ zobrazenyPanel: event.target.value });
        }
    }

    generovatPDFServisniList() {
        this.generovatPDFServisniListDleJazyka(this.state.servisniListJazyk, true);
    }

    generovatPDFServisniListDleJazyka(vybranyJazyk, bReloadVyjezdy) {
        let dataVyjezdy = null;
        let dataVyjezdyIds = [this.props.sevyData.sevy_id];
        if (this.tabServListVyjezdy.current) {
            dataVyjezdy = this.tabServListVyjezdy.current.getSelectedData();
        }

        if (dataVyjezdy && dataVyjezdy.length > 0) {
            dataVyjezdyIds.push(...dataVyjezdy.map(v => v.sevy_id))
        }

        this.setState({ zobrazenyPanel: "SERVLIST", servisniListBlob: null, servisniListVyjezdyVybraneIds: dataVyjezdyIds, servisniListSchvaleno: false, isLoading: true });

        const dokumentData = {
            aktualniSevy_id: this.props.sevyData.sevy_id,
            vyjezdyIds: dataVyjezdyIds,
            jazyk: vybranyJazyk,
            podpisIMGbase64PNG: "",
            bOdeslatEmail: false,
            seve_prevzal: "",
            email: ""
        }

        if (bReloadVyjezdy) {
            LoadingSpinner.open();

            let filtrDo = new Date();
            let podzakazka_id = "00000000-0000-0000-0000-000000000000";
            if (this.props.sevyData.sevy_podzakazka_id && this.props.sevyData.sevy_podzakazka_id != Global.GuidEmpty) {
                podzakazka_id = this.props.sevyData.sevy_podzakazka_id;
            }

            Promise.all([
                fetchWrapper.get(fetchWrapper.VratProtokolServisniVyjezdy(this.props.sevyData.sevy_id, podzakazka_id, filtrDo)),
                fetchWrapper.fileLoadPost(fetchWrapper.NovyServisVyjezdProtokol(), dokumentData)])
                .then(data => {
                    LoadingSpinner.close();

                    this.setState({ servisniListBlob: data[1], servisniListVyjezdy: data[0], isLoading: false });
                })
                .catch(error => {
                    LoadingSpinner.close();
                    this.setState({ isLoading: false });

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se vygenerovat dokument: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else {
            LoadingSpinner.open();

            fetchWrapper.fileLoadPost(fetchWrapper.NovyServisVyjezdProtokol(), dokumentData)
                .then(data => {
                    LoadingSpinner.close();

                    this.setState({ servisniListBlob: data, isLoading: false });
                })
                .catch(error => {
                    LoadingSpinner.close();
                    this.setState({ isLoading: false });

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se vygenerovat dokument: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    vybratVychoziZarizeni() {
        let vent_id = this.props.vybraneZarizeniID;
        if (this.state.vybraneSeveZarizeni) vent_id = this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id;

        if (this.tabServZarizeni.current.getRows().length > 0) {
            this.tabServZarizeni.current.getRows().forEach(row => {
                if (vent_id == row.getData().seve_zarizeni.vent_id) {
                    this.tabServZarizeni.current.selectRow(row);
                    this.nastavitDataVybranehoZarizeni(row.getData());
                }
            });

        }
    }

    vybratVyjezdyServisnihoListu() {
        if (this.state.servisniListVyjezdyVybraneIds && this.tabServListVyjezdy.current.getRows().length > 0) {
            this.tabServListVyjezdy.current.getRows().forEach(row => {
                if (this.state.servisniListVyjezdyVybraneIds.includes(row.getData().sevy_id)) {
                    this.tabServListVyjezdy.current.selectRow(row);
                }
            });
        }
    }

    otevritDialogZaznam(typZaznamu, zaznamEdit) {
        this.setState({ bEditData: true, editDataZaznamEdit: zaznamEdit, editDataZaznamKopieZdroj: null, editDataTyp: typZaznamu });
    }

    otevritDialogZaznamKopie(typZaznamu, zaznamZdroj) {
        this.setState({ bEditData: true, editDataZaznamEdit: null, editDataZaznamKopieZdroj: zaznamZdroj, editDataTyp: typZaznamu });
    }


    zavritDialogZaznam(bZmena, vybranyZaznam) {
        if (bZmena) this.reloadEditovanaData(this.state.editDataTyp, vybranyZaznam);

        this.setState({ bEditData: false, editDataTyp: "", editDataZaznamEdit: null, editDataZaznamKopieZdroj: null });
        window.scrollTo(0, 0);
    }

    reloadEditovanaData(typZaznamu, vybranyZaznam) {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        LoadingSpinner.open();

        if (typZaznamu == "MATERIAL") {
            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisMaterial(this.props.sevyData.sevy_id) : fetchWrapper.VratServisMaterialOffline())
                .then(data => {
                    LoadingSpinner.close();

                    this.props.sevyData.servisMaterial = data.filter(sm => sm.sema_offlineStav != 3);
                    this.props.sevyData.servisMaterialDeleted = data.filter(sm => sm.sema_offlineStav == 3);

                    let servisMaterialVent = this.props.sevyData.servisMaterial.filter(sm => sm.vent_id == this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id);
                    this.setState({ vybraneZarizeniMaterial: servisMaterialVent });

                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (typZaznamu == "SPECVYBAVENI") {
            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisPotrebneVybaveni(this.props.sevyData.sevy_id) : fetchWrapper.VratServisPotrebneVybaveniOffline())
                .then(data => {
                    LoadingSpinner.close();

                    this.props.sevyData.servisPotrebneVybaveni = data.filter(sv => sv.sepv_offlineStav != 3);
                    this.props.sevyData.servisPotrebneVybaveniDeleted = data.filter(sv => sv.sepv_offlineStav == 3);

                    let servisSpecVybaveniVent = this.props.sevyData.servisPotrebneVybaveni.filter(sm => sm.vent_id == this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id);
                    this.setState({ vybraneZarizeniSpecVybaveni: servisSpecVybaveniVent });

                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (typZaznamu == "PRACE") {
            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisPrace(this.props.sevyData.sevy_id) : fetchWrapper.VratServisPraceOffline())
                .then(data => {
                    LoadingSpinner.close();

                    this.props.sevyData.servisPrace = data.filter(sp => sp.sepr_offlineStav != 3);
                    this.props.sevyData.servisPraceDeleted = data.filter(sp => sp.sepr_offlineStav == 3);

                    let servisPraceVent = this.props.sevyData.servisPrace.filter(sm => sm.vent_id == this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id);
                    this.setState({ vybraneZarizeniPrace: servisPraceVent });

                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (typZaznamu == "ODPRACHODINY") {
            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisOdpracovaneHodiny(this.props.sevyData.sevy_id) : fetchWrapper.VratServisOdpracovaneHodinyOffline())
                .then(data => {
                    LoadingSpinner.close();

                    this.props.sevyData.servisOdpracovaneHodiny = data.filter(sh => sh.soh_offlineStav != 3);
                    this.props.sevyData.servisOdpracovaneHodinyDeleted = data.filter(sh => sh.soh_offlineStav == 3);

                    let servisOdpracovaneHodinyVent = this.props.sevyData.servisOdpracovaneHodiny.filter(sm => sm.vent_id == this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id);
                    this.setState({ vybraneZarizeniOdpracovaneHodiny: servisOdpracovaneHodinyVent });

                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (typZaznamu == "PROVHODN") {
            const offlineRequest = jeOfflineRezim ? { vent_id: this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id } : null;

            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratZarizeniProvozniHodnoty(this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id) : fetchWrapper.VratZarizeniProvozniHodnotyOffline(), offlineRequest)
                .then(data => {
                    LoadingSpinner.close();

                    const vybraneZarizeniTemp = this.state.vybraneSeveZarizeni;
                    vybraneZarizeniTemp.seve_zarizeni.provozniHodnoty = data.filter(ph => ph.vph_offlineStav != 3);
                    vybraneZarizeniTemp.seve_zarizeni.provozniHodnotyDeleted = data.filter(ph => ph.vph_offlineStav == 3);

                    let servisProvHodnVent = vybraneZarizeniTemp.seve_zarizeni.provozniHodnoty;
                    let servisProvHodnVyjezduVent = vybraneZarizeniTemp.seve_zarizeni.provozniHodnoty.filter(ph => ph.sevy_id == this.props.sevyData.sevy_id);
                    this.setState({ vybraneSeveZarizeni: vybraneZarizeniTemp, vybraneZarizeniProvHodn: servisProvHodnVent, vybraneZarizeniProvHodnVyjezdu: servisProvHodnVyjezduVent });

                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else if (typZaznamu == "ZARIZENI") {
            fetchWrapper.get(!jeOfflineRezim ? fetchWrapper.VratServisovanaZarizeni(this.props.sevyData.sevy_id) : fetchWrapper.VratServisovanaZarizeniOffline())
                .then(data => {
                    LoadingSpinner.close();

                    const vent_idSel = (this.state.vybraneSeveZarizeni ? this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id : Global.GuidEmpty);

                    this.props.sevyData.servisZarizeni = data.filter(sz => sz.seve_offlineStav != 3);
                    this.props.sevyData.servisZarizeniDeleted = data.filter(sz => sz.seve_offlineStav == 3);
                    let servisZarizeniVyjezd = this.props.sevyData.servisZarizeni;

                    let ventNovySel = (vybranyZaznam ? servisZarizeniVyjezd.find(v => v.seve_zarizeni.vent_id === vybranyZaznam.seve_zarizeni_id)
                        : servisZarizeniVyjezd.find(v => v.seve_zarizeni.vent_id === vent_idSel));

                    if (!ventNovySel && servisZarizeniVyjezd.length > 0) ventNovySel = servisZarizeniVyjezd[0];
                    this.setState({ vybraneSeveZarizeni: ventNovySel });
                    this.ulozitServisVyjezdDoPameti();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
        else { console.log(typZaznamu); }
    }


    smazatZaznam(typZaznamu, zaznamDel) {
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;
        let offlineRequest = null;

        let URLDelete = "";
        if (typZaznamu == "MATERIAL") {
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisMaterial(zaznamDel.sema_id) : fetchWrapper.VymazServisMaterialOffline();
            if (jeOfflineRezim) offlineRequest = { sema_id: zaznamDel.sema_id };
        }
        else if (typZaznamu == "SPECVYBAVENI") {
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisPotrebneVybaveni(zaznamDel.sepv_id) : fetchWrapper.VymazServisPotrebneVybaveniOffline();
            if (jeOfflineRezim) offlineRequest = { sepv_id: zaznamDel.sepv_id };
        }
        else if (typZaznamu == "PRACE") {
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisPrace(zaznamDel.sepr_id) : fetchWrapper.VymazServisPraceOffline();
            if (jeOfflineRezim) offlineRequest = { sepr_id: zaznamDel.sepr_id };
        }
        else if (typZaznamu == "ODPRACHODINY") {
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisOdpracovaneHodiny(zaznamDel.soh_id) : fetchWrapper.VymazServisOdpracovaneHodinyOffline();
            if (jeOfflineRezim) offlineRequest = { soh_id: zaznamDel.soh_id };
        }
        else if (typZaznamu == "PROVHODN") {
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisProvozniHodnoty(zaznamDel.vph_id) : fetchWrapper.VymazServisProvozniHodnotyOffline();
            if (jeOfflineRezim) offlineRequest = { vph_id: zaznamDel.vph_id, vent_id: zaznamDel.vent_id };
        }
        else if (typZaznamu == "ZARIZENI") {
            URLDelete = !jeOfflineRezim ? fetchWrapper.VymazServisovaneZarizeni(zaznamDel.seve_id) : fetchWrapper.VymazServisovaneZarizeniOffline();
            if (jeOfflineRezim) offlineRequest = { seve_id: zaznamDel.seve_id };
        }

        LoadingSpinner.open();

        fetchWrapper.del(URLDelete, offlineRequest)
            .then(data => {
                LoadingSpinner.close();

                this.reloadEditovanaData(typZaznamu, null);
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data:<br /> {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    ulozitServisVyjezdDoPameti() {
        CacheData.SetData(AuthService.getCurrentUser(), "servisvyjezd", this.props.sevyData);
    }

    pridatZarizeni() {
        this.otevritDialogZaznam("ZARIZENI", null);
    }

    noveFoto(file, popisZadany) {
        const uziv = AuthService.getCurrentUser();

        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const sefo_datum = new Date();
        const sefo_nazev_zdroj = this.state.vybraneSeveZarizeni.seve_zarizeni.vent_vyrobni_cislo + "_" + format(sefo_datum, "yyyy_MM_dd") + "_" + uziv.uziv_inicialy + ".png";

        const dataFoto = {
            sefo_id: !jeOfflineRezim ? Global.GuidEmpty : uuidv4(),
            sefo_datum: sefo_datum,
            sefo_nazev_zdroj: sefo_nazev_zdroj,
            sefo_popis: popisZadany,
            sevy_id: this.props.sevyData.sevy_id,
            vent_id: this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id,
            vent_vyrobni_cislo: this.state.vybraneSeveZarizeni.seve_zarizeni.vent_vyrobni_cislo
        }

        LoadingSpinner.open();

        fetchWrapper.post(!jeOfflineRezim ? fetchWrapper.NovyServisFoto() : fetchWrapper.NovyServisFotoOffline(), dataFoto)
            .then(data => {
                LoadingSpinner.close();

                const filename = data.sefo_nazev_zdroj;
                const aTag = document.createElement('a');

                aTag.href = file;
                aTag.download = data.sefo_nazev_zdroj;
                aTag.click();

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Foto bylo úspěšně zpracováno.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data:<br /> {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    onFiltrServisFotoVyjezdChecked() {
        const novyStav = !this.state.bServisFotoFiltrVyjezd;

        this.nacistServisFotoGallery(novyStav);
    }

    nacistServisFotoGallery(bFiltrVyjezd) {
        let vent_id = null;
        if (this.state.vybraneSeveZarizeni) vent_id = this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id;

        const att_datumOd = bFiltrVyjezd ? Global.toISOString(new Date(this.props.sevyData.sevy_datum_odjezd_prach)) : "1753-01-01T00:00:00";
        const att_datumDo = bFiltrVyjezd ? Global.toISOString(new Date(this.props.sevyData.sevy_datum_prijezd_prach)) : "1753-01-01T00:00:00";

        if (vent_id && vent_id != Global.GuidEmpty) {
            fetchWrapper.get(fetchWrapper.VratServisFotoGallery(vent_id, att_datumOd, att_datumDo))
                .then(data => {
                    LoadingSpinner.close();

                    if (data) {
                        for (var i = 0; i < data.length; i++) {
                            data[i].original = fetchWrapper.VratServisFotoGalleryOriginal(data[i].att_id);
                            data[i].thumbnail = fetchWrapper.VratServisFotoGalleryThumbnail(data[i].att_id);
                        }
                        this.setState({ zobrazenyPanel: "PROHLIZECFOTO", bServisFotoFiltrVyjezd: bFiltrVyjezd, servisFotoGallery: data });
                    }
                    else {
                        this.setState({ zobrazenyPanel: "PROHLIZECFOTO", bServisFotoFiltrVyjezd: bFiltrVyjezd, servisFotoGallery: [] });
                    }
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    smazatServisFotoPriloha = async (e, cell) => {

        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybranou fotografii?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result !== "ano") return;

        let vybranaFotoPrilohaIndex = -1;
        if (this.imgGalleryServisFoto) {
            vybranaFotoPrilohaIndex = this.imgGalleryServisFoto.getCurrentIndex();
        }

        if (vybranaFotoPrilohaIndex < 0 || this.state.servisFotoGallery.length <= vybranaFotoPrilohaIndex) {

            MessageBox.open({
                title: "Chyba",
                content: <p>Nepodařilo se vrátit aktuálně vybranou fotografii.</p>,
                buttons: [{ name: "OK", handler: () => "OK" }]
            });
            return;
        }

        const att_id = this.state.servisFotoGallery[vybranaFotoPrilohaIndex].att_id;

        LoadingSpinner.open();

        fetchWrapper.del(fetchWrapper.VymazServisFotoPriloha(att_id))
            .then(data => {
                LoadingSpinner.close();

                const aktualniServisFotoGallery = this.state.servisFotoGallery.filter(f => f.att_id != att_id);

                this.setState({ servisFotoGallery: aktualniServisFotoGallery });

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Vybraná fotografie byla úspěšně vymazána.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data:<br /> {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    zarizeniNovyZaznam = async (e, cell) => {
        this.otevritDialogZaznam("ZARIZENI", null);
    }

    zarizeniNovyZaznamKopie(e, cell) {
        this.otevritDialogZaznamKopie("ZARIZENI", cell.getRow().getData());
    }

    zarizeniUpravitZaznam = async (e, cell) => {
        const dataZarizeniEdit = cell.getRow().getData();

        this.otevritDialogZaznam("ZARIZENI", dataZarizeniEdit);
    }

    zarizeniSmazatZaznam = async (e, cell) => {
        const dataZarizeniEdit = cell.getRow().getData();
        console.log(dataZarizeniEdit);
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybrané zařízení {dataZarizeniEdit.seve_zarizeni.vent_vyrobni_cislo + " - " + dataZarizeniEdit.seve_zarizeni.vent_popis}?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") this.smazatZaznam("ZARIZENI", dataZarizeniEdit);
    }

    aktualizaceServisnihoListuPDF(fileData, bSchvaleno) {
        this.setState({ servisniListBlob: fileData, servisniListSchvaleno: bSchvaleno });
    }

    render() {
        let cellContextMenu = [];

        if (this.props.bAkce) {
            cellContextMenu = [
                {
                    label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                    action: this.zarizeniNovyZaznam
                },
                /*{
                    label: "<img src='./img/copy.png'/> <div class='customContextmenuArea3ListItem'>Kopie</div>",
                    action: this.zarizeniNovyZaznamKopie
                },*/
                {
                    label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                    action: this.zarizeniUpravitZaznam
                },
                {
                    label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                    action: this.zarizeniSmazatZaznam
                }
            ];
        }

        const tabServZarizeni = [
            {
                field: 'seve_zarizeni.vent_typ', title: 'Typ zařízení', width: 200, headerSort: true, contextMenu: cellContextMenu, formatter: function (cell, formatterParams, onRendered) {
                    if (cell.getValue() == 0) return "Ventilátor";
                    else return "Jiné";
                }
            },
            { field: 'seve_zarizeni.vent_vyrobni_cislo', title: 'Výr.číslo', width: 100, sorter: "number", headerSort: true, contextMenu: cellContextMenu },
            { field: 'seve_zarizeni.vent_popis', title: 'Typové označení', width: 400, headerSort: true, contextMenu: cellContextMenu },
            { field: 'seve_zarizeni.vent_popis_zarizeni', title: 'Popis zařízení', width: 400, headerSort: true, contextMenu: cellContextMenu },
            { field: 'seve_popis', title: 'Poznámka', width: 400, headerSort: true, contextMenu: cellContextMenu }
        ];

        const tabServListVyjezdy = [
            {
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerHozAlign: "center", headerSort: false, cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                }
            },
            {
                field: 'sevy_datum', title: 'Datum výjezdu', width: 130, headerSort: true, formatter: function (cell, formatterParams, onRendered) {

                    return Global.toDateStr(new Date(cell.getValue()));
                }
            },
            {
                field: 'sevy_stav', title: 'Stav', width: 130, headerSort: true, formatter: function (cell, formatterParams, onRendered) {
                    //cell - the cell component
                    //formatterParams - parameters set for the column
                    //onRendered - function to call when the formatter has been rendered
                    if (cell.getValue() == 0) return "Založený";
                    else if (cell.getValue() == 1) return "Připravený";
                    else if (cell.getValue() == 2) return "Dokončený";
                    return "-";
                }
            },
            { field: 'sevy_vedouci_technik_PrijmeniJmeno', title: 'Vedoucí', width: 200, headerSort: true },
            { field: 'semi_misto.semi_adresa', title: 'Místo servisu', width: 400, headerSort: true },
            { field: 'poza_cislo', title: 'Zakázka', width: 150, headerSort: true }
        ];

        const user = AuthService.getCurrentUser();
        const offlineRezim = CacheData.GetData(null, "offlinerezim");
        const jeOfflineRezim = offlineRezim && offlineRezim == 1;

        const bMoznostGenerovatServList = (user && user.uziv_money_prac_id == this.props.sevyData.sevy_vedouci_technik_id);

        return (
            <div>
                {this.state.bEditData && this.state.editDataTyp === "MATERIAL" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ServisVyjezdMaterialForm sevyData={this.props.sevyData} zarizeni={this.state.vybraneSeveZarizeni.seve_zarizeni} dataObject={this.state.editDataZaznamEdit} zavritDialog={this.zavritDialogZaznam} />
                        </div>
                        <div className="clear" />
                    </div>
                }

                {this.state.bEditData && this.state.editDataTyp === "SPECVYBAVENI" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ServisVyjezdPotrebneVybaveniForm sevyData={this.props.sevyData} zarizeni={this.state.vybraneSeveZarizeni.seve_zarizeni} dataObject={this.state.editDataZaznamEdit} zavritDialog={this.zavritDialogZaznam} />
                        </div>
                        <div className="clear" />
                    </div>
                }

                {this.state.bEditData && this.state.editDataTyp === "PRACE" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ServisVyjezdPraceForm sevyData={this.props.sevyData} zarizeni={this.state.vybraneSeveZarizeni.seve_zarizeni} dataObject={this.state.editDataZaznamEdit} zavritDialog={this.zavritDialogZaznam} />
                        </div>
                        <div className="clear" />
                    </div>
                }

                {this.state.bEditData && this.state.editDataTyp === "ODPRACHODINY" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ServisVyjezdOdpracovaneHodinyForm sevyData={this.props.sevyData} zarizeni={this.state.vybraneSeveZarizeni.seve_zarizeni} dataObject={this.state.editDataZaznamEdit} zavritDialog={this.zavritDialogZaznam} dataTechnici={this.props.sevyData.servisTechnici} />
                        </div>
                        <div className="clear" />
                    </div>
                }


                {this.state.bEditData && this.state.editDataTyp === "PROVHODN" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ZarizeniProvozniHodnotyForm sevyData={this.props.sevyData} zarizeni={this.state.vybraneSeveZarizeni.seve_zarizeni} dataObject={this.state.editDataZaznamEdit} dataObjectKopieZdroj={this.state.editDataZaznamKopieZdroj} zavritDialog={this.zavritDialogZaznam} dataTechnici={this.props.sevyData.servisTechnici} />
                        </div>
                        <div className="clear" />
                    </div>
                }

                {this.state.bEditData && this.state.editDataTyp === "ZARIZENI" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ServisovaneZarizeniForm sevyData={this.props.sevyData} dataObject={this.state.editDataZaznamEdit} dataObjectKopieZdroj={this.state.editDataZaznamKopieZdroj} zavritDialog={this.zavritDialogZaznam} dataTechnici={this.props.sevyData.servisTechnici} />
                        </div>
                        <div className="clear" />
                    </div>
                }





                <div className="odsazeni_bottom10" style={{ minHeight: 500, display: this.state.bEditData ? 'none' : 'block' }}>
                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                        <h1>Servisovaná zařízení&nbsp; &nbsp;  <button className="form_button" style={{ marginRight: 30 }} onClick={this.props.zavritDetail}>Zpět</button>
                            {this.props.bAkce && <button className="form_button" onClick={this.pridatZarizeni}>Nový záznam</button>}
                        </h1>
                        <ReactTabulator
                            onRef={(r) => (this.tabServZarizeni = r)}
                            data={this.props.sevyData.servisZarizeni}
                            columns={tabServZarizeni}
                            layout={'fitDataStretch'}
                            selectable={1}
                            selectablePersistence={true}
                            events={{ rowClick: this.tabServZarizeniRowClick, renderComplete: this.vybratVychoziZarizeni }}
                            initialSort={[{ column: "seve_zarizeni.vent_vyrobni_cislo", dir: "asc" }]}
                        />
                    </div>
                    <div className="clear" />

                    {this.props.sevyData.servisZarizeni.length > 0 &&
                        <>
                            <div className="grid_12 odsazeni_top20">
                                <button className={this.state.zobrazenyPanel === "PRACE" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="PRACE">Servisní práce</button>
                                <button className={this.state.zobrazenyPanel === "MATERIAL" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="MATERIAL">Spotřebovaný materiál</button>
                                <button className={this.state.zobrazenyPanel === "SPECVYBAVENI" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="SPECVYBAVENI">Speciální vybavení</button>
                                <button className={this.state.zobrazenyPanel === "ODPRACHODINY" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="ODPRACHODINY">Odpracované hodiny</button>
                                <button className={this.state.zobrazenyPanel === "PROVHODN" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="PROVHODN">Provozní hodnoty</button>
                                <button className={this.state.zobrazenyPanel === "VLASTNOSTI" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="VLASTNOSTI">Vlastnosti</button>
                                {!jeOfflineRezim && <button className={this.state.zobrazenyPanel === "PROHLIZECFOTO" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="PROHLIZECFOTO">Prohlížeč foto</button>}
                                <button className={this.state.zobrazenyPanel === "FOTO" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="FOTO">Foto</button>

                                {!jeOfflineRezim && bMoznostGenerovatServList && <button className={this.state.zobrazenyPanel === "SERVLIST" ? styles.button_sekce_sel : styles.button_sekce} onClick={this.zobrazitPanel} value="SERVLIST" disabled={this.state.isLoading}>Generovat servisní list</button>}
                                {this.state.zobrazenyPanel == "SERVLIST" &&
                                    <FormControl sx={{ minWidth: 90 }}>
                                        <Select value={this.state.servisniListJazyk} onChange={e => { this.setState({ servisniListJazyk: e.target.value }); this.generovatPDFServisniListDleJazyka(e.target.value, false) }} style={{ marginLeft: 5, marginTop: 5 }} size="small" inputProps={{ MenuProps: { disableScrollLock: true } }}>
                                            <MenuItem value="CS">ČJ</MenuItem>
                                            <MenuItem value="EN">AJ</MenuItem>
                                        </Select>
                                    </FormControl>}
                                {this.state.zobrazenyPanel == "SERVLIST" && <button className="form_button_img" style={{ marginLeft: 5, marginTop: 5, backgroundImage: "url(/img/refresh.png)" }} onClick={this.generovatPDFServisniList}></button>}
                            </div>
                            <div className="clear" />
                        </>
                    }




                    {this.state.zobrazenyPanel === "MATERIAL" && this.state.vybraneSeveZarizeni &&
                        <div className="grid_12">
                            <ServisVyjezdMaterialList
                                sevy_id={this.props.sevyData.sevy_id}
                                data={this.state.vybraneZarizeniMaterial}
                                otevritDialogZaznam={this.otevritDialogZaznam}
                                smazatZaznam={this.smazatZaznam}
                                bAkce={this.props.bAkce}
                            />
                        </div>
                    }

                    {this.state.zobrazenyPanel === "SPECVYBAVENI" && this.state.vybraneSeveZarizeni &&
                        <div className="grid_12">
                            <ServisVyjezdPotrebneVybaveniList
                                sevy_id={this.props.sevyData.sevy_id}
                                data={this.state.vybraneZarizeniSpecVybaveni}
                                otevritDialogZaznam={this.otevritDialogZaznam}
                                smazatZaznam={this.smazatZaznam}
                                bAkce={this.props.bAkce}
                            />
                        </div>
                    }

                    {this.state.zobrazenyPanel === "PRACE" && this.state.vybraneSeveZarizeni &&
                        <div className="grid_12">
                            <ServisVyjezdPraceList
                                sevy_id={this.props.sevyData.sevy_id}
                                data={this.state.vybraneZarizeniPrace}
                                otevritDialogZaznam={this.otevritDialogZaznam}
                                smazatZaznam={this.smazatZaznam}
                                bAkce={this.props.bAkce}
                            />
                        </div>
                    }

                    {this.state.zobrazenyPanel === "ODPRACHODINY" && this.state.vybraneSeveZarizeni &&
                        <div className="grid_12">
                            <ServisVyjezdOdpracovaneHodinyList
                                sevy_id={this.props.sevyData.sevy_id}
                                data={this.state.vybraneZarizeniOdpracovaneHodiny}
                                otevritDialogZaznam={this.otevritDialogZaznam}
                                smazatZaznam={this.smazatZaznam}
                                bAkce={this.props.bAkce}
                            />
                        </div>
                    }

                    {this.state.zobrazenyPanel === "PROVHODN" && this.state.vybraneSeveZarizeni &&
                        <div className='grid_12'>
                            <ZarizeniProvozniHodnotyList
                                sevyData={this.props.sevyData}
                                zarizeniData={this.state.vybraneSeveZarizeni.seve_zarizeni}
                                data={this.state.vybraneZarizeniProvHodn}
                                dataVyjezdu={this.state.vybraneZarizeniProvHodnVyjezdu}
                                otevritDialogZaznam={this.otevritDialogZaznam}
                                otevritDialogZaznamKopie={this.otevritDialogZaznamKopie}
                                smazatZaznam={this.smazatZaznam}
                                bAkce={this.props.bAkce}
                                bFiltrMereni={true} />
                        </div>
                    }

                    {this.state.zobrazenyPanel === "VLASTNOSTI" && this.state.vybraneSeveZarizeni &&
                        <>
                            <div className="grid_12" style={{ marginTop: 15 }}>
                                <div className="box round" style={{ position: "relative" }}>
                                    <h2>Servisní informace</h2>
                                    <ObjektDetailHodnotaRadek popis="Poznámka:" typ="TEXTAREA" hodnota={this.state.vybraneSeveZarizeni.seve_popis} />
                                    <ObjektDetailHodnotaRadek popis="Požadované úpravy:" typ="TEXTAREA" hodnota={this.state.vybraneSeveZarizeni.seve_opravy} />
                                    <ObjektDetailHodnotaRadek popis="Požadované opravy (doplněno technikem):" typ="TEXTAREA" hodnota={this.state.vybraneSeveZarizeni.seve_opravy_doplneno} />
                                    <ObjektDetailHodnotaRadek popis="Stav po dokončení prací:" typ="TEXTAREA" hodnota={this.state.vybraneSeveZarizeni.seve_konecny_stav} />
                                    <ObjektDetailHodnotaRadek popis="Předal:" hodnota={this.state.vybraneSeveZarizeni.seve_predal_PrijmeniJmeno} />
                                    <ObjektDetailHodnotaRadek popis="Převzal:" hodnota={this.state.vybraneSeveZarizeni.seve_prevzal} />
                                </div>
                            </div>
                            <ZarizeniDetail seveZarizeniData={this.state.vybraneSeveZarizeni} zarizeniData={this.state.vybraneSeveZarizeni.seve_zarizeni} zobrazitTlacitkoZpet={false} nadpis="Vlastnosti zařízení" bAkce={this.props.bAkce} zobrazitMereni={false} sevyData={this.props.sevyData} />
                        </>
                    }

                    {this.state.zobrazenyPanel === "PROHLIZECFOTO" && this.state.vybraneSeveZarizeni &&
                        <div className="grid_12" style={{ marginTop: 15 }}>
                            <FormControlLabel style={{ display: 'inline-flex' }}
                                name="filtrServisFotoVyjezd"
                                control={<Checkbox
                                    checked={this.state.bServisFotoFiltrVyjezd}
                                    onClick={this.onFiltrServisFotoVyjezdChecked} />}
                                label="Během aktuálního výjezdu" />
                            {this.state.servisFotoGallery && this.state.servisFotoGallery.length > 0 ?
                                <>
                                    <ImageGallery
                                        ref={(r) => (this.imgGalleryServisFoto = r)}
                                        items={this.state.servisFotoGallery}
                                        thumbnailPosition={'bottom'}
                                        showPlayButton={false}
                                        showFullscreenButton={true}
                                        slideOnThumbnailOver={true}
                                        lazyLoad={true}
                                    />

                                    <div className="odsazeni_top10">
                                        <button type="button" className="form_button" onClick={this.smazatServisFotoPriloha}>Vymazat fotografii</button>
                                    </div>
                                </>
                                :
                                <div>Žádné fotografie.</div>
                            }
                        </div>
                    }

                    {this.state.zobrazenyPanel === "FOTO" && this.state.vybraneSeveZarizeni &&
                        <div className="grid_12" style={{ marginTop: 15 }}>
                            <CameraFeed noveFoto={this.noveFoto} />
                        </div>
                    }

                    {this.state.zobrazenyPanel === "SERVLIST" && this.state.vybraneSeveZarizeni && this.state.servisniListVyjezdy && this.state.servisniListVyjezdy.length > 0 &&
                        <>
                            <div className="grid_12" style={{ display: 'block' }}>
                                <h2>Servisní výjezdy na zakázce: {this.props.sevyData.poza_cislo}</h2>
                                <ReactTabulator
                                    onRef={(r) => (this.tabServListVyjezdy = r)}
                                    data={this.state.servisniListVyjezdy}
                                    columns={tabServListVyjezdy}
                                    layout={'fitDataStretch'}
                                    initialSort={[{ column: "sevy_datum", dir: "asc" }]}
                                    events={{ renderComplete: this.vybratVyjezdyServisnihoListu }}
                                    style={{ maxHeight: 200 }}
                                />
                            </div>
                            <div className="clear" />
                        </>
                    }

                    {this.state.zobrazenyPanel === "SERVLIST" && this.state.vybraneSeveZarizeni && this.state.servisniListBlob &&
                        <ServisniListDokument sevyData={this.props.sevyData} aktualizaceDokumentuPDF={this.aktualizaceServisnihoListuPDF} dataDokumentPDF={this.state.servisniListBlob} servisniListJazyk={this.state.servisniListJazyk} servisniListVyjezdyVybraneIds={this.state.servisniListVyjezdyVybraneIds} servisniListSchvaleno={this.state.servisniListSchvaleno} />
                    }
                    <div className="clear" />
                </div>

            </div>
        );
    }
}
