import { format } from 'date-fns'


const GuidEmpty = "00000000-0000-0000-0000-000000000000";

function JeGuidValid(hodnota) {
    const bOK = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(hodnota);

    return bOK && hodnota != GuidEmpty;
}

function dateToStr(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds());
}

function toISOString(date) {

    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds())
        + "Z";

        /* místo Z na konci vloži UTC značku posun (+001 například)
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60)*/;
}

function textAreaOpravaPredUlozeni(text) {
    return text.replaceAll("\r\n", "\n").replaceAll("\n", "\r\n");
}

function toDateTimeStr(date) {

    if (date.getFullYear() <= 1753) return "";

    return format(date, "dd.MM.yyyy HH:mm:ss");
    
}

function toDateTimeStrBezSek(date) {

    if (date.getFullYear() <= 1753) return "";

    return format(date, "dd.MM.yyyy HH:mm");

}

function toDateStr(date) {

    if (date.getFullYear() <= 1753) return "";

    return format(date, "dd.MM.yyyy");

}

function VratRovnomerneRozdelenyCas(hodiny, minuty, delitel) {
    var minutCelkem = ((Number(hodiny) * 60) + Number(minuty));
    minutCelkem = minutCelkem / Number(delitel);

    return { hodiny: Math.floor(minutCelkem / 60), minuty: minutCelkem % 60 };
}

function removeNonNumericChars(text) {
    return text.replace(/^(-)|[^0-9.,]+/g, '$1');
}

function EnumJednotka(jednKod) {
    if (jednKod == 0) return "ks";
    else if (jednKod == 2) return "bm";
    else if (jednKod == 3) return "km";
    else if (jednKod == 5) return "set";
    else if (jednKod == 6) return "sada";
    else if (jednKod == 8) return "kplt";
    else if (jednKod == 11) return "l";
    else if (jednKod == 10) return "-";
    else if (jednKod == 12) return "mm";
    else if (jednKod == 13) return "paleta";
    else if (jednKod == 14) return "kg";
    else return "-";
}

function EnumJednotkaCiselnik() {
    return [
        { kod: 10, popis: "-" },
        { kod: 0, popis: "ks" },
        { kod: 14, popis: "kg" },
        { kod: 2, popis: "bm" },
        { kod: 12, popis: "mm" },
        { kod: 3, popis: "km" },
        { kod: 11, popis: "l" },
        { kod: 5, popis: "set" },
        { kod: 6, popis: "sada" },
        { kod: 8, popis: "kplt" },
        { kod: 13, popis: "paleta" },
    ];
}

function EnumProfilRemenice(profilKod) {
    if (profilKod == 0) return "SPA";
    else if (profilKod == 1) return "SPB";
    else if (profilKod == 2) return "SPC";
    else if (profilKod == 3) return "3V";
    else if (profilKod == 4) return "5V";
    else if (profilKod == 5) return "8V";
    else if (profilKod == 6) return "3L";
    else if (profilKod == 7) return "4L";
    else if (profilKod == 8) return "5L";
    else if (profilKod == 9) return "SPZ";
    else if (profilKod == 10) return "Nepřiřazeno";
    else if (profilKod == 11) return "A";
    else if (profilKod == 12) return "B";
    else if (profilKod == 13) return "C";
    else if (profilKod == 14) return "Z";
    else return "Neznámý";
}

function EnumProfilRemeniceCiselnik() {
    return [
        { kod: 0, popis: "SPA" },
        { kod: 1, popis: "SPB" },
        { kod: 2, popis: "SPC" },
        { kod: 3, popis: "3V" },
        { kod: 4, popis: "5V" },
        { kod: 5, popis: "8V" },
        { kod: 6, popis: "3L" },
        { kod: 7, popis: "4L" },
        { kod: 8, popis: "5L" },
        { kod: 9, popis: "SPZ" },
        { kod: 10, popis: "Nepřiřazeno" },
        { kod: 11, popis: "A" },
        { kod: 12, popis: "B" },
        { kod: 13, popis: "C" },
        { kod: 14, popis: "Z" },
    ];
}


function EnumZarizeniTyp(kod) {
    if (kod == 0) return "Ventilátor";
    else if (kod == 1) return "Jiný";
    else return "Neznámý";
}

function EnumZarizeniTypCiselnik() {
    return [
        { kod: 0, popis: "Ventilátor" },
        { kod: 1, popis: "Jiný" }
    ];
}


function EnumVentilatorTyp(kod) {
    if (kod == 0) return "Neznámý";
    else if (kod == 1) return "Axiální";
    else if (kod == 2) return "Radiální";
    else if (kod == 3) return "Speciální";
    else if (kod == 4) return "Plug";
    else if (kod == 5) return "Komorový";
    else return "Neznámý";
}

function EnumVentilatorTypCiselnik() {
    return [
        { kod: 0, popis: "Neznámý" },
        { kod: 1, popis: "Axiální" },
        { kod: 2, popis: "Radiální" },
        { kod: 3, popis: "Speciální" },
        { kod: 4, popis: "Plug" },
        { kod: 5, popis: "Komorový" },
    ];
}


function EnumSpojkaProvedeni(kod) {
    if (kod == 0) return "Nezadáno";
    else if (kod == 1) return "Lamelová";
    else if (kod == 2) return "Pružinová";
    else if (kod == 3) return "Čepová";

    else return "Neznámý";
}

function EnumSpojkaProvedeniCiselnik() {
    return [
        { kod: 0, popis: "Nezadáno" },
        { kod: 1, popis: "Lamelová" },
        { kod: 2, popis: "Pružinová" },
        { kod: 3, popis: "Čepová" },
    ];
}

function EnumVentilatorUsporadani(kod) {
    if (kod == 0) return "Na spojku";
    else if (kod == 1) return "Na řemen";
    else if (kod == 2) return "Napřímo";

    else return "Neznámý";
}

function EnumVentilatorUsporadaniCiselnik() {
    return [
        { kod: 0, popis: "Na spojku" },
        { kod: 1, popis: "Na řemen" },
        { kod: 2, popis: "Napřímo" },
    ];
}


function EnumSkupinaServisnichPraci(kod) {
    if (kod == 0) return "Nezadáno";
    else if (kod == 1) return "Ventilátor";
    else if (kod == 2) return "Motor";
    else if (kod == 3) return "Ložiska ventilátoru";
    else if (kod == 4) return "Rotor";
    else if (kod == 5) return "Spojka";
    else if (kod == 6) return "Řemenový převod";

    else if (kod == 7) return "Základový rám";
    else if (kod == 8) return "Pružné uložení";
    else if (kod == 9) return "Ucpávka hřídele";
    else if (kod == 10) return "Klapka / Regulační ústrojí";
    else if (kod == 11) return "Servopohon";
    else if (kod == 12) return "Akustický kryt";
    else if (kod == 13) return "Mazací stanice";
    else return "Neznámá";
}

function EnumSkupinaServisnichPraciCiselnik() {
    return [
        { kod: 0, popis: "Nezadáno" },
        { kod: 1, popis: "Ventilátor" },
        { kod: 2, popis: "Motor" },
        { kod: 3, popis: "Ložiska ventilátoru" },
        { kod: 4, popis: "Ložiska motoru" },
        { kod: 5, popis: "Spojka" },
        { kod: 6, popis: "Řemen" },
    ];
}




function VratEnumUsporadani(usporadaniStr)
{
    usporadaniStr = usporadaniStr.trim().toLowerCase();
    let usporadaniEnum = -1;

    if (usporadaniStr.includes("arr.4")
        || usporadaniStr.includes("arr. 4")
        || usporadaniStr.includes("na přímo")
        || usporadaniStr.includes("napřímo")) {
        usporadaniEnum = 2;
    }
    else if (usporadaniStr.includes("arr.7")
        || usporadaniStr.includes("arr. 7")
        || usporadaniStr.includes("arr.8")
        || usporadaniStr.includes("arr. 8")
        || usporadaniStr.includes("na spojku")) {
        usporadaniEnum = 0;
    }
    else if (usporadaniStr.includes("arr.1")
        || usporadaniStr.includes("arr. 1")
        || usporadaniStr.includes("arr.3")
        || usporadaniStr.includes("arr. 3")
        || usporadaniStr.includes("arr.9")
        || usporadaniStr.includes("arr. 9")
        || usporadaniStr.includes("arr.10")
        || usporadaniStr.includes("arr. 10")
        || usporadaniStr.includes("na řemen")) {
        usporadaniEnum = 1;
    }

    return usporadaniEnum;
}

function VratUsporadaniKorekce(usporadaniStr) {
    let usporadaniStrEdit = usporadaniStr.trim().toLowerCase();

    if (usporadaniStrEdit.includes("napřímo")) {
        return "Napřímo";
    }
    else if (usporadaniStrEdit.includes("na spojku")) {
        return "Na spojku";
    }
    else if (usporadaniStrEdit.includes("na řemen")) {
        return "Na řemen"
    }

    return usporadaniStr;
}


function EnumMistoMereni(kod) {
    if (kod == 0) return "Neznámé";
    else if (kod == 1) return "Kancelář";
    else if (kod == 2) return "Dílna";
    else if (kod == 3) return "Stavba";

    else return "Neznámé";
}

function EnumMistoMereniCiselnik() {
    return [
        { kod: 0, popis: "Neznámé" },
        { kod: 1, popis: "Kancelář" },
        { kod: 2, popis: "Dílna" },
        { kod: 3, popis: "Stavba" },
    ];
}

function EnumTypUlozeni(kod) {
    if (kod == "Nezadáno") return "Nezadáno";
    else if (kod == "Pevné") return "Pevné";
    else if (kod == "Pružné") return "Pružné";

    else return "Nezadáno";
}

function EnumTypUlozeniCiselnik() {
    return [
        { kod: "Nezadáno", popis: "Nezadáno" },
        { kod: "Pevné", popis: "Pevné" },
        { kod: "Pružné", popis: "Pružné" },
    ];
}

function EnumStaty(kod) {
    if (kod == "Nezadáno") return "Nezadáno";
    else if (kod == "Pevné") return "Pevné";
    else if (kod == "Pružné") return "Pružné";

    else return "Nezadáno";
}

function EnumStatyCiselnik() {
    return [
        { kod: "Česká republika", popis: "Česká republika" },
        { kod: "Francie", popis: "Francie" },
        { kod: "Itálie", popis: "Itálie" },
        { kod: "Maďarsko", popis: "Maďarsko" },
        { kod: "Německo", popis: "Německo" },
        { kod: "Polsko", popis: "Polsko" },
        { kod: "Rakousko", popis: "Rakousko" },
        { kod: "Slovensko", popis: "Slovensko" },
        { kod: " ", popis: "Ostatní" },
    ];
}

function EnumOfflineStav(kod) {
    if (kod == 0) return "-";
    else if (kod == 1) return "Nový";
    else if (kod == 2) return "Editovaný";
    else if (kod == 3) return "Vymazaný";

    else return "-";
}

export const Global = {
    toISOString,
    toDateTimeStr,
    toDateStr,
    VratRovnomerneRozdelenyCas,
    removeNonNumericChars,
    EnumProfilRemenice,
    EnumProfilRemeniceCiselnik,
    EnumZarizeniTyp,
    EnumZarizeniTypCiselnik,
    EnumVentilatorTyp,
    EnumVentilatorTypCiselnik,
    EnumSpojkaProvedeni,
    EnumSpojkaProvedeniCiselnik,
    EnumVentilatorUsporadani,
    EnumVentilatorUsporadaniCiselnik,
    EnumSkupinaServisnichPraci,
    EnumSkupinaServisnichPraciCiselnik,
    VratEnumUsporadani,

    VratUsporadaniKorekce,

    EnumTypUlozeniCiselnik,
    EnumTypUlozeni,
    EnumMistoMereniCiselnik,
    EnumMistoMereni,
    EnumOfflineStav,

    EnumStatyCiselnik,

    toDateTimeStrBezSek,
    textAreaOpravaPredUlozeni,
    EnumJednotka,
    EnumJednotkaCiselnik,
    JeGuidValid,
    dateToStr,

    GuidEmpty
};

export default Global;



